import React, { useEffect, useContext, useState } from "react";
import Header from "../../../taskTracker/commonComponent/Header";
import SideBar from "../../Clients/component/SideBar";
import style from "./ResourcesAllocationOnsite.module.css";
import TableRow from "./TableRow";
import FilterListIcon from "@mui/icons-material/FilterList";
import constants, { rmsAccessCode } from "../../../../Utils/constants";
import axios from "axios";
import { useHistory } from "react-router-dom";
import loaderImg from "../../../../assets/loader.gif";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import { customSearch } from "../../Clients/component/style";
import searchIcon from "../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import milestone from "../../../../assets/milestone.png";
import Pagination from "../../CommonComponent/Pagination";
import rightTickPRM from "../../../../assets/rightTickPRM.png";
import Filter from "../../components/Filter";
import { api } from "../../CommonApi/api";
import { get } from "../../CommonApi/axiosCall";
import { DataContext } from "../../CommonComponent/useContext/DataContext";
import cookie from "react-cookies";
import { useMutation, useQuery } from "react-query";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { getEmployeeList1 } from "../../../../Utils/axios/clientContractApi";
import { notifyError, notifySuccess } from "../../../atsDashboard/utils/notify";
import { addToPool } from "../../../../Utils/axios/rmsApi";
import PoolResourcesDownload from "./PoolResourcesDownload";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
const ResourcesAllocationOnsite = (props) => {
  const history = useHistory();
  const [downLoadData, setDownloadData] = useState(0);
  const { data, setData } = useContext(DataContext);
  const baseUrl = constants.apiBaseUrlResource;
  const [partnerName, setParnername] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [applied, setApplied] = useState(
    false
  );
  const [OnsiteData, setOnsiteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    1
  );
  const [pageCount, setpageCount] = useState(0);
  const [totalResourceOnsite, setTotalResourceOffShore] = useState(0);
  const [clientListing, setClientListing] = useState([]);
  const [months, setMonths] = useState([]);
  const [empListing, setEmpListing] = useState([]);
  const [salesPersonList, setSalesPersonList] = useState([]);
  const [onsiteSkills, setOnsiteSkills] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [action, setAction] = useState([]);
  const [clientId, setClientId] = useState([]);
  const [show, setShow] = useState(false);
  const [employeeId, setEmployeeId] = useState([]);
  const [ClientInformedReason, setReason] = useState("");
  const [skills, setSkills] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [filterName, setFilterName] = useState(
    {}
  );
  const [filterEmp, setFilterEmp] = useState(
    {}
  );
  const [allData, setAllData] = useState(
    data?.ResourcesAllocationOnsite
      ? data?.ResourcesAllocationOnsite
      : {
        action: [1],
        clientId: [],
        employeeId: [],
        skill: [],
        salaryRange: [],
        partners: [],
        startDateMonth: [],
        endDateMonth: [],
        employeeStatus: [null, null],
        quaterlyEndOfDeployment: [],
        quaterlyDeployment: [],
        handleFilter: false,
        partnerId: [],
        salesPersonId: [],
        isForcedAddedToPool: null,
        sortBy: "",
        sortOrder: "",
      }
  );
  const { checkValidateAccess } = useValidationAccess();
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const addToPoolResourceMutate = useMutation(addToPool, {
    onSuccess: (res) => {
      if (res?.data?.responseStatus?.statusCode === 0) {
        notifySuccess(res?.data?.responseData)
      } else {
        notifyError(res?.data?.responseData?.message)
      }
    },
    onError: (err) => {
      notifyError(err)
    }
  })

  const { data: getSalesPersonList } = useQuery(["getEmployeeLis1t"], () =>
    getEmployeeList1()
  );

  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code":
          setAllData({ ...allData, clientId: [e?.value] });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        case "Code1":
          setAllData({ ...allData, employeeId: [e?.value] });
          setFilterEmp({ ...filterEmp, positionCode: e?.label });
          break;
        case "Code2":
          setAllData({ ...allData, partnerId: [e?.value] });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        default:
      }
    }
  };

  const getOffshorePartner = () => {
    let baseURL = api.getonsitePartnerList;
    get(baseURL)
      .then((res) => {
        setParnername(res.data.responseData);
      })
      .catch(() => { });
  };

  const HistoryHandler1 = () => {
    history.push("/onSiteReportDashBoard");
  };

  empListing?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const getClientList = clientListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getemployee = empListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getAddResourceOnsiteData = (clearData, currentPage) => {
    const filterData = clearData?.clientId?.length == 0 ? allData : allData;
    if (filterData?.action.length > 1 && filterData?.action[0] === 1) {
      filterData.action.shift();
    }
    let url = api.getOnsiteResourceList;
    if (limit) {
      url += `?limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}`;
    }
    console.log("filterData", filterData);

    setIsLoaded(true);
    axios
      .post(
        baseUrl +
        "/getAddResourceOnsite?" +
        "limit=" +
        limit +
        "&page=" +
        currentPage,
        filterData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: null,
          },
        }
      )
      .then((res) => {
        setIsLoaded(false);
        setOnsiteData(res.data.responseData);
        setTotalResourceOffShore(res.data.responseData.totalResources);
        setpageCount(res.data.responseData.totalResources / 10);
        setDownloadData(res.data.responseData.resources);
      })
      .catch(() => {
        setIsLoaded(false);
      });
  };

  const handleAddResources = () => {
    history.push("/AddResourcesAllocationOnsite");
  };
  const getClientListing = () => {
    let url = api.getOnsiteClientList;

    get(url)
      .then((res) => {
        setClientListing(res.data.responseData);
      })
      .catch(() => { });
  };

  const getSlaesPersonList = () => {
    let url = api.getOnsiteClientList;

    get(url)
      .then((res) => {
        setClientListing(res.data.responseData);
      })
      .catch(() => { });
  };
  const getMonths = () => {
    axios
      .get(baseUrl + "/getMonthDetails", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setMonths(res.data.responseData);
      })
      .catch(() => { });
  };

  const getEmpListing = () => {
    let url = api.getOnsiteEmployeeList;

    get(url)
      .then((res) => {
        setEmpListing(res.data.responseData.employees);
      })
      .catch(() => { });
  };

  const getOnsiteSkills = () => {
    let url = api.getOnsiteFilterSkillData;
    get(url)
      .then((res) => {
        setOnsiteSkills(res.data.responseData);
      })
      .catch(() => { });
  };

  const limit = 10;

  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const closeFilter = () => {
    setCurrentPage(1);
    setAllData((preValue) => {
      return preValue
        ? {
          ...preValue,
          action: [1],
          clientId: allData?.clientId,
          employeeId: allData?.employeeId,
          skill: [],
          salaryRange: [],
          partners: [],
          salesPersonId: [],
          startDateMonth: [],
          endDateMonth: [],
          quaterlyEndOfDeployment: [],
          quaterlyDeployment: [],
          employeeStatus: [null, null],
          handleFilter: false,
          isForcedAddedToPool: null
        }
        : null;
    });
    setAction([]);
    setClientId([]);
    setEmployeeId([]);
    setSkills([]);
    setShowFilter(false);
    setApplied(false);
  };
  useEffect(() => {
    setData({ ...data, ResourcesAllocationOnsite: allData });
  }, [allData]);
  useEffect(() => {
    if (data?.ResourcesAllocationOnsite?.handleFilter) {
      setApplied(true);
    }
  }, []);
  const handleApplied = (arg) => {
    setAllData({ ...allData, handleFilter: true });
    if (
      action?.length !== 0 ||
      clientId?.length !== 0 ||
      employeeId?.length !== 0 ||
      skills?.length !== 0 ||
      allData.salesPersonId.length !== 0 ||
      allData?.salaryRange?.length !== 0 ||
      allData?.partners?.length !== 0 ||
      allData?.employeeStatus?.length !== 0 ||
      allData?.startDateMonth?.length !== 0 ||
      allData?.endDateMonth?.length !== 0
    ) {
      setApplied(true);
    }
    setShowFilter(false);
    setCurrentPage(1);
    getAddResourceOnsiteData(allData, 1);
  };

  const handleSort = (field) => {
    setAllData((prev) => ({
      ...prev, sortBy: field,
    }));
    if (allData.sortBy !== field) {
      setAllData((prev) => ({
        ...prev, sortOrder: "ASC",
      }));
      return;
    }
    if (allData.sortOrder === "ASC") {
      setAllData((prev) => ({
        ...prev, sortOrder: "DESC",
      }));
    } else {
      setAllData((prev) => ({ ...prev, sortOrder: "ASC", }));
    }
  };

  const renderSortArrow = (field) => {
    if (allData.sortBy !== field) return null;

    return allData.sortOrder === "ASC" ? (
      <TiArrowSortedUp
        style={{
          zIndex: -1,
          fontSize: "1.1rem",
          cursor: "pointer",
          color: "blue",
        }}
      />
    ) : (
      <TiArrowSortedDown
        style={{
          zIndex: -1,
          fontSize: "1.1rem",
          cursor: "pointer",
          color: "blue",
        }}
      />
    );
  };

  useEffect(() => {
    getAddResourceOnsiteData(allData, currentPage);
  }, [currentPage, allData, addToPoolResourceMutate?.isSuccess]);

  useEffect(() => {
    // getUserData();
    getMonths();
    getClientListing();
    getEmpListing();
    getOnsiteSkills();
    getOffshorePartner();
  }, []);
  const getPartnerList = partnerName?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const value1 = allData?.employeeId[0]
    ? getemployee?.find((e) => e.value === allData.employeeId[0])
    : null;
  const value2 = allData?.clientId[0]
    ? getClientList?.find((e) => e.value === allData.clientId[0])
    : null;
  const value3 = allData?.partnerId[0]
    ? getPartnerList?.find((e) => e.value === allData.partnerId[0])
    : null;
  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <SideBar />

        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>

            <div className={style.second_child}>
              <div className={style.title}>Resource (Onsite)</div>
              <BootstrapTooltip title="Allocate Resource">
                {checkValidateAccess(rmsAccessCode?.allocate_resource_onsite) && <span
                  className={style.plus}
                  onClick={() => handleAddResources()}
                >
                  <span style={{ color: "rgb(252,187,28)", cursor: "pointer" }}>
                    <ControlPointIcon
                      style={{ width: "3rem", height: "2rem" }}
                    />
                  </span>
                </span>}
              </BootstrapTooltip>
              <div style={{ width: "5%" }}>
                <p className={style.count}>
                  <span
                    className={style.backColor1}
                    style={{ fontSize: "1.235rem", fontWeight: "500" }}
                  >
                    {totalResourceOnsite}
                  </span>
                </p>
              </div>
              <div className={style.mainFilter}>
                <button className={style.filter_btn} onClick={handleFilter}>
                  {applied && (
                    <img className={style.appliedcheck} src={rightTickPRM} />
                  )}
                  {<FilterListIcon style={{ marginRight: "10px" }} />}
                  Filter
                </button>
              </div>

              <div style={{ width: "8%" }} aria-id="PM0001">
                <p className={style.download}>
                  <span
                    className={style.backColor1}
                    style={{
                      fontSize: "2rem",
                      fontWeight: "500",
                      cursor: "pointer",
                      color: "#a15e5e",
                    }}
                  >
                    <PoolResourcesDownload total={totalResourceOnsite} allData={allData} />
                  </span>
                </p>
              </div>

              <div
                className={style.s1}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.clientId?.length == 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        clientId: [],
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Client Name"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={value2}
                  onChange={(e) => {
                    onSearchHandler(e, "Code");
                    setShowFilter(false);
                    setCurrentPage(1);
                  }}
                  options={getClientList}
                />
              </div>
              <div
                className={style.s2}
                // style={{ width: "26%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.employeeId?.length == 0 ? (
                  <span className={style.cp_serchIcon2}>
                    <img src={searchIcon} />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon2}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        employeeId: [],
                      });
                      setFilterEmp({ ...filterEmp, positionCode: null });
                    }}
                  >
                    {allData?.employeeId?.length != 0 ? (
                      <AiOutlineClose />
                    ) : null}
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Employee Name/Code"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={value1}
                  onChange={(e) => {
                    onSearchHandler(e, "Code1");
                    setCurrentPage(1);
                    setShowFilter(false);
                  }}
                  options={getemployee}
                />
              </div>
              <div
                className={style.s1}
                style={{ width: "18%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.partnerId?.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="img" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        partnerId: [],
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Partner"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={value3}
                  onChange={(e) => {
                    onSearchHandler(e, "Code2");
                    setCurrentPage(1);
                    setShowFilter(false);
                  }}
                  options={getPartnerList}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
            <input
              type="checkbox"
              style={{ accentColor: "green", visibility: "visible", width: "1.1rem", height: "1.1rem" }}
              defaultChecked={allData?.isForcedAddedToPool}
              id="removedFromPool"
              onClick={() => { allData?.isForcedAddedToPool && setCurrentPage(1); setAllData(prev => ({ ...prev, isForcedAddedToPool: prev.isForcedAddedToPool === null ? true : null })) }}
            />
            <label htmlFor="removedFromPool" className={style.main} style={{ fontWeight: "600", color: "black" }}>

              Added To Pool
            </label>
          </div>

          <div
            onClick={() => {
              setShowFilter(false);
            }}
            className={style.onsiteList}
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : OnsiteData.resources?.length > 0 ? (
              <table style={{ fontSize: "12px", border: "none" }} className={style.resourceOnSiteTable}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "100px" }}>
                    <th style={{ padding: "10px" }}>Code</th>
                    <th style={{ padding: "10px" }}>Clients Name</th>
                    <th style={{ padding: "10px" }}>Name</th>
                    <th style={{ padding: "10px" }}></th>
                    <th style={{ padding: "10px" }}>Employer</th>
                    <th style={{ padding: "10px" }}>Sales Person</th>
                    <th style={{ padding: "10px" }}>Exp</th>
                    <th style={{ padding: "10px" }}>Salary Range</th>
                    <th style={{ padding: "10px" }}>Technology</th>
                    <th style={{ padding: "10px" }}>
                      {" "}
                      Assignment
                      <br /> Status
                    </th>
                    <th style={{ padding: "10px", cursor: allData?.action?.includes(4) ? "pointer" : "", textDecoration: allData?.action?.includes(4) ? "underline" : "" }} onClick={()=> allData?.action?.includes(4) && handleSort("allocation_date")}>Start Date {allData?.action?.includes(4) && renderSortArrow("allocation_date")}</th>
                    <th style={{ padding: "10px" }}>
                      Deployment
                      <br /> Quarter
                    </th>
                    <th style={{ padding: "10px", cursor: allData?.action?.includes(4) ? "pointer" : "", textDecoration: allData?.action?.includes(4) ? "underline" : "" }} onClick={()=> allData?.action?.includes(4) && handleSort("deallocation_date")}>End Date {allData?.action?.includes(4) && renderSortArrow("deallocation_date")}</th>
                    <th style={{ padding: "10px" }}>
                      {" "}
                      Expected <br />
                      End Date
                    </th>
                    <th style={{ padding: "10px" }}>
                      Last Comment
                      <br /> Date
                    </th>
                    <th style={{ padding: "10px" }}>
                      Client Notification
                      <br /> Date
                    </th>
                    <th style={{ padding: "10px" }}>
                      Partner
                      <br /> Informed
                    </th>
                    <th style={{ padding: "10px" }}>
                      Partner
                      <br /> Informed Date
                    </th>
                    <th style={{ padding: "10px" }}>
                      Candidate
                      <br /> Informed Date
                    </th>
                    <th style={{ padding: "10px" }}>
                      Client
                      <br /> Informed
                    </th>
                    <th style={{ padding: "10px" }}>
                      {" "}
                      Estimated Client
                      <br /> Informed Date
                    </th>
                    <th style={{ padding: "10px" }}>
                      {" "}
                      Client Notification
                      <br /> Reason
                    </th>
                    {checkValidateAccess(rmsAccessCode?.onsite_action_column) && <th style={{ padding: "10px" }}>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {OnsiteData?.resources.map((val) => {
                    return (
                      <TableRow
                        applied={applied}
                        currentPage={currentPage}
                        allData={allData}
                        filterName={filterName}
                        filterEmp={filterEmp}
                        setShow={setShow}
                        setReason={setReason}
                        val={val}
                        addToPoolResourceMutate={addToPoolResourceMutate}
                      />
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={style.noData}>
                <img src={milestone} />
                <h3 className={style.noData_header}>No data available</h3>
              </div>
            )}

            {/* } */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <button className={style?.gotoListBtn} onClick={HistoryHandler1}>
              Back
            </button>
            {totalResourceOnsite > limit && pageCount > 0 && (
              <div
                className={style.paginationmobileContainer}
                style={{ marginTop: "10px" }}
              >
                <Pagination
                  pagesCount={pageCount}
                  pageSize={limit}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          filterData={allData}
          type={"onSite"}
          months={months}
          empListing={empListing}
          salesPersonListing={getSalesPersonList}
          clientListing={clientListing}
          setAction={setAction}
          setEmployeeId={setEmployeeId}
          setClientId={setClientId}
          setSkills={setSkills}
          onsiteSkills={onsiteSkills}
          setAllSelected={setAllSelected}
        />
      )}
      <Modal
        open={show}
        center
        onClose={() => setShow(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container}>{ClientInformedReason}</div>
      </Modal>
    </>
  );
};

export default ResourcesAllocationOnsite;

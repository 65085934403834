import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import classes from "../CreateList/CreateList.module.css";
import { useMutation, useQuery } from "react-query";
import { createList, createPartner, getPartnerTypeList } from "../../../../Utils/axios/PortalManagement";
import cancel from "../../../../assets/cancell.png";
import calender from "../../../../assets/calblue.png"
import Select from "react-select";
import { InvoiceStyles4, InvoiceStyles5 } from "../../utils/style";
import dropdownIcon from "../../../../assets/downIcon.png";
import { urlValidation } from "../../../atsDashboard/utils/validation";
import { ToastifyError } from "../../../../App";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import {
  getCityList,
  getStateList,
  addViewCity,
  getWorkModeList,
} from "../../../../Utils/axios/PortalManagement";
import { employeeId } from "../../../../Utils/constants";
import { getLeadLocationList, getLeadSourceList, getLeadStatusList, getPartnerRecruiterList, getPartnerRecruiterList1 } from "../../../../Utils/axios/api";
import { notifySuccess } from "../../../atsDashboard/utils/notify";
const CreatePartner = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPag,
}) => {

  const [formData, setFormData] = useState({
    companySize: null,
    partnerName: "",
    id: null,
    linkedInUrl: "",
    locationId: null,
    partnerName: "",
    sourceId: null,
    // sourceOfLead: "",
    statusId: null,
    talentAcquisitionId: null
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const baseUrl = process.env.REACT_APP_PARTNER_MANAGEMENT;
  const mutation = useMutation(createList, {
    onSuccess: (res) => {
      notifySuccess("Partner Lead Created Successfully")
      setPrevData((prevData) => ({
        ...prevData,
        CreateId: res?.id,
      }));

      setNextScreen("ViewCreateProject");
    },
  });
  const [validate, setValidate] = React.useState(false);
  const [validate1, setValidate1] = React.useState(false);

  const [show, setShow] = useState(false);
  const [AddCity, setAddCity] = useState("");


  const handleAdd = () => {
    if (AddCity === "") {
      setValidate1(true);
    } else {
      setValidate1(false);
      setShow(false);
    }
  };

  const handleAddCancle = () => {
    setShow(false);
  };

  const handleShow = (expenseId) => {
    setShow(true);
  };

  const handleCreatePartner = () => {
    if (
      formData?.partnerName.trim() &&
      // formData?.sourceOfLead.trim() &&
      formData?.companySize &&
      formData?.linkedInUrl.trim() &&
      formData?.locationId &&
      formData?.sourceId &&
      formData?.statusId &&
      formData?.talentAcquisitionId
    ) {
      setValidate(false);
      setIsSubmitting(true);
      mutation.mutate(formData, {
        onSettled: () => {
          setIsSubmitting(false);  
        },
      });
    } else {
      setValidate(true);
    }
  };
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick} onKeyDown={props.onClick} tabIndex={0}>
        <label ref={ref}>{props.value || props.placeholder}</label>
        <img
          src={calender}
          style={{ position: "absolute", right: "5%", top: "13%" }}
          alt="calender"
        />
      </div>
    );
  });

  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          alt="cancel"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
          onKeyDown={() => setFormData({ ...formData, [props?.properties]: "" })}
          tabIndex={0}
        />
      </div>
    );
  });


  const { data: statusList } = useQuery(
    ["getLeadStatusList"],
    getLeadStatusList
  );

  const statusOptions = statusList?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const { data: sourceList } = useQuery(
    ["getLeadSourceList"],
    getLeadSourceList
  );

  const sourceOptions = sourceList?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));


  const { data: locationList } = useQuery(
    ["getLeadLocationList"],
    getLeadLocationList
  );

  const locationOptions = locationList?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));


  const { data: recruiterList } = useQuery(
    ["getPartnerRecruiterList1"],
    getPartnerRecruiterList1
  );

  const recruiterOptions = recruiterList?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const linkendInUrl = (value) => {
    if (!value) {
      return true;
    } else if (urlValidation(value)) {
      return true;
    } else {
      return false;
    }
  };

  const handleInputClick = (e) => {
    e.target.addEventListener("mousewheel", (ev) => {
      e.target.blur();
    });
  };

  const handleKeyDown = (e) => {
    // Prevent 'e' from being entered
    if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h6 style={{ marginTop: "15px", marginLeft: "25px" }}>
              Create Partner Lead
            </h6>
          </Col>
        </Row>
        <InvoiceContainer>
          <Row>
            <Col md={4}>
              <p className={classes.label}>
                Partner Name <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    partnerName: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.partnerName.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Partner Name
              </p>
            </Col>
            {/* <Col md={4}>
              <p className={classes.label}>
                Source Of Lead <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    sourceOfLead: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.sourceOfLead
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Source Of Lead
              </p>
            </Col> */}
            <Col md={4}>
              <p className={classes.label1}>
                TA <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={recruiterOptions}
                styles={InvoiceStyles5}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    talentAcquisitionId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.talentAcquisitionId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Select Talent Acquisition
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Status <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={statusOptions}
                styles={InvoiceStyles5}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    statusId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.statusId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Select Status
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Location <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={locationOptions}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    locationId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.locationId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Select Location
              </p>
            </Col>


            <Col md={4}>
              <p className={classes.label1}>
                Source <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={sourceOptions}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    sourceId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.sourceId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Select Source
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                LinkedIn Url <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    linkedInUrl: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate &&
                    (!formData?.linkedInUrl.trim() ||
                      !linkendInUrl(formData?.linkedInUrl))
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter LinkedIn Url
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                Company Size
                <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    companySize: e.target.value,
                  });
                }}
                onKeyDown={handleKeyDown}
                onClick={handleInputClick}
              />
              <p
                className={
                  validate && !formData?.companySize
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Company Size
              </p>
            </Col>


            <Col md={4}>

            </Col>
          </Row>

        </InvoiceContainer>
      </Container>

      <button className={classes.buttonAdd} onClick={handleCreatePartner}>
        Submit
      </button>
    </>
  );
};
export default CreatePartner;

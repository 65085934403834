import React, { useState, useEffect } from "react";
import style from "./OffShoreDesCription.module.css";
import Sidebar from "../../Clients/component/SideBar";
import cookie from "react-cookies";
import axios from "axios";
import TaskTable from "./TaskTable";
import Pagination from "../../CommonComponent/Pagination";
import TableRow from "../PoolResources/ResourceOnsiteDescription/TableRow";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import constants from "../../../../Utils/constants";
import Moment from "moment";
import Tooltip from '@mui/material/Tooltip';
import { get,post } from "../../CommonApi/axiosCall";
import { ToastifyError,Toastify } from "../../../../App";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ApplicationInformation from "../PoolResources/PoolResourcesComment/PoolComment";
import { Table } from "react-bootstrap";
import { api } from "../../CommonApi/api";
import { employeeId } from "../../../../Utils/constants";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import { rmsAccessCode } from "../../../../Utils/constants";

const ResourcesOffshoreDescription = (props) => {
  const { checkValidateAccess } = useValidationAccess();

  const [OfShoreData, setOfShoreData] = useState([]);
  const [disable] = useState(false);
  const [AddToggle, setAddToggle] = useState(false);
  const [ApplicationData, setApplicationData] = useState([]);
  const [applicationDetail, setApplicationDetail] = useState([]);
  const [show, setShow] = useState(false);
  const [commenttype, setCommentType] = useState("");
  const [commentID, setCommentID] = useState();
  const [addhandler, setAddHandler] = useState(false);
  const [myTaskDetails, setMyTaskDetails] = useState();
  const [editHandler, setEditHandler] = useState(false);
  const [pageCount, setpageCount] = useState(0);
  const [offshoreTotalTask, setoffshoreTotalTask] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const baseUrl = constants.apiBaseUrlResource;
  const id = props.history.location?.state?.id;

  const history = useHistory();
  const url2 = constants.apiBaseUrl2;

  const limit = 3;

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const EditHandler = () => {
    history.push(`/EditResourcesOffshore?id=${id}`);
  };

  const AddTaskhandler = () => {
    history.push({
      pathname: "/CreateTask",
      state: {
        task: OfShoreData,
        comment: "",
        page: 1,
      },
    });
  };
  const viewPerformance = () => {
    window.open(`/EmployeePerformanceReview?id=${OfShoreData.empid}`, "_blank");
  };
  // history.push(`/CreateTask?id=${1}`)

  const EditTask = (id1) => {
    history.push({
      pathname: "/OffShore/TaskDetails",
      state: {
        id: id1,
        id1: id,
      },
    });
  };
  const HistoryHandler = () => {
    history.push(`/OffshoreChangeHistory?id=${id}`);
  };
  const HistoryHandler1 = () => {
    history.push({
      pathname: "/resourceManagement",
      state: props.history.location?.state,
    });
    // history.push(`/resourceManagement`)
  };
  const MyTaskDetails = (id) => {
    axios
      .get(
        url2 +
          `/getTaskDetailsByEmpIdForRms?empId=${id}&limit=3&page=${currentPage}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setMyTaskDetails(res.data.responseData);
        setoffshoreTotalTask(res.data.responseData.resourceCount);
        setpageCount(res.data.responseData.resourceCount / 3);
      })
      .catch((err) => {
        return err;
      });
  };

  const getOfShoreDetails = (id) => {
    axios
      .get(baseUrl + `/getOffshoreResourceDetailsById?id=${id}&empId=${employeeId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setOfShoreData(res.data.responseData);
        getApplicationDetails(res.data.responseData.empid);
        MyTaskDetails(res.data.responseData.empid);
        setShow(false);
        setAddToggle(false);
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    getOfShoreDetails(id);
  }, [id, show, currentPage]);

  useEffect(() => {
    commentID && getSingleDetailsById(commentID);
  }, [show]);
  const getApplicationDetails = (id) => {
    axios
      .get(baseUrl + `/getAllPoolResourceComments?empId=${id}&limit=10`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setApplicationData(res.data.responseData);
      })
      .catch((err) => {
        return err;
      });
  };
  const getSingleDetailsById = (id) => {
    axios
      .get(baseUrl + `/getPoolResourceCommentsById?commentId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setApplicationDetail(res.data.responseData);
      })
      .catch((err) => {
        return err;
      });
  };

  const AddHandler = (id, type) => {
    setCommentType(type);
    setAddHandler(false);
    setEditHandler(true);
    setCommentID(id);
    if (id) {
      getSingleDetailsById(id);
    }
    window.scrollTo(0, 0);
    setAddToggle(!AddToggle);
  };

  const AddHandler1 = () => {
    setAddToggle(!AddToggle);
    setAddHandler(true);
    setEditHandler(false);
  };

  const BackHandler = () => {
    setAddToggle(!AddToggle);
  };

  const addWatchListt = (requesterId,resourceId) => {
    let url = api.addResouresWatchList;
    if (requesterId !== undefined && requesterId !== null) {
        url += `?requesterId=${requesterId}`;
    }
    if (resourceId !== undefined && resourceId !== null) {
        url += `&resourceId=${resourceId}`;
    }
        post(url)
        .then((res)=>{
if(res.data.responseStatus.statusCode===1){
  ToastifyError(res.data.responseData.message)
}else if(res.data.responseStatus.statusCode===0){
  Toastify(res.data.responseData)
}
        })
        .catch();
};
  return (
    <>
      <div className={style.main_client_body}>
        <Sidebar />

        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb} style={{ marginTop: "22px" }}>
              <span style={{ fontWeight: "700" }}>
                View{" "}
                <span className={style.arrow} style={{ fontWeight: "700" }}>
                  {" "}
                  {">"} Resource (Offshore)
                </span>{" "}
              </span>
              <Tooltip title="Add to Watchlist">
      <GroupAddIcon
        onClick={() => addWatchListt(employeeId, OfShoreData?.empid)}
        style={{ cursor: "pointer", color: "rgb(252, 187, 8)" }}
      />
    </Tooltip>
            </div>

            <div className={style.form_div}>
              <div id="create_task_id" className={style.gridContainer}>
                <div className={style.assignLabel} style={{ padding: "6px" }}>
                  <label>Employee Name </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.empName}
                  </p>
                </div>

                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label>Project </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.projectName}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label>Application </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.applcationName}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label>Technology </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.skillName}
                  </p>
                </div>

                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label>
                    <strong>%</strong> Allocation{" "}
                  </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.bandwidth === "Half" ? (
                      <td>50% </td>
                    ) : OfShoreData?.bandwidth === "ThreeFourth" ? (
                      <td>75% </td>
                    ) : OfShoreData?.bandwidth == "OneFourth" ? (
                      <td>25% </td>
                    ) : (
                      <td>100% </td>
                    )}
                  </p>
                </div>

                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label>Status</label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.allocationTypeName}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Project Start Date </label>{" "}
                  <br />
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {Moment(OfShoreData?.allocationdate).format("DD MMM YY")}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Project End Date </label> <br />
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {Moment(OfShoreData?.deallocationdate).format("DD MMM YY")}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Experience </label> <br />
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {~~(OfShoreData?.experience / 12)} Years{" "}
                    {OfShoreData?.experience % 12} Months
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Last Comment Date </label>{" "}
                  <br />
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.currentCommentDate
                      ? Moment(OfShoreData?.currentCommentDate).format(
                          "DD MMM YY"
                        )
                      : null}
                  </p>
                </div>

                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Emp Code </label> <br />
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.empCode}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Salary Range </label> <br />
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.salaryRange}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Additional Core Skill </label> <br />
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                  {OfShoreData?.additionalSkills
                          ?.map((e) => e.additionalSkillName)
                          .join(", ")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={style.container}>
            <div style={{ marginTop: "15px" }}>
              {" "}
              <button className={style.gotoListBtn} onClick={HistoryHandler1}>
                {" "}
                Go To List
              </button>
            </div>
            <div className={style.btn_div}>
                <button className={style.viewFeedback} onClick={viewPerformance} >
                  {" "}
                  View Feedback
                </button>
           
              {checkValidateAccess(rmsAccessCode?.ddd_task_offshore) && (
                <button className={style.addTaskBtn} onClick={AddTaskhandler}>
                  {" "}
                  <span className={style.plus}>+</span>Add Task
                </button>
              )}
              {checkValidateAccess(rmsAccessCode?.add_comment_offshore) && (
                <button className={style.editbtnn} onClick={AddHandler1}>
                  {" "}
                  <span className={style.plus}>+</span>Add Comment
                </button>
              )}

              {checkValidateAccess(rmsAccessCode?.offshore_history) && (
                <button
                  onClick={() => HistoryHandler()}
                  className={style.editbtnn}
                  disabled={disable}
                >
                  Offshore History
                </button>
              )}

              {checkValidateAccess(rmsAccessCode?.edit_offshore) && (
                <button
                  onClick={EditHandler}
                  className={style.editbtn}
                  disabled={disable}
                >
                  Edit
                </button>
              )}
            </div>
          </div>
          {AddToggle ? (
            <ApplicationInformation
              id={OfShoreData?.empid}
              commenttype={commenttype}
              historyy="offshorecomment"
              addhandler={addhandler}
              BackHandler={BackHandler}
              editHandler={editHandler}
              setShow={setShow}
              applicationDetail={applicationDetail}
            />
          ) : null}

          <div
            className={style.description_box1}
            style={{ marginBottom: "15px" }}
          >
            <div className={style.span2}>
              <p>My Task</p>
            </div>
            <div className={style.maindivContent}></div>
            <div
              className={style.original_table}
              style={{
                backgroundColor: "white",
                minHeight: "3rem",
                marginTop: "7px",
              }}
            >
              {myTaskDetails?.getAllData?.length > 0 ? (
                <Table borderless size="sm" style={{ fontSize: "12px" }}>
                  <thead
                    style={{
                      backgroundColor: "#F0F0F0",
                      position: "sticky",
                      top: "0px",
                    }}
                  >
                    <tr style={{ padding: "100px" }}>
                      <th style={{ padding: "25px" }}>Task Id</th>
                      <th style={{ padding: "25px" }}>Creation Date</th>
                      <th style={{ padding: "25px" }}>Task Title</th>
                      <th style={{ padding: "25px" }}>Assigned To</th>
                      <th style={{ padding: "25px" }}>Assigned By</th>
                      <th style={{ padding: "25px" }}>Status</th>
                      <th style={{ padding: "25px" }}>Priority</th>
                      <th style={{ padding: "25px" }}>Planned End Date</th>
                      <th style={{ padding: "25px" }}>Functional Area</th>
                    </tr>
                  </thead>
                  <tbody>
                    {myTaskDetails?.getAllData?.map((val) => {
                      return (
                        <TaskTable
                          clickHandler={EditTask}
                          data={val}
                          historyy={"offshore"}
                          id={id}
                          setShow={setShow}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <p className={style.p1} style={{ textAlign: "center" }}>
                  No Task Assigned
                </p>
              )}
            </div>
          </div>
          {offshoreTotalTask > 3 ? (
            <div style={{ marginBottom: "66px" }}>
              {offshoreTotalTask > limit && pageCount > 0 && (
                <div
                  className={style.paginationmobileContainer}
                  style={{ marginTop: "10px" }}
                >
                  <Pagination
                    pagesCount={pageCount}
                    pageSize={limit}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                  />
                </div>
              )}
            </div>
          ) : null}
          <div className={style.description_box1}>
            <div className={style.span2}>
              <p>Comment History</p>
            </div>
            <div className={style.maindivContent}></div>
            <div
              className={style.original_table}
              style={{
                backgroundColor: "white",
                minHeight: "3rem",
                marginTop: "7px",
              }}
            >
              {ApplicationData?.getAllData?.length > 0 ? (
                <Table borderless size="sm" style={{ fontSize: "12px" }}>
                  <thead
                    style={{
                      backgroundColor: "#F0F0F0",
                      position: "sticky",
                      top: "0px",
                    }}
                  >
                    <tr style={{ padding: "100px" }}>
                      <th style={{ padding: "25px" }}>Reported Date</th>
                      <th style={{ padding: "25px" }}>Username</th>
                      <th style={{ padding: "25px" }}>Comment</th>
                      <th style={{ padding: "25px" }}>Action</th>
                      {/* <th></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {ApplicationData.getAllData?.map((val) => {
                      return (
                        <TableRow
                          OfShoreData={OfShoreData}
                          clickHandler={AddHandler}
                          data={val}
                          historyy={"offshore"}
                          id={id}
                          setShow={setShow}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <p className={style.p1} style={{ textAlign: "center" }}>
                  No Comment
                </p>
              )}
            </div>
          </div>
        </div>

        <ToastContainer />
      </div>
    </>
  );
};

export default ResourcesOffshoreDescription;

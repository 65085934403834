import React, { useState, useEffect } from "react";
import style from "./CreateIssueCom.module.css";
import { customStyles } from "../../../commonComponent/style";
import Select from "react-select";
import axios from "axios";
import cookie from "react-cookies";
import { useHistory } from "react-router";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
import ClearIcon from "@mui/icons-material/Clear";
import dropdownIcon from "../../../../../assets/downIcon.png";
import { Toastify, ToastifyError } from "../../../../../App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import constants, { employeeId } from "../../../../../Utils/constants";
import { useQuery } from "react-query";
import { getUserAccess } from "../../../../../Utils/axios/expenceApi";
import useValidationAccess from "../../../../custumHooks/useValidationAccess";
import { hrHelpDesk } from "../../../../../Utils/constants";

export const CreateIssueComponent = () => {
  const [formData, setFormData] = useState({
    requestById: null,
    categoryId: 0,
    description: "",
    loggedDate: new Date(),
    raisedBy: employeeId,
    subCategoryId: 0,
    attachment: "",
  });

  const handleChange = (field, value) => {
    if (field === "categoryId") {
      setFormData({
        ...formData,
        [field]: value,
        subCategoryId: 0,
      });
    }else {
      setFormData({
        ...formData,
        [field]: value,
      });
    }
  };

  const source = axios.CancelToken.source();
  const { checkValidateAccess } = useValidationAccess();
  const [subCategary, setSubCategory] = useState();
  const [subcatlabel, setCategaryLavel] = useState();
  const [categary, setcategary] = useState();
  const [btnDisable, setButtonDisable] = useState(false);
  const [validate, setValidate] = useState(false);
  const [fileName, setFileName] = useState("");
    const [employeeList, setEmployeeList] = useState([]);

  const url = process.env.REACT_APP_HRHELPDESK_APP_URL;
  const url1 = constants.apiBaseUrl;
  const history = useHistory();

  const CustomInput = () => {
    return (
      <span>
        <img
          alt="dropdownIcon"
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };

  const getAllCategaryOption = categary?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  employeeList?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  const getEmployee = employeeList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getAllsubCategaryOption = subCategary?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  // Handle Redirect After Successfull Create Issue
  const handleRedirect = (id) => {
    setTimeout(() => {
      history.push(`/DoneIssue?id=${id}`);
    }, 1000);
  };

  // API Integration
  const {data:empAccess}=useQuery(["getUserAccess"],()=>getUserAccess())
  const getCategary = () => {
    axios
      .get(url + "/getCategoryList", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
        cancelToken: source.token,
      })
      .then((res) => {
        if (res.data.responseStatus?.statusCode === 1) {
          return;
        } else {
          setcategary(res.data.responseData);
        }
      })
      .catch((err) => {});
  };
  const getSubCategary = (id) => {
    axios
      .get(url + `/getSubCategoryListByCategoryId?categoryId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus?.statusCode === 1) {
          return;
        } else {
          setSubCategory(res.data.responseData);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getCategary();
    return () => {
      // Cancel the request when the component is unmounted
      source.cancel("Component is unmounted");
    };
  }, []);
  useEffect(() => {
    async function employeedata() {
      await axios
        .get(url1 + "/getEmployeeList", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        })
        .then((res) => {
          setEmployeeList(res.data.responseData.employeeList);
        })
        .catch((err) => {
          return err;
        });
    }
    employeedata();
  }, []);

  async function handleFileUpload(file) {
    setFileName(file.name);
    const formDataa = new FormData();
    formDataa.append("file", file);

    try {
      const response = await axios.post(url + "/uploadFile", formDataa);
      if (response.status === 200) {
        setFormData({ ...formData, attachment: response.data.responseData });
      } else {
        ToastifyError("File upload failed. Please try again.");
      }
    } catch (error) {
      ToastifyError(error.response?.data?.message || "An error occurred while uploading the file.");
    }
  }

  async function handleFileDeletion() {
    setFileName("");

    try {
      const response = await axios.put(
        url + `/deleteUploadFile?url=${fileName}`
      );
      if (response.status === 200) {
        setFileName("");
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  }

  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf />;
      case "docx":
        return <AiOutlineFileWord />;
    }
  };
  // cancel Button Handler

  const cancelHandler = () => {
    history.push("/MyIssue");
  };
  // formHAndler
  const formHAndler = (e) => {
    e.preventDefault();
    if (
      (formData?.categoryId === 8
        ? formData?.subCategoryId
        : !formData?.subCategoryId) ||
      !formData?.description?.trim() ||
      !formData?.categoryId
    ) {
      setValidate(true);
      return true;
    }
    setButtonDisable(true);
    axios
      .post(url + "/createIssue", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setButtonDisable(false);
        if (res.data.responseStatus?.statusCode === 1) {
          ToastifyError(res.message);
        } else {
          Toastify("Issue Created Successfully");
          handleRedirect(res.data.responseData?.id);
          setButtonDisable(true);
        }
      })
      .catch((err) => {
        setButtonDisable(false);
        return err;
      });
  };
  return (
    <>
      <ToastContainer />
      <div className={style.main}>
        <p className={style.createissue}>Create Issue</p>
        <div>
          <div className={style.card}>
            <p className={style.title}>Issue Details</p>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>
                  Category<span className={style.mandatory}>*</span>
                </p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  options={getAllCategaryOption}
                  onChange={(e) => {
                    handleChange("categoryId", e?.value);
                    if (e?.label !== "Other") {
                      getSubCategary(e?.value);
                    }
                  }}
                ></Select>
                <p
                  className={
                    validate && !formData?.categoryId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Category
                </p>
              </div>
              <div className={style.cu_col2}>
                {formData?.categoryId === 8 ? null : (
                  <div>
                    <p className={style.cu_label}>
                      Sub Category<span className={style.mandatory}>*</span>
                    </p>
                    <Select
                      styles={customStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      options={getAllsubCategaryOption}
                      value={
                        formData?.subCategoryId
                          ? {
                              value: formData?.subCategoryId,
                              label: subcatlabel,
                            }
                          : null
                      }
                      onChange={(e) => {
                        handleChange("subCategoryId", e?.value);
                        setCategaryLavel(e?.label);
                      }}
                    ></Select>
                    <p
                      className={
                        validate && !formData?.subCategoryId
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Sub Category
                    </p>
                  </div>
                )}
              </div>
              <div className={style.cu_col3}>
                {checkValidateAccess(hrHelpDesk?.hrHelpDeskRequestedBy) ? (
                  <>
                    <p className={style.cu_label}>Requested By</p>
                    <Select
                      styles={customStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      options={getEmployee}
                      onChange={(e) => {
                          handleChange("requestById", e?.value);
                        }}
                    ></Select>
                  </>
                ) : (null)}
              </div>
            </div>
            <div className={style.cu_col1} style={{ marginLeft: "21px" }}>
              <p className={style.cu_label}>
                Description<span className={style.mandatory}>*</span>
              </p>
              <textarea
                name="postContent"
                rows={2}
                onChange={(e) => handleChange(`description`, e?.target.value)}
                style={{
                  outline: "none",
                  width: "98%",
                  paddingLeft: "5px",
                  marginTop: "5px",
                  minHeight: "20px"
                }}
                onKeyUp={(e) => {
                  if (
                    e.ctrlKey &&
                    (e.key === "c" || e.key === "x" || e.key === "Backspace")
                  ) {
                    setFormData({
                      ...formData,
                      description: e.target.value,
                    });
                  }
                }}
              />
              <p
                className={
                  validate && !formData?.description?.trim()
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please Enter Description
              </p>
            </div>

            <p className={style.cu_label1}>Attachment</p>
            <div className={style.chooseFile}>
              <div>
                {fileName ? (
                  <div className={style.rmpdf} style={{ paddingTop: "3px" }}>
                    {
                      <>
                        {checkfileType(fileName?.split(".")[1])}&nbsp;
                        <span>{fileName}</span>
                      </>
                    }
                    <ClearIcon
                      onClick={handleFileDeletion}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ) : (
                  <input
                    id="file-upload"
                    accept="image/*, .pdf, .doc, .docx"
                    type="file"
                    className={style.upload_resume}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        const fileSize = file.size / 1024 / 1024; // File size in MB
                        if (fileSize <= 10) {
                          handleFileUpload(file);
                        } else {
                          ToastifyError("File size exceeds the limit of 10MB.");
                          document.getElementById('file-upload').value = null; // Reset the file input value
                          return;
                        }
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={style.buttonDiv}>
            <button
              className={style.cancelbtn}
              onClick={() => {
                cancelHandler();
              }}
            >
              Cancel
            </button>
            <button
              className={style.submitbtn}
              disabled={btnDisable}
              onClick={formHAndler}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

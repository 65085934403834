import React, { useEffect, useState, useRef } from "react";
import style from "./ViewCandidate.module.css";
import specialStyle from "./CreateCand.module.css";
import editStyle from "./editCandidate.module.css";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { toast } from "react-toastify";
import { driveIcon } from "../../../../assets";
import "react-toastify/dist/ReactToastify.css";
import {
  handleAddComment1,
  getCommentForCandidate,
} from "../../commonComponent/CommentCommon";
// import commentIcon from "../../../../assets/icons8-comment-30.png";
import { FaLinkedin, FaUserTag, FaRegCommentAlt } from "react-icons/fa";
import { MdOutlineInsertComment, MdDelete } from "react-icons/md";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import { IoDownloadSharp } from "react-icons/io5";
import { FaBuilding } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import ConfirmPopup from "../../../userManegment/pageLayout/viewUser/ConfirmPopup";
import dropdownIcon from "../../../../assets/downIcon.png";
// import changeRecruiterIcon from "../../../../assets/change_recruiter.png";
import editIcon from "../../../../assets/edit_icon.png";
// import downloadIcon from "../../../../assets/download_icon.png";
// import deleteIcon from "../../../../assets/delete_icon.png";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { HiInformationCircle } from "react-icons/hi";
import whiteLoader from "../../../../assets/blue_loader.gif";
import calender from "../../../../assets/calblue.png";
import { customSearch, customStyles, customStyles5 } from "../../utils/style";
import { GrPowerReset } from "react-icons/gr";
import NoteCandidate from "../createCandidate/NoteCandidate";
import ATSButton from "../../customTooltip/ATSButton";
import word from "../../../../assets/word.png";
import pdf from "../../../../assets/pdf.png";
import file from "../../../../assets/file.png";
// import cdtDelete from "../../../../assets/cdtDelete.png";
// import cdtEdit from "../../../../assets/cdtEdit.png";
// import cdtDownload from "../../../../assets/cdtDownload.png";
import ATSCard from "../../customTooltip/ATSCard";
import { employeeId, employeeName } from "../../../../Utils/constants";
import moment from "moment";
import check from "../../../../assets/check.png";
import cancel from "../../../../assets/cancell.png";
// import SocialAndResume from "../createCandidate/createCandidateCommon/CreateCandidateCommon";
// import CreatableSelect from "react-select/creatable";

import createStyle from "./CreateCand.module.css";
import {
  getCandidateById,
  deleteCandidateById,
  downloadScreeningCommentsByCandidateId,
  getPositionOwnerList,
  getAllDropReason,
  addJoiningDate,
  uploadResume,
  deleteResume,
  getFutureHireList,
  getLowerEmployee,
  getAllRecruiterByManager,
  assignNewRecruiter,
  addReadyForClientSharing,
  getAllRecruiters,
  dropCandidateStatus,
  getNegotiableDaysList,
  addScreenRejectNote,
  getAllWishlistReviewCases,
  addCandidateToWishlistReview,
  statusMarkByTeamLead,
  getAllEmployees,
  getAllPartnerName,
  checkCandidateByEmail,
  checkCandidateByMobileNo,
  getAllCoreSkills,
  checkExistCompanyName,
  getAllDegrees,
  addDegree,
  getNoticePeriodList,
  getAllSourceList,
  createCandidate,
  cancelMarkForOffer,
  sendMailForUpdatedProfileReview,
} from "../../../../Utils/axios/api";
import BreadCrum from "../../breadcrums/BreadCrum";
import Loader from "../../../../GenericComponent/Loader/Loader";
import ATSContainer from "../../customTooltip/ATSContainer";
import ValidationEmpAccess from "../validationEmpAccess/ValidationEmpAccess";
import { accessCode } from "../../../../Utils/constants";
import {
  academicValidationEdit,
  checkAlternetMobileNumber,
  // checkMobileNumber,
  checkNegotiable,
  // companyWebsiteInUrl,
  experienceInDescendingOrder,
  linkendInUrl,
  onRelevantExp,
  onTotalExp,
  validateExp,
  // workExperienceValidation,
} from "./../createCandidate/CreateCandidateValidation";
import { AiFillFolder } from "react-icons/ai";
import { Toastify, ToastifyError } from "../../../../App";
import { notifyError, notifySuccess } from "../../utils/notify";
import { validateEmail } from "../../utils/validation";
// import { experimental_sx } from "@mui/material";
import CheckBoxCustom from "../../commonComponent/ElementCommon/CheckBoxCustom";
import CommentHistory from "../../commonComponent/Comment/CommentHistory/CommentHistory";
import FinalStatusPopup from "../../../userManegment/pageLayout/viewUser/ConfirmPopup";
import { lazyLoad } from "../../../../GenericComponent/lazyLoad/LazyLoadCommon";
import { useParams } from "react-router-dom";
// import { truncate } from "lodash";
import { MdAddTask } from "react-icons/md";
import CandidatePriorityModal from "../PositionReport/SourcingNumberReport/CandidatePriorityModal/CandidatePriorityModal";
import { EmailTooltip, MobileTooltip } from "../../customTooltip/CustomTooltip";

function ViewCandidate({
  setNextScreen,
  prevData,
  setPrevData,
  roles,
  validateAccess,
}) {
  const Modal1 = lazyLoad(() => import("../../commonComponent/Modal/Modal"));
  const [isModalOpen1, setModalOpen1] = useState(false);
  const openModal1 = () => setModalOpen1(true);
  const closeModal1 = () => setModalOpen1(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [candidateJoined, setCandidateJoined] = useState(false);
  const [reActivate, setrReActivate] = useState(false);
  const [validate, setValidate] = useState(false);
  const [offerDoc, setOfferDoc] = useState("");
  const [disable, setDisabled] = useState(false);
  const [lastNameCheckbox, setLastNameCheckbox] = useState(); // assuming the checkbox is unchecked by default
  const [recuiterShow, setRecuiterShow] = useState(false);
  const [recuiterData, setRecuiterData] = useState({
    recruiterId: null,
    date: null,
  });
  const [showFinal, setShowFinal] = useState(false);
  const [offerDate, setOfferDate] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [memberOptions, setMemberOptions] = useState([]);
  const [candidateData, setCandidateData] = useState({});
  const [gaps, setGaps] = useState({
    gapModal : false,
    gapDetails : [],
    totalGap : null,
  });
  let tempCandidatedataRef = useRef();
  const [loader2, setLoader2] = useState(false);
  const [details, setDetails] = useState(false);
  const [workExperienceArray, setWorkExperienceArray] = useState([1]);
  const [edit, setEdit] = useState({
    partner: false,
    salary: false,
    disableSalaryBtn: false,
    personalInfo: false,
    experience: false,
    workExp: false,
    academic: false,
    resume: false,
    socialPlatform: false,
    oInfo: false,
  });
  const editObj = {
    partner: false,
    salary: false,
    disableSalaryBtn: false,
    personalInfo: false,
    experience: false,
    workExp: false,
    academic: false,
    resume: false,
    socialPlatform: false,
    oInfo: false,
  };
  const params = useParams();
  const [nameValidate, setNameValidate] = useState(false);
  const [presentWorkIndex, setPresentWorkIndex] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [existEmployee, setExistEmployee] = useState();
  const [cancelMrkShow, setCancelMrkShow] = useState(false);
  const [endDtErr, setEndDtErr] = useState([]);
  const [startDtErr, setStartDtErr] = useState([]);
  const gradeStatusVal = [
    { value: 1, label: "CGPA" },
    { value: 2, label: "Percentage" },
    { value: 1, label: "Grade" },
  ];
  const [candidateId, setCandidateId] = useState(null);
  const [priorityId, setPriorityId] = useState(null);
  const [priorityModal, setPriorityModal] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState([]);

  const environmentType = process.env.REACT_APP_ENV_TYPE;
  const tldecisionDataNoteRef = useRef("");
  // const dropformRef = useRef({ note: "", futureHireReason: "" })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const employees = await getAllEmployees();
        let filteredEmployees = employees;
        if (environmentType === "production") {
          filteredEmployees = employees.filter((employee) =>
            [3, 4, 13, 361].includes(employee.id)
          );
        } else {
          filteredEmployees = employees.filter((employee) =>
            [ 4, 5, 1457, 1530].includes(employee.id)
          );
        }
        const updatedOptions = filteredEmployees.map((employee) => ({
          value: employee.id,
          label: employee.name,
        }));

        const employeeExists = updatedOptions.some(
          (option) => option.value === employeeId
        );
        if (employeeExists) {
          setExistEmployee({ value: employeeId, label: employeeName });
        }

        setMemberOptions(updatedOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [environmentType]);

  const [candiDropForm, setCandiDropForm] = useState({
    reasonId: null,
    joiningDate: "",
    note: "",
    docUrl: "",
    futureHireId: null,
    futureHireReason: "",
  });
  const [formData, setFormData] = useState({
    empId: employeeId,
    id: prevData?.candidateId,
    note: "",
  });
  const offerOnHandOptions = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];
  const [showNote, setShowNote] = useState(false);
  const [showWatchlistModal, setShowWatchlistModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showTLDecisionModal, setShowTLDecisionModal] = useState(false);
  const [watchlistData, setWatchlistData] = useState({
    candidateId: prevData?.candidateId,
    requesterId: [],
    reviewCaseByEmpId: null,
    toBeReviewed: null,
    wishlistReviewCasesId: null, //reason id
  });
  const [tlDecisionData, setTlDecisionData] = useState({
    candidateId: prevData?.candidateId,
    teamLeadId: employeeId,
    noteByTeamLead: null,
    statusByTeamLead: null,
  });
  const [isWatchlistValid, setIsWatchlistValid] = useState(true);
  const [isTL_DecisionValid, setIsTL_DecisionValid] = useState(true);
  const [showScreenRejectNote, setShowScreenRejectNote] = useState(false);
  const [clientShare, setClientShare] = useState(false);
  const [clientDate, setClientDate] = useState(null);
  const [fmRating, setFMRating] = useState("");
  const [screenRejectData, setScreenRejectData] = useState({});
  const [isNoteValid, setIsNoteValid] = useState(true);
  const [acadamicsArray, setAcadamicsArray] = useState([1]);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [showFHNModal, setShowFHNModal] = useState(false);
  const [showDNModal, setShowDNModal] = useState(false);
  const [showSFR, setShowSFR] = useState(false);
  const [SFRNote, setSFRNote] = useState('');
  const bottomRef = useRef(null);

  const {
    data,
    isSuccess,
    isLoading: candidateLoading,
    refetch: refetchCandidate,
  } = useQuery(["getCandidateById", { candidateId: prevData?.candidateId || params?.candidateId }], () =>
    getCandidateById(prevData?.candidateId || params?.candidateId)
  );
  let futureHN = data?.joiningDropResponseModel?.futureHireReason?.length > 110 ? data?.joiningDropResponseModel?.futureHireReason?.slice(0, 110) + ' ' : data?.joiningDropResponseModel?.futureHireReason;
  let dropN = data?.joiningDropResponseModel?.dropNote?.length > 110 ? data?.joiningDropResponseModel?.dropNote?.slice(0, 110) + ' ' : data?.joiningDropResponseModel?.dropNote;


  useEffect(() => {
    showNote && bottomRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [showNote]);
  const { data: getPositionOwnerListData } = useQuery(
    ["getPositionOwnerList"],
    getPositionOwnerList
  );
  const { data: getAllSourceData } = useQuery(
    ["getAllSource"],
    getAllSourceList,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: getAllDegreesData } = useQuery(
    ["getAllDegrees"],
    getAllDegrees,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: getNoticePreData } = useQuery(
    ["getNoticePre"],
    getNoticePeriodList,
    {
      refetchOnWindowFocus: false,
    }
  );

  const getAllDegreesOption = getAllDegreesData?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getNoticePreOption = getNoticePreData?.map((i) => ({
    value: i.noticePeriod,
    label: i.noticePeriod,
  }));
  const getAllSourceOption = getAllSourceData?.map((i) => ({
    value: i.id,
    label: i.sourceName,
  }));

  const { data: negotialbleList } = useQuery(["getNegotiableDaysList"], () =>
    getNegotiableDaysList()
  );
  const negotialbleOptions = negotialbleList?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));
  const { data: getCoreSkillData } = useQuery(
    ["getAllCoreSkills"],
    getAllCoreSkills,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: checkByCompanyName, refetch: refetchCompany } = useQuery(
    ["checkExistCompanyName", companyName],
    () => checkExistCompanyName(companyName),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
  const checkDuplicateeCompany = () => {
    setTimeout(() => {
      refetchCompany();
    }, 600);
  };

  const handleInputClick = (e) => {
    e.target.addEventListener("mousewheel", (ev) => {
      e.target.blur();
    });
  };

  const handleKeyDown = (e) => {
    // Prevent 'e' from being entered
    if (e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };

  const options = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];

  const getCoreSkillOption = getCoreSkillData?.map((i) => ({
    value: i.skillId,
    label: i.coreSkillName,
  }));

  const watchlistMutation = useMutation(addCandidateToWishlistReview, {
    onSuccess: (res) => {
      if (res.responseStatus.statusCode === 0) {
        ToastifyError(res?.responseData?.message);
      }
      if (res.responseStatus.statusCode === 1) {
        Toastify(res?.responseData);
      }
      setShowWatchlistModal(false);
      setWatchlistData({
        candidateId: prevData?.candidateId,
        requesterId: [],
        reviewCaseByEmpId: null,
        toBeReviewed: null,
        wishlistReviewCasesId: null,
      });
    },
  });
  const TL_DecisionMutation = useMutation(statusMarkByTeamLead, {
    onSuccess: (res) => {
      if (res.responseStatus.statusCode === 0) {
        ToastifyError(res?.responseData?.message);
      }
      if (res.responseStatus.statusCode === 1) {
        Toastify(res?.responseData);
      }
      setShowTLDecisionModal(false);
      setTlDecisionData({
        candidateId: null,
        teamLeadId: employeeId,
        noteByTeamLead: null,
        statusByTeamLead: null,
      });
      tldecisionDataNoteRef.current = "";
      refetchCandidate();
    },
  });

  const checkFileType = (value) => {
    switch (value) {
      case "pdf":
        return <img src={pdf} alt="pdf" />;
      case "docx":
        return <img src={word} alt="word" />;
      default:
        return <img src={file} alt="file" />;
    }
  };

  const { data: lowerEmployeeData } = useQuery(
    ["getLowerEmployee"],
    () => roles?.includes(3) && getLowerEmployee(parseInt(employeeId))
  );
  const { data: RecruiterByManagerData } = useQuery(
    ["getAllRecruiterByManager"],
    () => roles?.includes(2) && getAllRecruiterByManager(parseInt(employeeId))
  );
  const { data: allRecruitersData } = useQuery(
    ["getAllRecruiters"],
    () => roles?.includes(1) && getAllRecruiters(parseInt(employeeId))
  );
  const { data: checkByEmail, refetch } = useQuery(
    ["checkCandidateByEmail", candidateData?.email],
    () => checkCandidateByEmail(prevData?.candidateId, candidateData?.email),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );
  const checkDuplicateeMail = () => {
    setTimeout(() => {
      refetch();
    }, 600);
  };
  let recuiterOption = [];
  if (roles?.includes(1)) {
    recuiterOption =
      allRecruitersData?.length > 0
        ? allRecruitersData?.map((e) => {
          return { label: e?.name, value: e?.id };
        })
        : [];
  }
  if (roles?.includes(2)) {
    recuiterOption =
      RecruiterByManagerData?.length > 0
        ? RecruiterByManagerData?.map((e) => {
          return { label: e?.name, value: e?.id };
        })
        : [];
  }
  if (roles?.includes(3)) {
    recuiterOption =
      lowerEmployeeData?.length > 0
        ? lowerEmployeeData?.map((e) => {
          return { label: e?.name, value: e?.id };
        })
        : [];
  }
  const recuiterMutaion = useMutation(assignNewRecruiter, {
    onSuccess: (res) => {
      Success(res);
      setRecuiterData((prev) => ({ ...prev, recruiterId: null, date: null }));
      setValidate(false);
      setRecuiterShow(false);
      refetchCandidate(prevData?.candidateId || params?.candidateId);
    },
  });
  const changeRcuiter = () => {
    if (
      candidateData?.joiningDropResponseModel &&
      candidateData?.joiningDropResponseModel?.status === "Dropped"
    ) {
      if (!recuiterData?.recruiterId || !recuiterData?.date) {
        setValidate(true);
      } else {
        recuiterMutaion.mutate({
          candidateId: prevData?.candidateId,
          recruiterId: recuiterData?.recruiterId,
          date: moment(recuiterData?.date).format("YYYY-MM-DDTHH:mm:ssZ"),
        });
      }
      return;
    } else {
      if (recuiterData?.recruiterId) {
        recuiterMutaion.mutate({
          candidateId: prevData?.candidateId,
          recruiterId: recuiterData?.recruiterId,
          date: null,
        });
      } else {
        setValidate(true);
      }
    }
  };
  const getgetPositionOwnerListOption =
    getPositionOwnerListData?.length > 0
      ? getPositionOwnerListData?.map((i) => ({
        value: i?.id,
        label: i?.name,
      }))
      : [];
  const { data: getAllDropReasonData } = useQuery(
    ["getAllDropReason"],
    () => getAllDropReason(),
    { refetchOnWindowFocus: false }
  );
  const { data: getFutureHireListData } = useQuery(
    ["getFutureHireList"],
    () => getFutureHireList(),
    { refetchOnWindowFocus: false }
  );
  const { data: getAllWishlistReviewCasesData } = useQuery(
    ["getAllWishlistReviewCases"],
    () => getAllWishlistReviewCases(),
    { refetchOnWindowFocus: false }
  );
  const dropOption =
    getAllDropReasonData?.length > 0
      ? getAllDropReasonData?.map((e) => {
        return { value: e.id, label: e.reason };
      })
      : [];
  const futureOption =
    getFutureHireListData?.length > 0
      ? getFutureHireListData?.map((e) => {
        return { value: e.id, label: e.name };
      })
      : [];
  const watchlistReasonOption =
    getAllWishlistReviewCasesData?.length > 0
      ? getAllWishlistReviewCasesData?.map((e) => {
        return { value: e.id, label: e.name };
      })
      : [];

  const { data: getAllPartnerNameData } = useQuery(
    ["getAllPartnerName"],
    getAllPartnerName,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: checkByNumber, refetch: refetchMobile } = useQuery(
    ["checkCandidateByMobileNo", candidateData?.mobileNo],
    () =>
      checkCandidateByMobileNo(prevData?.candidateId, candidateData?.mobileNo),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );
  const checkDuplicateeNumber = () => {
    setTimeout(() => {
      refetchMobile();
    }, 600);
  };

  const mutationDegree = useMutation(addDegree, {
    onSuccess: (res) => {
      let academics = candidateData?.candidateAcademics;
      for (let i = 0; i < academics.length; i++) {
        if (academics[i].degreeId.label == res?.name) {
          let obj = {
            label: res?.name,
            value: res?.id,
          };
          academics[i].degreeId = obj;
          break;
        }
      }
    },
  });

  const gradeOption = [
    { value: 1, label: "CGPA" },
    { value: 2, label: "Percentage" },
    { value: 3, label: "Grade" },
  ];

  const checkValidPoint = (input) => {
    let singleDigit = /^\d$/; // regular expression to match a single digit
    let twoDigits = /^\d{2}$/; // regular expression to match two digits
    let decimal = /^\d+\.\d+$/; // regular expression to match a decimal number
    if (input == null) {
      return false;
    }
    if (singleDigit.test(input)) {
      return false;
    } else if (twoDigits.test(input)) {
      return false;
    } else if (decimal.test(input)) {
      return false;
    } else {
      return true;
    }
  };

  const getAllPartnerNameOption = getAllPartnerNameData?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  useEffect(() => {
    let newWork = experienceInDescendingOrder(data?.workExperiences)?.map(
      (e, index) => {
        if (e.presentlyWorking === true) {
          setPresentWorkIndex(index + 1);
          return { ...e, present: index + 1 };
        } else {
          return { ...e, present: 0 };
        }
      }
    );

    let newData = {
      ...data,
      workExperiences: newWork,
      ectc: data?.ectc === 0 ? null : data?.ectc,
    };
    if (newData.maritalStatus === "Married") {
      newData.maritalStatus = true;
    } else if (newData.maritalStatus === "Unmarried") {
      newData.maritalStatus = false;
    }
    const tempDtArr = new Array(newWork.length).fill(false);
    setStartDtErr([...tempDtArr]);
    setEndDtErr([...tempDtArr]);
    setCandidateData({ ...newData });
    tempCandidatedataRef.current = { ...newData };
  }, [data]);

  useEffect(() => {
    setFormData((prev) => ({ ...prev, note: data?.note }));
  }, [data?.note]);

  // useEffect(()=>{
  //   if(candidateData?.lastName==='') setLastNameCheckbox(true);
  // },[candidateData.lastName])

  const [resumeName, setResumeName] = useState("");
  const downloadMutation = useMutation(downloadScreeningCommentsByCandidateId, {
    onSuccess: (response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `${data?.name}_Screening_Comments.txt`); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
  });

  useEffect(() => {
    setResumeName(data?.resumeUrl ? data?.resumeUrl.split("@")[1] : "");
    setScreenRejectData({
      candidateId: data?.id,
      note: data?.screenNote,
      position: data?.positionId,
      screening: "Screen Rejected",
      empId: employeeId,
    });
  }, [data]);

  const mutation = useMutation(deleteCandidateById, {
    onSuccess: (res) => {
      DeleteSuccess();
      setShow(false);
      setTimeout(() => {
        setNextScreen("candidateList");
        setPrevData((prevData) => ({
          ...prevData,
          page1: "candidateList",
          page2: null,
          page3: null,
          active: "candidateList",
        }));
      }, 1000);
    },
  });

  const removeFieldFromArray = (data) => {
    const newData = data?.filter((e) => e?.companyName != "");
    return newData;
  };

  const removeFieldFromAcademics = (data) => {
    const newData = data?.filter(
      (e) => e?.degreeId != "" && e?.passingYear != ""
    );
    return newData;
  };

  const handleEditCandidateWOPR = () => {
    setValidate(false);
    let data = {
      ...candidateData,
      recruiterId: employeeId,
      firstName: candidateData?.firstName?.trim(),
      lastName: candidateData?.lastName?.trim(),
      workExperiences: removeFieldFromArray(candidateData?.workExperiences),
      candidateAcademics: removeFieldFromAcademics(
        candidateData.candidateAcademics
      ).map((i) => ({
        grade: i?.grade,
        gradeStatus: i?.gradeStatus,
        degreeId: i?.degreeId?.value ? i?.degreeId?.value : i?.degreeId,
        board: i?.board,
        passingYear: Number(i?.passingYear),
        rank: i?.rank,
      })),
      sourceId: candidateData.sourceId,
      source:
        candidateData.sourceId === 4 || candidateData.sourceId === 6
          ? candidateData.source
          : null,
      coreSkill:
        typeof candidateData?.coreSkill == "string"
          ? getCoreSkillOption?.find(
            (i) => i?.label === candidateData?.coreSkill
          ).value
          : candidateData?.coreSkill,
      monthlySalaryByCTC: (candidateData?.ctc * 100000 / 12).toFixed(1),
      monthlySalaryByECTC: (candidateData?.ectc * 100000 / 12).toFixed(1),
      incrementPercentage: candidateData?.incrementPercentage !== null ? Math.round(candidateData?.incrementPercentage) : null
    };
    delete data?.other;
    editCandidateMutation.mutate(data);
    setPrevData({ ...prevData, data: candidateData });
  };

  const getGradeStatusVal = (label) => {
    let val = gradeStatusVal.find((obj) => obj.label === label);
    return val ? val.value : "";
  };

  const handleEditCandidateWPR = (obj) => {
    setNameValidate(false);
    setDisabled(true);

    const mobileEmpty =
      obj?.mobileEmpty === true || obj?.mobileEmpty === false
        ? obj?.mobileEmpty
        : false;
    const emailEmpty =
      obj?.emailEmpty === true || obj?.emailEmpty === false
        ? obj?.emailEmpty
        : false;
    const altEmailEmp =
      obj?.altEmailEmp === true || obj?.altEmailEmp === false
        ? obj?.altEmailEmp
        : false;
    let data = {
      ...candidateData,
      recruiterId: employeeId,
      buyOutNegotiable: candidateData?.buyOutNegotiable ? true : false,
      workExperiences: removeFieldFromArray(candidateData?.workExperiences),
      candidateAcademics: candidateData.candidateAcademics.map((i) => ({
        grade: i?.grade,
        degreeId: i?.degreeId?.value ? i?.degreeId?.value : i?.degreeId,
        board: i?.board,
        passingYear: Number(i?.passingYear),
        rank: i?.rank,
      })),
      sourceId: candidateData.sourceId,
      source:
        candidateData.sourceId === 4 || candidateData.sourceId === 6
          ? candidateData.source
          : null,

      coreSkill:
        typeof candidateData?.coreSkill == "string"
          ? getCoreSkillOption?.find(
            (i) => i?.label === candidateData?.coreSkill
          ).value
          : candidateData?.coreSkill,
      mobileNo: mobileEmpty ? null : candidateData?.mobileNo,
      email: emailEmpty ? null : candidateData?.email,
      alternateEmail: altEmailEmp ? null : candidateData?.alternateEmail,
      monthlySalaryByCTC: (candidateData?.ctc * 100000 / 12).toFixed(1),
      monthlySalaryByECTC: (candidateData?.ectc * 100000 / 12).toFixed(1),
      incrementPercentage: candidateData?.incrementPercentage !== null ? Math.round(candidateData?.incrementPercentage) : null
    };
    delete data?.other;
    setPrevData({ ...prevData, data: candidateData });

    editCandidateMutation.mutate(data);
  };

  const handleValidationWOPR = () => {
    setValidate(true);
  };

  const handleValidationWPR = () => {
    setNameValidate(true);
  };

  const validateWE = (tempWorkExperiences) => {
    if (tempWorkExperiences && tempWorkExperiences.length) {
      const filterArr = tempWorkExperiences.filter((item, ind) => {
        return presentWorkIndex > 0
          ? !(ind + 1 === presentWorkIndex) &&
          (!item?.endDate ||
            !item?.companyName ||
            !item?.role ||
            !item?.startDate)
          : !item?.endDate ||
          !item?.companyName ||
          !item?.role ||
          !item?.startDate;
      });
      return filterArr.length > 0 ? false : true;
    } else {
      return true;
    }
  };

  const findVal = () => {
    if (candidateData?.maritalStatus === true) {
      return { label: "Married", value: true };
    } else if (candidateData?.maritalStatus === false) {
      return { label: "Unmarried", value: false };
    } else return null;
  };

  const workExpValidation = (presentWork, formData) => {
    let status = false;
    for (let value of formData) {
      if (presentWork > 0 && value?.present === presentWork) {
        if (
          value?.companyName &&
          value?.startDate &&
          value?.role &&
          value?.reasonForChange
        ) {
          status = true;
        } else {
          status = false;
          break;
        }
      } else {
        status = true;
      }
    }
    if (formData?.length === 0) {
      status = true;
    }
    return status;
  };

  const verifyExperience = () => {
    if(candidateData?.workExperiences?.length === 1 && candidateData?.isPartnerResource){
      if((!candidateData?.workExperiences[0]?.companyName) &&
        (!candidateData?.workExperiences[0]?.reasonForChange) &&
        (!candidateData?.workExperiences[0]?.role) &&
        (!candidateData?.workExperiences[0]?.endDate) &&
        (!candidateData?.workExperiences[0]?.startDate) &&
        (!candidateData?.workExperiences[0]?.present)){
          return true;
        }
    }
    let experienceYears = 0;
    let experienceMonths = 0;
    if(candidateData?.workExperiences?.length === 1 && !candidateData?.isPartnerResource){
      if((!candidateData?.workExperiences[0]?.companyName) &&
        (!candidateData?.workExperiences[0]?.reasonForChange) &&
        (!candidateData?.workExperiences[0]?.role) &&
        (!candidateData?.workExperiences[0]?.endDate) &&
        (!candidateData?.workExperiences[0]?.startDate) &&
        (!candidateData?.workExperiences[0]?.present)){
          if(candidateData?.totalExperienceYear === 0 && candidateData?.totalExperienceMonth === 0){
            return true;
          }
        }
    }
    let jobs = [...candidateData?.workExperiences];
    jobs.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)));

    let totalExperienceMonths = 0;
    for (let i = 0; i < jobs.length; i++) {
      const job = jobs[i];
      const start = moment(job.startDate);
      const end = job.present ? moment() : moment(job.endDate);

      totalExperienceMonths += end.diff(start, 'months')+1;
    }
    
    experienceYears = Math.floor(totalExperienceMonths / 12);
    experienceMonths = totalExperienceMonths % 12;

    if(candidateData?.workExperiences) {
      let jobs = [...candidateData?.workExperiences];
      jobs.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)));

      let totalExperienceMonths = 0;
      for (let i = 0; i < jobs.length; i++) {
        const job = jobs[i];
        const start = moment(job.startDate);
        const end = job.present ? moment() : moment(job.endDate);

        totalExperienceMonths += end.diff(start, 'months');
      }
      
      const experienceYears = Math.floor(totalExperienceMonths / 12);
      const experienceMonths = totalExperienceMonths % 12;

      if(candidateData?.totalExperienceYear !== null || candidateData?.totalExperienceMonth !== null){
        if(candidateData?.totalExperienceYear !== experienceYears || candidateData?.totalExperienceMonth !== experienceMonths){
          notifyError("Total experience Does Not Match! Please Re-Check.");
          return false;
        }
        else return true;
      }
      else {
        return false;
      }
    }
  }

  const calculateGaps = () => {
    if(candidateData?.workExperiences){let jobs = [...candidateData?.workExperiences];
    let tempJ = jobs.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)));

    let totalGapMonths = 0;
    let tempGaps = [];

    for (let i = 0; i < tempJ.length; i++) {
      const job = tempJ[i];
      const start = moment(job.startDate);

      if (i > 0) {
        const previousJob = tempJ[i - 1];
        const prevEndDate = previousJob.present ? moment() : moment(previousJob.endDate);
        if (start.isAfter(prevEndDate)) {
          const gapMonths = start.diff(prevEndDate, 'months');
          totalGapMonths += gapMonths;
          tempGaps.push({
              gapStart: prevEndDate.format("MMMM YYYY"),
              gapEnd: start.format("MMMM YYYY"),
              gapDuration: `${start.diff(prevEndDate, 'months')} months`
          });
        }
      }
    }
  
    const gapYears = Math.floor(totalGapMonths / 12);
    const gapMonths = totalGapMonths % 12;

    const formatTime = (count, unit) => `${count} ${unit}${count === 1 ? "" : "s"}`;
    setGaps(prev=>({
      ...prev,
      totalGap : (gapYears || gapMonths) ? (`Total Gap: ${gapYears > 0 ? formatTime(gapYears, "year") : ""}${
                gapYears > 0 && gapMonths > 0 ? ", " : ""
            }${gapMonths > 0 ? formatTime(gapMonths, "month") : ""}`) : '',
      gaps: tempGaps,
    }));

    console.log(`Total Gap: ${gapYears} years, ${gapMonths} months`);}
  }

  const handlePage = (item) => {
    if (prevData.page2 === "positionHealthReportNew") {
      setNextScreen("candidateMovement");
      setPrevData((prevData) => ({
        ...prevData,
        reportCandidateId: item?.id,
        sidebar: "reportNew",
        page1: "reportNew",
        page2: "positionHealthReportNew",
        page3: "ViewCandidate",
        page4: "candidateMovement",
        page5: null,
        active: "candidateMovement",
      }));
    } else {
      setNextScreen("candidateMovement");
      setPrevData((prevData) => ({
        ...prevData,
        reportCandidateId: item?.id,
        page1: "candidateList",
        page2: "ViewCandidate",
        page3: "candidateMovement",
        page4: null,
        active: "candidateMovement",
      }));
    }
  };
  const onCreateHandler = (editInfoName) => {
    if (
      editInfoName === "Partner" &&
      !candidateData?.isPartnerResource &&
      candidateData?.ctc >= 0 &&
      ((candidateData?.ectc > 0 && !checkValidPoint(candidateData?.ectc)) ||
        candidateData?.asPerMarketStandard) &&
      !checkValidPoint(candidateData?.ctc)
    ) {
      handleEditCandidateWOPR();
    } else if (
      editInfoName === "Partner" &&
      !candidateData?.isPartnerResource
    ) {
      handleValidationWOPR();
    }
    if (
      editInfoName === "Partner" &&
      candidateData?.isPartnerResource &&
      candidateData?.partnerId &&
      candidateData?.proposedRates
    ) {
      handleEditCandidateWPR();
    } else if (editInfoName === "Partner" && candidateData?.isPartnerResource) {
      handleValidationWPR();
    }

    if (
      editInfoName === "Personal Information" &&
      candidateData?.firstName &&
      (!lastNameCheckbox ? candidateData?.lastName?.trim() : true) &&
      !candidateData?.isPartnerResource &&
      candidateData?.coreSkill &&
      (candidateData?.totalExperienceMonth === 0 ||
        candidateData?.totalExperienceMonth) &&
      onRelevantExp(candidateData) &&
      onTotalExp(candidateData) &&
      validateExp(candidateData) &&
      candidateData?.totalExperienceMonth <= 11 &&
      candidateData?.relevantExperienceMonth <= 11 
    ) {
      handleEditCandidateWOPR();
    } else if (
      editInfoName === "Personal Information" &&
      !candidateData?.isPartnerResource
    ) {
      handleValidationWOPR();
    }
    if (
      editInfoName === "Personal Information" &&
      candidateData?.isPartnerResource &&
      candidateData?.coreSkill &&
      (candidateData?.totalExperienceMonth === 0 ||
        candidateData?.totalExperienceMonth) &&
      onRelevantExp(candidateData) &&
      onTotalExp(candidateData) &&
      validateExp(candidateData) &&
      candidateData?.totalExperienceMonth <= 11 &&
      candidateData?.relevantExperienceMonth <= 11 &&
      candidateData?.firstName?.trim() &&
      (!lastNameCheckbox ? candidateData?.lastName?.trim() : true)
    ) {
      handleEditCandidateWPR();
    } else if (
      editInfoName === "Personal Information" &&
      candidateData?.isPartnerResource
    ) {
      handleValidationWPR();
    }
    if (
      editInfoName === "Experience" &&
      !candidateData?.isPartnerResource &&
      candidateData?.mobileNo &&
      candidateData?.mobileNo?.length >= 10 &&
      candidateData?.mobileNo?.length <= 14 &&
      checkAlternetMobileNumber(candidateData) &&
      validateEmail(candidateData?.email?.trim()) &&
      !checkByEmail?.exists &&
      !checkByNumber?.message &&
      (candidateData?.alternateEmail
        ? validateEmail(candidateData?.alternateEmail?.trim())
        : true) 
    ) {
      handleEditCandidateWOPR();
    } else if (
      editInfoName === "Experience" &&
      !candidateData?.isPartnerResource
    ) {
      handleValidationWOPR();
    }

    if (
      editInfoName === "Experience" &&
      candidateData?.isPartnerResource &&
      (candidateData?.mobileNo
        ? candidateData?.mobileNo?.length >= 10 &&
        candidateData?.mobileNo?.length <= 14 &&
        !checkByNumber?.message
        : true) &&
      checkAlternetMobileNumber(candidateData) &&
      (candidateData?.email
        ? validateEmail(candidateData?.email?.trim()) && !checkByEmail?.exists
        : true) &&
      (candidateData?.alternateEmail
        ? validateEmail(candidateData?.alternateEmail?.trim())
        : true)
    ) {
      const mobileEmpty = !candidateData?.mobileNo ? true : false;
      const emailEmpty = !candidateData?.email?.trim() ? true : false;
      const altEmailEmp = !candidateData?.alternateEmail?.trim() ? true : false;
      handleEditCandidateWPR({ mobileEmpty, emailEmpty, altEmailEmp });
    } else if (
      editInfoName === "Experience" &&
      candidateData?.isPartnerResource
    ) {
      handleValidationWPR();
    }

    if (
      editInfoName === "Salary" &&
      candidateData?.ctc >= 0 &&
      ((candidateData?.ectc > 0 && !checkValidPoint(candidateData?.ectc)) ||
        candidateData?.asPerMarketStandard) &&
      ((!candidateData?.asPerMarketStandard &&
        candidateData?.negotiable !== null) ||
        candidateData?.asPerMarketStandard) &&
      !checkValidPoint(candidateData?.ctc)
    ) {
      handleEditCandidateWOPR();
    } else if (editInfoName === "Salary") {
      handleValidationWOPR();
    }
    if (editInfoName === "Rates" && candidateData?.proposedRates) {
      handleEditCandidateWOPR();
    } else if (editInfoName === "Rates") {
      handleValidationWPR();
    }
    if (
      editInfoName === "Work Experience" &&
      endDtErr &&
      endDtErr.filter((item) => item).length === 0 &&
      startDtErr &&
      startDtErr.filter((item) => item).length === 0 &&
      workExpValidation(presentWorkIndex, candidateData?.workExperiences) &&
      !checkByCompanyName?.exists &&
      validateWE(candidateData?.workExperiences) 
    ) {
      handleEditCandidateWOPR();
    } else if (editInfoName === "Work Experience") {
      handleValidationWOPR();
    }
    if (editInfoName === "Academics" && academicValidationEdit(candidateData)) {
      handleEditCandidateWOPR();
    } else if (editInfoName === "Academics") {
      handleValidationWOPR();
    }
    if (
      editInfoName === "Other Information" &&
      !candidateData?.isPartnerResource &&
      checkNegotiable(candidateData) &&
      candidateData?.noticePeriod &&
      candidateData?.homeTown?.trim() &&
      candidateData?.currentLocation?.trim() &&
      (candidateData?.sourceId === 4 || candidateData?.sourceId === 6
        ? candidateData?.source
        : candidateData?.sourceId) &&
      candidateData?.preferredLocation &&
      (!candidateData?.openForPanIndia
        ? candidateData?.openForNG === true ||
          candidateData?.openForNG === false
          ? true
          : false
        : true)
    ) {
      handleEditCandidateWOPR();
    } else if (
      editInfoName === "Other Information" &&
      !candidateData?.isPartnerResource
    ) {
      handleValidationWOPR();
    }
    if (
      editInfoName === "Other Information" &&
      candidateData?.isPartnerResource &&
      checkNegotiable(candidateData) &&
      candidateData?.preferredLocation
    ) {
      handleEditCandidateWPR();
    } else if (
      editInfoName === "Other Information" &&
      candidateData?.isPartnerResource
    ) {
      handleValidationWPR();
    }
    if (
      editInfoName === "Social Platforms" &&
      linkendInUrl(candidateData?.linkedInUrl) &&
      // companyWebsiteInUrl(
      //   candidateData?.companyWebUrl,
      //   candidateData?.totalExperienceYear,
      //   candidateData?.totalExperienceMonth
      // ) &&
      linkendInUrl(candidateData?.companyLinkedInUrl)
    ) {
      handleEditCandidateWOPR();
    } else if (editInfoName === "Social Platforms") {
      handleValidationWOPR();
    }
    if (editInfoName === "Resume" && candidateData?.resumeUrl) {
      handleEditCandidateWOPR();
      toBeDeleted.forEach(element => {
        deleteResume(element);
      });
      setToBeDeleted([]);
    } else if (editInfoName === "Resume") {
      handleValidationWOPR();
    }
  };

  const editCandidateMutation = useMutation(createCandidate, {
    onMutate: () => {
      setDisabled(true);
    },
    onSuccess: (res) => {
      if (res?.responseStatus?.statusCode == 0) {
        notifyError(res?.responseData?.message);
        setErrorMsg(res?.responseData?.message);
        setShow3(true);
        // setNextScreen("candidateList");
      } else {
        // setNextScreen("ViewCandidate");
        // setPrevData((prevData) => ({
        //   ...prevData,
        //   page1: "candidateList",
        //   page2: "ViewCandidate",
        //   page3: null,
        //   active: "ViewCandidate",
        // }));
        notifySuccess("Candidate Updated Successfully");
        setEdit({ ...editObj });
        refetchCandidate();
      }
      setDisabled(false);

      // notifySuccess("Candidate Updated Successfully");
    },
    onError: () => {
      setShowError(true);
      setNameValidate(true);
      setDisabled(false);
    },
  });

  const handleDateChange = (date) => {
    // Get the current time
    const currentTime = moment();

    // Combine the selected date and current time
    const selectedDateWithTime = moment(date)
      .set({
        hour: currentTime.hour(),
        minute: currentTime.minute(),
        second: currentTime.second(),
      })
      .format();

    setRecuiterData((previous) => ({
      ...previous,
      date: selectedDateWithTime, // Store the date with time
    }));
  };

  const DeleteSuccess = () =>
    toast.success("Candidate Deleted Successfully...", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const SendForReview = useMutation(sendMailForUpdatedProfileReview, {
    onSuccess: (res) => {
      Toastify(res);
      setShowSFR(false);
    },
    onError: () => {
      ToastifyError("Something Went Wrong");
    }
  })
  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label ref={ref}>{props.value || props.placeholder}</label>
        <img
          src={calender}
          style={{ position: "absolute", right: "5%", top: "13%" }}
          alt={"calender"}
        />
      </div>
    );
  });
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt="dropdownIcon"
        />
      </span>
    );
  };

  const uploadMutation = useMutation(uploadResume, {
    onSuccess: (data) => {
      setCandiDropForm({ ...candiDropForm, docUrl: data.toString() });
    },
  });

  const mutationDrapped = useMutation(addJoiningDate, {
    onSuccess: (res) => {
      notifySuccess(res.responseData);
      setrReActivate(false);
      refetchCandidate();
      setDisabled(false);
      setCandidateJoined(false);
      setCandiDropForm((prevData) => ({
        ...prevData,
        joiningDate: null,
        note: "",
        docUrl: "",
        reasonId: null,
        futureHireReason: "",
        futureHireId: null,
      }));
      // setDate((prevData) => ({ ...prevData, joinData: "" }));
    },
    onError: (res) => {
      notifyError(res.message);
    },
  });

  const uploadResumeMutation = useMutation(uploadResume, {
    onSuccess: (data) => {
      setCandidateData({ ...candidateData, resumeUrl: data?.toString() });
    },
    onError: () => {
      setShow4(true);
    },
  });

  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <img src={pdf} alt="pdf" />;
      case "docx":
        return <img src={word} alt="word" />;
      default:
        return <img src={file} alt="file" />;
    }
  };

  const handleReActivate = () => {
    if (
      candiDropForm?.joiningDate &&
      data?.joiningDropResponseModel?.dropJoinDate <
      candiDropForm?.joiningDate &&
      moment(candiDropForm?.joiningDate).format("YYYY-MM-DD") >=
      moment(Date.now()).format("YYYY-MM-DD")
    ) {
      setDisabled(true);
      setValidate(false);
      mutationDrapped.mutate({
        candidateId: prevData.candidateId,
        date: candiDropForm?.joiningDate,
        note: candiDropForm?.note,
        dropStage: "Candidate Profile",
        status: true,
        isForInterview: false,
      });
    } else {
      setValidate(true);
    }
  };
  const dropReasonFn = (reasonId) => {
    if (
      Number(reasonId) === 3 ||
      Number(reasonId) === 5 ||
      Number(reasonId) === 9 ||
      Number(reasonId) === 10 ||
      Number(reasonId) === 12 ||
      Number(reasonId) === 11 ||
      Number(reasonId) === 14 ||
      Number(reasonId) === 17 ||
      Number(reasonId) === 21 ||
      Number(reasonId) === 16
    ) {
      return true;
    } else {
      return false;
    }
  };
  const declineFormHandler = () => {
    if (
      !candiDropForm?.reasonId ||
      !candiDropForm?.futureHireId ||
      !candiDropForm?.joiningDate ||
      !candiDropForm?.note
    ) {
      setValidate(true);
    } else if (dropReasonFn(candiDropForm?.reasonId)) {
      setValidate(true);
      if (!candiDropForm?.note) {
        setValidate(true);
      } else {
        setValidate(false);
        setDisabled(true);
        mutationDrapped.mutate({
          candidateId: prevData.candidateId,
          date: candiDropForm?.joiningDate,
          note: candiDropForm?.note,
          status: false,
          url: candiDropForm?.docUrl,
          isForInterview: false,
          reasonId: candiDropForm?.reasonId,
          dropStage: "Candidate Profile",
          futureHireReason: candiDropForm?.futureHireReason,
          futureHireId: candiDropForm?.futureHireId,
          empId: employeeId
        });
      }
    } else {
      setValidate(false);
      setDisabled(true);
      mutationDrapped.mutate({
        candidateId: prevData.candidateId,
        date: candiDropForm?.joiningDate,
        note: candiDropForm?.note,
        status: false,
        url: candiDropForm?.docUrl,
        isForInterview: false,
        reasonId: candiDropForm?.reasonId,
        dropStage: "Candidate Profile",
        futureHireReason: candiDropForm?.futureHireReason,
        futureHireId: candiDropForm?.futureHireId,
        empId: employeeId
      });
    }
  };
  useEffect(() => {
    setOfferDoc(candiDropForm?.docUrl);
  }, [candiDropForm?.docUrl]);
  const Success = (res) =>
    toast.success(res, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  const clientShareMutaion = useMutation(addReadyForClientSharing, {
    onSuccess: (res) => {
      if(res?.responseStatus?.statusCode === 0){
        notifyError(res?.responseData?.message);
      } else {
        Success(res?.responseData);
      }
      setValidate(false);
      setClientDate(null);
      setFMRating("");
      refetchCandidate();
      setShowLoader(false);
    },
    onError: () => {
      notifyError("Something Went Wrong!");
      setShowLoader(false);
    }
  });
  const dropCandidate = useMutation(dropCandidateStatus, {
    onSuccess: () => {
      notifySuccess("Candidate Marked for Offer Release Successfully");
      setValidate(false);
      setShowFinal(false);
      setOfferDate(null);
      refetchCandidate();
    },
    onError: () => {
      notifyError("Error Occurred");
    },
  });
  const screenRejectNote = useMutation(addScreenRejectNote, {
    onSuccess: () => {
      Success(
        "Screen Reject Note is added and Email Sent to Partner Successfully"
      );
      refetchCandidate();
    },
    onError: (err) => {
      notifyError("Error ocurred: ", err);
    },
  });
  const readyforClient = () => {
    if (clientDate && fmRating) {
      clientShareMutaion?.mutate({
        candidateId: prevData?.candidateId,
        date: clientDate,
        fmRating: fmRating,
      });
      setShowLoader(true);
      setClientShare(false);
    } else {
      setValidate(true);
      setShowLoader(false);
    }
  };
  const readyforOffer = () => {
    if (offerDate) {
      dropCandidate.mutate({
        candidateId: prevData.candidateId,
        finalStatusDate: offerDate,
        status: 2,
        notes: "",
      });
    } else {
      setValidate(true);
    }
  };

  const submitScreenRejectNote = () => {
    screenRejectNote.mutate(screenRejectData);
  };
  const changeColor = (value) => {
    switch (value) {
      case "Approved":
        return "green";
      case "Hold":
        return "orange";
      case "Rejected":
        return "red";
      default:
        return null;
    }
  };
  const cancelMrkmutation = useMutation(cancelMarkForOffer, {
    onSuccess: (res) => {
      setCancelMrkShow(false);
      Success(res);
      refetchCandidate();
    },
  });

  const handleInputNumberClick = (e) => {
    e.target.addEventListener("mousewheel", (ev) => {
      ev.preventDefault();
    });
  };

  const isLastNameCheckboxChecked =
    lastNameCheckbox || candidateData?.lastName?.trim() === "";

  const formatIndianCurrency = (num) => {
    if (isNaN(num)) return num; // Handle invalid numbers
    if (num !== undefined && num !== null) {
      const [integerPart, decimalPart] = num?.toString()?.split('.');
      let lastThreeDigits = integerPart.slice(-3);
      let otherDigits = integerPart.slice(0, -3);

      if (otherDigits) {
        lastThreeDigits = ',' + lastThreeDigits; // Add comma before last three digits
      }

      // Add commas for the other digits in pairs
      const formattedIntegerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThreeDigits;

      // Return the formatted number
      return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
    }
  };

  useEffect(() => {
    if ((candidateData?.ctc !== NaN && candidateData?.ectc !== NaN) && (candidateData?.ctc !== null && candidateData?.ectc !== null) && (candidateData?.ctc !== undefined && candidateData?.ectc !== undefined) && (candidateData?.ctc !== 0) && (candidateData?.ectc !== '')) {
      let temp = (candidateData?.ectc - candidateData?.ctc) / candidateData?.ctc * 100
      setCandidateData((prev) => ({
        ...prev,
        incrementPercentage: Math.round(temp)
      }))
    }
    else setCandidateData((prev) => ({
      ...prev,
      incrementPercentage: null
    }))
  }, [candidateData?.ctc, candidateData?.ectc]);

  const handleKeyDownNotes = (event) => {
    // Check if the last character is a newline and the Enter key is pressed
    if ((candiDropForm?.note?.endsWith('\n') || candiDropForm?.futureHireReason?.endsWith('\n')) && event.key === 'Enter') {
      event.preventDefault(); // Prevent adding another line break
    }
  };

  useEffect(() => {
      calculateGaps();
    },[candidateData?.workExperiences]);

  return (
    <div style={{ overflowX: "hidden" }}>
      {candidateLoading ? <Loader /> : null}
      {showLoader ? <Loader /> : null}
      {screenRejectNote.isLoading ? <Loader /> : null}
      {priorityModal && <CandidatePriorityModal
        status={"candidate profile"}
        candidateId={candidateId}
        priorityId={priorityId}
        isOpen={priorityModal}
        onClose={() => setPriorityModal(false)}
        refetchCandidateData={refetchCandidate}
      />}
      <Modal
        open={showError}
        center
        onClose={() => setShowError(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container1}>
          <div>
            <p className={style.textM}>Internal Server Error.!</p>
          </div>
          <div className={style.M_button1}>
            <div className={style.button}>
              <button
                className={style.okay}
                onClick={() => setShowError(false)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setShowError(false);
                  }
                }}
                tabIndex={0}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={show3}
        center
        onClose={() => setShow3(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container1}>
          <div>
            <p className={style.textM}>{errorMsg}</p>
          </div>
          <div className={style.M_button1}>
            <div className={style.button}>
              <button
                className={style.okay}
                onClick={() => setShow3(false)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setShow3(false);
                  }
                }}
                tabIndex={0}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={show4}
        center
        onClose={() => setShow4(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container2}>
          <div>
            <p className={style.textM}>
              Please Upload Valid Format (pdf/Docx) and Size Should Be Less Than
              10 MB.
            </p>
          </div>
          <div className={style.M_button1}>
            <div className={style.button}>
              <button
                className={style.okay}
                onClick={() => {
                  setShow4(false);
                  setEdit({ ...editObj });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setShow4(false);
                    setEdit({ ...editObj });
                  }
                }}
                tabIndex={0}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
      >
        <div className={style?.topbutton}>
          {params?.candidateId ? null : (
            <div
              onClick={() => {
                setPriorityModal(true);
                setCandidateId(data?.id);
                setPriorityId(data?.priorityId);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setPriorityModal(true);
                }
              }}
              tabIndex={0}
              title={"Add Priority"}
              className={style.imgClass}
            >
              <MdAddTask style={{ cursor: "pointer", color: "black" }} />
            </div>
          )}
          {params?.candidateId ? null : (
            <div
              onClick={() => {
                openModal1();
                setShowCommentModal(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setShowCommentModal(true);
                }
              }}
              tabIndex={0}
              title={"Comments"}
              className={style.imgClass}
            >
              <MdOutlineInsertComment />
            </div>
          )}
          {params?.candidateId ? null : (
            <div
              onClick={() => (
                setShowWatchlistModal(true),
                setWatchlistData({
                  ...watchlistData,
                  toBeReviewed: null,
                })
              )}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setShowWatchlistModal(true);
                  setWatchlistData({
                    ...watchlistData,
                    toBeReviewed: null,
                  });
                }
              }}
              tabIndex={0}
              title={"Add Watchlist"}
              className={style.imgClass}
            >
              <FaUserTag />
            </div>
          )}
          {/* {prevData?.page1 !== "duplicityCheck" &&
            ValidationEmpAccess(
              validateAccess,
              accessCode?.position_management_edit
            ) && (
              <div
                onClick={() => {
                  setNextScreen("EditCandidate");
                  setPrevData((prevData) => ({
                    ...prevData,
                    page1: "candidateList",
                    page2: "ViewCandidate",
                    page3: "EditCandidate",
                    active: "EditCandidate",
                  }));
                }}
                title={"Edit"}
                className={style.imgClass}
              >
                <img src={editIcon} alt="Edit" width="20rem" height="20rem" />
              </div>
            )} */}
          {prevData?.page1 !== "duplicityCheck" &&
            ValidationEmpAccess(
              validateAccess,
              accessCode?.position_management_delete
            ) && (
              <div
                onClick={() => {
                  setShow(true);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setShow(true);
                  }
                }}
                tabIndex={0}
                title={"Delete"}
                className={style.imgClass}
              >
                <MdDelete />
              </div>
            )}
          {prevData?.page1 !== "duplicityCheck" &&
            ValidationEmpAccess(validateAccess, accessCode?.download) && (
              <div
                onClick={() => {
                  downloadMutation.mutate(prevData?.candidateId);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    downloadMutation.mutate(prevData?.candidateId);
                  }
                }}
                tabIndex={0}
                title={"Screening Comments"}
                className={style.imgClass}
              >
                <IoDownloadSharp />
              </div>
            )}

          {ValidationEmpAccess(
            validateAccess,
            accessCode?.recruiter_change
          ) && (
              <div
                title="Change Recruiter"
                onClick={() => {
                  setRecuiterShow(!recuiterShow);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setRecuiterShow(true);
                  }
                }}
                tabIndex={0}
                className={style.imgClass}
              >
                <MdOutlinePublishedWithChanges />
              </div>
            )}
        </div>
      </BreadCrum>
      <div className={style.main_create}>
        {/*Drop Candidate */}
        {data?.joiningDropResponseModel?.status === "Re-Activated" && (
          <div className={style.createpositiondrop}>
            <div className={style.Cu_row1} style={{ paddingTop: "1rem" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Date</p>
                <p className={style.cu_field2}>
                  {data?.joiningDropResponseModel?.dropJoinDate &&
                    moment(data?.joiningDropResponseModel?.dropJoinDate).format(
                      "DD MMM YY"
                    )}
                </p>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Note</p>
                <p className={style.cu_field2}>
                  {data?.joiningDropResponseModel?.dropNote}
                </p>
              </div>
              <div className={style.cu_col3}></div>
            </div>
          </div>
        )}
        {data?.joiningDropResponseModel?.status === "Dropped" && (
          <div className={style.createpositiondrop}>
            <div className={style.Cu_row1} style={{ paddingTop: "1rem" }}>
              <div style={{ width: "33%" }}>
                <p className={style.cu_label}>Drop Date</p>
                <p className={style.cu_field2}>
                  {data?.joiningDropResponseModel?.dropJoinDate &&
                    moment(data?.joiningDropResponseModel?.dropJoinDate).format(
                      "DD MMM YY"
                    )}
                </p>
              </div>
              <div style={{ width: "33%" }}>
                <p className={style.cu_label}>Drop Reason</p>
                <p className={style.cu_field2}>
                  {data?.joiningDropResponseModel?.dropReason}
                </p>
              </div>
              <div style={{ width: "33%" }}>
                <p className={style.cu_label}>Supporting Document</p>
                <div className={style.Cu_resume}>
                  {data?.joiningDropResponseModel?.dropUrl ? (
                    <p
                      className={style.rmpdf}
                      style={{
                        border: "none",
                        textDecoration: "underline",
                      }}
                    >
                      <a
                        href={data?.joiningDropResponseModel?.dropUrl}
                        target="_blank"
                        title={"Click Here"}
                        rel="noopener noreferrer"
                      >
                        {data?.joiningDropResponseModel?.dropUrl.split("@")[1]
                          ? data?.joiningDropResponseModel?.dropUrl.split(
                            "@"
                          )[1]?.length > 30
                            ? data?.joiningDropResponseModel?.dropUrl
                              .split("@")[1]
                              ?.slice(0, 30) + "..."
                            : data?.joiningDropResponseModel?.dropUrl.split(
                              "@"
                            )[1]
                          : "No file selected"}
                      </a>
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className={style.Cu_row1} style={{ paddingTop: "1rem" }}>
              <div style={{ width: "33%" }}>
                <p className={style.cu_label}>Future Hire</p>
                <p className={style.cu_field2}>
                  {data?.joiningDropResponseModel?.futureHire}
                </p>
              </div>
              <div style={{ width: "33%" }}>
                <p className={style.cu_label}>Future Hire Note</p>
                <p
                  className={style.cu_field2}
                  style={{
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  {futureHN?.split('\n')?.length > 1 ? futureHN.split('\n').filter(Boolean).map((element, index) => (
                    <>
                      {index < 2 && element}
                      {index == 1 ? <span onClick={() => { setShowFHNModal(true); }} style={{ cursor: "pointer", color: "blue", fontSize: "14px" }}>... Read more</span> : <br />}
                    </>
                  )) : futureHN?.length === 111 ? <>futureHN<span onClick={() => { setShowDNModal(true) }} style={{ cursor: "pointer", color: "blue", fontSize: "14px" }}>... Read more</span></> : futureHN}
                </p>
              </div>
              <div style={{ width: "33%" }}>
                <p className={style.cu_label}>Drop Note</p>
                <p
                  className={style.cu_field2}
                  style={{
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}

                >

                  {dropN?.split('\n')?.length > 1 ? dropN.split('\n').filter(Boolean).map((element, index) => (
                    <>
                      {index < 2 && element}
                      {index == 1 ? <span onClick={() => { if (data?.joiningDropResponseModel?.dropNote?.length > 110) setShowDNModal(true) }} style={{ cursor: "pointer", color: "blue", fontSize: "14px" }}>... Read more</span> : <br />}
                    </>
                  )) : dropN?.length === 111 ? <>{dropN}<span onClick={() => { if (data?.joiningDropResponseModel?.dropNote?.length > 110) setShowDNModal(true) }} style={{ cursor: "pointer", color: "blue", fontSize: "14px" }}>... Read more</span></> : dropN}
                </p>
              </div>
            </div>
          </div>
        )}
        {/*Partner */}
        {!edit.partner && (
          <div className={style.createposition} style={{ height: "80px" }}>
            <div className={style.Cu_row1} style={{ paddingTop: "1rem" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Account Management</p>
                <p className={style.cu_field2}>
                  {data?.forClient ? "Yes" : "No"}
                </p>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Partner Resource</p>
                <p className={style.cu_field2}>
                  {data?.isPartnerResource ? "Yes" : "No"}
                </p>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>Partner</p>
                <p className={style.cu_field2}>{data?.partnerName}</p>
              </div>
              <div className={style.cu_col3}>
                <p>
                  {prevData?.page1 !== "duplicityCheck" &&
                    ValidationEmpAccess(
                      validateAccess,
                      accessCode?.position_management_edit
                    ) && (
                      <div
                        onClick={() => {
                          setEdit({
                            ...edit,
                            partner: !edit.partner,
                            salary: !edit.salary,
                            disableSalaryBtn: true,
                            personalInfo: false,
                            experience: false,
                            workExp: false,
                            academic: false,
                            resume: false,
                            socialPlatform: false,
                            oInfo: false,
                          });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setEdit({
                              ...edit,
                              partner: false,
                              salary: false,
                              disableSalaryBtn: false,
                              personalInfo: false,
                              experience: false,
                              workExp: false,
                              academic: false,
                              resume: false,
                              socialPlatform: false,
                              oInfo: false,
                            });
                          }
                        }}
                        tabIndex={0}
                        title={"Edit"}
                        className={style.imgClass}
                      >
                        <img
                          src={editIcon}
                          alt="Edit"
                          width="20rem"
                          height="20rem"
                        />
                      </div>
                    )}
                </p>
              </div>
            </div>
          </div>
        )}
        {/*Partner edit*/}
        {edit.partner && (
          <div className={editStyle.createposition} style={{ height: "163px" }}>
            <div className={editStyle.Cu_row1}>
              <div className={editStyle.cu_col1}>
                <div className={editStyle.list} style={{ marginTop: "1rem" }}>
                  <input
                    type="checkbox"
                    id="account"
                    name="checkbox"
                    checked={candidateData?.forClient}
                    onChange={() =>
                      setCandidateData((prevData) => ({
                        ...prevData,
                        forClient: !prevData?.forClient,
                      }))
                    }
                  />
                  <label
                    for="account"
                    style={{
                      fontSize: "1rem",
                      display: "flex",
                      alignItems: "center",
                      color: "#000000",
                    }}
                  >
                    Account Management ?
                  </label>
                </div>
              </div>

              <div className={editStyle.cu_col2}>
                <div className={editStyle.list} style={{ marginTop: "1rem" }}>
                  <input
                    type="checkbox"
                    id="partner"
                    name="checkbox"
                    checked={candidateData?.isPartnerResource}
                    onChange={() =>
                      prevData?.isPartnerResource
                        ? setCandidateData((prevData) => ({
                          ...prevData,
                          isPartnerResource: !prevData?.isPartnerResource,
                        }))
                        : setCandidateData((prevData) => ({
                          ...prevData,
                          isPartnerResource: !prevData?.isPartnerResource,
                          partnerId: null,
                          proposedRates: 0,
                        }))
                    }
                  />
                  <label
                    for="partner"
                    style={{
                      fontSize: "1rem",
                      color: "#000000",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Partner Resource
                  </label>
                </div>
              </div>
              <div className={editStyle.cu_col3} style={{ marginTop: "1rem" }}>
                <p className={editStyle.cu_label}>
                  Partner{" "}
                  {candidateData?.isPartnerResource ? (
                    <span className={editStyle.mandatory}>*</span>
                  ) : null}
                </p>
                <Select
                  styles={customStyles}
                  menuPlacement="top"
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  isDisabled={!candidateData?.isPartnerResource}
                  value={
                    candidateData?.partnerId
                      ? getAllPartnerNameOption?.find(
                        (e) => e.value == candidateData?.partnerId
                      )
                      : null
                  }
                  options={getAllPartnerNameOption}
                  onChange={(e) =>
                    setCandidateData({
                      ...candidateData,
                      partnerId: e?.value,
                    })
                  }
                ></Select>
                <p
                  className={
                    candidateData?.isPartnerResource &&
                      nameValidate &&
                      !candidateData?.partnerId
                      ? editStyle.errorText
                      : editStyle.errorTextHidden
                  }
                >
                  Please Select Partner
                </p>
                <p className={editStyle.btnRow}>
                  <button
                    className={editStyle.cancel_btn22}
                    onClick={() => {
                      setEdit({
                        ...editObj,
                      });
                      setCandidateData({ ...tempCandidatedataRef.current });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setEdit({
                          ...editObj,
                        });
                        setCandidateData({ ...tempCandidatedataRef.current });
                      }
                    }}
                    tabIndex={0}
                  >
                    Cancel
                  </button>
                  <button
                    className={style.create_btn}
                    onClick={() => onCreateHandler("Partner")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onCreateHandler("Partner");
                      }
                    }}
                    tabIndex={0}
                  >
                    Update
                  </button>
                </p>
              </div>
            </div>
          </div>
        )}
        {/* personal Information */}
        {!edit.personalInfo && (
          <div className={style.createposition3}>
            <p className={style.title}>
              Candidate Information{" "}
              <p style={{ position: "relative", left: "35%" }}>
                {prevData?.page1 !== "duplicityCheck" &&
                  ValidationEmpAccess(
                    validateAccess,
                    accessCode?.position_management_edit
                  ) && (
                    <div
                      onClick={() => {
                        setEdit({
                          ...edit,
                          personalInfo: true,
                          partner: false,
                          salary: false,
                          disableSalaryBtn: false,
                          experience: false,
                          // workExp: false,
                          academic: false,
                          resume: false,
                          socialPlatform: false,
                          oInfo: false,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setEdit({
                            ...edit,
                            personalInfo: true,
                            partner: false,
                            salary: false,
                            disableSalaryBtn: false,
                            experience: false,
                            // workExp: false,
                            academic: false,
                            resume: false,
                            socialPlatform: false,
                            oInfo: false,
                          });
                        }
                      }}
                      tabIndex={0}
                      title={"Edit"}
                      className={style.imgClass}
                    >
                      <img
                        src={editIcon}
                        alt="Edit"
                        width="20rem"
                        height="20rem"
                      />
                    </div>
                  )}
              </p>
            </p>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Name</p>
                <p className={style.cu_field2}>{data?.name} </p>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Total Experience</p>
                <p className={style.cu_field2}>
                  {data?.totalExperienceYear}Y - {data?.totalExperienceMonth}M
                </p>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>Relevant Experience</p>
                <p className={style.cu_field2}>
                  {data?.relevantExperienceYear}Y -{" "}
                  {data?.relevantExperienceMonth}M
                </p>
              </div>
            </div>
            <div className={style.Cu_row1} style={{ marginTop: "1.7rem" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Core Skill</p>
                <p className={style.cu_field2}>{data?.coreSkill}</p>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Status</p>
                <p
                  className={style.cu_field2}
                  style={
                    data?.joiningDropResponseModel?.status === "Dropped"
                      ? {
                        color: "red",
                      }
                      : {
                        color: "#0fd90f",
                      }
                  }
                >
                  {data?.joiningDropResponseModel?.status}
                </p>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>Recruiter</p>
                <p className={style.cu_field2}>{data?.recruiter}</p>
              </div>
            </div>
            {/*Status*/}

            <div className={style.Cu_row1} style={{ marginTop: "1.7rem" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Screen</p>
                <p
                  className={style.cu_field2}
                  style={{ color: "red", fontWeight: "600" }}
                >
                  {data?.screening}
                </p>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Screening Note</p>
                <p className={style.cu_field2}>{data?.screenNote}</p>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>Sourced Date</p>
                <p className={style.cu_field2}>
                  {moment(data?.sourcedDate).format("DD MMM YY")}
                </p>
              </div>
            </div>
            <div className={style.Cu_row1} style={{ marginTop: "1.7rem" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Pipeline Age</p>
                <p className={style.cu_field2}>{data?.pipelineAge ?? ""}</p>
              </div>
            </div>
          </div>
        )}
        {/* personal Information Edit */}
        {edit.personalInfo && (
          <div className={editStyle.createposition} style={{ height: "15rem" }}>
            <p className={editStyle.title}>Candidate Information </p>
            <div
              className={editStyle.Cu_row1}
              style={{ marginBottom: "0px", height: "100px" }}
            >
              <div className={editStyle.cu_col11}>
                <p className={editStyle.cu_label}>
                  First Name<span className={editStyle.mandatory}>*</span>
                </p>
                <input
                  className={editStyle.bigInput}
                  onChange={(e) =>
                    setCandidateData({
                      ...candidateData,
                      firstName: e?.target?.value,
                    })
                  }
                  value={candidateData?.firstName}
                />
                <p
                  className={
                    (nameValidate || validate) &&
                      !candidateData?.firstName?.trim()
                      ? editStyle.errorText
                      : editStyle.errorTextHidden
                  }
                >
                  Please Enter First Name
                </p>
              </div>
              <div className={style.cu_col21}>
                <p style={{ display: "flex" }} className={style.cu_label}>
                  <div>
                    Last Name
                    <span className={editStyle.mandatory}>
                      {lastNameCheckbox ? "" : "*"}
                    </span>
                  </div>
                </p>
                <input
                  className={specialStyle.bigInput}
                  onChange={(e) => {
                    setCandidateData({
                      ...candidateData,
                      lastName: e?.target?.value,
                    });
                  }}
                  value={candidateData?.lastName}
                  disabled={lastNameCheckbox}
                />
                <div
                  className={style.list}
                  style={{ transform: "translateX(-9px)" }}
                >
                  <input
                    type="checkbox"
                    id="lastname"
                    name="checkbox"
                    checked={lastNameCheckbox}
                    onChange={() => {
                      setLastNameCheckbox((prevData) => !prevData);
                      if (!lastNameCheckbox) {
                        setCandidateData({ ...candidateData, lastName: "" });
                      }
                    }}
                  />
                  <label
                    htmlFor="lastname"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                      color: "#000000",
                    }}
                  >
                    {" "}
                    Mark as NA
                  </label>
                </div>
                <p
                  className={
                    (validate || nameValidate) &&
                      !lastNameCheckbox &&
                      !candidateData.lastName &&
                      candidateData.lastName === ""
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Last Name
                </p>
              </div>

              <div className={editStyle.cu_col31}>
                <p className={editStyle.cu_label}>
                  Total Experience{" "}
                  {candidateData?.isPartnerResource ? null : (
                    <span className={editStyle.mandatory}>*</span>
                  )}
                </p>
                <div
                  className={editStyle.multiinput3}
                  style={{ height: "3.2rem" }}
                >
                  <div style={{ width: "100%" }}>
                    <input
                      style={{ width: "100%" }}
                      className={editStyle.cu_field22}
                      placeholder="Years"
                      type="number"
                      min="0"
                      onChange={(e) =>
                        setCandidateData({
                          ...candidateData,
                          totalExperienceYear: parseFloat(e?.target?.value),
                        })
                      }
                      value={candidateData?.totalExperienceYear}
                      onKeyDown={handleKeyDown}
                      onClick={handleInputClick}
                    />
                    <p
                      className={
                        (nameValidate || validate) && !onTotalExp(candidateData)
                          ? editStyle.errorText
                          : editStyle.errorTextHidden
                      }
                    >
                      Please Enter Total Experience
                    </p>
                  </div>
                  <div style={{ width: "100%" }}>
                    <input
                      style={{ width: "100%" }}
                      className={editStyle.cu_field22}
                      placeholder="Months"
                      type="number"
                      max="12"
                      min="0"
                      onChange={(e) =>
                        setCandidateData({
                          ...candidateData,
                          totalExperienceMonth: parseFloat(e?.target?.value),
                        })
                      }
                      value={candidateData?.totalExperienceMonth}
                      onKeyDown={handleKeyDown}
                      onClick={handleInputClick}
                    />
                    <p
                      className={
                        (nameValidate || validate) &&
                          candidateData?.totalExperienceMonth > 11 &&
                          validateExp(candidateData)
                          ? editStyle.errorText
                          : editStyle.errorTextHidden
                      }
                    >
                      Max 11 months allowed
                    </p>
                  </div>
                </div>
                <p
                  className={
                    (nameValidate || validate) &&
                      !validateExp(candidateData) &&
                      onTotalExp(candidateData)
                      ? editStyle.errorText
                      : editStyle.errorTextHidden
                  }
                >
                  Relevant Experience should be less than Total Experience
                </p>
              </div>
              <div className={editStyle.cu_col41}>
                <p className={editStyle.cu_label}>Relevant Experience</p>
                <div className={editStyle.multiinput3}>
                  <div style={{ width: "100%" }}>
                    <input
                      style={{ width: "100%" }}
                      className={editStyle.cu_field22}
                      placeholder="Years"
                      type="number"
                      min="0"
                      onChange={(e) =>
                        setCandidateData({
                          ...candidateData,
                          relevantExperienceYear: parseFloat(e?.target?.value),
                        })
                      }
                      value={candidateData?.relevantExperienceYear}
                      onKeyDown={handleKeyDown}
                      onClick={handleInputClick}
                    />
                    <p
                      className={
                        (nameValidate || validate) &&
                          !onRelevantExp(candidateData)
                          ? editStyle.errorText
                          : editStyle.errorTextHidden
                      }
                    >
                      Please Enter Relevant Experience
                    </p>
                  </div>
                  <div style={{ width: "100%" }}>
                    <input
                      style={{ width: "100%" }}
                      className={editStyle.cu_field22}
                      placeholder="Months"
                      type="number"
                      max="12"
                      min="0"
                      onChange={(e) =>
                        setCandidateData({
                          ...candidateData,
                          relevantExperienceMonth: parseFloat(e?.target?.value),
                        })
                      }
                      value={candidateData?.relevantExperienceMonth}
                      onKeyDown={handleKeyDown}
                      onClick={handleInputClick}
                    />
                    <p
                      className={
                        (nameValidate || validate) &&
                          candidateData?.relevantExperienceMonth > 11 &&
                          validateExp(candidateData)
                          ? editStyle.errorText
                          : editStyle.errorTextHidden
                      }
                    >
                      Max 11 months allowed
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={editStyle.Cu_row1}>
              <div className={editStyle.cu_col1}>
                <p className={editStyle.cu_label}>
                  Core Skill
                  {candidateData?.isPartnerResource ? null : (
                    <span className={editStyle.mandatory}>*</span>
                  )}
                </p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  options={getCoreSkillOption}
                  onChange={(e) =>
                    setCandidateData({ ...candidateData, coreSkill: e?.value })
                  }
                  value={getCoreSkillOption?.find(
                    (i) => i?.label === candidateData?.coreSkill
                  )}
                ></Select>
                <p
                  className={
                    !nameValidate && validate && !candidateData?.coreSkill
                      ? editStyle.errorText2
                      : editStyle.errorTextHidden
                  }
                >
                  Please Select Core Skill
                </p>
              </div>
              <div className={editStyle.cu_col21}></div>
              <div className={editStyle.cu_col31}></div>
              <div
                className={editStyle.cu_col41}
                style={{ paddingTop: "3rem" }}
              >
                <p className={editStyle.btnRow}>
                  <button
                    className={editStyle.cancel_btn22}
                    style={{ width: "4rem" }}
                    onClick={() => {
                      setEdit({
                        partner: false,
                        salary: false,
                        disableSalaryBtn: false,
                        personalInfo: false,
                        experience: false,
                        // workExp: false,
                        academic: false,
                        resume: false,
                        socialPlatform: false,
                        oInfo: false,
                      });
                      setCandidateData({ ...tempCandidatedataRef.current });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Escape") {
                        setEdit({
                          partner: false,
                          salary: false,
                          disableSalaryBtn: false,
                          personalInfo: false,
                          experience: false,
                          // workExp: false,
                          academic: false,
                          resume: false,
                          socialPlatform: false,
                          oInfo: false,
                        });
                        setCandidateData({ ...tempCandidatedataRef.current });
                      }
                    }}
                    tabIndex={0}
                  >
                    Cancel
                  </button>
                  <button
                    className={style.create_btn}
                    onClick={() => onCreateHandler("Personal Information")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onCreateHandler("Personal Information");
                      }
                    }}
                    tabIndex={0}
                    style={{ width: "5rem" }}
                  >
                    Update
                  </button>
                </p>
              </div>
            </div>
          </div>
        )}
        {/* TL Decision Status */}
        <div className={style.createposition3} style={{ marginTop: "2rem" }}>
          <p className={style.title}>TL Decision</p>
          <div className={style.Cu_row1}>
            <div className={style.cu_col1} style={{ flex: "0 0 15%" }}>
              <p className={style.cu_label}>Date</p>
              <p className={style.cu_field2}>
                {data?.tldecisionDate &&
                  moment(data?.tldecisionDate).format("DD MMM yyyy")}{" "}
              </p>
            </div>
            <div className={style.cu_col2} style={{ flex: "1 0 10%" }}>
              <p className={style.cu_label}>Status</p>
              <p
                className={style.cu_field2}
                style={{ color: changeColor(data?.statusByTeamLead) }}
              >
                {data?.statusByTeamLead}
              </p>
            </div>
            <div className={style.cu_col2} style={{ flex: "2 0 20%" }}>
              <p className={style.cu_label}>Lead</p>
              <p className={style.cu_field2}>{data?.teamLead}</p>
            </div>
            <div
              className={style.cu_col3}
              style={{ flex: "0 0 50%", wordBreak: "break-word" }}
            >
              <p className={style.cu_label}>TL Decision Note</p>
              {(data?.statusByTeamLead === "Rejected" ||
                data?.statusByTeamLead === "Hold") &&
                data?.noteByTeamLead?.length > 200 ? (
                <p className={style.cu_field2} title={data?.noteByTeamLead}>
                  {data?.noteByTeamLead
                    ?.substr(0, 200)
                    .split("\n")
                    .map((item) => (
                      <div>{item}</div>
                    ))}
                  ...
                </p>
              ) : (
                <p className={style.cu_field2}>
                  {data?.noteByTeamLead?.length > 0 &&
                    data?.noteByTeamLead
                      .split("\n")
                      .map((item) => <div>{item}</div>)}
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Experience years */}
        {!edit.experience && (
          <div
            className={style.createposition}
            style={{ marginTop: "2rem", height: "140px" }}
          >
            <p className={style.title}>
              Contact{" "}
              <p style={{ position: "relative", left: "35%" }}>
                {prevData?.page1 !== "duplicityCheck" &&
                  ValidationEmpAccess(
                    validateAccess,
                    accessCode?.position_management_edit
                  ) && (
                    <div
                      onClick={() => {
                        setEdit({
                          ...edit,
                          experience: true,
                          partner: false,
                          salary: false,
                          disableSalaryBtn: false,
                          personalInfo: false,
                          workExp: false,
                          academic: false,
                          resume: false,
                          socialPlatform: false,
                          oInfo: false,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setEdit({
                            ...edit,
                            experience: true,
                            partner: false,
                            salary: false,
                            disableSalaryBtn: false,
                            personalInfo: false,
                            workExp: false,
                            academic: false,
                            resume: false,
                            socialPlatform: false,
                            oInfo: false,
                          });
                        }
                      }}
                      tabIndex={0}
                      title={"Edit"}
                      className={style.imgClass}
                    >
                      <img
                        src={editIcon}
                        alt="Edit"
                        width="20rem"
                        height="20rem"
                      />
                    </div>
                  )}
              </p>
            </p>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Email </p>
                <div className={style.multiinput3}>
                  <p className={style.cu_field22}>
                    <EmailTooltip
                      value={data?.email}
                      limit={23}
                      index={"email"}
                    />
                  </p>
                </div>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Mobile Number</p>
                <div className={style.multiinput3}>
                  <p className={style.cu_field22}><MobileTooltip
                    value={data?.mobileNo}
                  /></p>
                </div>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>Alternate Mobile Number</p>
                <p className={style.cu_field2}><MobileTooltip
                  value={data?.alternateMobileNo}
                /></p>
              </div>
            </div>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Alternate Email Id</p>
                <div className={style.multiinput3}>
                  <p className={style.cu_field22}>
                    <EmailTooltip
                      value={data?.alternateEmail}
                      limit={23}
                      index={"emailAlt"}
                    />
                  </p>
                </div>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Marital Status</p>
                <div className={style.multiinput3}>
                  {data?.hasOwnProperty("maritalStatus") && (
                    <p className={style.cu_field2}>{data?.maritalStatus}</p>
                  )}
                </div>
              </div>
              <div className={style.cu_col3}></div>
            </div>
          </div>
        )}
        {/* Edit experience section */}
        {edit.experience && (
          <div
            className={editStyle.createposition}
            style={{ height: "200px", marginTop: "2rem" }}
          >
            <p className={editStyle.title}>
              Contact{" "}
              {!loader2 && checkByEmail && (
                <span
                  className={style.searchLoader}
                  style={{ position: "absolute", right: "10%" }}
                >
                  {checkByEmail?.loading && (
                    <div>
                      <img
                        src={whiteLoader}
                        alt="whiteLoader"
                        width="150px"
                        height="30px"
                      />
                      <span style={{ fontSize: "14px", fontWeight: "700" }}>
                        Checking for duplicacy
                      </span>
                    </div>
                  )}
                  {checkByEmail?.hasOwnProperty("exists") ? (
                    checkByEmail?.exists ? (
                      <div>
                        {/* <img
                  src={redLoader}
                  alt="whiteLoader"
                  width="100px"
                  height="20px"
            />*/}
                        <spam style={{ fontSize: "15px", color: "red" }}>
                          Email Id already exists
                        </spam>
                        &nbsp;
                        <HiInformationCircle
                          style={{ fontSize: "23px" }}
                          onMouseEnter={() => setDetails(true)}
                          onMouseLeave={() => setDetails(false)}
                        />
                      </div>
                    ) : (
                      <div>
                        <span style={{ fontSize: "15px", color: "green" }}>
                          Unique Email Id
                        </span>
                        &nbsp;
                        <img
                          src={check}
                          alt="whiteLoader"
                          width="25px"
                          height="18px"
                        />
                      </div>
                    )
                  ) : null}
                  {details && (
                    <section className={style.detailsCantainer}>
                      <div className={style.roeww}>
                        <div className={style.label}>Recuiter Name:</div>
                        <div className={style.value}>
                          {checkByEmail?.recruiterName}
                        </div>
                      </div>
                      <div className={style.roeww}>
                        <div className={style.label}>Lead Name:</div>
                        <div className={style.value}>
                          {checkByEmail?.leadName}
                        </div>
                      </div>
                      <div className={style.roeww}>
                        <div className={style.label}>Source Date:</div>
                        <div className={style.value}>
                          {moment(checkByEmail?.sourceDate).format(
                            "DD MMM yyyy"
                          )}
                        </div>
                      </div>
                      {checkByEmail?.droppedReason && (
                        <div className={style.roeww}>
                          <div className={style.label}>Dropped Reason:</div>
                          <div className={style.value}>
                            {checkByEmail?.droppedReason}
                          </div>
                        </div>
                      )}
                    </section>
                  )}
                </span>
              )}
              {loader2 && checkByNumber && (
                <span
                  className={style.searchLoader}
                  style={{ position: "absolute", right: "10%" }}
                >
                  {checkByNumber?.loading && (
                    <div>
                      <img
                        src={whiteLoader}
                        alt="whiteLoader"
                        width="150px"
                        height="30px"
                      />
                      <span style={{ fontSize: "14px", fontWeight: "700" }}>
                        Checking for duplicacy
                      </span>
                    </div>
                  )}
                  {checkByNumber?.hasOwnProperty("exists") ? (
                    checkByNumber?.exists ? (
                      <div>
                        {/* <img
                  src={redLoader}
                  alt="whiteLoader"
                  width="100px"
                  height="20px"
            />*/}
                        <spam style={{ fontSize: "15px", color: "red" }}>
                          Mobile Number already exists
                        </spam>
                        &nbsp;
                        <HiInformationCircle
                          style={{ fontSize: "23px" }}
                          onMouseEnter={() => setDetails(true)}
                          onMouseLeave={() => setDetails(false)}
                        />
                      </div>
                    ) : (
                      <div>
                        <spam style={{ fontSize: "15px", color: "green" }}>
                          Valid Mobile Number
                        </spam>
                        &nbsp;
                        <img
                          src={check}
                          alt="whiteLoader"
                          width="25px"
                          height="18px"
                        />
                      </div>
                    )
                  ) : (
                    checkByNumber?.hasOwnProperty("message") && (
                      <div>
                        <span style={{ fontSize: "15px", color: "red" }}>
                          {checkByNumber?.message}
                        </span>
                      </div>
                    )
                  )}
                  {details && (
                    <section className={style.detailsCantainer}>
                      <div className={style.roeww}>
                        <div className={style.label}>Recuiter Name:</div>
                        <div className={style.value}>
                          {checkByNumber?.recruiterName}
                        </div>
                      </div>
                      <div className={style.roeww}>
                        <div className={style.label}>Lead Name:</div>
                        <div className={style.value}>
                          {checkByNumber?.leadName}
                        </div>
                      </div>
                      <div className={style.roeww}>
                        <div className={style.label}>Source Date:</div>
                        <div className={style.value}>
                          {moment(checkByNumber?.sourceDate).format(
                            "DD MMM yyyy"
                          )}
                        </div>
                      </div>
                    </section>
                  )}
                </span>
              )}
            </p>
            <div className={editStyle.Cu_row1} style={{ height: "88px" }}>
              <div className={editStyle.cu_col11}>
                <p className={editStyle.cu_label}>
                  Email Id
                  {candidateData?.isPartnerResource ? null : (
                    <span className={editStyle.mandatory}>*</span>
                  )}
                </p>
                <input
                  className={editStyle.bigInput}
                  onChange={(e) => {
                    checkDuplicateeMail();
                    setLoader2(false);
                    setCandidateData({
                      ...candidateData,
                      email: e?.target?.value,
                    });
                  }}
                  value={candidateData?.email}
                  style={
                    checkByEmail?.exists ? { border: "1px solid red" } : null
                  }
                  onKeyDown={handleKeyDown}
                  onClick={handleInputClick}
                />
                <p
                  className={
                    (!nameValidate &&
                      validate &&
                      !candidateData?.email?.trim()) ||
                      (!nameValidate &&
                        validate &&
                        !validateEmail(candidateData?.email?.trim()))
                      ? editStyle.errorText
                      : editStyle.errorTextHidden
                  }
                >
                  {!validateEmail(candidateData?.email?.trim())
                    ? "Please Enter Valid Email Id"
                    : "Please Enter Email Id"}
                </p>
              </div>
              <div className={editStyle.cu_col21}>
                <p className={editStyle.cu_label}>
                  Mobile Number
                  {candidateData?.isPartnerResource ? null : (
                    <span className={editStyle.mandatory}>*</span>
                  )}
                </p>
                <input
                  className={editStyle.bigInput}
                  type="text"
                  onChange={(e) => {
                    checkDuplicateeNumber();
                    setLoader2(true);
                    setCandidateData({
                      ...candidateData,
                      mobileNo: e?.target?.value,
                    });
                  }}
                  value={candidateData?.mobileNo}
                  style={
                    checkByNumber?.exists ? { border: "1px solid red" } : null
                  }
                  onKeyDown={handleKeyDown}
                  onClick={handleInputClick}
                />
                <p
                  className={
                    !nameValidate &&
                      validate &&
                      (!candidateData?.mobileNo ||
                        candidateData?.mobileNo?.length < 10 ||
                        candidateData?.mobileNo?.length > 14)
                      ? editStyle.errorText
                      : editStyle.errorTextHidden
                  }
                >
                  {!candidateData?.mobileNo ? (
                    <>Please Enter Mobile Number</>
                  ) : (
                    <>Please Enter Valid Mobile Number</>
                  )}
                </p>
              </div>

              <div className={editStyle.cu_col31}>
                <p className={editStyle.cu_label}>Alternate Mobile Number</p>
                <input
                  className={editStyle.bigInput}
                  type="number"
                  value={candidateData?.alternateMobileNo}
                  onChange={(e) =>
                    setCandidateData({
                      ...candidateData,
                      alternateMobileNo: e.target.value,
                    })
                  }
                  onKeyDown={handleKeyDown}
                  onClick={handleInputClick}
                />
                <p
                  className={
                    ((validate || nameValidate) &&
                      candidateData?.alternateMobileNo?.length < 10) ||
                      ((validate || nameValidate) &&
                        candidateData?.alternateMobileNo?.length >= 13)
                      ? editStyle.errorText
                      : editStyle.errorTextHidden
                  }
                >
                  Please Enter Valid Alternate Mobile Number
                </p>
              </div>
              <div className={editStyle.cu_col41}>
                <p className={editStyle.cu_label}>Alternate Email Id</p>
                <input
                  className={editStyle.bigInput}
                  value={candidateData?.alternateEmail}
                  onChange={(e) =>
                    setCandidateData({
                      ...candidateData,
                      alternateEmail: e.target.value,
                    })
                  }
                  onKeyDown={handleKeyDown}
                  onClick={handleInputClick}
                />
                <p
                  className={
                    (nameValidate || validate) &&
                      candidateData?.alternateEmail?.trim() &&
                      !validateEmail(candidateData?.alternateEmail?.trim())
                      ? editStyle.errorText
                      : editStyle.errorTextHidden
                  }
                >
                  Please Enter Valid Alternate Email Id
                </p>
              </div>
            </div>
            <div className={editStyle.Cu_row1}>
              <div className={editStyle.cu_col11}>
                <p className={editStyle.cu_label}>Marital Status</p>

                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  options={[
                    { label: "Married", value: true },
                    { label: "Unmarried", value: false },
                  ]}
                  onChange={(e) => {
                    setCandidateData({
                      ...candidateData,
                      maritalStatus: e?.value,
                    });
                  }}
                  value={
                    candidateData?.hasOwnProperty("maritalStatus")
                      ? findVal()
                      : ""
                  }
                ></Select>
              </div>
              <div className={editStyle.cu_col21}></div>
              <div className={editStyle.cu_col31}></div>
              <div className={editStyle.cu_col41}>
                <p className={editStyle.btnRow}>
                  <button
                    className={editStyle.cancel_btn22}
                    onClick={() => {
                      setEdit({
                        ...editObj,
                      });
                      setCandidateData({ ...tempCandidatedataRef.current });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setEdit({
                          ...editObj,
                        });
                        setCandidateData({ ...tempCandidatedataRef.current });
                      }
                    }}
                    tabIndex={0}
                  >
                    Cancel
                  </button>
                  <button
                    className={style.create_btn}
                    onClick={() => onCreateHandler("Experience")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onCreateHandler("Experience");
                      }
                    }}
                    tabIndex={0}
                  >
                    Update
                  </button>
                </p>
              </div>
            </div>
          </div>
        )}
        {/* Rates */}
        {data?.isPartnerResource
          ? !edit.salary && (
            <div className={style.createposition}>
              <p className={style.title}>
                Rates{" "}
                <p style={{ position: "relative", left: "40%" }}>
                  {prevData?.page1 !== "duplicityCheck" &&
                    ValidationEmpAccess(
                      validateAccess,
                      accessCode?.position_management_edit
                    ) && (
                      <div
                        onClick={() => {
                          setEdit({
                            ...edit,
                            salary: !edit.salary,
                            disableSalaryBtn: false,
                            partner: false,
                            personalInfo: false,
                            experience: false,
                            workExp: false,
                            academic: false,
                            resume: false,
                            socialPlatform: false,
                            oInfo: false,
                          });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setEdit({
                              ...edit,
                              salary: !edit.salary,
                              disableSalaryBtn: false,
                              partner: false,
                              personalInfo: false,
                              experience: false,
                              workExp: false,
                              academic: false,
                              resume: false,
                              socialPlatform: false,
                              oInfo: false,
                            });
                          }
                        }}
                        tabIndex={0}
                        title={"Edit"}
                        className={style.imgClass}
                      >
                        <img
                          src={editIcon}
                          alt="Edit"
                          width="20rem"
                          height="20rem"
                        />
                      </div>
                    )}
                </p>
              </p>
              <div className={style.Cu_row1}>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>Proposed Rate</p>
                  <p className={style.cu_field2}>{data?.proposedRates}</p>
                </div>
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>
                    Final Negotiable Rates (Monthly)
                  </p>
                  <p className={style.cu_field2}>
                    {data?.finalNegotiationRates}
                  </p>
                </div>
                <div className={style.cu_col3}>
                  <p className={style.cu_label}>Approved By</p>

                  <p className={style.cu_field2}>
                    {data?.approvedBy &&
                      getgetPositionOwnerListOption?.find(
                        (e) => Number(e.value) === Number(data?.approvedBy)
                      )?.label}
                  </p>
                </div>
              </div>
            </div>
          )
          : !edit.salary && (
            <div className={style.createposition}>
              {/*Salary*/}
              <p className={style.title}>
                Salary{" "}
                <p style={{ position: "relative", left: "40%" }}>
                  {prevData?.page1 !== "duplicityCheck" &&
                    ValidationEmpAccess(
                      validateAccess,
                      accessCode?.position_management_edit
                    ) && (
                      <div
                        onClick={() => {
                          setEdit({
                            ...edit,
                            salary: !edit.salary,
                            disableSalaryBtn: false,
                            partner: false,
                            personalInfo: false,
                            experience: false,
                            workExp: false,
                            academic: false,
                            resume: false,
                            socialPlatform: false,
                            oInfo: false,
                          });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setEdit({
                              ...edit,
                              salary: !edit.salary,
                              disableSalaryBtn: false,
                              partner: false,
                              personalInfo: false,
                              experience: false,
                              workExp: false,
                              academic: false,
                              resume: false,
                              socialPlatform: false,
                              oInfo: false,
                            });
                          }
                        }}
                        tabIndex={0}
                        title={"Edit"}
                        className={style.imgClass}
                      >
                        <img
                          src={editIcon}
                          alt="Edit"
                          width="20rem"
                          height="20rem"
                        />
                      </div>
                    )}
                </p>
              </p>
              <div className={style.Cu_row1}>
                <div className={style.cu_col1} style={{ flex: "0 0 20%" }}>
                  <p className={style.cu_label}>CTC (LPA)</p>
                  <p className={style.cu_field2}>{data?.ctc} {data?.monthlySalaryByCTC ? `(${formatIndianCurrency(Math.round(data?.monthlySalaryByCTC))})` : ''}</p>
                </div>
                <div className={style.cu_col2} style={{ flex: "0 0 20%" }}>
                  <p className={style.cu_label}>As per market standard</p>
                  <p className={style.cu_field2}>
                    {data?.asPerMarketStandard ? "Yes" : "No"}
                  </p>
                </div>
                <div className={style.cu_col2} style={{ flex: "0 0 15%" }}>
                  {data?.asPerMarketStandard ? (
                    <></>
                  ) : (
                    <>
                      <p className={style.cu_label}>ECTC (LPA)</p>
                      <p className={style.cu_field2}>{data?.ectc} {data?.monthlySalaryByECTC ? `(${formatIndianCurrency(Math.round(data?.monthlySalaryByECTC))})` : ''}</p>
                    </>
                  )}
                </div>
                {!data?.asPerMarketStandard && <div
                  className={style.cu_col2}
                  style={{ flex: "0 0 15%" }}
                >
                  <p className={editStyle.cu_label}>Increment %</p>
                  <p className={style.cu_field2}>{data?.incrementPercentage || data?.incrementPercentage == 0 ? `${Math.round(data?.incrementPercentage)}%` : ''}</p>
                </div>}
                <div className={style.cu_col2} style={{ flex: "0 0 20%" }}>
                  <p className={style.cu_label}>RCTC (LPA)</p>
                  <p className={style.cu_field2}>{data?.revisedCtc}</p>
                </div>

                <div className={style.cu_col3} style={{ flex: "0 0 23%" }}>
                  {data?.asPerMarketStandard ? (
                    <></>
                  ) : (
                    <>
                      <p className={style.cu_label}>Negotiable </p>
                      {data?.hasOwnProperty("negotiable") && (
                        <p className={style.cu_field2}>
                          {data?.negotiable === null
                            ? null
                            : data?.negotiable
                              ? "Yes"
                              : "No"}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        {/*Edit Rates */}
        {candidateData?.isPartnerResource
          ? edit.salary && (
            <div
              className={editStyle.createposition}
              style={{ height: !edit.disableSalaryBtn && "155px" }}
            >
              <p className={editStyle.title}>Rates</p>
              <div className={editStyle.Cu_row1}>
                <div className={editStyle.cu_col1}>
                  <p className={editStyle.cu_label}>
                    Proposed Rate
                    {!candidateData?.isPartnerResource ? null : (
                      <span className={editStyle.mandatory}>*</span>
                    )}
                  </p>
                  <input
                    className={editStyle.bigInput}
                    type={"number"}
                    min={0}
                    // onClick={handleInputNumberClick}
                    value={candidateData?.proposedRates}
                    style={{ marginTop: "0.4rem" }}
                    onChange={(e) =>
                      setCandidateData({
                        ...candidateData,
                        ctc: null,
                        proposedRates: parseFloat(
                          e.target.value.replace(/[^0-9.-]/g, "")
                        ),
                      })
                    }
                    onKeyDown={handleKeyDown}
                    onClick={handleInputClick}
                  ></input>
                  <p
                    className={
                      nameValidate && !candidateData?.proposedRates
                        ? editStyle.errorText
                        : editStyle.errorTextHidden
                    }
                  >
                    Please Enter Proposed Rate
                  </p>
                </div>

                <div className={editStyle.cu_col2}>
                  <div>
                    <p className={editStyle.cu_label}>
                      Final Negotiated Rates (Monthly)
                    </p>
                    <input
                      className={editStyle.bigInput}
                      type={"number"}
                      // onClick={handleInputNumberClick}
                      style={{ marginTop: "0.4rem" }}
                      min={0}
                      value={candidateData?.finalNegotiationRates}
                      onChange={(e) =>
                        setCandidateData({
                          ...candidateData,
                          ectc: null,
                          finalNegotiationRates: parseFloat(e?.target?.value),
                        })
                      }
                      onKeyDown={handleKeyDown}
                      onClick={handleInputClick}
                    />
                  </div>
                </div>
                <div className={editStyle.cu_col3}>
                  <p className={editStyle.cu_label}>Approved By </p>
                  <Select
                    styles={customStyles}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    value={getgetPositionOwnerListOption?.find(
                      (e) => e.value == candidateData?.approvedBy
                    )}
                    options={getgetPositionOwnerListOption}
                    onChange={(e) =>
                      setCandidateData({
                        ...candidateData,
                        negotiable: null,
                        approvedBy: e?.value,
                      })
                    }
                  ></Select>
                  {!edit.disableSalaryBtn && (
                    <p
                      style={{
                        textAlign: "right",
                        position: "relative",
                        top: "7%",
                        left: "3%",
                      }}
                    >
                      <button
                        className={editStyle.cancel_btn22}
                        onClick={() => {
                          setEdit({ ...editObj });
                          setCandidateData({
                            ...tempCandidatedataRef.current,
                          });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setEdit({ ...editObj });
                            setCandidateData({
                              ...tempCandidatedataRef.current,
                            });
                          }
                        }}
                        tabIndex={0}
                      >
                        Cancel
                      </button>
                      <button
                        className={style.create_btn}
                        onClick={() => {
                          onCreateHandler("Rates");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            onCreateHandler("Rates");
                          }
                        }}
                        tabIndex={0}
                      >
                        Update
                      </button>
                    </p>
                  )}
                </div>
              </div>
            </div>
          )
          : // edit salary
          edit.salary && (
            <div
              className={editStyle.createposition}
              style={{ height: !edit.disableSalaryBtn && "165px" }}
            >
              <p className={editStyle.title}>Salary</p>
              <div
                className={editStyle.Cu_row1}
                style={{ paddingBottom: "1rem", alignItem: "center" }}
              >
                <div
                  className={editStyle.cu_col1}
                  style={{ flex: "0 0 15%" }}
                >
                  <p className={editStyle.cu_label}>
                    CTC (LPA){" "}
                    {candidateData?.isPartnerResource ? null : (
                      <span className={editStyle.mandatory}>*</span>
                    )}
                  </p>
                  <input
                    className={editStyle.cu_field2}
                    type="number"
                    min="0"
                    onChange={(e) =>
                      setCandidateData((prev) => ({
                        ...prev,
                        ctc: parseFloat(e?.target?.value),
                      }))
                    }
                    value={candidateData?.ctc}
                    onKeyDown={handleKeyDown}
                    onClick={handleInputClick}
                  />
                  {!nameValidate &&
                    validate &&
                    candidateData?.ctc !== null && (
                      <p
                        className={
                          (candidateData?.ctc === 0 ? false : (!nameValidate && validate && !candidateData?.ctc))
                            ? editStyle.errorText
                            : editStyle.errorTextHidden
                        }
                      >
                        Please Enter CTC (LPA)
                      </p>
                    )}
                  {/* {!nameValidate && validate && candidateData?.ctc === 0 && (
                      <p
                        className={
                          !nameValidate && validate && !candidateData?.ctc
                            ? editStyle.errorText
                            : editStyle.errorTextHidden
                        }
                      >
                        Please enter CTC greater than 0.
                      </p>
                    )} */}
                  {checkValidPoint(candidateData?.ctc) && (
                    <p
                      className={
                        checkValidPoint(candidateData?.ctc) &&
                          candidateData?.ctc
                          ? editStyle.errorText
                          : editStyle.errorTextHidden
                      }
                    >
                      Please Enter Valid CTC
                    </p>
                  )}
                </div>
                <div
                  className={editStyle.list}
                  style={{ flex: "0 0 23%", alignItem: "center" }}
                >
                  <input
                    type="checkbox"
                    id="market"
                    name="checkbox"
                    checked={candidateData?.asPerMarketStandard}
                    onChange={() =>
                      setCandidateData((prevData) => ({
                        ...prevData,
                        asPerMarketStandard: !prevData?.asPerMarketStandard,
                        ectc: '',
                        negotiable: null,
                      }))
                    }
                    onKeyDown={handleKeyDown}
                    onClick={handleInputClick}
                  />
                  <label
                    for="market"
                    style={{
                      fontSize: "1rem",
                      color: "#000000",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    As per market standard
                  </label>
                </div>
                <div
                  className={editStyle.cu_col2}
                  style={{ flex: "0 0 12%" }}
                >
                  <div>
                    <p className={editStyle.cu_label}>
                      ECTC (LPA)
                      {candidateData?.isPartnerResource ||
                        candidateData?.asPerMarketStandard ? null : (
                        <span className={editStyle.mandatory}>*</span>
                      )}
                    </p>
                    <input
                      className={editStyle.cu_field2}
                      type="number"
                      min="0"
                      disabled={candidateData?.asPerMarketStandard}
                      style={
                        candidateData?.asPerMarketStandard
                          ? { backgroundColor: "#999", opacity: "0.2" }
                          : null
                      }
                      onChange={(e) =>
                        setCandidateData((prev) => ({
                          ...prev,
                          ectc: parseFloat(e?.target?.value),
                        }))
                      }
                      value={candidateData?.ectc}
                      onKeyDown={handleKeyDown}
                      onClick={handleInputClick}
                    />
                  </div>

                  <p
                    className={
                      (!candidateData?.asPerMarketStandard &&
                        checkValidPoint(candidateData?.ectc)) ||
                        (!nameValidate &&
                          validate &&
                          !candidateData?.ectc &&
                          !candidateData?.asPerMarketStandard)
                        ? editStyle.errorText
                        : editStyle.errorTextHidden
                    }
                  >
                    {checkValidPoint(candidateData?.ectc) &&
                      candidateData?.ectc
                      ? "Please Enter Valid ECTC (LPA)"
                      : candidateData?.ectc === 0
                        ? "Please enter ECTC greater than 0"
                        : "Please Enter ECTC (LPA)"}
                  </p>
                </div>

                <div
                  className={editStyle.cu_col2}
                  style={{ flex: "0 0 12%" }}
                >
                  <div>
                    <p className={editStyle.cu_label}>Increment %</p>
                    <div className={editStyle.incrementDiv}>{((candidateData?.incrementPercentage !== NaN && candidateData?.incrementPercentage) || candidateData?.incrementPercentage == 0) ? `${Math.round(candidateData?.incrementPercentage)}%` : ''}</div>
                  </div>
                </div>

                <div
                  className={editStyle.cu_col2}
                  style={{ flex: "0 0 12%" }}
                >
                  <div>
                    <p className={editStyle.cu_label}>RCTC (LPA)</p>
                    <input
                      className={editStyle.cu_field2}
                      type="number"
                      min="0"
                      onChange={(e) =>
                        setCandidateData({
                          ...candidateData,
                          revisedCtc: e?.target?.value
                            ? parseFloat(e?.target?.value)
                            : 0,
                        })
                      }
                      value={candidateData?.revisedCtc}
                      onKeyDown={handleKeyDown}
                      onClick={handleInputClick}
                    />
                  </div>
                </div>
                <div
                  className={editStyle.cu_col3}
                  style={{ flex: "0 0 20%" }}
                >
                  <p className={editStyle.cu_label}>
                    Negotiable
                    {!candidateData?.asPerMarketStandard && (
                      <span className={editStyle.mandatory}>*</span>
                    )}
                  </p>
                  <Select
                    styles={customStyles}
                    classNamePrefix={"create_position"}
                    isDisabled={candidateData?.asPerMarketStandard}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      candidateData?.negotiable !== null
                        ? options?.find(
                          (i) => i?.value == candidateData?.negotiable
                        )
                        : null
                    }
                    options={options}
                    onChange={(e) =>
                      setCandidateData({
                        ...candidateData,
                        negotiable: e?.value,
                      })
                    }
                  ></Select>
                  <p
                    className={
                      validate &&
                        !candidateData?.asPerMarketStandard &&
                        candidateData?.negotiable === null
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select the Negotiable
                  </p>
                  {!edit.disableSalaryBtn && (
                    <p
                      style={{
                        textAlign: "right",
                        position: "relative",
                        bottom: "4%",
                        left: "3%",
                      }}
                    >
                      <button
                        className={editStyle.cancel_btn22}
                        onClick={() => {
                          setEdit({ ...editObj });
                          setCandidateData({
                            ...tempCandidatedataRef.current,
                          });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setEdit({ ...editObj });
                            setCandidateData({
                              ...tempCandidatedataRef.current,
                            });
                          }
                        }}
                        tabIndex={0}
                      >
                        Cancel
                      </button>
                      <button
                        className={style.create_btn}
                        onClick={() => onCreateHandler("Salary")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            onCreateHandler("Salary");
                          }
                        }}
                        tabIndex={0}
                      >
                        Update
                      </button>
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}

        {/* Work Experience */}
        {!edit.workExp && (
          <div
            className={style.createposition_WE}
            style={{ marginBottom: "2rem" }}
          >
            <p className={style.title}>
              Work Experience{" "}
              <p style={{ position: "relative", left: "35%" }}>
                {prevData?.page1 !== "duplicityCheck" &&
                  ValidationEmpAccess(
                    validateAccess,
                    accessCode?.position_management_edit
                  ) && (
                    <div
                      onClick={() => {
                        setEdit({
                          ...edit,
                          workExp: true,
                          partner: false,
                          salary: false,
                          disableSalaryBtn: false,
                          // personalInfo: false,
                          experience: false,
                          academic: false,
                          resume: false,
                          otherPlatform: false,
                          oInfo: false,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setEdit({
                            ...edit,
                            workExp: true,
                            partner: false,
                            salary: false,
                            disableSalaryBtn: false,
                            // ss
                            experience: false,
                            academic: false,
                            resume: false,
                            otherPlatform: false,
                            oInfo: false,
                          });
                        }
                      }}
                      tabIndex={0}
                      title={"Edit"}
                      className={style.imgClass}
                    >
                      <img
                        src={editIcon}
                        alt="Edit"
                        width="20rem"
                        height="20rem"
                      />
                    </div>
                  )}
              </p>
            </p>
            <div className={style.Cu_row1} style={{ paddingBottom: "1rem" }}>
              <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                <p className={style.cu_label}>Company Name</p>
              </div>
              <div className={style.cu_col2_to} style={{ flex: "0 0 17%" }}>
                {
                  <div className={style.multiinput}>
                    <div className={style.cu_label} style={{ flex: "0 0 55%" }}>
                      From
                    </div>
                    <div className={style.cu_label} style={{ flex: "0 0 55%" }}>
                      To
                    </div>
                  </div>
                }
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 9%" }}>
                <p className={style.cu_label}>Dur</p>
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 19%" }}>
                <p className={style.cu_label}>Role</p>
              </div>

              <div className={style.cu_col3} style={{ flex: "0 0 27%" }}>
                <p className={style.cu_label}>Reason For Change</p>
              </div>
            </div>
            {experienceInDescendingOrder(data?.workExperiences)?.map(
              (item, index) => {
                return (
                  <div key={item?.id}>
                    <div className={style.Cu_row1}>
                      <div
                        className={style.cu_col1}
                        style={{ flex: "0 0 23%" }}
                      >
                        <p className={style.cu_field2}>{item?.companyName}</p>
                      </div>
                      <div
                        className={style.cu_col2}
                        style={{ flex: "0 0 17%" }}
                      >
                        <div
                          className={style.multiinput}
                          style={{ width: "inherit" }}
                        >
                          <div
                            className={style.cu_field23}
                            style={{ flex: "0 0 55%" }}
                          >
                            <p className={style.cu_field2}>
                              {item?.startDate &&
                                moment(item?.startDate).format("MMM YYYY")}
                            </p>
                          </div>

                          <div
                            className={style.cu_field23}
                            style={{ flex: "0 0 55%" }}
                          >
                            <p className={style.cu_field2}>
                              {item?.endDate &&
                                moment(item?.endDate).format("MMM YYYY")}
                              {item?.presentlyWorking && "Till Now"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={style.cu_col3} style={{ flex: "0 0 9%" }}>
                        <p className={style.cu_field2}>
                          {(function () {
                            let year1 = Math.floor(item.expDurationYear);
                            let month = item.expDurationMonth;
                            let year = year1 > 0 ? year1 + " Y  " : "";
                            month =
                              year1 > 0 && month > 0
                                ? ` - ${month}  M `
                                : month > 0
                                  ? `${month}  M `
                                  : "";
                            return `   ${year}  ${month}`;
                          })()}
                        </p>
                      </div>
                      <div
                        className={style.cu_col3}
                        style={{ flex: "0 0 19%" }}
                      >
                        <p className={style.cu_field2}>{item?.role}</p>
                      </div>
                      <div
                        className={style.cu_col1Reasone}
                        style={{ flex: "0 0 27%" }}
                      >
                        <p className={style.cu_fieldReasone}>
                          {item?.reasonForChange}
                        </p>
                      </div>
                    </div>
                    {/* Second */}
                    <div className={style.Cu_row11Reasone}>
                      {/* <div className={style.cu_col1Reasone}>
                    <p className={style.cu_label}>Reason For Change</p>
                    <p className={style.cu_fieldReasone}>
                      {item?.reasonForChange}
                    </p>
                            </div>*/}
                      {/* <div className={style.cu_col2}></div>
                  <div className={style.cu_col4}></div> */}
                    </div>
                    <hr />
                  </div>
                );
              }
            )}
          </div>
        )}
        {/* Work Experience Edit */}
        {edit.workExp && (
          <div className={editStyle.createposition_WE}>
            
              
            <p className={editStyle.title} style={{display: "flex", justifyContent : "space-between"}}>
              <span style={{display : "flex", width : "10rem"}}>
              {checkByCompanyName && (
                <div
                  className={editStyle.companyLoader}
                  style={{ position: "relative", left: "26%" }}
                >
                  {checkByCompanyName?.hasOwnProperty("exists") ? (
                    checkByCompanyName?.exists ? (
                      
                        <span style={{ fontSize: "15px", color: "red" }}>
                          Blacklisted Company
                        </span>
                    ) : (
                      <div>
                        <span style={{ fontSize: "15px", color: "green" }}>
                          Valid Company
                        </span>
                        &nbsp;
                        <img
                          src={check}
                          alt="whiteLoader"
                          width="25px"
                          height="18px"
                        />
                      </div>
                    )
                  ) : null}
                </div>
              )}
              </span>
              Work Experience <span style={{display : "flex", minWidth : "10rem", textAlign : "right"}}>{gaps?.totalGap}</span></p>
            <div className={editStyle.Cu_row1}>
              <div className={editStyle.cu_col1} style={{ flex: "0 0 23%" }}>
                <p className={editStyle.cu_label}>Company Name</p>
              </div>
              <div className={editStyle.cu_col2_to} style={{ flex: "0 0 23%" }}>
                {
                  <div className={editStyle.multiinput}>
                    <div className={editStyle.cu_label}>From</div>
                    <div
                      className={editStyle.cu_label}
                      style={{ paddingRight: "34%" }}
                    >
                      To
                    </div>
                  </div>
                }
              </div>
              <div className={editStyle.cu_col3} style={{ flex: "0 0 23%" }}>
                <p className={editStyle.cu_label}>Role</p>
              </div>

              <div className={editStyle.cu_col3} style={{ flex: "0 0 23%" }}>
                <p className={editStyle.cu_label}>Reason For Change</p>
              </div>
            </div>
            {candidateData?.workExperiences?.map((item, index) => {
              return (
                <div className={editStyle.workExperiences} key={index}>
                  {
                    <div className={editStyle.radioitem}>
                      <input
                        type="radio"
                        id={`present${index}`}
                        name={`present`}
                        value={index}
                        checked={
                          candidateData?.workExperiences?.[index].present ===
                          index + 1
                        }
                        onChange={() => {
                          let temp = [
                            ...(candidateData && candidateData.workExperiences
                              ? candidateData.workExperiences
                              : []),
                          ];
                          temp = temp.map((e) => ({ ...e, present: 0 }));
                          temp[index].present = index + 1;
                          setPresentWorkIndex(index + 1);
                          temp[index].startDate = null;
                          temp[index].endDate = null;
                          setCandidateData({
                            ...candidateData,
                            workExperiences: temp,
                          });
                        }}
                        onKeyDown={handleKeyDown}
                        onClick={handleInputClick}
                      />
                      <label
                        for={`present${index}`}
                        style={{ fontSize: "1rem", color: "#000000" }}
                      >
                        Presently working here
                      </label>
                      &nbsp;&nbsp;{" "}
                      {candidateData?.workExperiences?.[index].present ===
                        index + 1 && (
                          <span
                            onClick={() => {
                              let temp = [
                                ...(candidateData && candidateData.workExperiences
                                  ? candidateData.workExperiences
                                  : []),
                              ];
                              temp = temp.map((e) => ({ ...e, present: 0 }));
                              temp[index].present = 0;
                              temp[index].presentlyWorking = false;
                              setPresentWorkIndex(0);
                              temp[index].endDate = null;
                              setCandidateData({
                                ...candidateData,
                                workExperiences: temp,
                              });
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                let temp = [
                                  ...(candidateData &&
                                    candidateData.workExperiences
                                    ? candidateData.workExperiences
                                    : []),
                                ];
                                temp = temp.map((e) => ({ ...e, present: 0 }));
                                temp[index].present = 0;
                                temp[index].presentlyWorking = false;
                                setPresentWorkIndex(0);
                                temp[index].endDate = null;
                                setCandidateData({
                                  ...candidateData,
                                  workExperiences: temp,
                                });
                              }
                            }}
                            tabIndex={0}
                            style={{ fontSize: "13px", color: "red" }}
                          >
                            <GrPowerReset />
                            &nbsp;Reset
                          </span>
                        )}
                    </div>
                  }
                  {candidateData?.workExperiences?.length > 1 && (
                    <button
                      className={editStyle.btn_remove}
                      onClick={(e) => {
                        setWorkExperienceArray(
                          workExperienceArray.filter((_, i) => {
                            return i !== index;
                          })
                        );
                        setPresentWorkIndex(
                          index + 1 === presentWorkIndex
                            ? null
                            : presentWorkIndex
                        );
                        setCandidateData({
                          ...candidateData,
                          workExperiences:
                            candidateData?.workExperiences?.filter(
                              (_, i) => i !== index
                            ),
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setWorkExperienceArray(
                            workExperienceArray.filter((_, i) => {
                              return i !== index;
                            })
                          );
                          setPresentWorkIndex(null);
                          setCandidateData({
                            ...candidateData,
                            workExperiences:
                              candidateData?.workExperiences?.filter(
                                (_, i) => i !== index
                              ),
                          });
                        }
                      }}
                      tabIndex={0}
                    >
                      ❌
                    </button>
                  )}
                  <div className={editStyle.Cu_row1}>
                    <div
                      className={editStyle.cu_col1}
                      style={{ flex: "0 0 23%" }}
                    >
                      <input
                        className={editStyle.bigInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [
                            ...(candidateData && candidateData.workExperiences
                              ? candidateData.workExperiences
                              : []),
                          ];
                          temp[index].companyName = e?.target?.value;
                          setCandidateData({
                            ...candidateData,
                            workExperiences: temp,
                          });
                          setCompanyName(e?.target?.value);
                          checkDuplicateeCompany();
                        }}
                        value={
                          candidateData?.workExperiences[index]?.companyName
                        }
                        onKeyDown={handleKeyDown}
                        onClick={handleInputClick}
                      />
                      <p
                        className={
                          (validate || nameValidate) &&
                            !candidateData?.workExperiences[index]?.companyName
                            ? editStyle.errorTextexp
                            : editStyle.errorTextHidden
                        }
                      >
                        Please Enter Company Name
                      </p>
                    </div>
                    <div
                      className={editStyle.cu_col2_to}
                      style={{ flex: "0 0 23%" }}
                    >
                      <div className={editStyle.multiinput}>
                        <div>
                          <div
                            className={editStyle.cu_field23}
                            style={{ marginTop: "0.6rem" }}
                          >
                            <DatePicker
                              placeholder="MM YYYY"
                              peekNextMonth
                              showMonthYearPicker
                              // showMonthDropdown
                              // showYearDropdown
                              // dropdownMode="select"
                              maxDate={
                                candidateData?.workExperiences[index]?.endDate
                                  ? moment(
                                    candidateData.workExperiences[index]
                                      .endDate
                                  ).toDate()
                                  : null
                              }
                              selected={
                                candidateData?.workExperiences[index]?.startDate
                                  ? moment(
                                    candidateData?.workExperiences[index]
                                      ?.startDate
                                  ).toDate()
                                  : null
                              }
                              onChange={(date) => {
                                let temp = [
                                  ...(candidateData &&
                                    candidateData.workExperiences
                                    ? candidateData.workExperiences
                                    : []),
                                ];
                                temp[index].startDate = moment(date).format();
                                if (
                                  moment(temp[index].endDate).isBefore(
                                    temp[index].startDate
                                  )
                                ) {
                                  // set end date to null to clear the invalid value
                                  temp[index].startDate = null;
                                  setStartDtErr(true);
                                  const tempArr = [...startDtErr];
                                  tempArr[index] = true;
                                  setStartDtErr([...tempArr]);
                                } else {
                                  const tempArr = [...startDtErr];
                                  tempArr[index] = false;
                                  setStartDtErr([...tempArr]);
                                }
                                setCandidateData({
                                  ...candidateData,
                                  workExperiences: temp,
                                });
                              }}
                              dateFormat="MMM yyyy"
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              clearButton
                              customInput={
                                <CustomInputClgWXS
                                  index={index}
                                  formData={candidateData}
                                  setFormData={setCandidateData}
                                />
                              }
                            />
                          </div>
                          <p
                            className={
                              (validate || nameValidate) &&
                                !startDtErr[index] &&
                                !candidateData?.workExperiences[index]?.startDate
                                ? editStyle.errorTextFrom
                                : editStyle.errorTextHidden
                            }
                          >
                            Please Enter Start Date
                          </p>
                          <p
                            className={
                              startDtErr[index] &&
                                index + 1 !== presentWorkIndex
                                ? editStyle.errorTextexp
                                : editStyle.errorTextHidden
                            }
                          >
                            Start date cannot be <br /> greater than end date
                          </p>
                        </div>
                        <div>
                          <div
                            className={editStyle.cu_field23}
                            style={
                              candidateData?.workExperiences[index]?.present ===
                                index + 1
                                ? {
                                  marginTop: "0.6rem",
                                  backgroundColor: "#b2a6a66b",
                                  flex: "0 0 8%",
                                }
                                : { marginTop: "0.6rem", flex: "0 0 5%" }
                            }
                          >
                            <DatePicker
                              // showMonthYearPicker
                              placeholder=" DD MM YYYY"
                              peekNextMonth
                              showMonthYearPicker
                              // showMonthDropdown
                              // showYearDropdown
                              // dropdownMode="select"
                              selected={
                                candidateData?.workExperiences[index]?.endDate
                                  ? moment(
                                    candidateData?.workExperiences[index]
                                      ?.endDate
                                  ).toDate()
                                  : null
                              }
                              onChange={(date) => {
                                let temp = [
                                  ...(candidateData &&
                                    candidateData.workExperiences
                                    ? candidateData.workExperiences
                                    : []),
                                ];
                                temp[index].endDate = moment(date).format();
                                if (
                                  moment(temp[index].endDate).isBefore(
                                    temp[index].startDate
                                  )
                                ) {
                                  // set end date to null to clear the invalid value
                                  temp[index].endDate = null;
                                  const tempArr = [...endDtErr];
                                  tempArr[index] = true;
                                  setEndDtErr([...tempArr]);
                                } else {
                                  const tempArr = [...endDtErr];
                                  tempArr[index] = false;
                                  setEndDtErr([...tempArr]);
                                }
                                setCandidateData({
                                  ...candidateData,
                                  workExperiences: temp,
                                });
                              }}
                              dateFormat="MMM yyyy"
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              customInput={
                                <CustomInputClgWXEND
                                  index={index}
                                  formData={candidateData}
                                  setFormData={setCandidateData}
                                />
                              }
                            />
                          </div>
                          <p
                            className={
                              (validate || nameValidate) &&
                                !endDtErr[index] &&
                                !candidateData?.workExperiences[index]?.endDate &&
                                index + 1 !== presentWorkIndex
                                ? editStyle.errorTextexp
                                : editStyle.errorTextHidden
                            }
                          >
                            Please Enter End <br /> Date
                          </p>
                          <p
                            className={
                              endDtErr[index] && index + 1 !== presentWorkIndex
                                ? editStyle.errorTextexp
                                : editStyle.errorTextHidden
                            }
                          >
                            End date cannot be <br /> less than start date
                          </p>
                        </div>
                      </div>
                      {/* validation start month is less than end month */}
                    </div>
                    <div
                      className={editStyle.cu_col3}
                      style={{ flex: "0 0 23%" }}
                    >
                      <input
                        className={editStyle.bigInput}
                        onChange={(e) => {
                          let temp = [
                            ...(candidateData && candidateData.workExperiences
                              ? candidateData.workExperiences
                              : []),
                          ];
                          temp[index].role = e?.target?.value;
                          setCandidateData({
                            ...candidateData,
                            workExperiences: temp,
                          });
                        }}
                        value={candidateData?.workExperiences[index]?.role}
                        onKeyDown={handleKeyDown}
                        onClick={handleInputClick}
                      ></input>
                      <p
                        className={
                          (validate || nameValidate) &&
                            !candidateData?.workExperiences[index]?.role
                            ? editStyle.errorTextexp
                            : editStyle.errorTextHidden
                        }
                      >
                        Please Enter Role
                      </p>
                    </div>
                    <div
                      className={editStyle.cu_col1}
                      style={{ flex: "0 0 23%" }}
                    >
                      <textarea
                        className={editStyle.bigInput}
                        onChange={(e) => {
                          let temp = [
                            ...(candidateData && candidateData.workExperiences
                              ? candidateData.workExperiences
                              : []),
                          ];
                          temp[index].reasonForChange = e?.target?.value;
                          setCandidateData({
                            ...candidateData,
                            workExperiences: temp,
                          });
                        }}
                        value={
                          candidateData?.workExperiences[index]?.reasonForChange
                        }
                      />
                      <p
                        className={
                          (validate || nameValidate) &&
                            presentWorkIndex === index + 1 &&
                            !candidateData?.workExperiences[index]
                              ?.reasonForChange
                            ? editStyle.errorText
                            : editStyle.errorTextHidden
                        }
                      >
                        Please Enter Reason for Change
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}

            <div
              className={editStyle.workExpButton}
              style={{ marginTop: "1.3rem" }}
            >
              <button
                className={editStyle.btn_add}
                onClick={() => {
                  // push new value 2 in array
                  setWorkExperienceArray([
                    ...workExperienceArray,
                    Math.floor(Math.random() * 100),
                  ]);
                  setCandidateData({
                    ...candidateData,
                    workExperiences: [
                      ...(candidateData && candidateData.workExperiences
                        ? candidateData.workExperiences
                        : []),
                      {
                        companyName: "",
                        reasonForChange: "",
                        role: "",
                        endDate: null,
                        startDate: null,
                        present: 0,
                      },
                    ],
                  });
                  setStartDtErr((prev) => [...prev, false]);
                  setEndDtErr((prev) => [...prev, false]);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    // push new value 2 in array
                    setWorkExperienceArray([
                      ...workExperienceArray,
                      Math.floor(Math.random() * 100),
                    ]);
                    setCandidateData({
                      ...candidateData,
                      workExperiences: [
                        ...(candidateData && candidateData.workExperiences
                          ? candidateData.workExperiences
                          : []),
                        {
                          companyName: "",
                          reasonForChange: "",
                          role: "",
                          endDate: null,
                          startDate: null,
                          present: 0,
                        },
                      ],
                    });
                  }
                }}
                tabIndex={0}
              >
                Add
              </button>
            </div>
            <p className={editStyle.btnRow}>
              <button
                className={editStyle.cancel_btn22}
                onClick={() => {
                  setEdit({
                    partner: false,
                    salary: false,
                    disableSalaryBtn: false,
                    // personalInfo: false,
                    experience: false,
                    workExp: false,
                    academic: false,
                    resume: false,
                    socialPlatform: false,
                    oInfo: false,
                  });
                  setCandidateData({ ...tempCandidatedataRef.current });
                  refetchCandidate();
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setEdit({
                      partner: false,
                      salary: false,
                      disableSalaryBtn: false,
                      // personalInfo: false,
                      experience: false,
                      workExp: false,
                      academic: false,
                      resume: false,
                      socialPlatform: false,
                      oInfo: false,
                    });
                    setCandidateData({ ...tempCandidatedataRef.current });
                  }
                }}
                tabIndex={0}
              >
                Cancel
              </button>
              <button
                className={style.create_btn}
                onClick={() => onCreateHandler("Work Experience")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onCreateHandler("Work Experience");
                  }
                }}
                tabIndex={0}
              >
                Update
              </button>
            </p>
          </div>
        )}
        {/* Academic */}
        {!edit.academic && (
          <div
            className={style.createposition_academic}
            style={{ marginBottom: "2rem" }}
          >
            <p className={style.title}>
              Academics{" "}
              <p style={{ position: "relative", left: "38%" }}>
                {prevData?.page1 !== "duplicityCheck" &&
                  ValidationEmpAccess(
                    validateAccess,
                    accessCode?.position_management_edit
                  ) && (
                    <div
                      onClick={() => {
                        setEdit({
                          ...edit,
                          academic: true,
                          workExp: false,
                          partner: false,
                          salary: false,
                          disableSalaryBtn: false,
                          personalInfo: false,
                          experience: false,
                          resume: false,
                          otherPlatform: false,
                          oInfo: false,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setEdit({
                            ...edit,
                            academic: true,
                            workExp: false,
                            partner: false,
                            salary: false,
                            disableSalaryBtn: false,
                            personalInfo: false,
                            experience: false,
                            resume: false,
                            otherPlatform: false,
                            oInfo: false,
                          });
                        }
                      }}
                      tabIndex={0}
                      title={"Edit"}
                      className={style.imgClass}
                    >
                      <img
                        src={editIcon}
                        alt="Edit"
                        width="20rem"
                        height="20rem"
                      />
                    </div>
                  )}
              </p>
            </p>
            <div className={style.Cu_row1} style={{ paddingBottom: "1rem" }}>
              <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                <p className={style.cu_label}>Degree</p>
              </div>
              <div className={style.cu_col2} style={{ flex: "0 0 20%" }}>
                <p className={style.cu_label}>Passing Year</p>
              </div>
              <div className={style.cu_col2} style={{ flex: "0 0 15%" }}>
                <p className={style.cu_label}>Score</p>
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 35%" }}>
                <p className={style.cu_label}>Board of Education/University</p>
              </div>
            </div>
            {data?.candidateAcademics?.map((item, index) => {
              return (
                <div key={item?.id}>
                  <div className={style.Cu_row1}>
                    <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                      <p className={style.cu_field2}>{item?.degree}</p>
                    </div>
                    <div className={style.cu_col2} style={{ flex: "0 0 20%" }}>
                      <p className={style.cu_field2}>{item?.passingYear}</p>
                    </div>
                    <div className={style.cu_col2} style={{ flex: "0 0 15%" }}>
                      <p className={style.cu_field2}>
                        {item?.grade}
                        {item?.rank}
                        {item?.gradeStatus === "CGPA" && " CGPA"}
                        {item?.gradeStatus === "Grade" && " Grade"}
                        {item?.gradeStatus === "Percentage" && "%"}
                      </p>
                    </div>
                    <div className={style.cu_col3} style={{ flex: "0 0 35%" }}>
                      <p className={style.cu_field2}>{item?.board}</p>
                    </div>
                  </div>
                  {/* Second */}
                  <div className={style.Cu_row1} style={{ marginTop: "30px" }}>
                    <div className={style.cu_col1}></div>
                    <div className={style.cu_col2}></div>
                  </div>
                  <hr />
                </div>
              );
            })}
          </div>
        )}
        {/* Academic Edit */}
        {edit.academic && (
          <div className={editStyle.createposition_academic}>
            <p className={editStyle.title}>Academics</p>
            <div className={editStyle.Cu_row1}>
              <div className={editStyle.cu_col1} style={{ flex: "0 0 23%" }}>
                <p className={editStyle.cu_label}>
                  Degree <span style={{ color: "red" }}>*</span>
                </p>
              </div>
              <div className={editStyle.cu_col3} style={{ flex: "0 0 23%" }}>
                <p className={editStyle.cu_label}>
                  Board of Education/University
                  <span style={{ color: "red" }}>*</span>
                </p>
              </div>
              <div className={editStyle.cu_col2_to} style={{ flex: "0 0 15%" }}>
                <p className={editStyle.cu_label}>
                  Passing Year <span style={{ color: "red" }}>*</span>
                </p>
              </div>
              <div className={editStyle.cu_col3} style={{ flex: "0 0 30%" }}>
                <p className={editStyle.cu_label}>Score</p>
              </div>
            </div>
            {candidateData?.candidateAcademics?.map((item, index) => {
              return (
                <div
                  className={editStyle.candidateAcademics}
                  style={{ marginTop: "0.5rem" }}
                >
                  {acadamicsArray?.length >= 1 && (
                    <button
                      className={editStyle.btn_remove}
                      style={{ top: "-23%" }}
                      onClick={() =>
                      // remove last value from array
                      {
                        let temp = [
                          ...(candidateData &&
                            candidateData.candidateAcademics
                            ? [...candidateData.candidateAcademics]
                            : []),
                        ];
                        temp.splice(index, 1);
                        setCandidateData((prevData) => ({
                          ...prevData,
                          candidateAcademics: temp,
                        }));
                        acadamicsArray.splice(index, 1);
                      }
                      }
                    // onKeyDown={(e) => {
                    //   if (e.key === "Enter") {
                    //     let temp = [
                    //       ...(candidateData &&
                    //         candidateData.candidateAcademics
                    //         ? [...candidateData.candidateAcademics]
                    //         : []),
                    //     ];
                    //     temp.splice(index, 1);
                    //     setCandidateData((prevData) => ({
                    //       ...prevData,
                    //       candidateAcademics: temp,
                    //     }));
                    //     acadamicsArray.splice(index, 1);
                    //   }
                    // }}
                    // tabIndex={0}
                    >
                      ❌
                    </button>
                  )}
                  <div
                    className={editStyle.Cu_row1}
                    style={{
                      margin: index === 0 ? "0% 2% 0% 1%" : "2% 2% 0% 1%",
                    }}
                  >
                    <div
                      className={editStyle.cu_col1}
                      style={{ flex: "0 0 23%" }}
                    >
                      <Select
                        styles={customStyles}
                        classNamePrefix={"create_position"}
                        options={getAllDegreesOption}
                        value={{
                          label: candidateData.candidateAcademics[index].degree,
                          value:
                            candidateData.candidateAcademics[index].degreeId,
                        }}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => {
                          let temp = [
                            ...(candidateData &&
                              candidateData.candidateAcademics
                              ? [...candidateData.candidateAcademics]
                              : []),
                          ];
                          temp[index].degreeId = e.value;
                          temp[index].degree = e.label;
                          setCandidateData({
                            ...candidateData,
                            candidateAcademics: temp,
                          });
                          let indexs = getAllDegreesOption.some(
                            (item) => item.value === e.value
                          );

                          !indexs && mutationDegree.mutate({ degree: e.label });
                        }}
                        formatCreateLabel={(userInput) =>
                          `+ Add "${userInput}"`
                        }
                      ></Select>
                      <p
                        className={
                          (validate || nameValidate) &&
                            !candidateData?.candidateAcademics[index]?.degreeId
                            ? editStyle.errorTextA
                            : editStyle.errorTextHidden
                        }
                      >
                        Please Enter Degree
                      </p>
                    </div>
                    <div
                      className={editStyle.cu_col3}
                      style={{ flex: "0 0 23%", marginTop: "0.1rem" }}
                    >
                      <input
                        name="boardEdu"
                        className={editStyle.bigInput}
                        onChange={(e) => {
                          let temp = [
                            ...(candidateData &&
                              candidateData.candidateAcademics
                              ? [...candidateData.candidateAcademics]
                              : []),
                          ];
                          temp[index].board = e?.target?.value;
                          setCandidateData({
                            ...candidateData,
                            candidateAcademics: temp,
                          });
                        }}
                        value={candidateData?.candidateAcademics[index]?.board}
                        onKeyDown={handleKeyDown}
                        onClick={handleInputClick}
                      />
                      <p
                        className={
                          (validate || nameValidate) &&
                            !candidateData?.candidateAcademics[index]?.board
                            ? editStyle.errorTextA
                            : editStyle.errorTextHidden
                        }
                      >
                        Please Enter Education/University
                      </p>
                    </div>
                    <div
                      className={editStyle.cu_col2_ac}
                      style={{ flex: "0 0 15%" }}
                    >
                      <div>
                        <div
                          className={editStyle.cu_fieldAC}
                          style={{ marginTop: "0.5rem" }}
                        >
                          <DatePicker
                            placeholder="YYYY"
                            selected={
                              candidateData?.candidateAcademics[index]
                                ?.passingYear
                                ? moment(
                                  String(
                                    candidateData?.candidateAcademics[index]
                                      ?.passingYear
                                  )
                                ).toDate()
                                : null
                            }
                            dateFormat="yyyy"
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            // showYearPicker
                            // showYearDropdown
                            showYearPicker
                            dropdownMode="select"
                            onChange={(date) => {
                              let temp = [
                                ...(candidateData &&
                                  candidateData.candidateAcademics
                                  ? [...candidateData.candidateAcademics]
                                  : []),
                              ];
                              temp[index].passingYear =
                                moment(date).format("YYYY");
                              setCandidateData({
                                ...candidateData,
                                candidateAcademics: temp,
                              });
                            }}
                            customInput={
                              <CustomInputClgPY
                                index={index}
                                formData={candidateData}
                                setFormData={setCandidateData}
                              />
                            }
                          />
                          <p
                            style={{ margin: "0px", paddingTop: "0.5rem" }}
                            className={
                              (validate || nameValidate) &&
                                !candidateData?.candidateAcademics[index]
                                  ?.passingYear
                                ? editStyle.errorTextPassing
                                : editStyle.errorTextHidden
                            }
                          >
                            Please Enter Passing Year
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={editStyle.cu_col3}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flex: "0 0 30%",
                      }}
                    >
                      <div style={{ width: "40%" }}>
                        <Select
                          styles={customStyles}
                          classNamePrefix={"create_position"}
                          options={gradeOption}
                          components={{
                            DropdownIndicator: () => CustomInput(),
                            IndicatorSeparator: () => null,
                          }}
                          // value={gradeOption.find(
                          //   (e) =>
                          //     e.label ==
                          //     candidateData?.candidateAcademics[index]
                          //       ?.gradeStatus
                          // )}
                          onChange={(e) => {
                            let temp = [
                              ...(candidateData &&
                                candidateData.candidateAcademics
                                ? [...candidateData.candidateAcademics]
                                : []),
                            ];

                            temp[index].gradeStatus = e.label;
                            if (temp[index].grade) {
                              temp[index].grade = null;
                            }
                            if (temp[index].rank) {
                              temp[index].rank = null;
                            }
                            setCandidateData({
                              ...candidateData,
                              candidateAcademics: temp,
                            });
                          }}
                          value={{
                            label:
                              candidateData?.candidateAcademics[index]
                                ?.gradeStatus,
                            value: getGradeStatusVal(
                              candidateData?.candidateAcademics[index]
                                ?.gradeStatus
                            ),
                          }}
                        ></Select>
                      </div>

                      {candidateData?.candidateAcademics[index]?.gradeStatus ===
                        "CGPA" && (
                          <div style={{ width: "50%" }}>
                            <input
                              name="grade"
                              type="number"
                              min="0"
                              max="10"
                              style={{ marginTop: "0.5rem" }}
                              value={
                                candidateData?.candidateAcademics[index]?.grade
                              }
                              onChange={(e) => {
                                let temp = [
                                  ...(candidateData &&
                                    candidateData.candidateAcademics
                                    ? [...candidateData.candidateAcademics]
                                    : []),
                                ];
                                if (
                                  e?.target?.value >= 0 &&
                                  e?.target?.value <= 10
                                ) {
                                  temp[index].grade = parseFloat(
                                    e?.target?.value
                                  );
                                }
                                temp[index].rank = null;
                                setCandidateData({
                                  ...candidateData,
                                  candidateAcademics: temp,
                                });
                              }}
                              className={editStyle.cu_field233}
                              onKeyDown={handleKeyDown}
                              onClick={handleInputClick}
                            />
                            <p
                              className={
                                candidateData?.candidateAcademics[index]?.grade >
                                  10
                                  ? editStyle.errorText
                                  : editStyle.errorTextHidden
                              }
                            >
                              CGPA should be less than 10
                            </p>
                          </div>
                        )}
                      {candidateData?.candidateAcademics[index]?.gradeStatus ===
                        "Percentage" && (
                          <div style={{ width: "50%" }}>
                            <input
                              name="grade"
                              type="number"
                              min={0}
                              max={100}
                              style={{ marginTop: "0.5rem" }}
                              value={
                                candidateData?.candidateAcademics[index]?.grade
                              }
                              onChange={(e) => {
                                let temp = [
                                  ...(candidateData &&
                                    candidateData.candidateAcademics
                                    ? [...candidateData.candidateAcademics]
                                    : []),
                                ];
                                if (
                                  e?.target?.value >= 0 &&
                                  e?.target?.value <= 100
                                ) {
                                  temp[index].grade = parseFloat(
                                    e?.target?.value
                                  );
                                }
                                temp[index].rank = null;
                                setCandidateData({
                                  ...candidateData,
                                  candidateAcademics: temp,
                                });
                              }}
                              className={editStyle.cu_field233}
                              onKeyDown={handleKeyDown}
                              onClick={handleInputClick}
                            />
                            <p
                              className={
                                candidateData?.candidateAcademics[index]?.grade >
                                  100
                                  ? editStyle.errorText
                                  : editStyle.errorTextHidden
                              }
                            >
                              % should be less than 100
                            </p>
                          </div>
                        )}
                      {candidateData?.candidateAcademics[index]?.gradeStatus ===
                        "Grade" && (
                          <div style={{ width: "50%" }}>
                            <input
                              name="grade"
                              style={{ marginTop: "0.5rem" }}
                              value={
                                candidateData?.candidateAcademics[index]?.rank
                              }
                              onChange={(e) => {
                                let temp = [
                                  ...(candidateData &&
                                    candidateData.candidateAcademics
                                    ? [...candidateData.candidateAcademics]
                                    : []),
                                ];
                                temp[index].rank = e?.target?.value;
                                temp[index].grade = null;
                                setCandidateData({
                                  ...candidateData,
                                  candidateAcademics: temp,
                                });
                              }}
                              className={editStyle.cu_field233}
                              onKeyDown={handleKeyDown}
                              onClick={handleInputClick}
                            />

                            {/* <p
                            className={
                              (validate || nameValidate) &&
                              !formData?.candidateAcademics[index]?.rank
                                ? style.errorText
                                : style.errorTextHidden
                            }
                          >
                            Please Enter Grade
                          </p>*/}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              );
            })}

            <div
              className={editStyle.workExpButton}
              style={{ marginTop: "1.5rem" }}
            >
              <button
                className={editStyle.btn_add}
                onClick={
                  () => {
                    setAcadamicsArray([
                      ...acadamicsArray,
                      Math.floor(Math.random() * 100),
                    ]);
                    setCandidateData({
                      ...candidateData,
                      candidateAcademics: [
                        ...(candidateData && candidateData.candidateAcademics
                          ? [...candidateData.candidateAcademics]
                          : []),
                        {
                          board: "",
                          degreeId: "",
                          degree: "",
                          grade: null,
                          gradeStatus: null,
                          passingYear: 0,
                        },
                      ],
                    });
                  }
                  // push new value 2 in array
                }
              >
                Add
              </button>
            </div>
            <p className={editStyle.btnRow}>
              <button
                className={editStyle.cancel_btn22}
                onClick={() => {
                  setEdit({
                    ...editObj,
                  });
                  setCandidateData({
                    ...tempCandidatedataRef.current,
                    candidateAcademics: [
                      ...(tempCandidatedataRef.current.candidateAcademics
                        ? tempCandidatedataRef.current.candidateAcademics
                        : []),
                    ],
                  });
                  refetchCandidate();
                }}
              >
                Cancel
              </button>
              <button
                className={style.create_btn}
                onClick={() => onCreateHandler("Academics")}
              >
                Update
              </button>
            </p>
          </div>
        )}
        {/* Other Information */}
        {!edit.oInfo && (
          <div
            className={style.createposition_other}
            style={{ height: "auto" }}
          >
            <p className={style.title}>
              Other Information{" "}
              <p style={{ position: "relative", left: "35%" }}>
                {prevData?.page1 !== "duplicityCheck" &&
                  ValidationEmpAccess(
                    validateAccess,
                    accessCode?.position_management_edit
                  ) && (
                    <div
                      onClick={() => {
                        setEdit({
                          ...edit,
                          oInfo: true,
                          workExp: false,
                          partner: false,
                          salary: false,
                          disableSalaryBtn: false,
                          personalInfo: false,
                          experience: false,
                          academic: false,
                          resume: false,
                          otherPlatform: false,
                        });
                      }}
                      title={"Edit"}
                      className={style.imgClass}
                    >
                      <img
                        src={editIcon}
                        alt="Edit"
                        width="20rem"
                        height="20rem"
                      />
                    </div>
                  )}
              </p>
            </p>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Notice Period</p>
                <p className={style.cu_field2}>{data?.noticePeriod}</p>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Last Working Day </p>
                <p className={style.cu_field2}>
                  {data?.lastWorkingDay &&
                    moment(data?.lastWorkingDay).format("DD MMM YYYY")}
                </p>
              </div>
              <div
                className={style.cu_col3}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "31%",
                }}
              >
                <div style={{ width: "25%" }}>
                  <p className={style.cu_label}> Buy Out </p>
                  <p className={style.cu_field2}>
                    {data?.buyOut === true ? "Yes" : "No"}
                  </p>
                </div>
                <div style={{ width: "35%" }}>
                  <p className={style.cu_label}> Negotiable </p>
                  {data?.hasOwnProperty("buyOutNegotiable") && (
                    <p className={style.cu_field2}>
                      {data?.buyOutNegotiable === true ? "Yes" : "No"}
                    </p>
                  )}
                </div>
                {data?.hasOwnProperty("negotiableDaysId") && (
                  <div style={{ width: "40%" }}>
                    <p className={style.cu_label}> Negotiable Days </p>

                    <p className={style.cu_field2}>
                      {
                        negotialbleOptions?.find(
                          (e) => e?.value === data?.negotiableDaysId
                        )?.label
                      }
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* Second */}
            <div className={style.Cu_row14}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}> Offer In Hand</p>
                <p className={style.cu_field2}>
                  {data?.offerInHand === true
                    ? "Yes"
                    : data?.offerInHand === false && "No"}{" "}
                </p>
              </div>
              <div className={style.cu_col2} style={{ width: "30%" }}>
                <p className={style.cu_label}>Company Name</p>
                <p
                  className={style.cu_field2}
                  title={
                    data?.companyName &&
                    data?.companyName?.length > 44 &&
                    data?.companyName
                  }
                  style={{ textWrap: "nowrap" }}
                >
                  {data?.companyName && data?.companyName?.length > 44
                    ? data?.companyName?.substring(0, 45) + "..."
                    : data?.companyName}
                </p>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>Amount (LPA)</p>
                <p className={style.cu_field2}>{data?.amount} {data?.amount ? `(${formatIndianCurrency(Math.round((data?.amount*100000)/12))})` : ''}</p>
              </div>
            </div>
            {/* three */}
            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Current Location</p>
                <p
                  className={style.cu_field2}
                  title={
                    data?.currentLocation &&
                    data?.currentLocation?.length > 34 &&
                    data?.currentLocation
                  }
                  style={{ textWrap: "nowrap" }}
                >
                  {data?.currentLocation && data?.currentLocation.length > 34
                    ? data?.currentLocation?.substring(0, 35) + "..."
                    : data?.currentLocation}
                </p>
              </div>
              <div className={style.cu_col2} style={{ width: "30%" }}>
                <p className={style.cu_label}>Hometown</p>
                <p
                  className={style.cu_field2}
                  title={
                    data?.homeTown &&
                    data?.homeTown?.length > 44 &&
                    data?.homeTown
                  }
                  style={{ textWrap: "nowrap" }}
                >
                  {data?.homeTown && data?.homeTown?.length > 44
                    ? data?.homeTown?.substring(0, 45) + "..."
                    : data?.homeTown}
                </p>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  {data?.openForPanIndia
                    ? "Open for Pan India"
                    : "Open for Noida/Gurugram"}
                </p>
                <p className={style.cu_field2}>
                  {data?.openForPanIndia
                    ? "Yes"
                    : data?.openForNG === true
                      ? "Yes"
                      : "No"}
                </p>
              </div>
            </div>
            {/* four */}
            <div className={style.Cu_row14}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Source</p>
                <p className={style.cu_field2} style={{ textWrap: "nowrap" }}>
                  {data?.sourceName}
                </p>
              </div>
              {data?.source && (
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>{data?.sourceName}</p>
                  <p
                    title={
                      data?.source && data?.source?.length > 34 && data?.source
                    }
                    style={{ textWrap: "nowrap" }}
                    className={style.cu_field2}
                  >
                    {data?.source && data?.source?.length > 34
                      ? data?.source.substr(0, 35) + "..."
                      : data?.source}
                  </p>
                </div>
              )}
              {!data?.source && (
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>Preferred Location</p>
                  <p
                    className={style.cu_field2}
                    style={{ textWrap: "nowrap" }}
                    title={
                      data?.preferredLocation &&
                      data?.preferredLocation?.length > 35 &&
                      data?.preferredLocation
                    }
                  >
                    {data?.preferredLocation &&
                      data?.preferredLocation.length > 35
                      ? data?.preferredLocation?.substring(0, 36) + "..."
                      : data?.preferredLocation}
                  </p>
                </div>
              )}
              <div className={style.cu_col3} style={{ width: "31%" }}>
                <p className={style.cu_label}>Google Drive Link</p>
                {data?.driveLink && (
                  <a
                    href={data?.driveLink}
                    target="_black"
                    rel="noreferrer"
                    title="Click here"
                    className={style.cp_value}
                    style={{ paddingLeft: "5px" }}
                  >
                    <img width={25} src={driveIcon} />
                  </a>
                )}
              </div>
            </div>
            <div className={style.Cu_row14}>
              {data?.source ? (
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>Preferred Location</p>
                  <p
                    className={style.cu_field2}
                    style={{ textWrap: "nowrap" }}
                    title={
                      data?.preferredLocation &&
                      data?.preferredLocation?.length > 35 &&
                      data?.preferredLocation
                    }
                  >
                    {data?.preferredLocation &&
                      data?.preferredLocation.length > 35
                      ? data?.preferredLocation?.substring(0, 36) + "..."
                      : data?.preferredLocation}
                  </p>
                </div>
              ) : (
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>Portfolio Link</p>
                  <p className={style.cu_field2}>
                    {data?.portfolioLink && (
                      <a
                        href={data?.portfolioLink}
                        target="_black"
                        rel="noreferrer"
                        title="Click here"
                        className={style.cp_value}
                      >
                        <AiFillFolder size={25} color="blue" />
                      </a>
                    )}
                  </p>
                </div>
              )}
              {data?.source ? (
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>Portfolio Link</p>
                  <p className={style.cu_field2}>
                    {data?.portfolioLink && (
                      <a
                        href={data?.portfolioLink}
                        target="_black"
                        rel="noreferrer"
                        title="Click here"
                        className={style.cp_value}
                      >
                        <AiFillFolder size={25} color="blue" />
                      </a>
                    )}
                  </p>
                </div>
              ) : (
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>Resignation Date</p>
                  <p className={style.cu_field2} style={{ textWrap: "nowrap" }}>
                    {data?.resignationDate &&
                      moment(data?.resignationDate).format("DD MMM YY")}
                  </p>
                </div>
              )}
              <div
                className={style.cu_col3}
                style={{ visibility: !data?.source && "hidden" }}
              >
                <p className={style.cu_label}>Resignation Date</p>
                <p className={style.cu_field2} style={{ textWrap: "nowrap" }}>
                  {data?.resignationDate &&
                    moment(data?.resignationDate).format("DD MMM YY")}
                </p>
              </div>
            </div>
          </div>
        )}
        {/* Other Information Edit */}
        {edit.oInfo && (
          <div
            className={editStyle.createposition_other}
            style={{ paddingBottom: "10px" }}
          >
            <p className={editStyle.title}>Other Information</p>
            {/* first */}
            <div className={editStyle.info_first_row}>
              <div className={editStyle.cu_col1}>
                <p className={editStyle.cu_label}>
                  Notice Period
                  {candidateData?.isPartnerResource ? null : (
                    <span className={editStyle.mandatory}>*</span>
                  )}
                </p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  options={getNoticePreOption}
                  value={getNoticePreOption?.find(
                    (obj) => obj.value === candidateData?.noticePeriod
                  )}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) =>
                    setCandidateData({
                      ...candidateData,
                      noticePeriod: e?.label,
                    })
                  }
                ></Select>
                <p
                  className={
                    !nameValidate && validate && !candidateData?.noticePeriod
                      ? editStyle.errorText3
                      : editStyle.errorTextHidden
                  }
                >
                  Please Select Notice Period
                </p>
              </div>
              <div className={editStyle.cu_col2}>
                <p className={editStyle.cu_label}>Last Working Day </p>
                <div className={editStyle.cu_field23Last}>
                  <DatePicker
                    placeholder=" DD MM YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={
                      candidateData?.lastWorkingDay
                        ? moment(candidateData?.lastWorkingDay).toDate()
                        : null
                    }
                    onChange={(date) => {
                      setCandidateData({
                        ...candidateData,
                        lastWorkingDay: moment(date).format(),
                      });
                    }}
                    dateFormat="dd MMM yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    customInput={
                      candidateData?.lastWorkingDay ? (
                        <CustomInputCross
                          properties="lastWorkingDay"
                          formData={candidateData}
                          setFormData={setCandidateData}
                        />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                  />
                </div>
              </div>
              <div
                className={editStyle.cu_col3}
                style={{
                  display: "flex",
                  width: "31%",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "45%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <CheckBoxCustom
                    item={"Buy Out"}
                    checked={candidateData?.buyOut}
                    onChange={() =>
                      setCandidateData((prevData) => ({
                        ...prevData,
                        buyOut: !prevData?.buyOut,
                      }))
                    }
                  />
                  <CheckBoxCustom
                    item={"Negotiable"}
                    checked={candidateData?.buyOutNegotiable}
                    onChange={() =>
                      setCandidateData((prevData) => ({
                        ...prevData,
                        negotiableDaysId: null,
                        buyOutNegotiable: !prevData?.buyOutNegotiable,
                      }))
                    }
                  />
                  <p
                    className={
                      (nameValidate || validate) &&
                        candidateData.buyOut &&
                        !candidateData?.buyOutNegotiable
                        ? editStyle.errorText4
                        : editStyle.errorTextHidden
                    }
                  >
                    Please Select Negotiable
                  </p>
                </div>

                <div style={{ width: "50%" }}>
                  <p
                    className={editStyle.cu_label}
                    style={{ paddingBottom: "0.5rem" }}
                  >
                    Negotiable Days
                  </p>

                  <Select
                    styles={customStyles}
                    options={negotialbleOptions}
                    classNamePrefix={"create_position"}
                    isDisabled={!candidateData?.buyOutNegotiable}
                    value={
                      candidateData?.negotiableDaysId
                        ? negotialbleOptions?.find(
                          (e) => e.value === candidateData?.negotiableDaysId
                        )
                        : null
                    }
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) =>
                      setCandidateData({
                        ...candidateData,
                        negotiableDaysId: e?.value,
                      })
                    }
                  ></Select>
                  {/* <p
                    className={
                      formData?.buyOut &&
                      (validate || nameValidate) &&
                      !formData?.buyOutNegotiable
                        ? style.errorText3
                        : style.errorTextHidden
                    }
                  >
                    Please Select Negotiable
                  </p> */}
                </div>
              </div>
            </div>
            {/* Second */}
            <div className={editStyle.info_first_row}>
              <div className={editStyle.cu_col1}>
                <p className={editStyle.cu_label}> Offer In Hand</p>
                <Select
                  styles={customStyles}
                  options={offerOnHandOptions}
                  classNamePrefix={"create_position"}
                  value={offerOnHandOptions?.find(
                    (obj) => obj.value === candidateData?.offerInHand
                  )}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) =>
                    setCandidateData({
                      ...candidateData,
                      offerInHand: e?.value,
                    })
                  }
                ></Select>
              </div>
              <div className={editStyle.cu_col2}>
                <p className={editStyle.cu_label}>Company Name</p>
                <input
                  className={editStyle.bigInput}
                  value={candidateData?.companyName}
                  onChange={(e) =>
                    setCandidateData({
                      ...candidateData,
                      companyName: e?.target?.value,
                    })
                  }
                  onKeyDown={handleKeyDown}
                  onClick={handleInputClick}
                ></input>
                {/* <p
                  className={
                    validate && !formData?.companyName
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Company Name
                </p> */}
              </div>
              <div className={editStyle.cu_col2}>
                <p className={editStyle.cu_label}>Amount (LPA)</p>
                <input
                  className={editStyle.bigInput}
                  value={candidateData?.amount}
                  type="number"
                  min={0}
                  onChange={(e) =>
                    setCandidateData({
                      ...candidateData,
                      amount: parseFloat(e?.target?.value),
                    })
                  }
                  onKeyDown={handleKeyDown}
                  onClick={handleInputClick}
                ></input>
              </div>
            </div>
            {/* three */}
            <div className={editStyle.info_third_row}>
              <div className={editStyle.cu_col2}>
                <p className={editStyle.cu_label}>
                  Current Location
                  {candidateData?.isPartnerResource ? null : (
                    <span className={editStyle.mandatory}>*</span>
                  )}
                </p>
                <input
                  className={editStyle.bigInput}
                  value={candidateData?.currentLocation}
                  onChange={(e) =>
                    setCandidateData({
                      ...candidateData,
                      currentLocation: e?.target?.value,
                    })
                  }
                  onKeyDown={handleKeyDown}
                  onClick={handleInputClick}
                />
                <p
                  className={
                    !nameValidate &&
                      validate &&
                      !candidateData?.currentLocation?.trim()
                      ? editStyle.errorText4
                      : editStyle.errorTextHidden
                  }
                >
                  Please Enter Current Location
                </p>
              </div>
              <div className={editStyle.cu_col2}>
                <p className={editStyle.cu_label}>
                  Hometown
                  {candidateData?.isPartnerResource ? null : (
                    <span className={editStyle.mandatory}>*</span>
                  )}
                </p>
                <input
                  className={editStyle.bigInput}
                  value={candidateData?.homeTown}
                  onChange={(e) =>
                    setCandidateData({
                      ...candidateData,
                      homeTown: e?.target?.value,
                    })
                  }
                  onKeyDown={handleKeyDown}
                  onClick={handleInputClick}
                />
                <p
                  className={
                    !nameValidate &&
                      validate &&
                      !candidateData?.homeTown?.trim()
                      ? editStyle.errorText4
                      : editStyle.errorTextHidden
                  }
                >
                  Please Enter Hometown
                </p>
              </div>
              <div className={editStyle.cu_col1} style={{ display: "flex" }}>
                <div style={{ width: "50%" }}>
                  <CheckBoxCustom
                    item={"Open for Pan India"}
                    checked={candidateData.openForPanIndia}
                    onChange={() =>
                      setCandidateData((prevData) => ({
                        ...prevData,
                        openForPanIndia: !prevData.openForPanIndia,
                        openForNG: null,
                      }))
                    }
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <p className={editStyle.cu_label}>
                    Open for Noida/Gurugram
                    {candidateData?.isPartnerResource
                      ? null
                      : !candidateData.openForPanIndia && (
                        <span className={editStyle.mandatory}>*</span>
                      )}
                  </p>
                  <Select
                    styles={customStyles}
                    classNamePrefix={"create_position"}
                    isDisabled={candidateData?.openForPanIndia}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    options={offerOnHandOptions}
                    value={
                      offerOnHandOptions?.find(
                        (obj) => obj.value === candidateData?.openForNG
                      ) === undefined
                        ? { value: null, label: "" }
                        : offerOnHandOptions?.find(
                          (obj) => obj.value === candidateData?.openForNG
                        )
                    }
                    onChange={(e) =>
                      setCandidateData({
                        ...candidateData,
                        openForNG: e?.value,
                      })
                    }
                  ></Select>
                  <p
                    className={
                      (!nameValidate &&
                        validate &&
                        candidateData?.openForNG === "") ||
                        (!nameValidate &&
                          validate &&
                          candidateData?.openForNG === null) ||
                        (!nameValidate &&
                          validate &&
                          candidateData?.openForNG === undefined)
                        ? editStyle.errorText4
                        : editStyle.errorTextHidden
                    }
                  >
                    Please Select Open for Noida/Gurugram
                  </p>
                </div>
              </div>
            </div>
            {/*four  */}
            <div className={editStyle.info_first_row}>
              <div className={editStyle.cu_col1}>
                <p className={editStyle.cu_label}>
                  Source
                  {candidateData?.isPartnerResource ? null : (
                    <span className={editStyle.mandatory}>*</span>
                  )}
                </p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  isDisabled={candidateData?.isPartnerResource}
                  value={getAllSourceOption?.find(
                    (obj) => obj.value === candidateData?.sourceId
                  )}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  options={getAllSourceOption}
                  onChange={(e) =>
                    setCandidateData({
                      ...candidateData,
                      sourceId: parseInt(e?.value),
                      source: "",
                    })
                  }
                ></Select>
                <p
                  className={
                    !candidateData?.isPartnerResource &&
                      validate &&
                      !candidateData?.sourceId
                      ? editStyle.errorText5
                      : editStyle.errorTextHidden
                  }
                >
                  Please Select Source
                </p>
              </div>
              <div className={editStyle.cu_col1}>
                <p className={editStyle.cu_label}>
                  {candidateData?.sourceId !== 6
                    ? "Other"
                    : "Colleges/Institution"}
                  {candidateData?.isPartnerResource ? null : candidateData?.sourceId ===
                    6 || candidateData?.sourceId === 4 ? (
                    <span className={editStyle.mandatory}>*</span>
                  ) : null}
                </p>
                <input
                  className={editStyle.bigInput}
                  disabled={
                    candidateData?.sourceId === 4 ||
                      candidateData?.sourceId === 6
                      ? false
                      : true
                  }
                  value={candidateData?.source}
                  onChange={(e) =>
                    setCandidateData({
                      ...candidateData,
                      source: e?.target?.value,
                    })
                  }
                  onKeyDown={handleKeyDown}
                  onClick={handleInputClick}
                />
                <p
                  className={
                    !candidateData?.isPartnerResource &&
                      validate &&
                      (candidateData?.sourceId === 4 ||
                        candidateData?.sourceId === 6) &&
                      !candidateData?.source
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select{" "}
                  {candidateData?.sourceId === 4
                    ? "Other"
                    : candidateData?.sourceId === 6 && "Colleges/Institution"}
                </p>
              </div>
              <div className={editStyle.cu_col2}>
                <p className={editStyle.cu_label}>Google Drive Link</p>
                <input
                  className={editStyle.bigInput}
                  value={candidateData?.driveLink}
                  type="text"
                  onChange={(e) =>
                    setCandidateData({
                      ...candidateData,
                      driveLink: e?.target?.value,
                    })
                  }
                  onKeyDown={handleKeyDown}
                  onClick={handleInputClick}
                ></input>
              </div>
            </div>
            {/* fifth */}
            <div className={editStyle.info_first_row}>
              <div className={editStyle.cu_col1}>
                <p className={editStyle.cu_label}>
                  Preferred Location
                  <span className={editStyle.mandatory}>*</span>
                </p>
                <input
                  className={editStyle.bigInput}
                  value={candidateData?.preferredLocation}
                  onChange={(e) =>
                    setCandidateData({
                      ...candidateData,
                      preferredLocation: e?.target?.value,
                    })
                  }
                />
                <p
                  className={
                    !nameValidate &&
                      validate &&
                      !candidateData?.preferredLocation?.trim()
                      ? editStyle.errorText4
                      : editStyle.errorTextHidden
                  }
                >
                  Please Enter Preferred Location
                </p>
              </div>
              <div className={editStyle.cu_col2}>
                <p className={editStyle.cu_label}>Portfolio Link</p>
                <input
                  className={editStyle.bigInput}
                  value={candidateData?.portfolioLink}
                  onChange={(e) =>
                    setCandidateData({
                      ...candidateData,
                      portfolioLink: e?.target?.value,
                    })
                  }
                />
              </div>
              <div className={editStyle.cu_col3}>
                <p className={editStyle.cu_label}>Resignation Date</p>
                <div className={editStyle.cu_field23Last}>
                  <DatePicker
                    placeholder=" DD MM YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={
                      candidateData?.resignationDate
                        ? moment(candidateData?.resignationDate).toDate()
                        : null
                    }
                    onChange={(date) => {
                      setCandidateData({
                        ...candidateData,
                        resignationDate: moment(date).format(),
                      });
                    }}
                    dateFormat="dd MMM yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    customInput={
                      candidateData?.resignationDate ? (
                        <CustomInputCross
                          properties="resignationDate"
                          formData={candidateData}
                          setFormData={setCandidateData}
                        />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div
              className={editStyle.cu_col1}
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <p className={editStyle.btnRow}>
                <button
                  className={editStyle.cancel_btn22}
                  onClick={() => {
                    setEdit({
                      ...editObj,
                    });
                    setCandidateData({ ...tempCandidatedataRef.current });
                  }}
                >
                  Cancel
                </button>
                <button
                  className={style.create_btn}
                  onClick={() => onCreateHandler("Other Information")}
                >
                  Update
                </button>
              </p>
            </div>
          </div>
        )}
        {/* Social Platforms */}
        <ATSCard>
          <div className={style.socialResume}>
            {!edit.socialPlatform && (
              <ATSContainer>
                <p className={style.title}>
                  Social Platforms{" "}
                  <p style={{ position: "relative", left: "30%" }}>
                    {prevData?.page1 !== "duplicityCheck" &&
                      ValidationEmpAccess(
                        validateAccess,
                        accessCode?.position_management_edit
                      ) && (
                        <div
                          onClick={() => {
                            setEdit({
                              ...edit,
                              socialPlatform: true,
                              workExp: false,
                              partner: false,
                              salary: false,
                              disableSalaryBtn: false,
                              personalInfo: false,
                              experience: false,
                              academic: false,
                              resume: false,
                              oInfo: false,
                            });
                          }}
                          title={"Edit"}
                          className={style.imgClass}
                        >
                          <img
                            src={editIcon}
                            alt="Edit"
                            width="20rem"
                            height="20rem"
                          />
                        </div>
                      )}
                  </p>
                </p>
                <div className={style.Cu_row1}>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>LinkedIn Profile</p>
                    {data?.linkedInUrl ? (
                      <>
                        <a
                          href={data?.linkedInUrl}
                          target="_blank"
                          title="Click Here"
                          rel="noopener noreferrer"
                        >
                          <FaLinkedin size={25} style={{ color: "black" }} />
                        </a>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>Company's LinkedIn</p>
                    {data?.companyLinkedInUrl ? (
                      <>
                        <a
                          href={data?.companyLinkedInUrl}
                          target="_blank"
                          title="Click Here"
                          rel="noopener noreferrer"
                        >
                          <FaLinkedin size={25} style={{ color: "black" }} />
                        </a>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={style.cu_col3}>
                    <p className={style.cu_label}>Company's Website</p>
                    {data?.companyWebUrl ? (
                      <>
                        <a
                          href={data?.companyWebUrl?.includes("https") || data?.companyWebUrl?.includes("http") ? data?.companyWebUrl : "//" + data?.companyWebUrl}
                          target="_blank"
                          title="Click Here"
                          rel="noopener noreferrer"
                        >
                          <FaBuilding size={25} color="blue" />
                        </a>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </ATSContainer>
            )}
            {edit.socialPlatform && (
              <ATSContainer>
                <div style={{ paddingBottom: "1rem" }}>
                  <p className={createStyle.title}>Social Platforms</p>

                  <div className={createStyle.leftSide}>
                    <div className={createStyle.cu_col1}>
                      <p className={createStyle.cu_label}>LinkedIn Profile</p>
                      <input
                        className={createStyle.bigInput}
                        value={candidateData?.linkedInUrl}
                        onChange={(e) =>
                          setCandidateData({
                            ...candidateData,
                            linkedInUrl: e?.target?.value,
                          })
                        }
                      ></input>
                      <p
                        className={
                          (nameValidate || validate) &&
                            !linkendInUrl(candidateData?.linkedInUrl)
                            ? createStyle.errorText
                            : createStyle.errorTextHidden
                        }
                      >
                        Please Enter Valid LinkedIn Profile
                      </p>
                    </div>
                    <div className={createStyle.cu_col2}>
                      <p className={createStyle.cu_label}>Company's LinkedIn</p>
                      <input
                        className={createStyle.bigInput}
                        onChange={(e) =>
                          setCandidateData({
                            ...candidateData,
                            companyLinkedInUrl: e?.target?.value,
                          })
                        }
                        value={candidateData?.companyLinkedInUrl}
                      ></input>
                      <p
                        className={
                          (nameValidate || validate) &&
                            !linkendInUrl(candidateData?.companyLinkedInUrl)
                            ? createStyle.errorText
                            : createStyle.errorTextHidden
                        }
                      >
                        Please Enter Valid Company's LinkedIn
                      </p>
                    </div>
                    <div className={createStyle.cu_col3}>
                      <p className={createStyle.cu_label}>
                        Company's Website{" "}
                        {/* {!(
                          candidateData?.totalExperienceMonth === 0 &&
                          candidateData?.totalExperienceYear === 0
                        ) && <span createStyle={{ color: "red" }}>*</span>} */}
                      </p>
                      <input
                        className={createStyle.bigInput}
                        value={candidateData?.companyWebUrl}
                        onChange={(e) =>
                          setCandidateData({
                            ...candidateData,
                            companyWebUrl: e?.target?.value,
                          })
                        }
                      ></input>
                      {/* <p
                        className={
                          (nameValidate || validate) &&
                            !companyWebsiteInUrl(
                              candidateData?.companyWebUrl,
                              candidateData?.totalExperienceYear,
                              candidateData?.totalExperienceMonth
                            )
                            ? createStyle.errorText
                            : createStyle.errorTextHidden
                        }
                      >
                        Please Enter valid Company's Website
                      </p> */}
                    </div>
                    <div className={createStyle.cu_col3}>
                      <p className={editStyle.btnRow}>
                        <button
                          className={editStyle.cancel_btn22}
                          onClick={() => {
                            setEdit({
                              ...editObj,
                            });
                            setCandidateData({
                              ...tempCandidatedataRef.current,
                            });
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className={style.create_btn}
                          onClick={() => onCreateHandler("Social Platforms")}
                        >
                          Update
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </ATSContainer>
            )}
            {/* resume */}
            {!edit.resume && (
              <ATSContainer styles={{ height: "7rem" }}>
                <p className={style.title}>
                  Upload Resume{" "}
                  <p style={{ position: "relative", left: "30%" }}>
                    {prevData?.page1 !== "duplicityCheck" &&
                      ValidationEmpAccess(
                        validateAccess,
                        accessCode?.position_management_edit
                      ) && (
                        <div
                          onClick={() => {
                            setEdit({
                              ...edit,
                              resume: true,
                              workExp: false,
                              partner: false,
                              salary: false,
                              disableSalaryBtn: false,
                              personalInfo: false,
                              experience: false,
                              academic: false,
                              socialPlatform: false,
                              oInfo: false,
                            });
                          }}
                          title={"Edit"}
                          className={style.imgClass}
                        >
                          <img
                            src={editIcon}
                            alt="Edit"
                            width="20rem"
                            height="20rem"
                          />
                        </div>
                      )}
                  </p>
                </p>
                <div className={style.Cu_resume}>
                  {data?.resumeUrl ? (
                    <p
                      className={style.rmpdf}
                      style={{ width: !resumeName && "4.5rem" }}
                    >
                      <a
                        href={data?.resumeUrl}
                        target="_blank"
                        style={{ color: "green" }}
                        rel="noopener noreferrer"
                      >
                        {resumeName
                          ? checkFileType(resumeName?.split(".")[1])
                          : "No file selected"}
                      </a>
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </ATSContainer>
            )}
            {edit.resume && (
              <ATSContainer styles={{ height: "10rem" }}>
                <div className={createStyle.rightSide}>
                  <p className={createStyle.title}>
                    Upload Resume <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={createStyle.Cu_row1} style={{height : resumeName ? "68px" : "48px", alignItems: "flex-end"}}>
                    {resumeName ? (
                      // <p className={createStyle.resume}>{formData?.resumeUrl}</p>
                      <div className={createStyle.Cu_resume}>
                        {/*                   {/* <img src={pdfIcon} alt="pdf" /> */}

                        <div className={createStyle.rmpdf}>
                          {
                            <>
                              {checkfileType(resumeName?.split(".")[1])}&nbsp;
                              {/*    <span>{resumeName}</span>*/}
                            </>
                          }
                        </div>
                        <p
                          onClick={() => {
                            setCandidateData({
                              ...candidateData,
                              resumeUrl: "",
                            });
                            setResumeName(null);
                            // deleteResume(candidateData?.resumeUrl);
                            setToBeDeleted(prev=>([...prev, candidateData?.resumeUrl]));
                          }}
                          className={createStyle.rmpdfcross}
                        >
                          x
                        </p>
                      </div>
                    ) : (
                      <input
                        placeholder={`${candidateData?.resumeUrl
                          ? candidateData?.resumeUrl
                          : "no file selected"
                          }`}
                        style={{ height: "fit-content" }}
                        type="file"
                        className={createStyle.upload_resume}
                        onChange={(e) => {
                          uploadResumeMutation.mutate(e.target.files[0]);
                          setResumeName(e.target.files[0].name);
                        }}
                      />
                    )}
                  </div>
                  {!resumeName && <p
                    className={
                      (nameValidate || validate) && !candidateData?.resumeUrl
                        ? createStyle.errorText
                        : createStyle.errorTextHidden
                    }
                    
                  >
                    &nbsp;&nbsp; Please Upload Resume
                  </p>}
                  <p className={editStyle.btnRow}>
                    <button
                      className={editStyle.cancel_btn22}
                      onClick={() => {
                        setResumeName(data?.resumeUrl ? data?.resumeUrl.split("@")[1] : "");
                        setEdit({
                          ...editObj,
                        });
                        setCandidateData({
                          ...tempCandidatedataRef.current,
                          resumeUrl: data?.resumeUrl
                            ? data?.resumeUrl
                            : "",
                        });
                        setToBeDeleted([]);
                        setValidate(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className={style.create_btn}
                      onClick={() => onCreateHandler("Resume")}
                    >
                      Update
                    </button>
                  </p>
                  
                </div>
              </ATSContainer>
            )}
          </div>
        </ATSCard>
        {/* Social Platforms Edit */}
        {/* <SocialAndResume
            setFormData={setCandidateData}
            formData={candidateData}
            nameValidate={nameValidate}
            validate={validate}
            linkendInUrl={linkendInUrl}
            companyWebsiteInUrl={companyWebsiteInUrl}
            resumeName={resumeName}
            checkfileType={checkfileType}
            setResumeName={setResumeName}
            uploadResumeMutation={uploadResumeMutation}
            deleteResume={deleteResume}
            edit={edit}
          /> */}
        {params?.candidateId ? null : (
          <div className={style.btnCotainer}>
            <div>
              {prevData?.page1 !== "duplicityCheck" &&
                ValidationEmpAccess(
                  validateAccess,
                  accessCode?.drop_candidate
                ) && (
                  <div>
                    {data?.joiningDropResponseModel?.status === "Dropped" ? (
                      <button
                        className={style.buttonDroppedAct}
                        onClick={() => {
                          setrReActivate(true);
                        }}
                      >
                        Re-Activate Candidate
                      </button>
                    ) : (
                      <button
                        className={style.buttonDropped}
                        onClick={() => {
                          setCandidateJoined(true);
                        }}
                      >
                        Drop Candidate
                      </button>
                    )}
                  </div>
                )}
            </div>
            <div className={style.last_btn}>
              <div className={style.last_btn_row}>
                {data?.partnerId && !data?.screenNote && (
                  <ATSButton
                    onClick={() => {
                      setShowScreenRejectNote(!showScreenRejectNote);
                    }}
                  >
                    Screen Reject
                  </ATSButton>
                )}
                {prevData?.page1 !== "duplicityCheck" &&
                  ValidationEmpAccess(
                    validateAccess,
                    accessCode?.candidate_profile_add_notes
                  ) && (
                    <ATSButton
                      onClick={() => {
                        setShowNote(!showNote);
                      }}
                    >
                      Add Note
                    </ATSButton>
                  )}
                {!data?.readyForClientSharing &&
                  prevData?.page1 !== "duplicityCheck" &&
                  data?.forClient &&
                  ValidationEmpAccess(
                    validateAccess,
                    accessCode?.ready_for_client_share
                  ) && (
                    <ATSButton onClick={() => setClientShare(true)}>
                      Ready for Client Share
                    </ATSButton>
                  )}
                {prevData?.page1 !== "duplicityCheck" &&
                  ValidationEmpAccess(
                    validateAccess,
                    accessCode?.mark_as_offer_release
                  ) &&
                  (data?.markForOffer === false ? (
                    <ATSButton onClick={() => setShowFinal(true)}>
                      Mark for Offer Release
                    </ATSButton>
                  ) : (
                    <ATSButton onClick={() => setCancelMrkShow(true)}>
                      Revoke for Offer Release
                    </ATSButton>
                  ))}
                {/* {prevData?.page1 !== "duplicityCheck" &&
                ValidationEmpAccess(validateAccess, accessCode?.interview) && (
                  <ATSButton
                    onClick={() => {
                      // refetchInterview();
                      setNextScreen("candidateAssessmentList");
                      setPrevData((prevData) => ({
                        ...prevData,
                        sidebar: "myInterviewList",
                        candidateId: prevData?.candidateId,
                        page1: "candidateList",
                        page2: "ViewCandidate",
                        page3: "candidateAssessmentList",
                        active: "candidateAssessmentList",
                      }));
                    }}
                  >
                    Interviews
                  </ATSButton>
                )} */}
                {/* {prevData?.page1 !== "duplicityCheck" &&
                ValidationEmpAccess(validateAccess, accessCode?.offers) && (
                  <ATSButton
                    onClick={() => {
                      setNextScreen("CandidateOfferScreen");
                      setPrevData((prevData) => ({
                        ...prevData,
                        sidebar: "myCandidateFinalStages",
                        candidateId: prevData?.candidateId,
                        page1: "candidateList",
                        page2: "ViewCandidate",
                        page3: "CandidateOfferScreen",
                        active: "CandidateOfferScreen",
                      }));
                    }}
                  >
                    Offers
                  </ATSButton>
                )} */}
                {/* <ATSButton
                onClick={() => {
                  setShowTLDecisionModal(true);
                }}
              >
                TL Decision
              </ATSButton> */}
              </div>
              <div className={style.last_btn_row}>
                {prevData?.page1 === "candidateAssessmentList" ||
                  prevData?.page2 === "candidateAssessmentList" ||
                  prevData?.page3 === "candidateAssessmentList"
                  ? null
                  : prevData?.page1 !== "duplicityCheck" &&
                  ValidationEmpAccess(
                    validateAccess,
                    accessCode?.interview
                  ) && (
                    <ATSButton
                      onClick={() => {
                        setNextScreen("candidateAssessmentList");
                        setPrevData((prevData) => {
                          const pages = [
                            prevData.page1,
                            prevData.page2,
                            prevData.page3,
                            prevData.page4,
                            prevData.page5,
                            prevData.page6,
                          ].filter((page) => page !== null);
                          const newPageKey = `page${pages.length + 1}`;
                          return {
                            ...prevData,
                            sidebar: prevData?.sidebar,
                            candidateId: prevData?.candidateId,
                            [newPageKey]: "candidateAssessmentList",
                            active: "candidateAssessmentList",
                          };
                        });
                      }}
                    >
                      Interviews
                    </ATSButton>
                  )}

                {prevData?.page1 === "CandidateOfferScreen" ||
                  prevData?.page2 === "CandidateOfferScreen" ||
                  prevData?.page3 === "CandidateOfferScreen"
                  ? null
                  : prevData?.page1 !== "duplicityCheck" &&
                  ValidationEmpAccess(
                    validateAccess,
                    accessCode?.interview
                  ) && (
                    <ATSButton
                      onClick={() => {
                        setNextScreen("CandidateOfferScreen");
                        setPrevData((prevData) => {
                          const pages = [
                            prevData.page1,
                            prevData.page2,
                            prevData.page3,
                            prevData.page4,
                            prevData.page5,
                            prevData.page6,
                          ].filter((page) => page !== null);
                          const newPageKey = `page${pages.length + 1}`;
                          return {
                            ...prevData,
                            sidebar: prevData?.sidebar,
                            candidateId: prevData?.candidateId,
                            [newPageKey]: "CandidateOfferScreen",
                            active: "CandidateOfferScreen",
                          };
                        });
                      }}
                    >
                      Offers
                    </ATSButton>
                  )}

                <ATSButton
                  onClick={() => {
                    setShowTLDecisionModal(true);
                  }}
                >
                  TL Decision
                </ATSButton>

                {prevData?.page1 === "candidateMovement" ||
                  prevData?.page2 === "candidateMovement" ||
                  prevData?.page3 === "candidateMovement" ||
                  prevData?.page4 === "candidateMovement"
                  ? null
                  : ValidationEmpAccess(
                    validateAccess,
                    accessCode?.interview
                  ) && (
                    <ATSButton
                      onClick={() => {
                        setNextScreen("candidateMovement");
                        setPrevData((prevData) => {
                          const pages = [
                            prevData.page1,
                            prevData.page2,
                            prevData.page3,
                            prevData.page4,
                            prevData.page5,
                            prevData.page6,
                          ].filter((page) => page !== null);
                          const newPageKey = `page${pages.length + 1}`;
                          return {
                            ...prevData,
                            sidebar: prevData?.sidebar,
                            reportCandidateId: data?.id,
                            [newPageKey]: "candidateMovement",
                            active: "candidateMovement",
                          };
                        });
                      }}
                    >
                      Candidate Journey
                    </ATSButton>
                  )}

                {/* BGV List */}
                {ValidationEmpAccess(validateAccess, accessCode?.interview) &&
                  candidateData?.bgvButtonVisibility && (
                    <ATSButton
                      onClick={() => {
                        setNextScreen("addBGV");
                        setPrevData((prev) => ({
                          ...prev,
                          page4: "addBGV",
                          active: "addBGV",
                          candidatebgvId: prevData.candidateId,
                        }));
                      }}
                    >
                      {" "}
                      BGV
                    </ATSButton>
                  )}


                {/* {prevData?.page1 !== "Watchlist" ?
                <ATSButton
                  onClick={() => {
                    handlePage(data);
                  }}
                >
                  Candidate Journey
                </ATSButton> : null
              } */}
              </div>
              {ValidationEmpAccess(validateAccess, accessCode?.send_for_review) && data?.joiningDropResponseModel?.status !== "Dropped" && data?.statusByTeamLead === "Hold" && <ATSButton
                onClick={() => setShowSFR(true)}
                styles={{ width: "10rem" }}
              >
                Send For Review
              </ATSButton>}
            </div>
          </div>
        )}
        <ConfirmPopup open={reActivate}>
          <div className={style.confirmPopup3}>
            <div className={style.center}>Re-Activate Candidate </div>
            <div className={style.cantent}>
              <div>
                {" "}
                Date <span style={{ color: "red" }}>*</span>
              </div>
              <div className={style.cu_field23Last}>
                <DatePicker
                  placeholder=" DD MM YYYY"
                  selected={
                    candiDropForm?.joiningDate
                      ? moment(candiDropForm?.joiningDate).toDate()
                      : null
                  }
                  onChange={(date) => {
                    setCandiDropForm({
                      ...candiDropForm,
                      joiningDate: moment(date).format(),
                    });
                  }}
                  dateFormat="dd MMM yyyy"
                  customInput={<CustomInputClg />}
                />
              </div>
              {
                <p
                  className={
                    validate && !candiDropForm?.joiningDate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                  style={{ paddingTop: "0.3rem", marginLeft: "0px" }}
                >
                  Please Select Date
                </p>
              }
              {
                <p
                  className={
                    validate &&
                      candiDropForm?.joiningDate <
                      data?.joiningDropResponseModel?.dropJoinDate &&
                      candiDropForm?.joiningDate !== ""
                      ? style.errorText
                      : style.errorTextHidden
                  }
                  style={{ transform: "translateY(-20px)", marginLeft: "0px" }}
                >
                  Please Select Date after Drop Date
                </p>
              }
              {
                <p
                  className={
                    validate &&
                      candiDropForm?.joiningDate < moment(Date.now()).format() &&
                      candiDropForm?.joiningDate !== "" &&
                      !(
                        candiDropForm?.joiningDate <
                        data?.joiningDropResponseModel?.dropJoinDate
                      )
                      ? style.errorText
                      : style.errorTextHidden
                  }
                  style={{ transform: "translateY(-40px)", marginLeft: "0px" }}
                >
                  Please Select Date after Past Date
                </p>
              }

              <div style={{ transform: "translateY(-40px)" }}>Note</div>
              <div style={{ transform: "translateY(-40px)" }}>
                <textarea
                  className={style.textarea}
                  onChange={(e) => {
                    setCandiDropForm((prevData) => ({
                      ...prevData,
                      note: e.target.value,
                    }));
                  }}
                ></textarea>
              </div>
            </div>

            <div
              className={style.modalBtn1}
              style={{ transform: "translateY(-40px)" }}
            >
              <div className={style.btnCantainer}>
                <button
                  className={style.cancelBtn}
                  onClick={() => {
                    setrReActivate(false);
                    setCandiDropForm((prev) => ({
                      ...prev,
                      note: "",
                      joiningDate: null,
                    }));
                  }}
                >
                  Cancel
                </button>
                <button
                  className={style.createOfferBtn}
                  disabled={disable}
                  style={
                    disable
                      ? { background: "gray", border: "1px solid gray" }
                      : null
                  }
                  onClick={() => {
                    // setrReActivate(false);
                    handleReActivate();
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </ConfirmPopup>
        <ConfirmPopup open={candidateJoined}>
          <div className={style.confirmPopup1}>
            <div className={style.center}>Drop Candidate </div>
            <div className={style.FutureHire}>
              <div className={style.cantent}>
                <div>
                  Drop Date <span style={{ color: "red" }}>*</span>
                </div>
                <div className={style.cu_field23Last}>
                  <DatePicker
                    placeholder=" DD MM YYYY"
                    selected={
                      candiDropForm?.joiningDate
                        ? moment(candiDropForm?.joiningDate).toDate()
                        : null
                    }
                    onChange={(date) => {
                      setCandiDropForm({
                        ...candiDropForm,
                        joiningDate: moment(date).format(),
                      });
                    }}
                    dateFormat="dd MMM yyyy"
                    customInput={<CustomInputClg />}
                  />

                  <p
                    className={
                      validate && !candiDropForm?.joiningDate
                        ? style.errorText
                        : style.errorTextHidden
                    }
                    style={{
                      paddingTop: "0.8rem",
                      marginLeft: "0px !important",
                    }}
                  >
                    Please Select Drop Date
                  </p>
                </div>

                <p style={{ paddingTop: "1rem" }}>
                  Drop Reason: <span style={{ color: "red" }}>*</span>
                </p>
                <div style={{ paddingTop: "0.5rem" }}>
                  <Select
                    placeholder="Reason For Drop"
                    styles={customStyles5}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    options={dropOption}
                    onChange={(e) =>
                      setCandiDropForm((prevData) => ({
                        ...prevData,
                        reasonId: e.value,
                      }))
                    }
                  />
                </div>
                <p
                  className={
                    validate && !candiDropForm?.reasonId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                  style={{ paddingTop: "0.1rem", marginLeft: "0px" }}
                >
                  Please Select Drop Reason
                </p>

                <div>
                  <div>
                    Drop Note
                    {/* {dropReasonFn(candiDropForm?.reasonId) && ( */}
                    <span style={{ color: "red" }}>*</span>
                    {/* )} */}
                  </div>
                  <div>
                    <textarea
                      className={style.textarea}
                      onChange={(e) =>
                        setCandiDropForm((prevData) => ({
                          ...prevData,
                          note: e.target.value,
                        }))
                      }
                      onKeyDown={handleKeyDownNotes}
                    ></textarea>
                  </div>
                  {
                    <p
                      className={
                        validate && !candiDropForm?.note
                          ? // !dropformRef.current.note
                          style.errorText
                          : style.errorTextHidden
                      }
                      style={{ paddingTop: "0.1rem", marginLeft: "0px" }}
                    >
                      Please Enter Drop Note
                    </p>
                  }
                </div>

                <div>
                  <div>Upload Supporting Document</div>
                  <div className={style.docOffer1}>
                    {offerDoc ? (
                      <div className={style.Cu_resume}>
                        <div className={style.rmpdf}>
                          {
                            <>
                              {checkFileType(
                                offerDoc.split("@")[1]?.split(".")[1]
                              )}
                              &nbsp;
                              <span>{offerDoc.split("@")[1]}</span>
                            </>
                          }
                        </div>

                        <p
                          onClick={() => {
                            setCandiDropForm({
                              ...candiDropForm,
                              docUrl: "",
                            });
                            setOfferDoc(null);
                            deleteResume(candiDropForm?.docUrl);
                          }}
                          className={style.rmpdfcross}
                        >
                          x
                        </p>
                      </div>
                    ) : (
                      <input
                        placeholder={`${candiDropForm?.docUrl
                          ? candiDropForm?.docUrl
                          : "no file selected"
                          }`}
                        type="file"
                        className={style.upload_resume}
                        onChange={(e) => {
                          uploadMutation.mutate(e.target.files[0]);
                          // setOfferDoc(e.target.files);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* Future Hire*/}
              <div className={style.cantent}>
                <p>
                  Future Hire <span style={{ color: "red" }}>*</span>{" "}
                </p>
                <div style={{ paddingTop: "0.5rem" }}>
                  <Select
                    placeholder="Future Hire"
                    styles={customStyles5}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    options={futureOption}
                    onChange={(e) =>
                      setCandiDropForm((prevData) => ({
                        ...prevData,
                        futureHireId: e.value,
                      }))
                    }
                  />
                  <p
                    className={
                      validate && !candiDropForm?.futureHireId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                    style={{ paddingTop: "0.1rem", marginLeft: "0px" }}
                  >
                    Please Select Future Hire
                  </p>
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <div>Future Hire Note</div>
                  <div>
                    <textarea
                      className={style.textarea}
                      onChange={(e) =>
                        setCandiDropForm((prevData) => ({
                          ...prevData,
                          futureHireReason: e.target.value,
                        }))
                      }
                      onKeyDown={handleKeyDownNotes}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            {/* Button */}
            <div className={style.modalBtn1}>
              <div className={style.btnCantainer}>
                <button
                  className={style.cancelBtn}
                  onClick={() => {
                    setCandidateJoined(false);
                    setCandiDropForm(null);
                    setValidate(false);
                    setCandiDropForm((prevData) => ({
                      ...prevData,
                      note: "",
                      futureHireReason: "",
                    }));
                  }}
                >
                  Cancel
                </button>
                <button
                  className={style.createOfferBtn}
                  style={
                    disable
                      ? { background: "gray", border: "1px solid gray" }
                      : null
                  }
                  onClick={() => {
                    declineFormHandler();
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </ConfirmPopup>
        {/* Watchlist */}
        <ConfirmPopup open={showWatchlistModal}>
          <div className={style.modal_container_watchlist}>
            <div className={style.watchlist_inner_container}>
              <div>
                <p className={style.heading}>
                  Add Candidate to <span style={{ color: "red" }}>*</span>
                </p>
              </div>
              <div className={style.text_input}>
                <div className={style.radiocontainer}>
                  <p className={style.radioitem}>
                    <input
                      type="radio"
                      id="myWatchlist"
                      name="watchlist"
                      onChange={() => {
                        setWatchlistData({
                          ...watchlistData,
                          requesterId: [employeeId],
                          reviewCaseByEmpId: null,
                          toBeReviewed: false,
                          wishlistReviewCasesId: null,
                        });
                        setIsWatchlistValid(true);
                      }}
                    />
                    <label for="myWatchlist">My Watchlist</label>
                  </p>
                  <p className={style.radioitem}>
                    <input
                      type="radio"
                      id="otherWatchlist"
                      name="watchlist"
                      onChange={() => {
                        setWatchlistData({
                          ...watchlistData,
                          requesterId: [],
                          reviewCaseByEmpId: employeeId,
                          toBeReviewed: true,
                          wishlistReviewCasesId: null,
                        });
                        setIsWatchlistValid(true);
                      }}
                    />
                    <label for="otherWatchlist">Other's Watchlist</label>
                  </p>
                </div>
                <p
                  className={
                    !isWatchlistValid && watchlistData.toBeReviewed === null
                      ? style.errorText
                      : style.errorTextHidden
                  }
                  style={{ textAlign: "center" }}
                >
                  Select Candidate to Add in My Watchlist or Other's Watchlist
                </p>
                {/* Multi Select */}
                {watchlistData?.toBeReviewed && (
                  <div className={style.otherWatchlistContainer}>
                    <p className={style.w_label}>
                      Select Members <span style={{ color: "red" }}>*</span>
                    </p>
                    <div style={{ width: "15rem", paddingTop: "5px" }}>
                      <Select
                        styles={customStyles5}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        isMulti
                        defaultValue={existEmployee}
                        onChange={(e) => {
                          setWatchlistData({
                            ...watchlistData,
                            requesterId: e.map((i) => i.value),
                          });
                        }}
                        options={memberOptions}
                      />
                      <p
                        className={
                          !isWatchlistValid &&
                            watchlistData.requesterId.length == 0
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please Select Members
                      </p>
                    </div>
                    {/* Reason Dropdown */}
                    <p className={style.w_label}>
                      Reason <span style={{ color: "red" }}>*</span>
                    </p>
                    <div style={{ paddingTop: "0.5rem", width: "15rem" }}>
                      <Select
                        placeholder="Reason"
                        styles={customStyles5}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        options={watchlistReasonOption}
                        onChange={(e) =>
                          setWatchlistData((prevData) => ({
                            ...prevData,
                            wishlistReviewCasesId: e.value,
                          }))
                        }
                      />
                      <p
                        className={
                          !isWatchlistValid &&
                            watchlistData.wishlistReviewCasesId === null
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please Select Reason
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={style.modal_btn}>
              <div className={style.button}>
                <button
                  className={style.w_inactive}
                  onClick={() => {
                    setShowWatchlistModal(false);
                    setIsWatchlistValid(true);
                    setWatchlistData({
                      candidateId: prevData?.candidateId,
                      requesterId: [],
                      reviewCaseByEmpId: null,
                      toBeReviewed: null,
                      wishlistReviewCasesId: null,
                    });
                  }}
                >
                  Cancel
                </button>
                <button
                  className={style.b_inactive}
                  onClick={() => {
                    if (watchlistData.toBeReviewed === null) {
                      setIsWatchlistValid(false);
                    } else if (watchlistData.toBeReviewed === true) {
                      if (
                        watchlistData.requesterId.length === 0 ||
                        watchlistData.wishlistReviewCasesId === null
                      ) {
                        setIsWatchlistValid(false);
                      } else {
                        watchlistMutation.mutate(watchlistData);
                      }
                    } else {
                      watchlistMutation.mutate(watchlistData);
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </ConfirmPopup>
        {/* Comments */}
        {/* <Modal
          open={showCommentModal}
          onClose={() => {
            setShowCommentModal(false);
          }}
          showCloseIcon={true}
          center
        >
          <div className={style.modal_container_watchlist2}>
            <div className={style.watchlist_inner_container}>
              <CommentHistory
                candidateId={prevData?.candidateId}
                setShowCommentModal={setShowCommentModal}
              />
            </div>
          </div>
        </Modal> */}
        <Modal1 isOpen={isModalOpen1} onClose={closeModal1}>
          <div className={style.modal_container_watchlist2}>
            <div className={style.watchlist_inner_container}>
              <CommentHistory
                candidateId={prevData?.candidateId}
                closeModal={closeModal1}
                setShowCommentModal={setShowCommentModal}
                myCandidateName={data?.name}
                handleAddComment={handleAddComment1}
                getCommentForPosition={getCommentForCandidate}
              />
            </div>
          </div>
        </Modal1>
        {/* TL Decision */}
        <ConfirmPopup open={showTLDecisionModal}>
          <div className={style.modal_container_watchlist}>
            <div className={style.watchlist_inner_container}>
              <div>
                <p className={style.heading}>
                  TL Decision <span style={{ color: "red" }}>*</span>
                </p>
              </div>
              <div className={style.text_input}>
                <div className={style.radiocontainer}>
                  <p className={style.radioitem}>
                    <input
                      type="radio"
                      id="tl_decision_accept"
                      name="tl_decision"
                      onChange={() => {
                        setTlDecisionData({
                          candidateId: prevData?.candidateId,
                          teamLeadId: employeeId,
                          noteByTeamLead: null,
                          statusByTeamLead: "Approved",
                        });
                        setIsTL_DecisionValid(true);
                      }}
                    />
                    <label for="tl_decision_accept">Approved</label>
                  </p>
                  <p className={style.radioitem}>
                    <input
                      type="radio"
                      id="tl_decision_hold"
                      name="tl_decision"
                      onChange={() => {
                        setIsTL_DecisionValid(true);
                        setTlDecisionData({
                          candidateId: prevData?.candidateId,
                          teamLeadId: employeeId,
                          noteByTeamLead: null,
                          statusByTeamLead: "Hold",
                        });
                      }}
                    />
                    <label for="tl_decision_hold">Hold</label>
                  </p>
                  <p className={style.radioitem}>
                    <input
                      type="radio"
                      id="tl_decision_reject"
                      name="tl_decision"
                      onChange={() => {
                        setIsTL_DecisionValid(true);
                        setTlDecisionData({
                          candidateId: prevData?.candidateId,
                          teamLeadId: employeeId,
                          noteByTeamLead: null,
                          statusByTeamLead: "Rejected",
                        });
                      }}
                    />
                    <label for="tl_decision_reject">Rejected</label>
                  </p>
                </div>
                <p
                  className={
                    !isTL_DecisionValid &&
                      tlDecisionData.statusByTeamLead === null
                      ? style.errorText
                      : style.errorTextHidden
                  }
                  style={{ textAlign: "center" }}
                >
                  Select TL Decision to Approved, Hold or Rejected
                </p>
              </div>
              {tlDecisionData?.statusByTeamLead && (
                <div className={style.otherWatchlistContainer}>
                  <p className={style.w_label}>
                    Note{" "}
                    {tlDecisionData?.statusByTeamLead !== "Approved" && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                  </p>
                  <div style={{ paddingTop: "5px" }}>
                    <textarea
                      className={style.textarea}
                      onChange={(e) => (
                        (tldecisionDataNoteRef.current = e.target.value),
                        setIsTL_DecisionValid(true)
                      )}
                    ></textarea>
                    <p
                      className={
                        !isTL_DecisionValid &&
                          (tldecisionDataNoteRef.current == null ||
                            tldecisionDataNoteRef.current == "")
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Write TL{" "}
                      {tlDecisionData.statusByTeamLead === "Hold"
                        ? "Hold"
                        : "Rejection"}{" "}
                      Note
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className={style.modal_btn}>
              <div className={style.button}>
                <button
                  className={style.w_inactive}
                  onClick={() => {
                    tldecisionDataNoteRef.current = "";
                    setShowTLDecisionModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className={style.b_inactive}
                  onClick={() => {
                    if (tlDecisionData.statusByTeamLead === null) {
                      setIsTL_DecisionValid(false);
                    } else if (
                      tlDecisionData.statusByTeamLead === "Rejected" ||
                      tlDecisionData.statusByTeamLead === "Hold"
                    ) {
                      if (
                        tldecisionDataNoteRef.current === null ||
                        tldecisionDataNoteRef.current === ""
                      ) {
                        setIsTL_DecisionValid(false);
                      } else {
                        TL_DecisionMutation.mutate({
                          ...tlDecisionData,
                          noteByTeamLead: tldecisionDataNoteRef.current,
                        });
                      }
                    } else {
                      TL_DecisionMutation.mutate({
                        ...tlDecisionData,
                        noteByTeamLead: tldecisionDataNoteRef.current,
                      });
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </ConfirmPopup>

        {/* Screen Reject */}
        <ConfirmPopup open={showScreenRejectNote}>
          <div className={style.modal_container_screen_reject}>
            <div>
              <p className={style.heading}>
                Screen Reject Note <span style={{ color: "red" }}>*</span>
              </p>
            </div>
            <div className={style.text_input}>
              <textarea
                className={style.textarea}
                onChange={(e) => {
                  setScreenRejectData({
                    ...screenRejectData,
                    note: e.target.value,
                  });
                  setIsNoteValid(true);
                }}
              ></textarea>
              {!isNoteValid && (
                <p className={style.errorText}>
                  Screen Reject Note is required.
                </p>
              )}
            </div>
            <div className={style.modal_btn}>
              <div className={style.button}>
                <button
                  className={style.w_inactive}
                  onClick={() => {
                    setShowScreenRejectNote(false);
                    setIsNoteValid(true);
                  }}
                >
                  Cancel
                </button>
                <button
                  className={style.b_inactive}
                  onClick={() => {
                    if (screenRejectData?.note?.trim()) {
                      submitScreenRejectNote();
                      setShowScreenRejectNote(false);
                      setIsNoteValid(true);
                    } else {
                      setIsNoteValid(false);
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </ConfirmPopup>
        {/*Ready for Offer release*/}
        <ConfirmPopup open={showFinal}>
          <div className={style.modal_container_share}>
            <div>
              <p className={style.clientShare}>
                Date <span style={{ color: "red" }}>*</span>
              </p>
            </div>
            <div className={style.positionOpenedOn}>
              <DatePicker
                onChange={(date) => {
                  setOfferDate(date);
                }}
                selected={offerDate ? moment(offerDate).toDate() : null}
                dateFormat="dd MMM yyyy"
                customInput={<CustomInputClg />}
              />
              <p
                className={
                  validate && !offerDate
                    ? style.errorText2
                    : style.errorTextHidden2
                }
              >
                Please Enter Date
              </p>
            </div>
            <div className={style.M_button} style={{ marginTop: "2rem" }}>
              <div className={style.button}>
                <button
                  className={style.w_inactive}
                  onClick={() => {
                    setShowFinal(false);
                    setClientDate(null);
                    setValidate(false);
                  }}
                >
                  No
                </button>
                <button
                  className={style.b_inactive}
                  onClick={() => {
                    readyforOffer();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </ConfirmPopup>
        {/*Client Share */}
        <ConfirmPopup open={clientShare}>
          <div className={style.modal_container_share}>
            <div>
              <p className={style.clientShare}>
                Date <span style={{ color: "red" }}>*</span>
              </p>
            </div>
            <div className={style.positionOpenedOn}>
              <DatePicker
                onChange={(date) => {
                  setClientDate(date);
                }}
                selected={clientDate ? moment(clientDate).toDate() : null}
                dateFormat="dd MMM yyyy"
                customInput={<CustomInputClg />}
              />
              <p
                className={
                  validate && !clientDate
                    ? style.errorText2
                    : style.errorTextHidden2
                }
              >
                Please Select Date
              </p>
            </div>
            <div style={{ marginTop: "1.5rem" }}>
              <p className={style.clientShare}>
                FM Rating <span style={{ color: "red" }}>*</span>
              </p>
            </div>
            <div>
              <input
                className={style.bigInput}
                value={fmRating}
                onChange={(e) => setFMRating(e?.target.value)}
              />
              <p
                className={
                  validate && !fmRating
                    ? style.errorText2
                    : style.errorTextHidden2
                }
                style={{ paddingTop: "0.2rem" }}
              >
                &nbsp;&nbsp;&nbsp; &nbsp;Please Enter FM Rating
              </p>
            </div>
            <div className={style.M_button} style={{ marginTop: "1rem" }}>
              <div className={style.button}>
                <button
                  className={style.w_inactive}
                  onClick={() => {
                    setClientShare(false);
                    setFMRating("");
                    setClientDate(null);
                    setValidate(false);
                  }}
                >
                  No
                </button>
                <button
                  className={style.b_inactive}
                  onClick={() => {
                    readyforClient();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </ConfirmPopup>
        <Modal
          open={show}
          center
          onClose={() => setShow(false)}
          showCloseIcon={false}
          classNames={{
            modal: "reactmodal",
          }}
        >
          <div className={style.modal_container}>
            <div>
              <p className={style.textM}>
                Do you want to delete this candidate?
              </p>
            </div>
            <div className={style.M_button}>
              <div className={style.button}>
                <button
                  className={style.w_inactive}
                  onClick={() => setShow(false)}
                >
                  No
                </button>
                <button
                  className={style.b_inactive}
                  onClick={() => {
                    mutation.mutate(prevData?.candidateId);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={show1}
          center
          onClose={() => setShow1(false)}
          showCloseIcon={false}
          classNames={{
            modal: "reactmodal",
          }}
        >
          <div className={style.modal_container}>
            <div>
              <p className={style.textM}>
                Candidate is in Assessment stage and has not been marked for
                Offer Release
              </p>
            </div>
            <div className={style.M_button}>
              <div className={style.button}>
                <button
                  className={style.b_inactive}
                  onClick={() => setShow1(false)}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={show2}
          center
          onClose={() => setShow2(false)}
          showCloseIcon={false}
          classNames={{
            modal: "reactmodal",
          }}
        >
          <div className={style.modal_container}>
            <div>
              <p className={style.textM}>
                First allocate candidate on any position to view Assessment
              </p>
            </div>
            <div className={style.M_button}>
              <div className={style.button}>
                <button
                  className={style.b_inactive}
                  onClick={() => setShow2(false)}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/*Change Recuiter*/}

        <ConfirmPopup open={recuiterShow}>
          <div
            className={style.recuiterCantainer}
            style={{ height: "auto", padding: "0.5rem 0" }}
          >
            <div className={style.recuiterData}>
              <div className={style.recuiter}>
                Recruiter <span style={{ color: "red" }}>*</span>
              </div>
              <Select
                placeholder="Reason For Drop"
                styles={customStyles5}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                options={recuiterOption}
                onChange={(e) =>
                  setRecuiterData((prev) => ({
                    ...prev,
                    recruiterId: e?.value,
                  }))
                }
              />
              <p
                className={
                  validate && !recuiterData?.recruiterId
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please Select Recruiter
              </p>
              {candidateData?.joiningDropResponseModel &&
                candidateData?.joiningDropResponseModel?.status ===
                "Dropped" && (
                  <>
                    <div className={style.recuiter}>
                      Re-activate Date <span style={{ color: "red" }}>*</span>
                    </div>
                    <div className={style.cu_field23Last}>
                      <DatePicker
                        placeholder=" DD MM YYYY"
                        selected={
                          recuiterData?.date
                            ? moment(recuiterData?.date).toDate()
                            : null
                        }
                        onChange={handleDateChange}
                        dateFormat="dd MMM yyyy"
                        customInput={<CustomInputClg />}
                      />
                    </div>
                    <p
                      className={
                        validate && !recuiterData?.date
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Re-activate Date
                    </p>
                  </>
                )}
              <div style={{ textAlign: "center", marginTop: "0.7rem" }}>
                <ATSButton
                  onClick={() => {
                    setRecuiterShow(false);
                    setValidate(false);
                    setRecuiterData((prev) => ({
                      ...prev,
                      recruiterId: null,
                      date: null,
                    }));
                  }}
                >
                  Cancel
                </ATSButton>
                <ATSButton
                  styles={{ marginLeft: "1rem" }}
                  onClick={() => changeRcuiter()}
                >
                  Done
                </ATSButton>
              </div>
            </div>
          </div>
        </ConfirmPopup>
        <FinalStatusPopup open={cancelMrkShow}>
          <div className={style.confirmPopup11}>
            <div style={{ width: "80%", margin: "1rem auto" }}>
              <div style={{ textAlign: "center" }}>
                Are you sure you want to cancel for offer Release?
              </div>
              <div
                style={{
                  marginTop: "1rem",
                  textAlign: "center",
                }}
              >
                <ATSButton onClick={() => setCancelMrkShow(false)}>
                  No
                </ATSButton>
                <ATSButton
                  styles={{
                    marginLeft: "1rem",
                  }}
                  onClick={() => {
                    cancelMrkmutation.mutate(prevData.candidateId);
                  }}
                >
                  Yes
                </ATSButton>
              </div>
            </div>
          </div>
        </FinalStatusPopup>
        <Modal
          open={showFHNModal}
          center
          onClose={() => setShowFHNModal(false)}
          showCloseIcon={true}
          classNames={{
            modal: "reactModalWidth",
          }}
        >
          <>
            <div style={{ fontWeight: "600", width: "100%", margin: "10px 0px", fontSize: "18px", textAlign: "center" }}>Future Hire Note</div>
            <div
              style={{
                width: "700px",
                overflowWrap: "break-word",
                wordWrap: "break-word",
              }}
            >{data?.joiningDropResponseModel?.futureHireReason?.split('\n').map((element, index) => (
              <>
                {element}
                {index !== data?.joiningDropResponseModel?.futureHireReason?.split('\n')?.length - 1 && <br />}
              </>
            ))}</div></>
        </Modal>
        <Modal
          open={showDNModal}
          center
          onClose={() => setShowDNModal(false)}
          showCloseIcon={true}
          classNames={{
            modal: "reactModalWidth",
          }}
        >
          <>
            <div style={{ fontWeight: "600", width: "100%", margin: "10px 0px", fontSize: "18px", textAlign: "center" }}>Drop Note</div>
            <div
              style={{
                width: "700px",
                overflowWrap: "break-word",
                wordWrap: "break-word",
              }}
            >{data?.joiningDropResponseModel?.dropNote?.split('\n').map((element, index) => (
              <>
                {element}
                {index !== data?.joiningDropResponseModel?.dropNote?.split('\n')?.length - 1 && <br />}
              </>
            ))}</div></>
        </Modal>
        <Modal
          open={showSFR}
          center
          onClose={() => setShowSFR(false)}
          showCloseIcon={true}
          classNames={{
            root: style.handleZindex
          }}
        >
          <>
            <div style={{ fontWeight: "600", width: "100%", margin: "10px 0px", fontSize: "18px", textAlign: "center" }}>Send For Review</div>
            <div >Note <span style={{ color: "red" }}>*</span></div>
            <textarea style={{ height: "12rem", width: "28rem" }} onChange={(e) => setSFRNote(e?.target?.value)}></textarea>
            <div className={style.twoButtons}>
              <button className={style.w_inactive} onClick={() => setShowSFR(false)}>Cancel</button>
              <button className={style.b_inactive} onClick={() => SendForReview.mutate({ candidateId: data?.id, note: SFRNote })}>Submit</button>
            </div>

          </>
        </Modal>

        {/* <button
            className={style.view_ass}
            onClick={() => {
              setNextScreen("candidateAssessmentList");
              setPrevData((prevData) => ({
                ...prevData,
                sidebar: "myInterviewList",
                candidateId: prevData?.candidateId,
                page1: "candidateList",
                page2: "ViewCandidate",
                page3: "candidateAssessmentList",
                active: "candidateAssessmentList",
              }));
            }}
          >
            View Assessments
          </button> */}
      </div>
      <div ref={bottomRef}>
        {showNote && (
          <NoteCandidate
            setShowNote={setShowNote}
            showNote={showNote}
            setFormData={setFormData}
            formData={formData}
            showUpdate={true}
          />
        )}
      </div>
    </div>
  );
}

const CustomInputClgWXS = React.forwardRef((props, ref) => {
  let temp = [...props?.formData?.workExperiences];
  return (
    <>
      {temp[props?.index]?.startDate ? (
        <div>
          <span
            ref={ref}
            style={{
              cursor: "pointer",
              fontWeight: "400",
            }}
          >
            {props.value || props.placeholder}
          </span>
          <img
            src={cancel}
            alt="cancel"
            width="15px"
            height="15px"
            style={{
              position: "absolute",
              right: "5%",
              top: "16%",
              cursor: "pointer",
            }}
            onClick={() => {
              temp[props?.index].startDate = null;
              props?.setFormData({
                ...props?.formData,
                workExperiences: temp,
              });
            }}
          />
        </div>
      ) : (
        <div onClick={props.onClick}>
          <label
            ref={ref}
            style={{
              cursor: "pointer",
            }}
          >
            {props.value || props.placeholder}
          </label>
          <img
            src={calender}
            alt="calender"
            style={{
              position: "absolute",
              right: "5%",
              top: "13%",
              cursor: "pointer",
            }}
          />
        </div>
      )}
    </>
  );
});
const CustomInputClgWXEND = React.forwardRef((props, ref) => {
  let temp = [...props?.formData?.workExperiences];
  return (
    <>
      {temp[props?.index]?.endDate ? (
        <div>
          <span
            ref={ref}
            style={{
              cursor: "pointer",
              fontWeight: "400",
            }}
          >
            {props.value || props.placeholder}
          </span>
          <img
            src={cancel}
            width="15px"
            alt="cancel"
            height="15px"
            style={{
              position: "absolute",
              right: "5%",
              top: "16%",
              cursor: "pointer",
            }}
            onClick={() => {
              temp[props?.index].endDate = null;
              props?.setFormData({
                ...props?.formData,
                workExperiences: temp,
              });
            }}
          />
        </div>
      ) : (
        <div
          onClick={
            temp[props?.index]?.present === props?.index + 1
              ? null
              : props.onClick
          }
        >
          <label
            ref={ref}
            style={{
              cursor: "pointer",
            }}
          >
            {props.value || props.placeholder}
          </label>
          <img
            src={calender}
            alt="calender"
            style={{
              position: "absolute",
              right: "5%",
              top: "13%",
              cursor: "pointer",
            }}
          />
        </div>
      )}
    </>
  );
});

const CustomInputClgPY = React.forwardRef((props, ref) => {
  let temp = [...props?.formData?.candidateAcademics];
  return (
    <>
      {temp[props?.index]?.passingYear ? (
        <div>
          <span
            ref={ref}
            style={{
              cursor: "pointer",
              fontWeight: "400",
            }}
          >
            {props.value || props.placeholder}
          </span>
          <img
            src={cancel}
            width="15px"
            height="15px"
            alt="cancel"
            style={{
              position: "absolute",
              right: "5%",
              top: "16%",
              cursor: "pointer",
            }}
            onClick={() => {
              temp[props?.index].passingYear = null;
              props?.setFormData({
                ...props?.formData,
                candidateAcademics: temp,
              });
            }}
          />
        </div>
      ) : (
        <div onClick={props.onClick}>
          <label
            ref={ref}
            style={{
              cursor: "pointer",
            }}
          >
            {props.value || props.placeholder}
          </label>
          <img
            src={calender}
            alt="calender"
            style={{
              position: "absolute",
              right: "5%",
              top: "13%",
              cursor: "pointer",
            }}
          />
        </div>
      )}
    </>
  );
});

const CustomInputCross = React.forwardRef((props, ref) => {
  return (
    <div>
      <span
        ref={ref}
        style={{
          cursor: "pointer",
          fontWeight: "400",
        }}
      >
        {props.value || props.placeholder}
      </span>
      <img
        src={cancel}
        width="15px"
        height="15px"
        alt="cancel"
        style={{
          position: "absolute",
          right: "5%",
          top: "16%",
          cursor: "pointer",
        }}
        onClick={() =>
          props?.setFormData({ ...props?.formData, [props?.properties]: "" })
        }
      />
    </div>
  );
});

export default ViewCandidate;

import React, {useState} from 'react';
import SideBar from '../commonComponent/SideBar';
import Header from '../commonComponent/Header';
import style from './ViewTask.module.css';
import ViewTaskComponent from './component/ViewTaskComponent';

const ViewTask = () => {
  // const [taskData, settaskData] = useState(props.history.location.state);
    return(<div>
        <Header />
  
        <div className={style.mainSection}>
          <SideBar />
  
          <div className={style.rightSection}>
            <ViewTaskComponent />
          </div>
        </div>
      </div>);
}

export default ViewTask;
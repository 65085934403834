import React, { useState, useEffect } from "react";
import style from "./CreateTaskComponent.module.css";
import calendar from "../../../../assets/calendar.png";
import cancel from "../../../../assets/cancell.png";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import cookie from "react-cookies";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import Select from "react-select";
import constants, { employeeId, taskTrackerAccessCode } from "../../../../Utils/constants";
import DatePicker from "react-datepicker";
import { customStylesForSelectTaskTracker, customStylesForTaskTracker, customStylesForTaskTracker2 } from "../../../Projects_and_Resource_Master/Clients/component/style";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import { useLocation } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { addSpaceElementToSpace, getActiveMembersBySpace, getTaskDetailsByTaskId } from "../../taskTrackerAPIs/taskTrackerAPI";
import { Toastify, ToastifyError } from "../../../../App";
import '../../commonCssSelect/multiSelect.css';

const CreateTaskComponent = () => {
  const location = useLocation();
  const { checkValidateAccess } = useValidationAccess();
  const [startDate1, setStartDate] = useState();
  const spaceElementId = location?.state?.spaceElementId ? location?.state?.spaceElementId : (location?.state?.task?.spaceElement==="Task" || location?.state?.task?.spaceElement===null) ? 1 : location?.state?.task?.spaceElement==="Guideline" ? 2 : location?.state?.task?.spaceElement==="Note" ? 3 : 1;
  const groupIdFromUrl = location?.state?.task?.spaceElement;

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick} style={{height: "30px"}}>
        <label ref={ref}>{props.value || props.placeholder}</label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "28%", zIndex: "0" }}
          src={calendar}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross = ({groupId, setGroupid}) => {
    if (!groupId) return null; 
    return (
      <span style={{position: "absolute", height: "38px", width: "100%", zIndex: '1', display: "flex", alignItems: "center", paddingBottom: "2px" }}>
        <img
          src={cancel}
          alt="dropdownIcon"
          style={{ position: "absolute", zIndex: "1", marginLeft: "83%", cursor: "pointer"}}
          height="15"
          onClick={() => {setGroupid(null)}}
        />
      </span>
    );
  };

  const { data:getTaskForEdit } = useQuery(
    ["getTaskByTaskId"],
    ()=>getTaskDetailsByTaskId(location?.state?.taskId),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled : !!location?.state?.taskId,
    }
  );

  const [employeeList, setEmployeeList] = useState([]);
  const [groupList, setGroupsList] = useState([]);
  const [employeeIds, setEmployeeIds] = useState(0);
  const [option, setOption] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [groupId, setGroupid] = useState(location.state?.spaceId);
  const [state, setState] = useState({
    description: "",
    title: "",
  });
  const [watcher, setWatcher] = useState(null);
  const history = useHistory();
  employeeList?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  const getemployee = employeeList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getGroups = groupList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  function handleChange(id) {
    setEmployeeIds(id);
  }

  const {data : activeMember} = useQuery(
    ["getActiveMembersBySpace"],
    ()=>getActiveMembersBySpace(groupId),
    {
      refetchOnWindowFocus: false,
      enabled: spaceElementId===2,
    }
  );

  const activeMembers = activeMember?.map((i) => ({
    value: i.memberId,
    label: i.memberName,
  }));

  const url = constants.apiBaseUrl;
  const url2 = constants.apiBaseUrl2;

  const addSpaceElementMutation = useMutation(addSpaceElementToSpace,{
    onSuccess: (res)=>{
      Toastify(res);
      setIsLoading(false);
      history.goBack();
    },
    onError : ()=>{
      ToastifyError("Something Went Wrong!");
      setIsLoading(false);
    }
  });

  function postSpaceData(e) {
    setIsLoading(true);
    let data;
    if(spaceElementId===1){
        if (
            !employeeIds ||
            !startDate1 ||
            !option ||
            !state?.title
      
          ) {
            setIsLoading(false);
            setValidate(true);
            return;
          }
          data = {
            assignedBy: employeeId,
            assignedTo: employeeIds,
            planedEndDate: new Date(startDate1).toISOString(),
            priority: option,
            title: state.title.trim(),
            groupId: groupId,
            spaceElementId: spaceElementId,
            taskWatcherIds : watcher,
          };
        
    } else if(spaceElementId===2){
        if (
            !employeeIds ||
            !state?.title
      
          ) {
            setIsLoading(false);
            setValidate(true);
            return;
          }
          data = {
            assignedBy: employeeId,
            assignedTo: employeeIds,
            title: state.title.trim(),
            groupId: groupId,
            spaceElementId: spaceElementId,
          };

    } else {
      if (
        !state?.title
  
      ) {
        setIsLoading(false);
        setValidate(true);
        return;
      }
      data = {
        assignedBy: employeeId,
        title: state.title.trim(),
        groupId: groupId,
        spaceElementId: spaceElementId,
      };
    }

    if (state.description) {
      data.description = state.description.trim();
    }
    if(location?.state?.taskId) data.taskId = location?.state?.taskId;
    
    
    if(!addSpaceElementMutation.isLoading) addSpaceElementMutation.mutate(data);
    
  }

  function postData(e) {
    if (
      !employeeIds ||
      !startDate1 ||
      !option ||
      !state?.title

    ) {
      setValidate(true);
      return;
    }
    e.preventDefault();
    const data = {
      assignedTo: employeeIds,
      planedEndDate: Moment(startDate1).format("YYYY-MM-DD hh:mm"),
      priority: option,
      title: state.title.trim(),
      groupId: groupId,
      taskWatcherIds : watcher,
    };

    if (state.description) {
      data.description = state.description.trim();
    }
    if(location?.state?.taskId){
      data.taskId = location?.state?.taskId;
      data.status = getTaskForEdit?.status;
      data.localDateTime = Moment().format("YYYY-MM-DD hh:mm");
    } 
    setIsLoading(true);
    if(location?.state?.taskId) {
      axios
      .post(url2 + "/editTask", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
          ToastifyError("Something Went Wrong!");
        } else {
          Toastify("Task Updated Successfully!");
          history.goBack();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        return err;
      });
    } else {
      axios
      .post(url2 + "/createTask", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 1) {
          ToastifyError("Something Went Wrong!");
          return;
        }
        if (res.data.responseStatus) {
          ToastifyError("Something Went Wrong!");
        } else {
          Toastify("Task Added Successfully!");
          history.push({
            pathname: "/taskTracker/TaskAssignedByMe",
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        return err;
      });
    }
    
  }

  useEffect(() => {
    async function employeedata() {
      await axios
        .get(url + "/getEmployeeList", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        })
        .then((res) => {
          setEmployeeList(res.data.responseData.employeeList);
        })
        .catch((err) => {
          return err;
        });
    }
    employeedata();
  }, []);

  useEffect(() => {
    async function getGroupData() {
      await axios
        .get(url2 + `/groupListing`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        })
        .then((res) => {
          if(Array.isArray(res.data.responseData)) setGroupsList(res.data.responseData);
        })
        .catch((err) => {
          return err;
        });
    }
    getGroupData();
  }, [employeeIds]);

  useEffect(()=>{
    console.log(getTaskForEdit);
    if(getTaskForEdit){
      setState({
        description: getTaskForEdit?.description,
        title: getTaskForEdit?.title,
      });
      setStartDate(getTaskForEdit?.plannedEndDate);
      setGroupid(getTaskForEdit?.groupId);
      setEmployeeIds(getemployee?.find(val=>val.label===getTaskForEdit?.assignedToName)?.value);
      setOption(getTaskForEdit?.priority);
      console.log(getTaskForEdit?.taskWatchers);
      setWatcher(getTaskForEdit?.taskWatchers?.map(val=>val.id));
    } else {
        setState({
          description: '',
          title: '',
        });
        setStartDate("");
        setEmployeeIds(0);
        setOption('');
    }
  },[getTaskForEdit, employeeList]);

  function onChange(e) {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  }
  return (
    <div className={style.rightSection}>
      <div className={style.container}>{console.log(location.pathname.endsWith('/EditTask'))}
        <span>{spaceElementId === 1 ? `${location.pathname.endsWith('/EditTask') ? 'Edit' : 'Create'} Task` : spaceElementId === 2 ? `${location.pathname.endsWith('/EditTask') ? 'Edit' : 'Create'} Guideline` : spaceElementId === 3 ? `${location.pathname.endsWith('/EditTask') ? 'Edit' : 'Create'} Note` : `${location.pathname.endsWith('/EditTask') ? 'Edit' : 'Create'} Task`}</span>
        <div className={style.createTask}>
          <div id="create_task_id" className={style.gridContainer}>
            <div>
              <div style={{ width: '95.5%' }}>
                <label className={style.font15}>
                {!spaceElementId ? 'Task Title' : spaceElementId===1 ? 'Task Title' : spaceElementId===2 ? 'Guideline Title' : 'Note Title'}<span style={{ color: "red" }}>*</span> <br></br>
                  <input
                    className={style.input}
                    autocomplete="off"
                    type="input"
                    id="tast_title"
                    name="title"
                    value={state.title}
                    style={{ marginTop: "10px" }}
                    onChange={onChange}
                  />
                  <p
                    className={
                      validate && !state?.title
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    {!spaceElementId ? 'Please Enter Task Title' : spaceElementId===1 ? 'Please Enter Task Title' : spaceElementId===2 ? 'Please Enter Guideline Title' : 'Please Enter Note Title'}
                  </p>
                </label>
              </div>
              <div>
                <label className={style.descLabel}>
                  {spaceElementId===1 ? 'Task Description' : spaceElementId===2 ? 'Guideline Description' : 'Note Description'}
                  <textarea
                    className={style.text}
                    style={{ color: "black" }}
                    name="description"
                    value={state.description}
                    onChange={onChange}
                    id="task_description"
                  />
                </label>
              </div>
              <div style={{ display: 'flex', flexWrap: "wrap" }}>
                {spaceElementId!==3 && <div style={{ width: '33%' }} onClick={()=>null}>
                  <label className={style.font15}>
                    Assigned To
                    <span style={{ color: "red" }}>*</span>
                    <Select
                      styles={customStylesForSelectTaskTracker}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => {
                        handleChange(e.value);
                      }}
                      value={spaceElementId==2 ? activeMembers?.find(i=>i.value===employeeIds): getemployee?.find(i=>i.value===employeeIds)}
                      options={spaceElementId===2 ? activeMembers : getemployee}
                    />
                    <p
                      className={
                        validate && !employeeIds
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Assigned To
                    </p>
                  </label>
                </div>}
                {spaceElementId===1 && <div style={{ width: '33%' }}>
                  <div className={style.font15}>
                    Priority<span style={{ color: "red" }}>*</span> <br />
                    <div style={{display: "flex", gap: "40px", marginTop: "4px"}}>
                      <div style={{display: "flex"}}>
                        <span style={{display: "flex", alignItems: "center", paddingTop: "2px"}}><input type="radio" id="priority1" name="priority" className={style.radio_input} value="Low" checked={option==="Low"} onClick={e=>setOption(e.target.value)}/></span>
                        <label htmlFor="priority1" className={style.radio_label}>Low</label>
                      </div>
                      <div style={{display: "flex"}}>
                        <span style={{display: "flex", alignItems: "center", paddingTop: "2px"}}><input type="radio" id="priority2" name="priority" className={style.radio_input} value="Medium" checked={option==="Medium"} onClick={e=>setOption(e.target.value)}/></span>
                        <label htmlFor="priority2" className={style.radio_label}>Medium</label>
                      </div>
                      <div style={{display: "flex"}}>
                        <span style={{display: "flex", alignItems: "center", paddingTop: "2px"}}><input type="radio" id="priority3" name="priority" className={style.radio_input} value="High" checked={option==="High"} onClick={e=>setOption(e.target.value)}/></span>
                        <label htmlFor="priority3" className={style.radio_label}>High</label>
                      </div>
                    </div>
                    <p
                      className={
                        validate && !option
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Priority
                    </p>
                  </div>
                </div>}
                {spaceElementId===1 && <div style={{ width: '33%' }}>
                  <label className={style.font15}>
                    Planned End Date<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className={style.input}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      minDate={new Date()}
                      value={startDate1 ? Moment(startDate1).format("DD MMM YY") : null}
                      startDate={null}
                      onChange={(date) => {let temp = Moment(date)?.add(12, 'hours'); setStartDate(temp)}}
                      customInput={<CustomInput />}
                    />
                  </div>
                  <p
                    className={
                      validate && !startDate1
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Planned End Date
                  </p>
                </div>}

                {spaceElementId===1 && <div style={{ width: '33%' }}>
                  <label className={style.font15}>
                    Watcher
                    <div style={{ marginTop: "10px", position: "relative" }}>
                      <Select
                        styles={customStylesForTaskTracker2}
                        placeholder="Search By Watcher"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => {
                          let temp = e?.map(val=>(
                            val?.value
                          ));
                          setWatcher(temp);
                        }}
                        value={watcher?.map((item) => {
                            return {
                              value: item,
                              label: getemployee?.find(
                                (val) => val?.value === item
                              )?.label,
                            };
                          })
                        }
                        options={getemployee?.filter((item) => item.value !== employeeId)}
                        isMulti
                      />
                    </div>
                  </label>
                </div>}
                
                <div style={{ width: '33%' }}>
                  <label className={style.font15}>
                    Space
                    <div style={{ marginTop: "10px", position: "relative" }}>
                        {groupId && !location?.state?.task?.spaceElement && <CustomInputCross groupId={groupId} setGroupid={setGroupid} />}
                      <Select
                        styles={customStylesForTaskTracker2}
                        placeholder="Search By Client Name"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        value={groupId ? getGroups?.find(
                          (i) => i.value === groupId
                        ) : null }
                        isDisabled={!!groupIdFromUrl}
                        onChange={(e) => {
                          setGroupid(e.value);
                        }}
                        options={getGroups}
                      />
                    </div>
                  </label>
                </div>
              </div>
              {/* <div style={{ display: 'flex' }}>
                
                
              </div> */}
            </div>
            
          </div>
        </div>
        {checkValidateAccess(taskTrackerAccessCode?.submit_CreateTask) && (<div style={{display: "flex", justifyContent: "flex-end", gap: "10px"}}>
          <button className={style.cancelButton} onClick={()=>history.goBack()}>Cancel</button>
          
            <button
              className={style.submitButton}
              onClick={(e)=>{
                if(location?.state?.task?.spaceElement) postSpaceData(e); else postData(e)}
              }
              disabled={isLoading}
            >
              Submit
            </button>
        </div>)}
      </div>
    </div>
  );
};

export default CreateTaskComponent;
import style from "./overheadList.module.css";
import { useHistory } from "react-router-dom";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CreateIcon from "@mui/icons-material/Create";
import React, { useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// import Modal from "react-responsive-modal";
import { useState, createContext, useContext } from "react";
import DatePicker from "react-datepicker";
import Moment from "moment/moment";
import calendarIcon from "../../../../assets/calendarIcon.png";
import { getOverheadLists, addOtherCostsInOverhead } from "../../../../Utils/axios/pcm_Api";
import { notifyError } from "../../../atsDashboard/utils/notify";
import { useQuery, useMutation } from "react-query";
import Loading from "../loading/loading";
import { addCommasWithDecimal } from "../../../Appraisal_System/commonComponent/utils/AddCommas";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import { projectCostingAndMarginAccessCode } from "../../../../Utils/constants";
import { MdEdit } from "react-icons/md";

import { Button, Modal } from "react-bootstrap";
import { padding } from "@mui/system";
import { Toastify, ToastifyError } from "../../../../App";
import moment from "moment/moment";

export default function OverheadsLists() {
  const [count, setCount] = useState(null);
  const { checkValidateAccess } = useValidationAccess();
  return (
    <div className={style.main}>
      {checkValidateAccess(
        projectCostingAndMarginAccessCode?.overheads_dashboard
      ) && (
          <>
            <Header count={count} />
            <div>
              <Body setCount={setCount} />
            </div>
          </>
        )}
    </div>
  );
}

function Header({ count }) {
  return (
    <div className={style.header}>
      <div className={`${style.heading} ${style.cmnt_icon} ${style.gap1rem}`}>
        <div>Overheads</div>
        <div>
          <p className={style.countPCM}>
            <span style={{ fontSize: "1.235rem", fontWeight: "500" }}>
              {count}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

function Body({ setCount }) {
  const { data, isLoading, refetch } = useQuery(
    ["overheadList"],
    () => getOverheadLists(),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (err) => notifyError("Something went wrong!"),
    }
  );

  const tableRow = data?.map((row, index) => (
    <Row key={row?.month + index} {...row} refetch={refetch} />
  ));

  useEffect(() => {
    setCount(data?.length);
  }, [data]);


  return (
    <div className={style.body}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={style.tableContainer}>
          <table>
            <tbody>
              <tr className={style.tHeading}>
                <th className={style.dateHead}>
                  Month
                </th>
                {/* <th className={`${style.dateHead} ${style.head1}`}>
                  Year
                </th> */}
                <th className={style.head1}>
                  Support Cost <br /> (in lakhs)
                </th>
                <th className={style.head1}>
                  Expense <br /> (in Lakhs)
                </th>
                <th className={style.head1}>
                  Total Overheads <br /> (in lakhs)
                </th>
                <th className={`${style.iconHead} ${style.head1}`}>
                  {/* Edit Icons */}
                </th>
              </tr>
              {isLoading ? (
                <tr className={style.tRow}>
                  <td
                    colSpan={3}
                    style={{ textAlign: "center", padding: "10px" }}
                  >
                    No Overheads Available
                  </td>
                </tr>
              ) : (
                tableRow
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

function Row({ key, month, year, overheadId, overheadCostWithoutExpensesByMonth, overheadCostWithExpensesByMonth, otherExpenses, refetch }) {
  const months = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  
  const convertToLakhs = (number) => {
    if (number === null || number === undefined) return null;
    const decimalsValue = roundToTwoDecimalPlaces(number / 100000);
    return decimalsValue === "0" ? "-" : decimalsValue;
  };
  const convertToLakhsForExpense = (number) => {
    if (number === null || number === undefined) return null;
    const decimalsValue = number / 100000;
    return decimalsValue === "0" ? "-" : decimalsValue;
  };
  function roundToTwoDecimalPlaces(number) {
    const tempVal = addCommasWithDecimal(number.toFixed(2));
    return tempVal === "0" ? "-" : tempVal;
  }

  function findMonth(index) {
    return months[index - 1];
  }

  const { mutate, isPending, isError, error } = useMutation({

    mutationKey: ["editExpense"],
    mutationFn: addOtherCostsInOverhead,
    onSuccess: (responseObject) => {
      console.log(responseObject);
      if (responseObject.responseStatus.statusCode === 1) {
        Toastify(responseObject.responseData);
      }
      else{
        ToastifyError("Something Went Wrong!")
      }
      setValidation(true)
      // setExpenseValue(null)
      setSelectedDate(null)
      refetch()
    }
  })

  // states for Modal 
  const [showModal, setShowModal] = useState(false);
  const modalClose = () => { setShowModal(false) };
  const modalShow = () => setShowModal(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [expenseValue, setExpenseValue] = useState(otherExpenses);
  const [validation, setValidation] = useState(true);
  // console.log(typeof new Date(selectedDate)?.toString(), new Date(selectedDate)?.toString(), "sel -- ")

  const handleEditExpense = (e) => {
    e.preventDefault();
    console.log("expenseValue", expenseValue, "selectedDate", selectedDate)
    if (expenseValue && selectedDate) {
      mutate({
        "overheadId": overheadId,
        "additionalAmountInOverhead": expenseValue,
        "additionalAmountdate": moment(selectedDate).format("YYYY-MM-DD") + "T12:00:00"
      });
      modalClose();
    }
    else {
      setValidation(false);
    }
  }


  return (
    <>
      <tr className={style.tRow}>
        <td className={style.month}>{findMonth(month)}{`'${year.toString().slice(2)}`}</td>
        {/* <td className={style.cell}>{year}</td> */}
        <td className={style.cell}>
          {/* <CurrencyRupeeIcon style={{ width: "14px", marginBottom: "2px" }} /> */}
          {overheadCostWithoutExpensesByMonth &&
            convertToLakhs(overheadCostWithoutExpensesByMonth)}
        </td>
        <td className={style.cell}>
          {
            otherExpenses ? convertToLakhsForExpense(otherExpenses) : "_"
          }
        </td>
        <td className={style.cell}>
          {overheadCostWithExpensesByMonth && convertToLakhs(overheadCostWithExpensesByMonth)}
        </td>
        <td className={style.cell}>
          <span onClick={() => { modalShow() }}>
            <MdEdit style={{ color: "#964b00", cursor: "pointer" }} />
          </span>
        </td>
      </tr>

      <Modal show={showModal} onHide={modalClose} centered size="md">
        <Modal.Header style={{ flex: "display", flexDirection: "column", alignItems: "start", maxHeight: "88px" }}>
          <h5>Edit Expense</h5>
          <p style={{ color: "red", fontWeight: "500", minHeight: "20px" }}>
            {(validation === false) ? "Enter Mandatory* Fields" : ""}
          </p>
        </Modal.Header>

        <Modal.Body>
          <form
            onSubmit={(e) => handleEditExpense(e)}
            style={{
              display: "flex", flexDirection: "column", gap: "2rem"
            }}
          >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", maxWidth: "100vw", maxHeight: "100vh" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", maxHeight: "60px" }}>
                <p style={{ fontWeight: "500" }}>
                  Pick Date
                  <span style={{ color: "red" }}> *</span>
                </p>
                <DatePicker
                  selected={selectedDate} // Controlled date state
                  onChange={(date) => setSelectedDate(date)} // Update state on date change
                  placeholderText="Select a date"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="dd MMM yyyy"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
 
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", maxHeight: "92px" }}>
                <p style={{ fontWeight: "500" }}>
                  Enter Expense
                  <span style={{ color: "red" }}> *</span>
                </p>
                <input
                  type="number"
                  placeholder="Eg. 1,00,000"
                  value={expenseValue}
                  onChange={(e) => setExpenseValue(e.target.value)}
                />

              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", borderTop: "1px solid lightgray", paddingTop: "0.8rem" }}>
              <button type="submit" style={{ padding: "5px 20px", backgroundColor: "#964b00", borderColor: "#964b00" }}>
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

    </>
  );
}

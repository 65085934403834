import axios from "axios";
import cookie from "react-cookies";
import { employeeId } from "../constants";

const url = process.env.REACT_APP_PCM_APP_URL;

const axiosConfig = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    Authorization: ` ${cookie.load("token")}`,
    employeeId: employeeId,
  },
};

export const getINvoiceByProjectId = async ({ limit, page, projectId }) => {
  const response = await axios.get(
    url +
      `/getAllInvoicesByProjectId?isSoftAllocated=false&limit=${limit}&page=${page}&projectId=${projectId}`,
    axiosConfig
  );
  return response.data.responseData;
};

export const addEditInvoice = async (data) => {
  const response = await axios.post(
    url + `/addInvoiceOrMoneyReceivedByProjectId`,
    data,
    axiosConfig
  );
  return response.data;
};

export const getAllProjects = async () => {
  const response = await axios.get(url + "/getAllProjects", axiosConfig);
  return response.data.responseData;
};

export const getProjects = async (obj, currPage, limit) => {
  const response = await axios.post(
    url + "/findAllProjectsCostByDate?limit=" + limit + "&page=" + currPage,
    obj,
    axiosConfig
  );
  return response.data.responseData;
};

export const addEditAdditionalProjectDetails = async (obj) => {
  const response = await axios.post(
    url + "/addProjectOtherCostsInfo",
    obj,
    axiosConfig
  );
  return response.data;
};

export const getMetricsByProjectId = async (obj) => {
  const response = await axios.post(
    url + "/findMetricsByProjectId",
    obj,
    axiosConfig
  );
  return response.data.responseData;
};

export const getActualProjectCostbreakup = async (obj) => {
  const response = await axios.post(
    url + "/findResourcesCostByProjectId",
    obj,
    axiosConfig
  );
  return response.data.responseData;
};

export const getACBDesignations = async (obj) => {
  const response = await axios.post(
    url + "/findCostByProjectIdAndDesignation",
    obj,
    axiosConfig
  );
  return response.data.responseData;
};

export const getPlannedCostByProjectIdAndDesignation = async (obj) => {
  const response = await axios.post(
    url + "/findPlannedCostByProjectIdAndDesignation",
    obj,
    axiosConfig
  );
  return response.data.responseData;
};

export const getPlannedCostByProjectId = async (obj) => {
  const response = await axios.post(
    url + "/findPlannedResourcesCostByProjectId",
    obj,
    axiosConfig
  );
  return response.data.responseData;
};

export const deleteInvoice = async (obj) => {
  const response = await axios.delete(
    url +
      "/deleteInvoiceByInvoiceIdAndProjectId?invoiceId=" +
      obj?.invoiceId +
      "&projectId=" +
      obj?.projectId,
    axiosConfig
  );
  return response.data;
};

export const getOverheadLists = async (obj) => {
  const response = await axios.get(
    url + "/getOverheadCostByMonth",
    axiosConfig
  );
  return response.data.responseData;
};

export const addOtherCostsInOverhead = async (obj) => {

  const response = await axios.post(
    // http://serviceimportal.innovationm.com:8100/projectcosting/v1
    url+"/addOtherCostsInOverhead",
    obj,
    axiosConfig
  )
  return response?.data;
}


// get resource margins
export const getResourceMargins = async (limit, page, activeEmployees, isOnsite, isClientActive, isProjectActive) => {

  // Conditionally, setting the 'Query Parameters'
  let path = `/getResourceMargins?activeEmployees=${activeEmployees}&limit=${limit}&page=${page}&isOnsite=${isOnsite}`;
  if(isOnsite === true && isClientActive !== '')
    path += `&isClientActive=${isClientActive}`;
  if(isOnsite === false && isProjectActive !== '')
    path += `&isProjectActive=${isProjectActive}`;

  const response = await axios.get(
    url + path,
    axiosConfig
  );
  return response.data.responseData;
};

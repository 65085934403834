import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button, Image } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Header from "../../../../taskTracker/commonComponent/Header";
import style from "./PoolResourcesDescription.module.css";
import Sidebar from "../../../Clients/component/SideBar";
import cookie from "react-cookies";
import TableRow from "./TableRow";
import TaskTable from "../../OffShoreDescription/TaskTable";
import axios from "axios";
import Pagination from "../../../CommonComponent/Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmPopup from "../../../Clients/clientdescription/ConfirmPopup";
import { Table } from "react-bootstrap";
import ApplicationInformation from "../PoolResourcesComment/PoolComment";
import constants, { localStorageSetItem, rmsAccessCode } from "../../../../../Utils/constants";
// import ApplicationInformation from "../../../Projects/component/ApplicationInformation/ApplicationInformation";
import Moment from "moment";
import { api } from "../../../CommonApi/api";
import Tooltip from '@mui/material/Tooltip';
import { post } from "../../../CommonApi/axiosCall";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Toastify, ToastifyError } from "../../../../../App";
import { employeeId } from "../../../../../Utils/constants";
import { useNavigation } from "./NavigationContext";
import useValidationAccess from "../../../../custumHooks/useValidationAccess";

const PoolResourcesDescription = (props) => {
  const [PoolData, setPoolData] = useState([]);
  const [AddToggle, setAddToggle] = useState(false);
  const baseUrl = constants.apiBaseUrlResource;
  const [ApplicationData, setApplicationData] = useState([]);
  const [applicationDetail, setApplicationDetail] = useState([]);
  const [show, setShow] = useState(false);
  const [commenttype, setCommentType] = useState("");
  const [addhandler, setAddHandler] = useState(false);
  const [editHandler, setEditHandler] = useState(false);
  const [commentID, setCommentID] = useState();
  const [myTaskDetails, setMyTaskDetails] = useState();
  const [pageCount, setpageCount] = useState(0);
  const [offshoreTotalTask, setoffshoreTotalTask] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [confirmModal, setConfirmModal] = useState(false);
  const [currentLocation, setCurrentLocation] = useState()

  const [validate, setValidate] = useState(false);

  const { checkValidateAccess } = useValidationAccess();

  const { nextScreen, setNextScreen, prevData, setPrevData, poolCondition, setPoolCondition } = useNavigation();


  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const id = 2;
  useEffect(() => {
    setPoolData(props.history.location?.state?.id)
    getApplicationDetails(props.history.location?.state?.id?.empId)
    MyTaskDetails(props.history.location?.state?.id?.empId);
  }, [show, currentPage])
  useEffect(() => {
    setCurrentLocation(PoolData?.currentLocation)
  }, [PoolData])
  const history = useHistory();
  const url2 = constants.apiBaseUrl2;

  const EditHandler = () => {
    history.push(`/EditPoolResource?id=${id}`);
  };

  const HistoryHandler = () => {
    history.push(`/PoolResourcesHistory?id=${id}`);
  };

  const limit = 3;

  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };
  const MyTaskDetails = (id) => {
    axios
      .get(
        url2 +
        `/getTaskDetailsByEmpIdForRms?empId=${id}&limit=3&page=${currentPage}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setMyTaskDetails(res.data.responseData);
        setoffshoreTotalTask(res.data.responseData.resourceCount);
        setpageCount(res.data.responseData.resourceCount / 3);
      })
      .catch(() => { });
  };

  const LocationHandle = () => {
    setConfirmModal(true);
  };
  function handleConfirmPopupClose() {
    setConfirmModal(false);
    setValidate(false)
    setCurrentLocation("")

  }
  const getPoolDetails = (id) => {
    axios
      .get(baseUrl + `/getPoolResourceDetaislByID?id=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        // setPoolData(res.data.responseData);
        // getApplicationDetails(res.data.responseData.empid);
        // setAddToggle(false);
        // MyTaskDetails(res.data.responseData.empid);
        // setShow(false);
      })
      .catch(() => { });
  };

  const getApplicationDetails = (id) => {
    axios
      .get(baseUrl + `/getAllPoolResourceComments?empId=${id}&limit=10`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setApplicationData(res.data.responseData);
        setAddToggle(false)
      })
      .catch(() => { });
  };
  const getSingleDetailsById = (id) => {
    axios
      .get(baseUrl + `/getPoolResourceCommentsById?commentId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setApplicationDetail(res.data.responseData);
      })
      .catch(() => { });
  };

  // useEffect(() => {
  //   getPoolDetails(id);
  // }, [show, currentPage]);

  useEffect(() => {
    commentID && getSingleDetailsById(commentID);
  }, [show]);

  const AddHandler = (id, type) => {
    setCommentType(type);
    setAddHandler(false);
    setEditHandler(true);
    setCommentID(id);
    if (id) {
      getSingleDetailsById(id);
    }
    window.scrollTo(0, 0);
    setAddToggle(!AddToggle);
  };

  const AddHandler1 = (id, type) => {
    setAddHandler(true);
    setEditHandler(false);
    setAddToggle(!AddToggle);
  };

  const AddTaskhandler = () => {
    history.push({
      pathname: "/CreateTask",
      state: {
        task: PoolData,
        comment: "",
        page: 3,
        relatedTo: PoolData?.empId
      },
    });
  };
  const viewPerformance = () => {
    window.open(`/EmployeePerformanceReview?id=${PoolData.empId}`, "_blank");
  };
  const EditTask = (id1) => {
    history.push({
      pathname: "/Pool/TaskDetails",
      state: {
        id: id1,
        id1: id,
      },
    });
  };
  const handleSubmitLocation = (id, location, editLocation) => {
    if (!location && !editLocation) {
      setValidate(true)
      return true
    }
    axios
      .post(baseUrl + `/add-current-location?currentLocation=${location}&empId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res?.data?.responseStatus?.statusCode === 0) {
          { PoolData?.currentLocation ? Toastify("Edit Location Successfully") : Toastify("Add Location Successfully") }
          setConfirmModal(false)
          history.push("/PoolResources");

        } else {
          ToastifyError("Some Thing went wrong");
          setConfirmModal(false)
        }

      })
      .catch(() => {
      });

  };


  const HistoryHandler1 = () => {
    history.push({
      pathname: "/PoolResources",
      state: {
        allData: props.history.location?.state,
      },
    });
  };

  const BackHandler = () => {
    setAddToggle(!AddToggle);
  };
  const addWatchListt = (requesterId, resourceId) => {
    let url = api.addResouresWatchList;
    if (requesterId !== undefined && requesterId !== null) {
      url += `?requesterId=${requesterId}`;
    }
    if (resourceId !== undefined && resourceId !== null) {
      url += `&resourceId=${resourceId}`;
    }
    post(url)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 1) {
          ToastifyError(res.data.responseData.message)
        } else if (res.data.responseStatus.statusCode === 0) {
          Toastify(res.data.responseData)
        }
      })
      .catch();
  };

  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <Sidebar />

        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb} style={{ marginTop: "22px" }}>
              <span style={{ fontWeight: "700" }}>
                View<span className={style.arrow}> {">"} </span>{" "}
                <span
                  className={style.recent_tab}
                  style={{ fontWeight: "700" }}
                >
                  Resource (Pool)
                </span>
              </span>
              <Tooltip title="Add to watchlist">
                <GroupAddIcon
                  onClick={() => addWatchListt(employeeId, props.history.location?.state?.id?.empId)}
                  style={{ cursor: "pointer", color: "rgb(252, 187, 8)" }}
                />
              </Tooltip>
            </div>
            {/* <h5 className={style.heading}>Add Resource (Onsite)</h5> */}
            <div className={style.form_div}>
              <div id="create_task_id" className={style.gridContainer}>
                <div className={style.assignLabel}>
                  <label>Employee name </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {PoolData?.empName}
                  </p>
                </div>
                <div className={style.assignLabel}>
                  <label>Technology </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {PoolData?.skillName}
                  </p>
                </div>

                <div className={style.proSecton}>
                  <label>Pool Start Date </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {Moment(PoolData?.poolStartdate).format("DD MMM YY")}
                  </p>
                </div>
                <div className={style.proSecton}>
                  <label>Salary Range </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {PoolData?.salaryRange}
                  </p>
                </div>

                <div className={style.proSecton}>
                  <label className={style.desc}>Bench Age </label> <br />
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {PoolData?.benchAge}
                  </p>
                </div>
                <div className={style.proSecton}>
                  <label className={style.desc}>
                    Experience Range{" "}
                  </label>{" "}
                  <br />
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {PoolData?.experienceRange}
                  </p>
                </div>
                <div className={style.proSecton}>
                  <label className={style.desc}>Experience</label> <br />
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {/* {PoolData?.experience} */}
                    {~~(PoolData?.experience / 12)} Years{" "}
                    {PoolData?.experience % 12} Months
                  </p>
                </div>

                <div className={style.proSecton}>
                  <label>Last Comment Date </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {PoolData?.currentCommentDate
                      ? Moment(PoolData?.currentCommentDate).format("DD MMM YY")
                      : null}
                  </p>
                </div>

                <div className={style.proSecton}>
                  <label>Employee Code </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {PoolData?.empCode}
                  </p>
                </div>

                <div className={style.proSecton}>
                  <label>Current Location </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {PoolData?.currentLocation}
                  </p>
                </div>

              </div>
            </div>
          </div>
          <div className={style.container}>
            <div style={{ marginTop: "15px" }}>
              {" "}
              <button className={style.gotoListBtn} onClick={HistoryHandler1}>
                {" "}
                Go To List
              </button>

            </div>
            
            <div className={style.btn_div}>
              <button className={style.gotoListBtn1}
                onClick={() => {
                  setNextScreen(
                    "viewCandidateAllocation(client)"
                  )
                  setPoolCondition(false)
                  setPrevData((prevData) => ({
                    ...prevData,
                    candidateId: PoolData.empId,
                    positionId: null,
                    isForEmployee: true,
                    sidebar:"candidateAllocation(Client)",
                    page1: "candidateAllocation(Client)",
                    page2: "viewCandidateAllocation(client)",
                    page3: null,
                    page4: null,
                    page5: null,
                    active: "viewCandidateAllocation(client)",
                  }));
                  history.push("/ats-navigation")
                }}
              >
                {" "}
                Deployment Journey
              </button>
              {checkValidateAccess(rmsAccessCode?.pool_resource_view_add_location) && <button className={style.viewFeedback} onClick={LocationHandle}>
                {" "}
                {PoolData?.currentLocation ? "Edit Location" : "Add Location"}
              </button>}
              <button className={style.viewFeedback} onClick={viewPerformance}>
                {" "}
                View Feedback
              </button>
              <button className={style.addTaskBtn} onClick={AddTaskhandler}>
                {" "}
                <span className={style.plus}>+</span>Add Task
              </button>
              <button className={style.addCommentbtn} onClick={AddHandler1}>
                {" "}
                <span className={style.plus}>+</span>Add Comment
              </button>

              <button
                onClick={() => HistoryHandler()}
                className={style.editbtnn}
              >
                Pool History
              </button>

            </div>
          </div>

          {AddToggle ? (
            <ApplicationInformation
              id={PoolData?.empId}
              commenttype={commenttype}
              historyy="poolcomment"
              BackHandler={BackHandler}
              addhandler={addhandler}
              editHandler={editHandler}
              setShow={setShow}
              show={show}
              applicationDetail={applicationDetail}
            />
          ) : null}

          <div
            className={style.description_box1}
            style={{ marginBottom: "15px" }}
          >
            <div className={style.span2}>
              <p>My Task</p>
            </div>
            <div className={style.maindivContent}></div>
            <div
              className={style.original_table}
              style={{
                backgroundColor: "white",
                minHeight: "3rem",
                marginTop: "7px",
              }}
            >
              {myTaskDetails?.getAllData?.length > 0 ? (
                <Table borderless size="sm" style={{ fontSize: "12px" }}>
                  <thead
                    style={{
                      backgroundColor: "#F0F0F0",
                      position: "sticky",
                      top: "0px",
                    }}
                  >
                    <tr style={{ padding: "100px" }}>
                      <th style={{ padding: "25px" }}>Task Id</th>
                      <th style={{ padding: "25px" }}>Creation Date</th>
                      <th style={{ padding: "25px" }}>Task Title</th>
                      <th style={{ padding: "25px" }}>Assigned To</th>
                      <th style={{ padding: "25px" }}>Assigned By</th>
                      <th style={{ padding: "25px" }}>Status</th>
                      <th style={{ padding: "25px" }}>Priority</th>
                      <th style={{ padding: "25px" }}>Planned End Date</th>
                      <th style={{ padding: "25px" }}>Functional Area</th>
                    </tr>
                  </thead>
                  <tbody>
                    {myTaskDetails?.getAllData?.map((val) => {
                      return (
                        <TaskTable
                          clickHandler={EditTask}
                          data={val}
                          historyy={"offshore"}
                          id={PoolData?.empId}
                          setShow={setShow}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <p className={style.p1} style={{ textAlign: "center" }}>
                  No Task Assigned
                </p>
              )}
            </div>
          </div>
          {offshoreTotalTask > 3 ? (
            <div style={{ marginBottom: "66px" }}>
              {offshoreTotalTask > limit && pageCount > 0 && (
                <div
                  className={style.paginationmobileContainer}
                  style={{ marginTop: "10px" }}
                >
                  <Pagination
                    pagesCount={pageCount}
                    pageSize={limit}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                  />
                </div>
              )}
            </div>
          ) : null}
          <div className={style.description_box1}>
            <div className={style.span2}>
              <p>Comment History</p>
            </div>
            <div className={style.maindivContent}></div>
            <div
              className={style.original_table}
              style={{
                backgroundColor: "white",
                minHeight: "3rem",
                marginTop: "7px",
              }}
            >
              {ApplicationData?.getAllData?.length > 0 ? (
                <Table borderless size="sm" style={{ fontSize: "12px" }}>
                  <thead
                    style={{
                      backgroundColor: "#F0F0F0",
                      position: "sticky",
                      top: "0px",
                    }}
                  >
                    <tr style={{ padding: "100px" }}>
                      <th style={{ padding: "25px" }}>Reported Date</th>
                      <th style={{ padding: "25px" }}>Username</th>
                      <th style={{ padding: "25px" }}>Comment</th>
                      <th style={{ padding: "25px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ApplicationData.getAllData?.map((val) => {
                      return (
                        <TableRow
                          clickHandler={AddHandler}
                          data={val}
                          historyy="pool"
                          show={show}
                          id={PoolData?.empId}
                          setShow={setShow}
                          OfShoreData={PoolData}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <p className={style.p1} style={{ textAlign: "center" }}>
                  No Comment
                </p>
              )}
            </div>
          </div>
        </div>
        <ConfirmPopup open={confirmModal}>
          <div className={style.confirmPopup}>
            <p style={{ textAlign: "justify", paddingLeft: "39px" }}>Current Location <span style={{ color: "red" }}>*</span></p>
            <input type="text" className={style.inputforlocation}
              defaultValue={PoolData?.currentLocation}
              onChange={(e) => setCurrentLocation(e.target.value)} />
            <p
              className={
                validate
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter Location
            </p>
            <div className={style.btnCont}>
              <button className={style.noBtn}
                onClick={handleConfirmPopupClose}

              >
                Cancel
              </button>
              <button
                className={style.yesBtn}
                onClick={() => handleSubmitLocation(props.history.location?.state?.id?.empId, currentLocation, PoolData?.currentLocation)}

              // onClick={() => deleteClient(clientData.clientid)}
              >
                Submit
              </button>

            </div>
          </div>
        </ConfirmPopup>

        <ToastContainer />
      </div>
    </>
  );
};

export default PoolResourcesDescription;

import React from "react";
import { useHistory } from "react-router-dom";
import "../component/ResourceOnsiteDescription/Table.css";
import Moment from "moment";
import Ricon from "./../../../../assets/RIcon.png";
import Eicon from "./../../../../assets/EIcon.png";
import moment from "moment";
import ShowStatus from "../../CommonComponent/showStatus/ShowStatus";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { FaPlusCircle } from "react-icons/fa";
import { CiCircleRemove } from "react-icons/ci";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import { rmsAccessCode } from "../../../../Utils/constants";

const TableRow = (props) => {
  const history = useHistory();
  const { checkValidateAccess } = useValidationAccess();
  const getBackgroundColor = (status) => {
    if (status === "Allocated") {
      return "green";
    }
    if (status === "Allocated (Soft)") {
      return "rgb(252, 187, 8)";
    }
    return "red";
  };

  const BackGroundColor = (status) => {
    if (status === "Resigned") {
      return "#f5bbc5";
    }
    return "white";
  };
  const GoToDetails = () => {
    history.push({
      pathname: "/ResourcesOffshoreDescription",
      state: {
        id: props.val.id,
        currentPage: props?.currentPage,
        allData: props.allData,
        filterName: props.filterName,
        applied: props.applied,
      },
    });
  };
  const ReginationData = (value) => {
    return (
      <>
        <div>Resignation Date</div>
        {value ? (
          <div>{moment(value).format("DD MMM YY")}</div>
        ) : (
          <div>No Date Available</div>
        )}
      </>
    );
  };
  const ExitDate = (value) => {
    return (
      <>
        <div>Exit Date</div>
        {value ? (
          <div>{moment(value).format("DD MMM YY")}</div>
        ) : (
          <div>No Date Available</div>
        )}
      </>
    );
  };
  return (
    <tr
      style={{
        borderBottom: "2px solid #F0F0F0",
        textAlign: "start",
        fontSize: "13px",
        position: "relative",
        backgroundColor: BackGroundColor(props.val.employeeStatus),
      }}
    >
      <td
        style={{
          padding: "10px",
          position: "sticky",
          left: "0px",
          backgroundColor: `${BackGroundColor(props.val.employeeStatus)}`,
        }}
      >
        {props.val.empCode}
      </td>
      <td
        onClick={GoToDetails}
        style={{
          cursor: "pointer",
          padding: "10px",
          textTransform: "capitalize",
          fontWeight: "bolder",
          textDecorationLine: "underline",
          position: "sticky",
          left: "56px",
          backgroundColor: `${BackGroundColor(props.val.employeeStatus)}`,
        }}
      >
        {props.val.empName}
      </td>
      {props?.val?.employeeStatus === "Exit" ? (
        <ShowStatus
          index={`R${props?.val.id}`}
          component={ExitDate(props?.val?.exitDate)}
          circleColor={{}}
        >
          <img
            src={Eicon}
            style={{ height: "1.5rem", maxWidth: "2rem" }}
            alt="img"
          />
        </ShowStatus>
      ) : props?.val?.employeeStatus === "Resigned" ? (
        <ShowStatus
          index={`R${props?.val.id}`}
          component={ReginationData(props?.val?.resignDate)}
          circleColor={{}}
        >
          <img
            src={Ricon}
            style={{ height: "1.5rem", maxWidth: "2rem" }}
            alt="img"
          />
        </ShowStatus>
      ) : (
        <td></td>
      )}
      <td style={{ padding: "10px" }}>
        {~~(props.val.experience / 12)} Y -{props.val.experience % 12} M
      </td>
      {props.access?.offshoreSalaryRange && <td style={{ padding: "10px" }}>{props.val.salaryRange}</td>}
      <td style={{ padding: "10px" }}>{props.val.projectName}</td>

      <td style={{ padding: "10px" }}>{props.val.skillName}</td>

      {props.val.bandwidth === "Half" ? (
        <td style={{ width: "5%", padding: "10px" }}>50% </td>
      ) : props.val.bandwidth === "ThreeFourth" ? (
        <td style={{ width: "5%", padding: "10px" }}>75% </td>
      ) : props.val.bandwidth === "OneFourth" ? (
        <td style={{ width: "5%", padding: "10px" }}>25% </td>
      ) : (
        <td style={{ width: "5%", padding: "10px" }}>100% </td>
      )}
      <td
        style={{
          padding: "10px",
          color: getBackgroundColor(props.val.allocationTypeName),
        }}
      >
        {props.val.allocationTypeName === "In-Active"
          ? "Inactive"
          : props.val.allocationTypeName}
      </td>
      <td style={{ padding: "10px" }}>
        {Moment(props.val.allocationdate).format("DD MMM YY")}
      </td>
      <td style={{ padding: "10px" }}>
        {Moment(props.val.deallocationdate).format("DD MMM YY")}
      </td>
      {props?.val.currentCommentDate ? (
        <td style={{ padding: "10px" }}>
          {Moment(props.val.currentCommentDate).format("DD MMM YY")}
        </td>
      ) : (
        <td></td>
      )}
      <td style={{ padding: "10px" }}>{props?.val.partnerName}</td>
      {props.access?.offshoreAction && <td style={{ textAlign: "center" }}>
        {props?.val.isForcedAddedToPool ? (
          <BlackTooltip title={"Forcefully Remove From Pool"}>
            <p
              onClick={() => {
                props?.addToPoolResourceMutate.mutate({
                  id: props?.val?.id,
                  resourceType: "offshore",
                  status: !props?.val.isForcedAddedToPool,
                });
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  props?.addToPoolResourceMutate.mutate({
                    id: props?.val?.id,
                    resourceType: "offshore",
                    status: !props?.val.isForcedAddedToPool,
                  });
                }
              }}
              tabIndex={0}
            >
              <CiCircleRemove size={18} style={{ cursor: "pointer" }} />
            </p>
          </BlackTooltip>
        ) : (
          <BlackTooltip title={"Forcefully Add To Pool"}>
            <p
              onClick={() => {
                props?.addToPoolResourceMutate.mutate({
                  id: props?.val?.id,
                  resourceType: "offshore",
                  status: !props?.val.isForcedAddedToPool,
                });
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  props?.addToPoolResourceMutate.mutate({
                    id: props?.val?.id,
                    resourceType: "offshore",
                    status: !props?.val.isForcedAddedToPool,
                  });
                }
              }}
              tabIndex={0}
              >
              <FaPlusCircle size={18} style={{ cursor: "pointer" }} />
            </p>
          </BlackTooltip>
        )}
      </td>}
    </tr>
  );
};

const BlackTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "#fff",
    fontSize: "1rem",
    whiteSpace: "pre-line",
    padding: "0.5rem",
  },
}))(Tooltip);

export default TableRow;

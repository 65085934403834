import React, { useState, useEffect, useRef } from "react";
import style from "./myTasks.module.css";
import filterimg from "./../../../assets/filter-results-button.png";
import Polygon from "../../../assets/Polygon.png";
import Iconmetrocross from "../../../assets/Iconmetrocross.png";
import Iconionicmdcheckbox from "../../../assets/Iconionicmdcheckbox.png";
import Iconfeatherfilter from "../../../assets/Iconfeatherfilter.png";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import milestone from "../../../assets/milestone.png";
import Pagination from "../commonComponent/Pagination";
import Filter from "./component/Filter";
import { useHistory } from "react-router-dom";
import loaderImg from "../../../assets/loader-green2.gif";
import Moment from "moment";
import axios from "axios";
import cookie from "react-cookies";
import constants, { employeeId } from "../../../Utils/constants";
import useValidationAccess from "../../custumHooks/useValidationAccess";
import { taskTrackerAccessCode } from "../../../Utils/constants";
import Modal from "react-responsive-modal";

const ary = [
  {
    a: "01",
    b: "Database Creation",
    c: "yyu ytujgj jhgj hjg ff",
    d: "Shailly Tyagi",
    e: "Assigned",
    f: "Low",
    g: "20 July(Fri)",
  },
];
const arr = [
  {
    a: "20 July(Fri)",
    b: "Shailly Tyagi",
    c: "Assigned",
    d: "Low",
  },
];

const MyTasks = (props) => {
  const [tasks, setTasks] = useState([]);
  const [totalTasks, settotalTasks] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [sortBy, setSortBy] = useState("planedEndDate");
  const [sort, setSort] = useState("ASC");
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [applied, setApplied] = useState(0);
  const [data, setData] = useState();
  const [assigned, setAssigned] = useState([]);
  const [planedEndDateOrder, setPlanedEndDateOrder] = useState(null);
  const [creationDateOrder, setCreationDateOrder] = useState(null);
  const [priority, setPriority] = useState([]);
  const [priorityOrder, setPriorityOrder] = useState(null);
  const [status, setStatus] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const prevApplied = useRef(applied);
  const [allData, setAllData] = useState({
    assignedBy: assigned,
    creationDateOrder: creationDateOrder,
    planedEndDateOrder: planedEndDateOrder,
    priority: priority,
    priorityOrder: priorityOrder,
    status: status,
    functionalAreaId: [],
  });
  const limit = 15;
  const history = useHistory();
  const { checkValidateAccess } = useValidationAccess();
  const [doneRevNote, setDoneRevNote] = useState("");
  const [openDoneRevNote, setOpenDoneRevNote] = useState(false)

  const getaskAssignedByFilterData = () => {
    axios
      .get(url2 + "getaskAssignedToFilterData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setData(res.data.responseData.employeeNames);
      })
      .catch(() => { });
  };

  const url = constants.apiBaseUrl;
  const url2 = constants.apiBaseUrl2;
  const handleapidata = () => {
    setIsLoaded(true);
    axios
      .post(url2 + "/myTask?limit=" + limit + "&page=" + currentPage + "&sort=" + sort + "&sortBy=" + sortBy, allData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        // setTimeout(() => {
        //   setIsLoaded(false);
        // }, 250);
        setIsLoaded(false);
        if (res.data.statusCode == 0) {
          const responseData = res.data.responseData;
          setpageCount(responseData.totalNumberOfPagesAsPerGivenPageLimit);
          setTasks(responseData.tasks);
          settotalTasks(responseData.totalTask);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const geyEmployeeList = () => {
    const employeeData = axios
      .get(url2 + "/getMytaskFilterData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.statusCode == 0) {
          setUserLists(res.data.responseData.employeeNames);
        }
      })
      .catch((err) => {
        return err;
      });
  };


  useEffect(() => {
    getaskAssignedByFilterData();
    handleapidata();
    geyEmployeeList();
  }, [currentPage, creationDateOrder, sort, sortBy]);


  const handleApplied = (arg) => {
    setCurrentPage(1);
    if (assigned.length !== 0 || priority.length !== 0 || status.length !== 0) {
      setApplied(prev=>prev+1);
    } else {
      setApplied(0);
    }
    setShowFilter(false);
    // handleapidata();
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };
  const handleTask = () => {
    history.push("/taskTracker/CreateTask");
  };

  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };

  const closeFilter = () => {
    setAllData((preValue) => {
      return preValue
        ? {
          ...preValue,
          assignedBy: [],
          priority: [],
          status: [],
          functionalAreaId: [],
        }
        : null;
    });
    setAssigned([]);
    setPriority([]);
    setStatus([]);
    setShowFilter(false);
    setApplied(0);
  };

  const handleView = (arg) => {
    history.push({
      pathname: "/taskTracker/MyTasks/ViewTask",
      state: {
        taskId: arg.taskId,
        task: arg,
        activePage: 1,
      },
    });
  };

  useEffect(() => {
    if(!showFilter && prevApplied.current!==applied) handleapidata();
    geyEmployeeList();
    prevApplied.current = applied;

  }, [currentPage, allData, showFilter]);

  function formatDate(dateString) {
    return dateString.split(' ')[0] + ' ' + dateString.split(' ')[1];
  }

  const handleSort = (field) => {
    setSortBy(field);
    if (sort === "ASC") {
      setSort("DESC")
    } else {
      setSort("ASC")
    }
  };


  const renderSortArrow = (field) => {
    if (sortBy !== field) return null;

    return sort === "ASC" ? (
      <TiArrowSortedUp
        style={{
          // fontSize: "1.1rem",
          cursor: "pointer",
          color: "blue",
        }}
      />
    ) : (
      <TiArrowSortedDown
        style={{
          // fontSize: "1.1rem",
          cursor: "pointer",
          color: "blue",
        }}
      />
    );

  };


  return (
    <>
      {checkValidateAccess(
        taskTrackerAccessCode?.my_task_menu
      ) && (<div className={style.taskTracker}>
        <ViewDoneRevNote doneRevNote={doneRevNote} openDoneRevNote={openDoneRevNote} setOpenDoneRevNote={setOpenDoneRevNote} />
        <div className={style.header}>
          <div>
            <span>My Tasks</span>
            <p>
              <span>{tasks ? totalTasks : 0}</span>
            </p>
          </div>
          <button className={style.filter} onClick={handleFilter}>
            {applied && (
              <img className={style.appliedcheck} src={Iconionicmdcheckbox} />
            )}
            <img src={filterimg} />
            <span>Filter</span>
          </button>

          {showFilter && <img className={style.polygon} src={Polygon} />}
          <div>
            {checkValidateAccess(taskTrackerAccessCode?.create_Task_MyTask) && (
              <button onClick={handleTask} className={style.createTask}>
                <img
                  style={{ width: "15px", marginRight: "10px" }}
                  alt="Iconmetro"
                  src={Iconmetrocross}
                />
                <span>Create Task</span>
              </button>
            )}
          </div>

          <div className={style.mobileHeader}>
            <p>
              <img onClick={handleFilter} src={Iconfeatherfilter} />
            </p>
            <p style={{ backgroundColor: "#65A15E" }}>
              <img onClick={handleTask} src={Iconmetrocross} />
            </p>
          </div>
        </div>
        <div
          className={style.container}
          id="div"
          onClick={() => {
            setShowFilter(false);
          }}
        >
          {isLoaded ? (
            <div>
              <img src={loaderImg} alt="loader" className={style.loader} />
              <p className={style.loaderPara}>Loading...</p>
            </div>
          ) : (
            ""
          )}
          {tasks.length > 0 && !isLoaded ? (
            <div style={{ height: '485px', overflowY: 'auto' }}>
              <table>
                <thead style={{ position: "sticky", top: "0px" }}>
                  <tr style={{ fontSize: '15px', textDecoration: 'underline', cursor: 'pointer' }} className={style.th}>
                    <th
                      onClick={() => handleSort("id")}
                      style={{ width: '4rem' }}
                    >
                      Task {renderSortArrow("id")}<br></br>
                      ID

                    </th>

                    <th
                      onClick={() => handleSort("created")}
                      style={{ width: '6rem', }}

                    >
                      Creation
                      {renderSortArrow("created")}
                      <br></br> Date

                    </th>
                    <th style={{minWidth: "3rem"}}></th>
                    <th
                      onClick={() => handleSort("title")}
                      style={{ width: '24.5rem', paddingLeft: "6px" }}
                    >
                      Task Title
                      {renderSortArrow("title")}
                    </th>

                    <th
                      onClick={() => handleSort("assignedBy")}
                      style={{ width: '10rem' }}
                    >
                      Assigned By
                      {renderSortArrow("assignedBy")}
                    </th>
                    <th
                      onClick={() => handleSort("status")}
                      style={{ width: '6rem' }}
                    >
                      Status
                      {renderSortArrow("status")}
                    </th>
                    <th
                      onClick={() => handleSort("priority")}
                      style={{ width: '5rem' }}
                    >
                      Priority
                      {renderSortArrow("priority")}
                    </th>
                    <th
                      onClick={() => handleSort("planedEndDate")}
                      style={{ width: '6rem' }}
                    >
                      Planned
                      {renderSortArrow("planedEndDate")}
                      <br /> End Date
                    </th>
                    {/* <th
                      style={{ width: '4rem' }}
                    >
                      {" "}
                      Functional Area
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {tasks &&
                    tasks.map((task, index) => (
                      <tr className={style.td} key={index}>
                        <td
                          style={{ width: '4rem' }}
                        >
                          {task.taskId}</td>
                        <td
                          style={{ width: '6rem' }}
                        >
                          {task?.created ? Moment().year() === Moment(task?.created).year() ? Moment(task?.created).format("DD MMM") : Moment(task?.created).format("DD MMM YYYY") : '-'}
                        </td>
                        <td style={{minWidth: "3rem", display: "flex", justifyContent: "right"}}>
                          {tasks[index].taskWatcher ? (<span
                            style={{
                              display: "inline-block",
                              fontSize: "10px",
                              color: "rgb(168, 50, 50)",
                              fontWeight: "800",
                              border: "1px solid rgb(168, 50, 50)",
                              borderRadius: "50%",
                              width : "21px",
                              padding: "2px 4px",
                              marginRight: tasks[index].recurringTask ? "4px" : "0px",
                            }}
                          >
                            W
                          </span>) : null}

                          {tasks[index].recurringTask ? (<span
                            style={{
                              display: "inline-block",
                              fontSize: "10px",
                              color: "rgb(168, 50, 50)",
                              fontWeight: "800",
                              border: "1px solid rgb(168, 50, 50)",
                              borderRadius: "50%",
                              padding: "2px 6px",
                              width : "21px",
                          }}
                        >
                          R
                        </span>) : null}</td>
                        
                        <td
                          style={{ paddingLeft:"8px", width: "24.5rem", fontSize: '15px', fontWeight: '500', textDecoration: 'underline', cursor: "pointer", color: task.status === "Closed" ? "red" : "#65A15E" }}
                          onClick={() => handleView(task)}
                          title={task?.title}
                        >
                          <div style={{width : "24.5rem", overflow: "hidden", paddingRight : "10px", fontWeight : "700", textOverflow : "ellipsis", whiteSpace : "nowrap"}}>{task?.title}</div>
                        </td>
                        <td
                          style={{ width: '10rem' }}
                          title={task && task.name?.length > 21 && task?.name}
                        >
                          {task && task.name && task.name?.length > 21 ? task.name.substring(0, 21) + "..." : task.name}
                        </td>
                        <td
                          style={{ width: '6rem' }}
                          className={
                            task.status == "Assigned"
                              ? style.asigned_color
                              : task.status == "Done"
                                ? style.done_color
                                : task.status == "Onhold"
                                  ? style.onhold_color
                                  : style.Closed_color
                          }
                        >
                          {task.status === "Inprogress"
                            ? "In Progress"
                            : task.status === "Onhold"
                              ? "On Hold"
                              : task.status}
                        </td>
                        <td
                          style={{ width: '5rem' }}
                        >
                          {task.priority}
                        </td>
                        <td
                          style={{ width: '6rem' }}
                        >
                          {task?.planedEndDate && Moment().year()===Moment(task?.planedEndDate).year() ?  Moment(task?.planedEndDate).format("DD MMM") : Moment(task?.planedEndDate).format("DD MMM YYYY")}
                        </td>
                        {/* <td
                          style={{ width: '4rem' }}
                        >
                          {task.functionalArea}
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={style.noTasks}>
              {!isLoaded ? (
                <div style={{ display: "block" }}>
                  <img src={milestone} alt="No data found" />
                  <h2>No Task Assigned Yet</h2>
                </div>
              ) : (
                ""
              )}
            </div>
          )}

          <div className={style.mobileContainer}>
            {arr.map((ar, indexa) => {
              return (
                <div
                  key={indexa}
                  style={{
                    display: "block",
                    borderBottom: "1px solid #0000001A",
                    marginBottom: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <strong>{indexa + 1}. Database Creation</strong>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Assigned By :
                    </span>
                    <span style={{ fontSize: "16px", opacity: ".7" }}>
                      {" " + ar.b}
                    </span>
                  </p>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Status :
                    </span>
                    <span
                      style={{
                        color: "#FCB902",
                        fontSize: "16px",
                        fontWeight: "300",
                      }}
                    >
                      {" " + ar.c}
                    </span>
                  </p>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Priority :
                    </span>
                    <span style={{ fontSize: "16px", opacity: ".7" }}>
                      {" " + ar.d}
                    </span>
                  </p>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Planned End Date :
                    </span>
                    <span style={{ fontSize: "16px", opacity: ".7" }}>
                      {" " + ar.a}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        {/* Snippet, implementing 'Back' Button and 'Pagination' in flex-box */}
        {/* <div 
          className={style.buttonsContainer}
        >
          <div >
            <button 
              className={style.backButton}              
              onClick={()=>(history.goBack())} // window.history.back()
            >
                Back
            </button>
          </div>

          <div 
            className="paginationHere"
          >            
            {totalTasks > limit && pageCount > 0 && (
              <div className={style.paginationmobileContainer}>
                <Pagination
                  pagesCount={pageCount}
                  pageSize={limit}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        
        </div> */}
        {/* ___ */}

        {totalTasks > limit && pageCount > 0 && (
          <div
            className={style.paginationmobileContainer}
            style={{ marginRight: "1.3rem", marginTop: "20px" }}
          >
            <Pagination
              pagesCount={pageCount}
              pageSize={limit}
              onPageChange={handlePageChange}
              currentPage={currentPage}
            />
          </div>
        )}
      </div>)}

      {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          userLists={userLists}
          filterData={allData}
          setassigned={setAssigned}
          setpriority={setPriority}
          setstatus={setStatus}
          data={data}
        />
      )}
    </>
  );
};

const ViewDoneRevNote = ({ doneRevNote, openDoneRevNote, setOpenDoneRevNote }) => {
  return (<Modal
    open={openDoneRevNote}
    center
    onClose={() => setOpenDoneRevNote(false)}
    showCloseIcon={false}
    className={style.modal}
  >
    <div>{doneRevNote}</div>
    <div className={style.btnRow}><button className={style.add_btn} onClick={() => setOpenDoneRevNote(false)}>Done</button></div>
  </Modal>)
}

export default MyTasks;

// useApi.js
import axios from "axios";
import cookie from "react-cookies";
import { useState, useEffect } from "react";
import { employeeId, localStorageSetItem } from "../../Utils/constants";

const useValidationAccess = () => {
  const [data, setData] = useState([]);

  const url = process.env.REACT_APP_PARTNER_MANAGEMENT;

  const getOnsiteSkills = () => {
    axios
      .get(url + `/validate-employee-access?empId=${employeeId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          // Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setData(res.data.responseData);
        localStorageSetItem("accessData", res.data.responseData);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getOnsiteSkills();
  }, []);
  const checkValidateAccess = (accessCode) => {
    if (data?.find((e) => e?.elementCode === accessCode)) {
      return true;
    } else {
      return false;
    }
  };

  return { checkValidateAccess };
};

export default useValidationAccess;

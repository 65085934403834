// import React from "react";
// import Moment from "moment";
// import "./Timeline.css"; // Assuming you have this CSS file

// const Timeline = ({ userDetails }) => {
//     const timelineHeadings = [
//         {
//             label: "Joined : ",
//             value:
//                 userDetails?.dateOfJoining &&
//                 Moment(userDetails?.dateOfJoining).format("DD MMM YY"),
//             color: "green",
//         },
//         {
//             label: "Confirmation Date (6 Months) : ",
//             value:
//                 userDetails?.confirmationDate &&
//                 Moment(userDetails?.confirmationDate).format("DD MMM YY"),
//         },
//         {
//             label: "Actual Confirmation Date : ",
//             value:
//                 userDetails?.actualConfirmationDate &&
//                 Moment(userDetails?.actualConfirmationDate).format("DD MMM YY"),
//         },
//         { label: "Resignation : ", value: Moment(userDetails?.resignationDate).format("DD MMM YY") },
//         { label: "Retain : ", value: Moment(userDetails?.retainDate).format("DD MMM YY") },
//         { label: "Exit Planned Date : ", value: Moment(userDetails?.exitPlanDate).format("DD MMM YY") },
//         { label: "Exit Decision Date : ", value: Moment(userDetails?.exitDecisionDate).format("DD MMM YY")},
//         { label: "Exit (LWD) : ", value: Moment(userDetails?.lastWorkingDayDate).format("DD MMM YY"), color: "red" },
//     ];

//     return (
//         <div className="timeline">
//             {timelineHeadings.map((item, index) => (
//                 <div className="cp_rowcol123 timeline-item" key={index}>
//                 <p className="cp_label" style={{ color: item.color || "black" }}>
//                     {item.label}
//                 </p>
//                 <p className="cp_value">&nbsp;{item.value || "N/A"}</p>
//                 </div>
//             ))}
//         </div>
//     );
// };

// export default Timeline;


import React from 'react';
import Moment from "moment";
import "./Timeline.css";
import info from "../../../../assets/infoButton.svg";

const Timeline = ({userDetails}) => {
  return (
    <div className="timeline" style={{width: "23rem"}}>
        {/* Vertical Line */}
        <div className="timeline-line"></div>

        <div className="timeline-item">
            <div className="circle green"></div>
            <div className="content">
            <div className="title" style={{color: "green"}}>Joined : </div>
            <div className="date">{Moment(userDetails?.dateOfJoining).format("DD MMM YY")}</div>
            </div>
        </div>

        {userDetails?.confirmationDate && <div className="timeline-item">
            <div className="circle gray"></div>
            <div className="content">
            <div className="title">Confirmation Date (<span>{userDetails?.periodId === 2 ? "3 Months" : "6 Months"}</span>) : </div>
            <div className="date">{Moment(userDetails?.confirmationDate).format("DD MMM YY")}</div>
            </div>
        </div>}

        {userDetails?.actualConfirmationDate && <div className="timeline-item">
            <div className="circle gray"></div>
            <div className="content">
            <div className="title">Actual Confirmation Date : </div>
            <div className="date">{Moment(userDetails?.actualConfirmationDate).format("DD MMM YY")} &nbsp;
                {userDetails?.delayReason && <span>
                    <img style={{width: "1rem", cursor: "pointer", marginTop: "-5px"}} 
                    title={userDetails?.delayReason} 
                    src={info} 
                    alt="Img Not Available"/>
                </span>}
            </div>
            </div>
        </div>}

        {userDetails?.lastWorkingDayDate && <div className="timeline-item">
            <div className="circle gray"></div>
            <div className="content">
            <div className="title">Last Working Date : </div>
            <div className="date">{Moment(userDetails?.lastWorkingDayDate).format("DD MMM YY")}</div>
            </div>
        </div>}

        {userDetails?.resignationDate && <div className="timeline-item">
            <div className="circle gray"></div>
            <div className="content">
            <div className="title">Resignation : </div>
            <div className="date">{Moment(userDetails?.resignationDate).format("DD MMM YY")}</div>
            </div>
        </div>}

        {userDetails?.retainDate && <div className="timeline-item">
            <div className="circle gray"></div>
            <div className="content">
            <div className="title">Retain : </div>
            <div className="date">{Moment(userDetails?.retainDate).format("DD MMM YY")}</div>
            </div>
        </div>}

        {userDetails?.exitPlanDate && <div className="timeline-item">
            <div className="circle gray"></div>
            <div className="content">
            <div className="title">Exit Planned Date : </div>
            <div className="date">{Moment(userDetails?.exitPlanDate).format("DD MMM YY")} &nbsp;
                {userDetails?.exitComment && <span>
                    <img style={{width: "1rem", cursor: "pointer", marginTop: "-5px"}} 
                    title={userDetails?.exitComment} 
                    src={info} 
                    alt="Img Not Available"/>
                </span>}
            </div>
            </div>
        </div>}

        {userDetails?.exitDecisionDate && <div className="timeline-item">
            <div className="circle gray"></div>
            <div className="content">
            <div className="title">Exit Decision Date : </div>
            <div className="date">{Moment(userDetails?.exitDecisionDate).format("DD MMM YY")}</div>
            </div>
        </div>}

        {userDetails?.expectedLWD && <div className="timeline-item">
            <div className="circle red"></div>
            <div className="content">
            <div className="title" style={{color: "red"}}>Exit (Expected LWD) : </div>
            <div className="date">{Moment(userDetails?.expectedLWD).format("DD MMM YY")}</div>
            </div>
        </div>}
    </div>
  );
};

export default Timeline;


import React, { useEffect, useState } from "react";
import style from "./UsersList.module.css";
import SideBar from "../../sidebar/SideBar";
import { Link, useHistory, useLocation } from "react-router-dom";
import calender from "../../../../assets/calblue.png";
import cancel from "../../../../assets/cancell.png";
import { CustomInputClg, CustomInputCross } from "./CalendarStyle.js";
import { CustomInputClgStartDate, CustomInputCrossStartDate } from "./CalenderStyleStartDate.js"
import icon from "../../../../assets/calblue.png";
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";
import FilterListIcon from "@mui/icons-material/FilterList";
import DatePicker from "react-datepicker";
import ExcelFileDownload from "../ExcelFileDownload/ExcelFileDownload";
import { MdEdit } from "react-icons/md";
import { CgNotes } from "react-icons/cg";
import Select from "react-select";
import { customSearch } from "../../../atsDashboard/utils/style.js";
import {
  getAllUsersWithFilter,
  getAllTechnologyforFilter,
  getAllType,
  getSalaryRange,
  getAllFunctionalArea,
  getAllLeads,
  // getClientList,
  getPartnerListForFilter,
  getClientListForFilter,
  getEmployeeHistoryDetails,
  getAllSalaryMasterWithFilter,
} from "../../../../Utils/axios/umsApi";
import moment from "moment";
import searchIcon from "../../../../assets/Icon-search.png";
import { MdHistory } from "react-icons/md";
import Table from "react-bootstrap/Table";
import Popup from "reactjs-popup";
import { AiOutlineClose } from "react-icons/ai";
import loaderImg from "../../../../assets/umsLoader.gif";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import FilterModal from "../../filter/FilterModal";
import { useQuery } from "react-query";
import UmsCustomTooltip from "../../umsCustomTooltips/UmsCustomTooltip";
import { path } from "../../../../routes/PathNames";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import FilterSearchSelect from "../../../atsDashboard/commonComponent/filterSearch/FilterSearchSelect";
import { getAllEmployeeList } from "../../../EmployeePerformanceReview/utils/api";
import { addCommas } from "../../../Appraisal_System/commonComponent/utils/AddCommas";
import SalaryMaster from "../ExcelFileDownload/SalaryMasterDownload";
import Modal from "react-responsive-modal";
import { IoSearchOutline } from "react-icons/io5";
import FilterModalSalary from "../../filter/FilterModalSalary.js";
// import { api } from "../../../Projects_and_Resource_Master/CommonApi/api";
// import { get } from "../../../Projects_and_Resource_Master/CommonApi/axiosCall";
export default function SalaryMasterList() {
  const location = useLocation();
  const { checkValidateAccess } = useValidationAccess();

  const [currentPage, setCurrentPage] = useState(
    location?.state?.currentPage ? location?.state?.currentPage : 1
  );
  const [pageCount, setpageCount] = useState(0);
  const [userList, setUserList] = useState([]);
  const [totalCount, settotaluserCount] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [allCount, setAllCount] = useState(0);
  const [clientListing, setClientListing] = useState([]);
  const [showEmpHistoryModal, setShowEmpHistoryModal] = useState(false);
  const [historyModalData, sethistoryModalData] = useState("");
  const [open, setShowOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const [filterData, setFilterData] = useState(
    location?.state?.filterData
      ? location?.state?.filterData
      : {
        status: [],
        allocated: [],
        employeeType: [],
        salaryRange: [],
        functionalArea: [],
        leadId: [],
        clientId: [],
        experienceRange: [],
        pfApplicable: "",
        month: null,
        year: null,
      }
  );
  {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [filter, setFilter] = useState({ empId: 0, empTypeId: 0, sortBy: null, variableComponentFilter: null });
    const [isDisabled, setIsDisabled] = useState(false);
    const limit = 20;
    const [currEmp, setCurrEmp] = useState(null);

    const { data, isLoading } = useQuery(
      ["getAllSalaryMasterWithFilter", { filter, currentPage }],
      () =>
        getAllSalaryMasterWithFilter(
          limit,
          currentPage,
          filter?.empId,
          filter?.empTypeId,
          startDate,
          endDate,
          filter?.sortBy,
          filter?.variableComponentFilter,
          true
        ),
      {
        refetchOnWindowFocus: false,
      }
    );
    useEffect(() => {
      setUserList(data?.salaryMasterResponses);
      settotaluserCount(data?.activeEmployeesCount);
      setIsLoaded(false);
      setpageCount();
      setpageCount(~~(data?.activeEmployeesCount / 20 + 1));
      setAllCount(data?.activeEmployeesCount);
    }, [data]);
    // const { data: getCoreSkillData } = useQuery(
    //   ["getAllTechnologyforFilter"],
    //   getAllTechnologyforFilter,
    //   {
    //     refetchOnWindowFocus: false,
    //   }
    // );
    // const getTechnologyList = getCoreSkillData?.map((i) => ({
    //   value: i.id,
    //   label: i.name,
    // }));
    const { data: getAllTypeData } = useQuery(["getAllType"], getAllType, {
      refetchOnWindowFocus: false,
    });
    const getAllTypeOptionFilter = getAllTypeData?.filter(
      (e) => e.name !== "Partner"
    );
    const getAllTypeOption = getAllTypeOptionFilter?.map((e) => ({
      value: e.id,
      label: e.name,
    }));
    // const { data: getAllLeadsData } = useQuery(["getAllLeads"], getAllLeads, {
    //   refetchOnWindowFocus: false,
    // });
    // const getAllLeadsOption = getAllLeadsData?.map((e) => ({
    //   value: e.id,
    //   label: e.name,
    // }));
    // const { data: getAllPartnerData } = useQuery(
    //   ["getPartnerListForFilter"],
    //   getPartnerListForFilter,
    //   {
    //     refetchOnWindowFocus: false,
    //   }
    // );
    // const getAllPartnerOption = getAllPartnerData?.map((e) => ({
    //   value: e.id,
    //   label: e.name,
    // }));
    // const { data: getSalaryRangeData } = useQuery(
    //   ["getSalaryRange"],
    //   getSalaryRange,
    //   {
    //     refetchOnWindowFocus: false,
    //   }
    // );
    // const { data: getAllFunctionalData } = useQuery(
    //   ["getAllFunctionalArea"],
    //   getAllFunctionalArea,
    //   {
    //     refetchOnWindowFocus: false,
    //   }
    // );
    // const getSalaryRangeOption = getSalaryRangeData?.map((e) => ({
    //   value: e.id,
    //   label: e.name,
    // }));
    // const getFunctionalOption = getAllFunctionalData?.map((e) => ({
    //   value: e.id,
    //   label: e.name,
    // }));
    // const { data: getClientData } = useQuery(
    //   ["getClientListForFilter"],
    //   getClientListForFilter,
    //   {
    //     refetchOnWindowFocus: false,
    //   }
    // );
    // const getClientListing = () => {
    //   let url = api.getOnsiteClientList;

    //   get(url)
    //     .then((res) => {
    //       setClientListing(res.data.responseData);
    //     })
    //     .catch(() => {});
    // };
    // useEffect(()=>{
    //   getClientListing()
    // },[])
    const history = useHistory();
    // const clientOption = getClientData?.map((item) => {
    //   return {
    //     value: item?.id,
    //     label: item?.name,
    //   };
    // });
    const fetchEmpHistory = async (idData) => {
      try {
        // Assuming you have a function InductionNotes that fetches data
        const response = await getEmployeeHistoryDetails(idData);
        sethistoryModalData(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const handlePageChange = (page, status) => {
      if (status === "next") {
        setCurrentPage(page + 1);
      } else if (status === "prev") {
        setCurrentPage(page - 1);
      }
    };

    const { data: getEmployeeLists } = useQuery(
      ["getEmployeeList"],
      getAllEmployeeList
    );

    const getEmployeeNameOptions = getEmployeeLists?.employeeList?.map(
      (item) => {
        return {
          id: item?.id,
          label: `${item?.name} (${item?.employeeCode})`,
          label1: item?.name,
          employeeCode: item?.employeeCode,
        };
      }
    );
    const variableComponentsList = [
      { value: "EmployerPF", label: "Employer PF",},
      { value: "EmployeePF", label: "Employee PF",},
      { value: "Gratuity", label: "Gratuity",},
      { value: "SA1", label: "SA1",},
      { value: "SA2", label: "SA2",},
      { value: "Incentives", label: "Incentives",},
      { value: "VariableBonus", label: "Variable Bonus",},
      { value: "JoiningBonus", label: "Joining Bonus",},
      { value: "RetentionBonus", label: "Retention Bonus",},
      { value: "Others", label: "Others",}
    ];

    const onSearchHandler = (e, option) => {
      if (e || e === null) {
        switch (option) {
          case "emailName":
            setCurrentPage(1);
            setFilter({ ...filter, nameEmail: e.target.value });
            break;
          case "codeTech":
            setCurrentPage(1);
            setFilter({ ...filter, employeeCode: e.target.value });
            break;
          default:
        }
      } else {
      }
    };
    const filterUpdateFuc = () => {
      setCurrentPage(1);
      setFilter((prev) => ({
        ...prev,
        status: filter && filter.status && filterData && filterData?.status ? [
          ...(filter?.status?.includes(1)
            ? filter?.status
            : filterData?.status),
        ] : [],
        technology: filterData && filterData?.allocated ? [...filterData?.allocated] : [],
        employeeType: filterData && filterData?.employeeType ? [...filterData?.employeeType] : [],
        salaryRange: filterData && filterData?.salaryRange ? [...filterData?.salaryRange] : [],
        functionalArea: filterData && filterData?.functionalArea ? [...filterData?.functionalArea] : [],
        leadId: filterData && filterData?.leadId ? [...filterData?.leadId] : [],
        clientId: filterData && filterData?.clientId ? [...filterData?.clientId] : [],
        experienceRange: filterData && filterData.experienceRange ? [...filterData.experienceRange] : [],
        pfApplicable: filterData && filterData?.pfApplicable,
        month: filterData && filterData?.month,
        year: filterData && filterData?.year,
      }));
      setShowFilterModal(false);
    };
    const filterClearAllFuc = () => {
      if (isDisabled) {
        setFilter((prev) => ({
          ...prev,
          status: [1],
          technology: [],
          employeeType: [],
          salaryRange: [],
          functionalArea: [],
          leadId: [],
          clientId: [],
          experienceRange: [],
          pfApplicable: "",
          month: null,
          year: null,
        }));
      } else {
        setFilter((prev) => ({
          ...prev,
          status: [],
          technology: [],
          employeeType: [],
          salaryRange: [],
          functionalArea: [],
          leadId: [],
          clientId: [],
          experienceRange: [],
          pfApplicable: "",
          month: null,
          year: null,
        }));
      }

      setFilterData((prev) => ({
        ...prev,
        status: [],
        allocated: [],
        employeeType: [],
        functionalArea: [],
        salaryRange: [],
        leadId: [],
        clientId: [],
        experienceRange: [],
        pfApplicable: "",
        month: null,
        year: null,
      }));
      setShowFilterModal(false);
    };
    const handleRedirect = (id) => {
      setTimeout(() => {
        history.push(path.ums_userDescription, {
          id,
          filterData,
          currentPage,
          filter,
        });
      }, 0);
    };
    const ReginationData = (value) => {
      return (
        <>
          <div>Resignation Date</div>
          <div>{moment(value).format("DD MMM YY")}</div>
        </>
      );
    };
    const ExitDate = (value) => {
      return (
        <>
          <div>Exit Date</div>
          <div>{moment(value).format("DD MMM YY")}</div>
        </>
      );
    };
    const BondDate = (value) => {
      return (
        <>
          <div>Bond Date</div>
          <div>{moment(value).format("DD MMM YY")}</div>
        </>
      );
    };
    const RetainDate = (value) => {
      return (
        <>
          <div>Retain Date</div>
          <div>{moment(value).format("DD MMM YY")}</div>
        </>
      );
    };
    const ExitDicisionPlanDate = (decision, planned, note) => {
      return (
        <>
          <div>Exit Decision Date</div>
          <div>{moment(decision).format("DD MMM YY")}</div>
          <div>Exit Planned Date</div>
          <div>{moment(planned).format("DD MMM YY")}</div>
          <div>Exit Note</div>
          <div>{note}</div>
        </>
      );
    };

    const handleClick = (emp) => {
      // open edit user screen
      setShowOpen(true);
      setCurrEmp({ ...emp });
    };

    const handleClickEdit = (id) => {
      history.push(path.ums_editUser, { id });
    };

    const handleStartDateChange = (date) => {
      const formattedDate = moment(date).format("DD-MM-YYYY");
      setStartDate(formattedDate);
      setFilter((prevFilter) => ({
        ...prevFilter,
        startDate: formattedDate,
      }));
    };


    const handleEndDateChange = (date) => {
      const formattedDate = moment(date).format("DD-MM-YYYY");

      setEndDate(formattedDate);
      setFilter((prevFilter) => ({
        ...prevFilter,
        endDate: formattedDate,
      }));
    };
    // const handleSort = (field) => {
    //   const currentSort = filter?.sortBy;
    //   if (currentSort === field) {
    //     // If the field is not in sortBy, add it as ascending
    //     setFilter((prevState) => ({
    //       ...prevState,
    //       sortBy: null,
    //     }));
    //   } else {
    //     // If the field is in sortBy, toggle the direction
    //     setFilter((prevState) => ({
    //       ...prevState,
    //       sortBy: field,
    //     }));
    //   }
    // };
    const handleSort = (field) => {
      if (filter?.sortBy?.split(',')[0] !== field) {
        setFilter((prev) => ({
          ...prev, sortBy: `${field},ASC`,
        }));
        return;
      }
      if (filter?.sortBy?.split(',')[1] === "ASC") {
        setFilter((prev) => ({
          ...prev, sortBy: `${field},DESC`,
        }));
      } else {
        setFilter((prev) => ({ ...prev, sortBy: `${field},ASC`, }));
      }
    };

    // const renderSortArrow = (field) => {
    //   const currentSort = filter?.sortBy;
    //   if (currentSort == field)
    //     return <TiArrowSortedDown
    //       style={{
    //         fontSize: "1.1rem",
    //         cursor: "pointer",
    //         color: "black",
    //       }}
    //     />
    //   else return <TiArrowSortedUp
    //     style={{
    //       fontSize: "1.1rem",
    //       cursor: "pointer",
    //       color: "black",
    //     }}
    //   />
    // };
    const renderSortArrow = (field) => {
      if (filter?.sortBy?.split(',')[0] !== field) return null;
  
      return filter?.sortBy?.split(',')[1] === "ASC" ? (
        <TiArrowSortedUp
          style={{
            fontSize: "1.1rem",
            cursor: "pointer",
          }}
        />
      ) : (
        <TiArrowSortedDown
          style={{
            fontSize: "1.1rem",
            cursor: "pointer",
          }}
        />
      );
    };


    return (
      <>
        {/* <Header />*/}
        <div className={style.main_client_body}>
          <SideBar />
          <NoteModal
            open={open}
            setOpen={setShowOpen}
            currEmp={currEmp}
          />
          <div className={style.main_create}>
            <div className={style.table_div_head}>
              <div
                className={style.breadscrumb}
                style={{ padding: "6px 0px" }}
              ></div>
              <div
                className={style.second_child}
                style={{
                  display: "flex",
                  justifyContent: "inherit",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    paddingLeft: "28px",
                    width: "30%",
                    marginTop: "10px",
                    fontSize: "16px",
                    fontWeight: 700,
                    display: "flex",
                  }}
                >
                  <div style={{width: "103px"}}>Salary Master</div>
                  <div
                    style={{
                      position: "relative",
                      top: "-14%",
                      left: "4%",
                      display: "flex",
                    }}
                  >
                    <p className={style.count}>
                      <span
                        className={style.backColor1}
                        style={{ fontSize: "1.235rem", fontWeight: "500" }}
                      >
                        {allCount}
                      </span>
                    </p>
                  </div>
                  <div className={style.download} style={{marginLeft: "20px"}}>
                    <SalaryMaster
                      filter={filter}
                      limit={limit}
                      startDate={startDate}
                      endDate={endDate}
                      allCount={allCount}
                      currentPage={currentPage}
                    />
                  </div>
                </div>

                <div style={{ width: "12%" }}>
                  <button
                    className={style.filter_btn}
                    onClick={() => setShowFilterModal(true)}
                    style={
                      (Array.isArray(filter?.variableComponentFilter) ? filter?.variableComponentFilter?.length>0 : filter?.variableComponentFilter!==null )
                        ? { background: "#289e98", color: "#fff" }
                        : null
                    }
                  >
                    <FilterListIcon style={{ marginRight: "10px" }} />
                    Filter
                  </button>
              </div>

                <div style={{ marginRight: "26px", display: "flex" }}>
                  <span className={`${style.s1} ${style.date}`} style={{width: "120px"}}>
                    <DatePicker
                      placeholder="DD MM YYYY"
                      className={style.datePicker}
                      placeholderText="Start Date"
                      onChange={handleStartDateChange}
                      dateFormat="DD MMM yyyy"
                      customInput={
                        startDate ? (
                          <CustomInputCrossStartDate
                            setStartDate={setStartDate}
                            setFilter={setFilter}
                            startDate={startDate}
                            placeholder="Start Date"
                            icon={icon}
                          />
                        ) : (
                          <CustomInputClgStartDate icon={icon} />
                        )
                      }
                    />

                  </span>

                  <span className={`${style.s1} ${style.date}`} style={{width: "120px"}}>
                    <DatePicker
                      placeholder="DD MM YYYY"
                      className={style.datePicker}
                      placeholderText="End Date"
                      onChange={handleEndDateChange}
                      dateFormat="DD MMM yyyy"
                      customInput={
                        endDate ? (
                          <CustomInputCross
                            setEndDate={setEndDate}
                            setFilter={setFilter}
                            endDate={endDate}
                            placeholder="End Date"
                            icon={icon}
                          />
                        ) : (
                          <CustomInputClg icon={icon} />
                        )
                      }
                    />

                  </span>

                  <span style={{ fontWeight: "400", marginLeft: "5px" }}>
                    <FilterSearchSelect
                      placeholder="Type"
                      value={filter?.empTypeId}
                      width="8rem"
                      mL="82%"
                      options={getAllTypeOption}
                      onChange={(e) => {
                        setFilter({ ...filter, empTypeId: e?.value });
                      }}
                      onClose={() => setFilter({ ...filter, empTypeId: 0 })}
                    />
                  </span>
                  <span style={{ fontWeight: "400", marginLeft: "5px" }}>
                    <FilterSearchSelect
                      placeholder="Employee Name"
                      value={filter?.empId}
                      width="210px"
                      options={getEmployeeNameOptions}
                      onChange={(e) => {
                        setFilter({ ...filter, empId: e?.id });
                      }}
                      onClose={() => setFilter({ ...filter, empId: 0 })}
                    />
                  </span>
                  
                </div>
              </div>
            </div>
            {isLoading ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "46%", marginTop: "8%", width: "12%" }}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#289e98",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "33.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : (
              <div className={style.UsersList}>
                <div
                  className={style.th}
                >
                  <p>
                    Type
                  </p>
                  <p>
                    Code
                  </p>
                  <p>
                    Name
                  </p>
                  <p>
                    DOJ
                  </p>
                  <p>
                    Salary Range
                  </p>
                  <p>
                    Current Monthly <br /> Core CTC
                  </p>
                  <p>
                    Current Monthly <br /> Full CTC
                  </p>
                  <p >
                    Current Yearly <br /> Core CTC
                  </p>
                  <p >
                    Current Yearly <br /> Full CTC
                  </p>
                  <p
                  >
                    Salary ( As per <br /> Project & Costing )
                  </p>
                  <p >
                    Basic
                  </p>
                  <p >
                    HRA
                  </p>
                  <p >
                    OA
                  </p>
                  <p >
                    Conveyance
                  </p>
                  <p >
                    Bonus
                  </p>
                  <p >
                    Gross Salary
                  </p>
                  <p
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => handleSort("employerPF")}>
                    Employer PF
                    {renderSortArrow("employerPF")}

                  </p>
                  <p
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => handleSort("employeePF")}>
                    Employee PF
                    {renderSortArrow("employeePF")}
                  </p>
                  <p >
                    Gratuity
                  </p>
                  <p
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => handleSort("sa1")}>
                    SA1
                    {renderSortArrow("sa1")}
                  </p>
                  <p
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => handleSort("sa2")}>
                    SA2
                    {renderSortArrow("sa2")}
                  </p>
                  <p
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => handleSort("incentives")}>
                    Incentives
                    {renderSortArrow("incentives")}
                  </p>
                  <p
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => handleSort("variableBonus")}>
                    Variable Bonus
                    {renderSortArrow("variableBonus")}
                  </p>
                  <p
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => handleSort("joiningBonus")}>
                    Joining Bonus
                    {renderSortArrow("joiningBonus")}
                  </p>
                  <p >
                    Retention Bonus
                  </p>
                  <p>
                    {" "}
                    Others
                  </p>

                  <p

                  >
                    Salary Note
                  </p>
                </div>
                {userList?.length > 0 ? (
                  userList?.map((val, key) => {
                    return (
                      <div
                        className={style.td}

                        key={key}
                      >
                        <UmsCustomTooltip
                          value={val.empType}
                          limit={15}
                          index={`type${key}`}
                        />
                        <p
                          className={style.backColor}

                        >
                          {val?.empCode}
                        </p>
                        <UmsCustomTooltip
                          value={val?.empName}
                          limit={24}
                          index={`name${key}`}

                        />
                        <p >
                          {moment(val?.dateOfJoining).format("DD MMM YY")}
                        </p>
                        <p >
                          {val?.salaryRange}
                        </p>
                        <p >
                          {val?.salary && addCommas(val?.salary)}
                        </p>
                        <p >
                          {val?.currentMonthlyFullCTC &&
                            addCommas(val?.currentMonthlyFullCTC)}
                        </p>
                        <p >
                          {val?.currentYearlyCoreCTC &&
                            addCommas(val?.currentYearlyCoreCTC)}
                        </p>
                        <p >
                          {val?.currentYearlyFullCTC &&
                            addCommas(val?.currentYearlyFullCTC)}
                        </p>
                        <p >
                          {val?.salaryAsProjectAndCosting &&
                            addCommas(val?.salaryAsProjectAndCosting)}
                        </p>
                        <p >
                          {val?.basic &&
                            addCommas(val?.basic)}
                        </p>
                        <p >
                          {val?.hra &&
                            addCommas(val?.hra)}
                        </p>
                        <p >
                          {val?.oa &&
                            addCommas(val?.oa)}
                        </p>
                        <p >
                          {val?.conveyance &&
                            addCommas(val?.conveyance)}
                        </p>
                        <p >
                          {val?.bonus &&
                            addCommas(val?.bonus)}
                        </p>
                        <p >
                          {val?.grossSalary &&
                            addCommas(val?.grossSalary)}
                        </p>
                        <p >
                          {val?.employerPF &&
                            addCommas(val?.employerPF)}
                        </p>
                        <p >
                          {val?.employeePF &&
                            addCommas(val?.employeePF)}
                        </p>
                        <p >
                          {val?.gratuity &&
                            addCommas(val?.gratuity)}
                        </p>
                        <p >
                          {val?.sa1 && addCommas(val?.sa1)}
                        </p>
                        <p >
                          {val?.sa2 && addCommas(val?.sa2)}
                        </p>
                        <p >{val?.incentives && addCommas(val?.incentives)}</p>
                        <p >
                          {val?.variableBonus && addCommas(val?.variableBonus)}
                        </p>
                        <p >
                          {val?.joiningBonus && addCommas(val?.joiningBonus)}
                        </p>
                        <p >
                          {val?.retentionBonus &&
                            addCommas(val?.retentionBonus)}
                        </p>
                        <p>
                          {val?.others && addCommas(val?.others)}
                        </p>

                        <p>
                          <span onClick={() => handleClick(val)} style={{ marginRight: "0.4rem", cursor: "pointer" }} onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              handleClick(val);
                            }
                          }} tabIndex={0}>{val?.salaryNotes && <CgNotes style={{ color: "#289e98" }} />}</span>
                          <span onClick={() => handleClickEdit(val?.empId)} style={{ cursor: "pointer" }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                handleClickEdit(val?.empId);
                              }
                            }} tabIndex={0}>
                            {val?.salaryNotes && (
                              <MdEdit
                                style={{ marginLeft: "5px", color: "#239e98" }}
                              />
                            )}
                          </span>
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <p
                      style={{
                        fontSize: "1rem",
                        paddingLeft: "45%",
                        color: "#289e98",
                        paddingTop: "1rem",
                      }}
                    >
                      No Data
                    </p>
                  </div>
                )}
              </div>
            )}
            {!isLoading && totalCount > limit && pageCount > 0 && !isLoaded && (
              <div
                className={style.paginationmobileContainer}
                style={{ marginRight: "29px" }}
              >
                <Pagination
                  pagesCount={pageCount}
                  pageSize={20}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                  color="#289e98"
                  color2="white"
                />
              </div>
            )}
            <div>
              <Popup
                open={showEmpHistoryModal}
                closeOnDocumentClick={false}
              // onClose={props.closeModal}
              >
                <div className={style.modal_emp_history}>
                  <div className={style.heading}>Employee History</div>
                  <div className={style.modal_table_container}>
                    {historyModalData?.empHistoryCount === 0 ? (
                      <p className={style.no_notes}>No Notes</p>
                    ) : (
                      <Table
                        className={style.modal_table}
                        borderless
                        hover
                        responsive
                      >
                        <thead>
                          <tr>
                            <th style={{ width: "8rem" }}>Date Modified</th>
                            <th style={{ width: "10rem" }}>Change By</th>
                            <th style={{ width: "10rem" }}>Fields</th>
                            <th style={{ width: "10rem" }}>Previous Info</th>
                            <th style={{ width: "10rem" }}>Current Info</th>
                          </tr>
                        </thead>
                        <tbody>
                          {false ? (
                            <tr>
                              <td colSpan={3} style={{ textAlign: "center" }}>
                                No Data Found
                              </td>
                            </tr>
                          ) : (
                            historyModalData?.empHistoryData?.map((item) => (
                              <tr>
                                <td style={{ width: "8rem" }}>
                                  {moment(item.modifiedOn).format("DD MMM YY")}
                                </td>
                                <td style={{ width: "10rem" }}>
                                  {item.changeByEmpName}
                                </td>
                                <td>{item.fields}</td>
                                <td style={{ width: "10rem" }}>
                                  {item.prevInfo}
                                </td>
                                <td>{item.currentInfo}</td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                    )}
                  </div>
                  <div className={style.modal_btn_container}>
                    <button
                      className={style.modal_btn}
                      onClick={() => {
                        setShowEmpHistoryModal(false);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          setShowEmpHistoryModal(false);
                        }
                      }}
                      tabIndex={0}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </Popup>
            </div>
            <FilterModalSalary showFilter={showFilterModal} setShowFilter={setShowFilterModal} setFilter={setFilter} setCurrentPage={setCurrentPage}/>
          </div>
        </div>
      </>
    );
  }
}

const NoteModal = ({ open, setOpen, currEmp }) => {
  return (
    <Modal
      open={open}
      center
      onClose={() => setOpen(false)}
      showCloseIcon={false}
    >
      <div className={style.note_modal}>
        <div className={style.notes_desc}>{currEmp?.salaryNotes?.split("\n").map(item => <div>{item}</div>)}</div>
        <div className={style.btnRow}>
          <button
            className={style.edit_btn}
            onClick={() => setOpen(false)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setOpen(false);
              }
            }}
            tabIndex={0}
          >
            Done
          </button>
        </div>
      </div>
    </Modal>
  );
};

import React from 'react';
import Header from '../commonComponent/Header';
import SideBar from '../commonComponent/SideBar';
import SearchTaskComponent from './SearchTaskComponent/SearchTaskComponent';
import style from '../ViewTask/ViewTask.module.css';

const SearchTask = () => {
  return (
    <div>
        <Header />
  
        <div className={style.mainSection}>
          <SideBar />
          <div className={style.rightSection}>
            <SearchTaskComponent />
          </div>
        </div>
    </div>
  )
}

export default SearchTask;
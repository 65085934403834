import style from './ManageMembers.module.css';
import style1 from '../groups.module.css'
import SideBar from '../../commonComponent/SideBar';
import ATSButton from '../../../atsDashboard/customTooltip/ATSButton';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { addMembersToSpace, getActiveEmployeesNotInSpace, getActiveMembersBySpace, updateMemberStatusToSpace } from '../../taskTrackerAPIs/taskTrackerAPI';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import { customStylesForSpaces } from '../../../atsDashboard/utils/style';
import Select from "react-select";
import { IoSearchOutline } from 'react-icons/io5';
import "./customSelectForMembers.css";
import { FaChevronDown } from 'react-icons/fa';
import { employeeId } from '../../../../Utils/constants';
import { Toastify, ToastifyError } from '../../../../App';
import ConfirmPopup from '../../taskAssignedByMe/component/ConfirmPopup';

const ManageMembersForGroup = () => {
    const location = useLocation();
    const history = useHistory();
    const [addMemberState, setAddMemberState] = useState(false);
    const [existingMembers, setExistingMembers] = useState([]);
    const [roleChange, setRoleChange] = useState(false);
    const [validate, setValidate] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteMember, setDeleteMember] = useState();
    const [deleteName, setDeleteName] = useState();
    const [inputValue, setInputValue] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [newMembers, setNewMembers] = useState(
        {
            memberAddedBy : employeeId,
            memberDetails: [],
            notifyUser: false,
            spaceId: location?.state?.spaceId
        }
    );
    const [roleChangeMembers, setRoleChangeMembers] = useState(
        {
            memberAddedBy : employeeId,
            memberDetails: [],
            notifyUser: false,
            spaceId: location?.state?.spaceId
        }
    );
    const [isRoleChanged, setIsRoleChanged] = useState(false);
    const { data: members, refetch } = useQuery(
        ["getActiveMembersBySpace"],
        ()=>getActiveMembersBySpace(location?.state?.spaceId),
        {
          refetchOnWindowFocus : false,
        }
    );
    useEffect(()=>{
        const updateMembers = async () => {
            if(isRoleChanged || roleChange) return;
            if(existingMembers?.length!=members?.length){
                setExistingMembers(members);
                return;
            }
            const isDifferent = members?.some((newMember) => {
              const currentMember = existingMembers?.find((m) => m.memberId === newMember.memberId);
              if (!currentMember) return true;
              return (
                currentMember.groupRole !== newMember.groupRole || 
                currentMember.groupRoleId !== newMember.groupRoleId
              );
            });
            if (isDifferent) {
                setExistingMembers(members);
            }
        };
        updateMembers();
        let temp = members?.find(val=>val?.memberId===employeeId)?.groupRoleId;
        if(temp===1){
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }      
    },[members, isRoleChanged, roleChange]);

    const { data: notMembers, refetch: refetchNotMembers} = useQuery(
        ["getActiveEmployeesNotInSpace"],
        ()=>getActiveEmployeesNotInSpace(location?.state?.spaceId),
        {
            refetchOnWindowFocus : false,
        }
    );
    const notMembersList = notMembers?.map(val=>{
        return {
            value: val?.memberId,
            label: val?.memberName,
        }
    });

    const addMembersMutation = useMutation(addMembersToSpace, {
        onSuccess : (res)=>{
            Toastify(res?.responseData?.message);
            setAddMemberState(false);
            setNewMembers(prev=>({
                ...prev,
                memberDetails: [],
                notifyUser: false
            }));
            refetch();
            refetchNotMembers();
        },
        onError : ()=>{
            ToastifyError("Something Went Wrong");
        }
    });
    const roleChangeMutation = useMutation(addMembersToSpace, {
        onSuccess : (res)=>{
            if(res.statusCode) {setIsRoleChanged(false); ToastifyError(res?.responseData?.message);}
            else Toastify("Updated Successfully!");
            setRoleChange(false);
            setRoleChangeMembers(prev=>({
                ...prev,
                memberDetails: []
            }));
            refetch();
        },
        onError : ()=>{
            ToastifyError("Something Went Wrong");
            setIsRoleChanged(false);
        }
    });
    const deleteMemberMutation = useMutation(updateMemberStatusToSpace, {
        onSuccess : (res)=>{
            if(res?.statusCode) ToastifyError(res?.responseData?.message);
            else{
                Toastify("Member Deleted Successfully!");
                refetch();
                refetchNotMembers();
            }
            setDeleteModal(false);
            
        },
        onError : ()=>{
            ToastifyError("Something Went Wrong!");
        }
    })
    const roleOptions = [
        {value: 1, label: "Admin"},
        {value: 2, label: "Member"},
    ];
    const handleRoleChange = (e, val)=>{
        if(roleChange===false) setRoleChange(true);
        setRoleChangeMembers((prevState) => ({
            ...prevState,
            memberDetails: [...prevState.memberDetails, {empId: val, groupRoleId: e?.value}],
        }));
        let arr = existingMembers?.map(item=>{
            if(item?.memberId === val){
                return {...item, groupRoleId: e?.value}
            } else return item;
        });
        setExistingMembers(arr);
    }
    const handleAddNewMembers = (e)=>{
        setValidate(false); 
        let tempMember = e?.map((val)=>({
            empId: val?.value,
            groupRoleId: 2
        }));
        setNewMembers((prevState) => ({
            ...prevState,
            memberDetails: tempMember,
        }));
    }
  return (
    <>
        <div className={style1.mainSection}>
            <SideBar />
            <div className={style1.rightSection}>
                <div className={style.container}>
                    <span>Manage Members</span>

                    <div className={style.createTask}>
                        <div>
                            {!addMemberState ? 
                                (isAdmin && location?.state?.isActive && <ATSButton onClick={()=>setAddMemberState(true)}>+ Add Members</ATSButton>)
                                :
                                <>
                                    <Select
                                        styles={customStylesForSpaces}
                                        placeholder="Search Name to Add Members..."
                                        components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => <IoSearchOutline style={{width: '25px', height: '25px', marginRight: '8px'}}/>,
                                        }}
                                        isMulti
                                        options={notMembersList}
                                        onChange={(e)=>handleAddNewMembers(e)}
                                        onInputChange={(e)=>setInputValue(e)}
                                        inputValue={inputValue}
                                        menuIsOpen={inputValue?.length>2}
                                    />
                                    <p
                                        className={validate ? style.errorText : style.errorTextHidden}
                                    >Please Select at Least One Name</p>
                                    <div className={style.twoButtonsAdd}>
                                        <div className={style.notify}>
                                            <input
                                                type="checkbox"
                                                className={style.showAllCheckBox}
                                                style={{
                                                    visibility: "visible",
                                                    accentColor:"#65A15E",
                                                    transform: "translateY(1px)",
                                                    margin: "0px 4px 0px 0px"
                                                }}
                                                checked={newMembers?.notifyUser}
                                                onChange={()=>setNewMembers(prev=>({...prev, notifyUser: !prev.notifyUser}))}
                                            />
                                            <label>Notify People</label>
                                        </div>
                                        <div style={{display: "flex", gap: "10px"}}>
                                            <button className={style.cancelButton} onClick={()=>{setAddMemberState(false); setValidate(false); setNewMembers(prev=>({...prev, memberDetails: [], notifyUser: false}))}}>Cancel</button>
                                            <button className={style.saveButton} onClick={()=>{
                                                if(newMembers?.memberDetails?.length>0){
                                                    setValidate(false);
                                                    if(!addMembersMutation?.isLoading){setIsRoleChanged(false); addMembersMutation.mutate(newMembers)};
                                                } 
                                                else setValidate(true);
                                            }}>Save</button>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className={style.members}>Members</div>
                        {existingMembers?.map((memberList, index)=>{
                            return <div className={style.existingMembersList} key={`member${index}`}>
                                <div className={style.memberName}>{memberList?.memberName}</div>
                                <div className={style.memberEmail} style={{textOverflow: "ellipsis"}}>{memberList?.memberEmail}</div>
                                <div className={style.role}>
                                    <Select
                                        classNamePrefix="customSelect"
                                        value={memberList?.groupRoleId===1 ? roleOptions[0] : roleOptions[1]}
                                        components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => (isAdmin && location?.state?.isActive) ? <FaChevronDown style={{height: "12px", width: "12px"}}/> : null,
                                        }}
                                        options={roleOptions}
                                        onChange={(e)=>handleRoleChange(e, memberList?.memberId)}
                                        isDisabled={!isAdmin || !location?.state?.isActive}
                                        isSearchable={false}
                                    />
                                </div>
                                <div style={{display: "flex", alignItems: "center", cursor: "pointer"}}>{isAdmin && location?.state?.isActive && <RiDeleteBin6Fill onClick={()=>{setDeleteModal(true); setDeleteMember({empId : memberList?.memberId, groupRoleId : memberList?.groupRoleId, spaceId : location?.state?.spaceId}); setDeleteName(memberList?.memberName)}}/>}</div>
                            </div>
                            }
                        )}
                        {roleChange && <div className={style.twoButtons}>
                            <button className={style.cancelButton} onClick={()=>{setRoleChange(false); setRoleChangeMembers(prev=>({...prev, memberDetails: []})); refetch()}}>Cancel</button>
                            <button className={style.saveButton} onClick={()=>{if(!roleChangeMutation?.isLoading){setIsRoleChanged(true); roleChangeMutation.mutate(roleChangeMembers)}}}>Save</button>
                        </div>}
                    </div>
                </div>
                <div className={style.paginationalign}>
                    <button className={style.goBack} onClick={() => history.goBack()}>
                        Back
                    </button>
                </div>
            </div>
        </div>
        <ConfirmPopup open={deleteModal}>
            <div className={style.modal_group_member2}>
            <div className={style.memberModalHeading} style={{width: "23rem", marginBottom: "1rem"}}>Remove Member</div>
            <div style={{marginBottom: "1rem"}}>Do you want to remove "{deleteName}" from this space?</div>
            <div className={style.deleteModalBtns}>
                <button className={style.cancelButton} onClick={()=>setDeleteModal(false)}>No</button>
                <button className={style.doneButton} onClick={()=>{setIsRoleChanged(false); setRoleChange(false); deleteMemberMutation.mutate(deleteMember)}}>Yes</button>
            </div>
            </div>
        </ConfirmPopup>
    </>
  )
}

export default ManageMembersForGroup;
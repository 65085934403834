//AllIssueComp
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import style from "./MyIssuecom.module.css";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import cookie from "react-cookies";
import sortDownarrowImg from "../../../../../assets/Down.png";
import sortUparrowImg from "../../../../../assets/Up.png";
import Pagination from "../../../commonComponent/Pagination";
import { employeeId } from "../../../../../Utils/constants";
import { getUserAccess } from "../../../../../Utils/axios/expenceApi";
import useValidationAccess from "../../../../custumHooks/useValidationAccess";
import { hrHelpDesk } from "../../../../../Utils/constants";
export const MyIssueCom = () => {
  const [myIssueList, setMyIssueList] = useState();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [totalIssue, settotalIssue] = useState(0);
  const [isLoading, setisLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState(null);

  const [allData, setAllData] = useState({
    categoryId: [],
    delayByOrder: sortOrder,
  });

  const limit = 10;
  // url
  const url = process.env.REACT_APP_HRHELPDESK_APP_URL;
  const source = axios.CancelToken.source();

  const createHandler = () => {
    history.push("/CreateIssue");
  };

  const viewIssueHandler = (id) => {
    history.push(`/ViewIssue?id=${id}`);
  };

  // pagination Handler
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  // My Issue API Integratio
  const getSummary = () => {
    axios
      .post(
        url +
          `/getMyIssueByFilter?empId=${employeeId}&` +
          "limit=" +
          limit +
          "&page=" +
          currentPage,
        allData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
          cancelToken: source.token,
        }
      )
      .then((res) => {
        if (res.data.responseStatus?.statusCode === 1) {
          return;
        } else {
          setMyIssueList(res.data.responseData);
          settotalIssue(res.data.responseData.totalIssue);
          setpageCount(res.data.responseData.totalIssue / 10);
          setisLoading(false);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getSummary();
    return () => {
      source.cancel("Component is unmounted");
    };
  }, [currentPage, allData]);

  const handleSorting = () => {
    setSortOrder(
      sortOrder === null ? "ASC" : sortOrder === "ASC" ? "DESC" : "ASC"
    );
  };
  useEffect(() => {
    setAllData({ ...allData, delayByOrder: sortOrder });
  }, [sortOrder]);
  const {data:empAccess}=useQuery(["getUserAccess"],() => getUserAccess())
  const { checkValidateAccess } = useValidationAccess();

  return (
    <div className={style.main}>
      <div className={style.issueAllign}>
        <p className={style.myIssueP}>My Issue</p>
        <button className={style.btnColor} onClick={createHandler}>
          <AddIcon /> Create Issue
        </button>
      </div>

      <div className={style.IssueList}>
        {isLoading ? (
          <p className={style.noDataAlign}>Loading...</p>
        ) : (
          <table style={{ width: "100%" }}>
            <thead>
              <tr className={style.th}>
                <th className={style.tableContent}>Issue ID</th>
                <th className={style.tableContent}>Logged Date</th>
                <th className={style.tableContent}>Category</th>
                <th style={{ width: "12rem" }}>Sub-Category</th>
                <th style={{ width: "5rem" }}>Status</th>
                <th
                  className={style.tableContent}
                  style={{ textAlign: "center" }}
                >
                  Delay By
                  <img
                    alt=""
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      bottom: "0px",
                      left: "5px",
                    }}
                    src={
                      sortOrder === "ASC" ? sortUparrowImg : sortDownarrowImg
                    }
                    onClick={handleSorting}
                  />
                </th>
                {checkValidateAccess(hrHelpDesk?.hrHelpDeskRequestedBy) ? (<th className={style.tableContent}>Requested By</th>) : null}
              </tr>
            </thead>
            {myIssueList?.issues?.length === 0 ? (
              <p className={style?.noDataAlign}>No Data Available</p>
            ) : (
              <tbody style={{ height: "20px" }}>
                {myIssueList?.issues.map((issue) => (
                  <tr key={issue.issueId} className={style.td}>
                    <td
                      className={`${style.link} ${style.tableContent}`}
                      onClick={() => viewIssueHandler(issue?.issueId)}
                    >{`HRHD${issue?.issueId}`}</td>
                    <td className={style.tableContent} style={{paddingLeft: "15px"}}>
                      {
                        Moment(issue.loggedDate).year() === new Date().getFullYear() ? 
                        Moment(issue.loggedDate).format("DD MMM") :                         
                        Moment(issue.loggedDate).format("DD MMM YY")
                      }
                    </td>
                    <td className={style.tableContent}>{issue.category}</td>
                    <td style={{ width: "12rem" }}>{issue.subCategory}</td>
                    <td style={{ width: "5rem" }}>{issue.status}</td>
                    <td
                      className={style.tableContent}
                      style={{ textAlign: "center" }}
                    >
                      {issue?.delayBy}
                    </td>
                    {empAccess?.some((item)=>item.id===9||item.id===1) ? (<td className={style.tableContent}>{issue.requestByName}</td>) : null}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        )}
      </div>
      {totalIssue > limit && pageCount > 0 && (
        <div
          className={style.paginationmobileContainer}
          style={{ marginTop: "0px", paddingRight: "10px" }}
        >
          <Pagination
            pagesCount={pageCount}
            pageSize={limit}
            onPageChange={handlePageChange}
            currentPage={currentPage}
          />
        </div>
      )}
    </div>
  );
};

//AllIssueComp
import React, { useState, useEffect, useRef } from "react";
import style from "./groupList.module.css";
import SideBar from "../../commonComponent/SideBar";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Filter from "../../myTasks/component/Filter";
import Moment from "moment";
import constants from "../../../../Utils/constants";
import cookie from "react-cookies";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Dropdown from 'react-bootstrap/Dropdown';
import sortDownarrowImg from "./../../../../assets/Down.png";
import sortUparrowImg from "./../../../../assets/Up.png";
import loaderImg from "./../../../../assets/loader-green2.gif";
import Polygon from "./../../../../assets/Polygon.png";
import Iconionicmdcheckbox from "../../../../assets/Iconionicmdcheckbox.png";
import filterimg from "./../../../../assets/filter-results-button.png";
import Pagination from "../../commonComponent/Pagination";
import ConfirmPopup from "../../taskAssignedByMe/component/ConfirmPopup";
import { IoMdSettings } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import searchIcon from "../../../../assets/Icon-search.png";
import "./customDropdown.css";
import Modal from "react-responsive-modal";
import Select from "react-select";
import { customStylesForSpaces } from "../../../atsDashboard/utils/style";
import { addSpace, closeSpace, getActiveMembersBySpace, getAllSpaceElements, getSpaceDetailsById } from "../../taskTrackerAPIs/taskTrackerAPI";
import { useMutation, useQuery } from "react-query";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Toastify, ToastifyError } from "../../../../App";
import SpaceFilter from "../../myTasks/component/SpaceFilter";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { CgNotes } from "react-icons/cg";

export const GetAllGroupList = (props) => {
  const location = useLocation();
  // const [GroupsList, setgroupList] = useState([1, 2]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState(null);
  const history = useHistory();
  const [showFilter, setShowFilter] = useState(false);
  const [pageCount, setpageCount] = useState(0);
  const [applied, setApplied] = useState(false);
  const [priority, setPriority] = useState([]);
  const [data, setData] = useState();
  const [assigned, setAssigned] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const [status, setStatus] = useState([]);
  const [elements, setElements] = useState([]);
  const [validate,setValidate] = useState(false);
  const [groupsName, setGroupsName] = useState(location?.state?.Groups);
  const [allData, setAllData] = useState({
    assignedBy: [],
    assignedTo: [],
    limit : 10,
    page : 1,
    priority: [],
    sort : 'DESC',
    sortBy : 'created',
    spaceElementId : [],
    spaceId : location?.state?.groupId ? location?.state?.groupId : null,
    status: [],
    title : '',
  });
  const [tempTitle, setTempTitle] = useState('');
  const [settingsState, setSettingsState] = useState({
    membersModal : false,
    editModal : false,
    deleteModal : false,
  });
  const [editSpace, setEditSpace] = useState(location?.state?.Groups);
  const [label, setLabel] = useState({ raiseBy: "" });
  const [enabled, setEnabled] = useState(false);
  const runOnceRef = useRef(true)
  const limit = 10;
  const url2 = constants.apiBaseUrl2;

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setAllData(prev=>({
        ...prev,
        page : prev.page+1
      }))
    } else if (status === "prev") {
      setAllData(prev=>({
        ...prev,
        page : prev.page-1
      }))
    }
  };
  const handleTask = (id, name) => {
    history.push({
      pathname: "/taskTracker/Spaces/SpaceDetails/CreateTask",
      state: {
        spaceId: location?.state?.groupId,
        spaceElementId: id,
        task: {spaceElement: name}
      },
    });
  };
  const getaskAssignedByFilterData = () => {
    axios
      .get(url2 + `/assignedByByGroupId?groupId=${location?.state?.groupId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setData(res.data.responseData);
      })
      .catch(() => { });
  };
  // const viewGroupDetailsByIdData = () => {
  //   axios
  //     .get(url2 + `/viewGroupDetailsById?groupId=${location?.state?.groupId}`, {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         Authorization: ` ${cookie.load("token")}`,
  //       },
  //     })
  //     .then((res) => {
  //       setMembers(res.data.responseData.groupMembers);
  //     })
  //     .catch(() => { });
  // };
  const { data: members } = useQuery(
    ["getActiveMembersBySpace"],
    ()=>getActiveMembersBySpace(allData?.spaceId),
    {
      refetchOnWindowFocus : false,
      enabled: enabled,
    }
  );
  const { data: spaceElements } = useQuery(
    ["getAllSpaceElements"],
    getAllSpaceElements,{
      refetchOnWindowFocus : false,
    }
  );
  const handleTaskDetail = (arg) => {
    history.push({
      pathname: "/taskTracker/Spaces/SpaceDetails/ViewTask",
      state: {
        task: arg,
        taskId: arg.taskId,
        type: arg.spaceElement ? arg.spaceElement : "Task",
        spaceElementId : (location?.state?.task?.spaceElement=== "Task" || location?.state?.task?.spaceElement===null) ? 1 : location?.state?.task?.spaceElement=== "Guideline" ? 2 : 3,
      },
    });
  };
  const handleBack = () => {
    history.goBack();
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleSorting = () => {
    setAllData(prev=>({
      ...prev,
      sort: prev.sort === "ASC" ? "DESC" : "ASC"
    }));
  };
  const editSpaceMutation = useMutation(addSpace, {
    onSuccess: (res)=>{
      if(res?.statusCode){
        res?.responseData?.message[0]==='G' ? ToastifyError("Group Name Already Taken!") : ToastifyError(res?.responseData?.message);
      }
      else{
        Toastify(res?.responseData);
        setGroupsName(editSpace);
      }
    },
    onError: ()=>{
      Toastify("Something Went Wrong!");
    }
  });
  const closeSpaceMutation = useMutation(closeSpace, {
    onSuccess : (res)=>{
      Toastify(res);
      history.goBack();
    },
    onError : ()=>{
      ToastifyError("Something Went Wrong!");
    }
  })

  // const getGroupDashboardList = () => {
  //   setIsLoading(true);
  //   axios
  //     .post(
  //       url2 +
  //       `/taskListByGroupId?groupId=${location?.state?.groupId
  //       }&limit=${10}&page=${currentPage}`,
  //       allData // Include allData in the request body
  //     )
  //     .then((res) => {
  //       setgroupList(res.data.responseData);
  //       setIsLoading(false);
  //       setpageCount(res.data.responseData.totalTasks / 10);
  //       settotalIssue(res.data.responseData.totalTasks);
  //     })
  //     .catch(() => {  
  //       setIsLoading(false);
  //     });
  // };
  const {data: groupsList, isLoading } = useQuery(
    ["getSpaceDetailsById", allData],
    ()=>getSpaceDetailsById(allData),
    {
      enabled: allData?.title?.length===0 || allData?.title?.length>2,
      refetchOnWindowFocus : false,
      keepPreviousData : true,
    }
  );
  useEffect(()=>{
    if(groupsList?.totalTasks%10===0){
      setpageCount(parseInt(groupsList?.totalTasks/10));
    } else {
      setpageCount(parseInt(groupsList?.totalTasks/10 + 1));
    }
    
  }, [groupsList])
  useEffect(() => {
    setAllData({
      ...allData,
      creationDateOrder:
        sortOrder == "ASC" ? true : sortOrder == "DESC" ? false : null,
    });
  }, [sortOrder]);

  
  useEffect(() => {
    // getGroupDashboardList();
    getaskAssignedByFilterData();
  }, [currentPage, allData]);

  const closeFilter = () => {
    setAllData((preValue) => {
      return preValue
        ? {
          ...preValue,
          assignedBy: [],
          priority: [],
          status: [],
          assignedTo: [],
          spaceElementId: [],
        }
        : null;
    });

    setShowFilter(false);
    setApplied(false);
  };
  const handleApplied = () => {
    if (
      allData?.assignedBy.length !== 0 ||
      allData?.assignedTo.length !== 0 ||
      allData?.priority.length !== 0 ||
      allData?.status.length !== 0 ||
      allData?.spaceElementId.length!==0
    ) {
      setApplied(true);
    } else {
      setApplied(false);
    }
    setShowFilter(false);

  };
  useEffect(() => {
    handleApplied();
  }, [allData]);

  useEffect(() => {
    if(tempTitle?.length===0 || tempTitle?.length>2){
      setAllData((prev)=>({...prev, title: tempTitle, page: 1}));
    }
  },[tempTitle]);

  return (
    <>
      <div className={style.mainSection}>
        <SideBar />
        <div className={style.rightSection}>
          <div className={style.main}>
            <div className={style.header}>
              <div className={style.alignGroupIssue}>
                <p style={{maxWidth: "250px"}}>
                  <span style={{
                    display: "inline-block",
                    width: "100%", 
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                  }
                }>{groupsName}</span></p>
                {groupsList?.totalTasks>0 && <span className={style.circle}>{groupsList?.totalTasks}</span>}
                <span style={{marginLeft:"10px",cursor:"pointer", transform: "translateY(-2px)"}}>
                  <Dropdown>
                    <Dropdown.Toggle className="custom-dropdown-toggle">
                      <IoMdSettings style={{ fontSize: "30px",color: "#65a15e"}} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="custom-dropdown-menu">
                      <Dropdown.Item style={{borderTopRightRadius: "0.375rem", borderTopLeftRadius: "0.375rem", borderBottomLeftRadius: groupsList?.admin ? '0rem' : '0.375rem', borderBottomRightRadius: groupsList?.admin ? '0rem' : '0.375rem'}} className={`custom-dropdown-menu-items ${groupsList?.admin ? 'custom-dropdown-menu-items-border' : 'custom-dropdown-menu-items-border-conditional'}`} onClick={()=>history.push({pathname: `${location.pathname}/ManageMembers`, state:{spaceId: location?.state?.groupId, isAdmin: groupsList?.admin, isActive: location?.state?.isActive}})}>Manage Members</Dropdown.Item>
                      {groupsList?.admin && location?.state?.isActive && <Dropdown.Item className="custom-dropdown-menu-items custom-dropdown-menu-items-border" onClick={()=>{setEditSpace(groupsName); setSettingsState((prev)=>({...prev, editModal: true}))}}>Edit Space</Dropdown.Item>}
                      {groupsList?.admin && location?.state?.isActive && <Dropdown.Item style={{borderBottomRightRadius: "0.375rem", borderBottomLeftRadius: "0.375rem"}} className="custom-dropdown-menu-items" onClick={()=>setSettingsState((prev)=>({...prev, deleteModal: true}))}>Close Space</Dropdown.Item>}
                    </Dropdown.Menu>
                  </Dropdown>
                  
                </span>
              </div>
              {/* <div className={style.checkboxContainer}>
              <input className={style.showAllCheckBox} id="showAll" type="checkbox" style={{visibility: "visible", accentColor:"#65A15E"}}
          checked={showAll}
          onChange={handleShowAll} />
                <span className={style.label}>Show All</span>
              </div> */}
              <button className={style.filter} onClick={handleFilter}>
                {applied && (
                  <img
                    className={style.appliedcheck}
                    src={Iconionicmdcheckbox}
                  />
                )}
                <img src={filterimg} />
                <span className={style.filterColor}>Filter</span>
              </button>
              {showFilter && <img className={style.polygon} src={Polygon} />}
              <div className={style.s1} style={{marginTop: "0"}}>
              {/* 1 */}
              {!allData?.title ? (
                <span className={style.cp_serchIcon}>
                  <img src={searchIcon} alt={"searchIcon"} />
                </span>
              ) : (
                <span
                  className={style.cp_serchIcon}
                  onClick={() => {
                    // setAllData(prev => ({...prev, title: "", page: 1}))
                    setTempTitle('');
                  }}
                >
                  <AiOutlineClose />
                </span>
              )}
              <input
                className={style.coreSkillInput}
                placeholder="Search Title"
                value={tempTitle}
                onChange={(e) => {
                  // setCurrentPage(1);
                  // setAllData(prev => ({...prev, title: e.target.value, page: 1}))
                  setTempTitle(e.target.value);
                }}
              />
            </div>
              {location?.state?.isActive && <Dropdown>
                <Dropdown.Toggle className="custom-dropdown-toggle">
                  <button className={style.createTask2}>Add</button>
                </Dropdown.Toggle>
                <Dropdown.Menu className="custom-dropdown-menu2">
                  {spaceElements?.map((val, index)=>{
                    return <Dropdown.Item
                        className={`custom-dropdown-menu-items ${!(index===spaceElements?.length-1) && 'custom-dropdown-menu-items-border'}`}
                        style={{borderTopRightRadius: `${index===0 && '0.375rem'}`, borderTopLeftRadius: `${index===0 && '0.375rem'}`, borderBottomLeftRadius: `${index===spaceElements?.length-1 && '0.375rem'}`, borderBottomRightRadius: `${index===spaceElements?.length-1 && '0.375rem'}`}}
                        onClick={()=>handleTask(val?.id, val?.name)}
                      >{val?.name}</Dropdown.Item>
                  })}
                </Dropdown.Menu>
              </Dropdown>}
            </div>
            {/* <div className={style.toggleButtonContainer}>
              <div className={style.toggleButtons}>
                <span className={style.toggleButtonItem} style={{borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px"}}>All</span>
                <span className={style.toggleButtonItem}>Tasks</span>
                <span className={style.toggleButtonItem}>Guideliness</span>
                <span className={style.toggleButtonItem} style={{borderTopRightRadius: "20px", borderBottomRightRadius: "20px"}}>Notes</span>
              </div>
            </div> */}
            <div className={style.IssueList}>
              {(isLoading && allData?.title?.length>1) ? (
                <div>
                  <img src={loaderImg} alt="loader" className={style.loader} />
                  <p className={style.loaderPara}>Loading...</p>
                </div>
              ) : (
                <>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr className={style.th} style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                        <th style={{ width: "4rem",paddingLeft: '.2rem' }}
                        >Task<br /> ID</th>
                        <th
                          style={{ width: "6rem" }}
                          onClick={handleSorting}
                        >
                          Creation <br/>
                          Date  
                          {/* <img
                            alt=""
                            style={{
                              cursor: "pointer",
                              marginLeft: "3px",
                              marginBottom: "2px"
                            }}
                            src={
                              allData?.sort === "ASC"
                                ? sortUparrowImg
                                : sortDownarrowImg
                            }
                            onClick={handleSorting}
                          /> */}
                          {allData?.sort === "ASC" ? <TiArrowSortedUp/> : <TiArrowSortedDown />}
                        </th>
                        <th style={{ width: "1rem" }}></th>
                        <th className={style.tableContent} style={{ width: "16rem" }}>Task Title</th>
                        <th className={style.tableContent} style={{ width: "10rem" }}>Assigned To</th>
                        <th className={style.tableContent} style={{ width: "10rem" }}>Assigned By</th>
                        <th className={style.tableContent} style={{ width: "5rem" }}>Status</th>

                        <th className={style.tableContent} style={{ width: "5rem" }}>Priority</th>
                        <th className={style.tableContent} style={{ width: "6rem" }}>
                          Planned <br />
                          End Date
                        </th>
                      </tr>
                    </thead>

                    {groupsList?.tasks?.length === 0 ? (
                      <tbody>
                        <tr style={{ textAlign: "center" }}>
                          <td colSpan="8">
                            <p className={style.noData}>No Data Available</p>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody
                        style={{ height: "20px" }}
                      >
                        {groupsList?.tasks?.map((task) => (
                          <tr key={task.taskId} className={style.td} style={{display: "flex", alignItems: "center", height : "44px"}}>
                            <td
                              style={{ width : "4rem",paddingLeft: '.2rem' }}
                            >
                              {task.taskId}
                            </td>
                            <td
                              className={style.tableContent}
                              style={{ width: "6rem" }}
                            >
                              {task?.created ? Moment().year() === Moment(task?.created).year() ? Moment(task?.created).format("DD MMM") : Moment(task?.created).format("DD MMM YYYY") : '-'}
                            </td>

                            {/* {task.title.length >= 40 ? (
                              <td
                                className={[
                                  style.tableContent,
                                  style.taskTitle,
                                ].join(" ")}
                                onClick={() => handleTaskDetail(task)}
                                style={{width:"9.3rem"}}
                              >
                                <BlackTooltip title={task?.title}>
                                  <p className={style.tooltips}>
                                    {task?.title}...
                                  </p>
                                </BlackTooltip>
                              </td>
                            ) : (
                              <td
                                className={[
                                  style.tableContent,
                                  style.taskTitle,
                                ].join(" ")}
                                onClick={() => handleTaskDetail(task)}
                              >
                                {task.title}
                              </td>
                            )} */}
                            <td style={{ width: "21px", textAlign : "center" }}>
                              {/* <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: "2px 6px",
                                  fontSize: "12px",
                                  fontWeight: "800",
                                  alignItems: "center",
                                  height: "20px",
                                  width: "20px",
                                  border: "1px solid #65a15e",
                                  color: "#65a15e",
                                  borderRadius: "50%"
                                }}>
                                  {task?.spaceElement==="Guideline" ? 'G' : task?.spaceElement==="Note" ? 'N' : task?.recurringTask ? 'R' : 'T'}
                              </span> */}
                              {task.taskWatcher ? (<span
                                style={{
                                  display: "inline-block",
                                  fontSize: "10px",
                                  color: "rgb(168, 50, 50)",
                                  fontWeight: "800",
                                  border: "1px solid rgb(168, 50, 50)",
                                  borderRadius: "50%",
                                  width : "21px",
                                  padding: "2px 4.5px",
                                }}
                              >
                                W
                              </span>) : null}
                              {task?.spaceElement==="Note" && <CgNotes />}
                            </td>
                            <td
                              className={style.tabletaskTitle}
                              onClick={() => handleTaskDetail(task)}
                              style={{
                                paddingRight : "4px",
                                width: "16rem",
                                fontSize: "15px",
                                fontWeight: "500",
                                textDecoration: "underline",
                                cursor: "pointer",
                                color: "rgb(101, 161, 94)",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              title={task?.title}
                            >
                              <div style={{width : "16rem", overflow: "hidden", fontWeight : "700", textOverflow : "ellipsis", whiteSpace : "nowrap"}}>{task.title}</div>
                            </td>
                            <td className={style.tableContent} style={{ width : "10rem" }}>{task.name}</td>
                            <td className={style.tableContent} style={{ width: "10rem" }}>
                              {task.assignedBy}
                            </td>
                            <td
                              className={
                                task.status === "Assigned"
                                  ? style.asigned_color
                                  : task.status === "Done"
                                    ? style.done_color
                                    : task.status === "Onhold"
                                      ? style.onhold_color
                                      : style.Closed_color
                              }
                              style={{ width: "5rem" }}
                            >
                              {task.status === "Closed"
                                ? "Closed"
                                : task.status === "Onhold"
                                  ? "On Hold"
                                  : task.status}
                            </td>
                            <td className={style.tableContent} style={{ width: "5rem" }}>
                              {task.priority}
                            </td>

                            <td className={style.tableContent} style={{ width : "6rem", textAlign: task?.planedEndDate ? "left" : "center", paddingRight : task?.planedEndDate ? "0" : "2rem"}}>
                              {task?.planedEndDate ? (Moment().year() === Moment(task?.planedEndDate).year() ? Moment(task?.planedEndDate).format("DD MMM") : Moment(task?.planedEndDate).format("DD MMM YYYY")) : '-'}
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </>
              )}
            </div>
            <div className={style.paginationalign}>
              <button className={style.goBack} onClick={() => handleBack()}>
                Back
              </button>
              {groupsList?.totalTasks > limit && pageCount > 0 && (
                <div
                  className={style.paginationmobileContainer}
                  style={{ marginTop: "20px" }}
                >
                  <Pagination
                    pagesCount={pageCount}
                    pageSize={limit}
                    onPageChange={handlePageChange}
                    currentPage={allData?.page}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showFilter && (
        location.pathname.endsWith("/SpaceDetails") ? 
        <SpaceFilter
          closeFilter={closeFilter}
          filterData={allData}
          setFilterData={setAllData}
          groupId={location?.state?.groupId}
          isActive={location?.state?.isActive}
          setShowFilter={setShowFilter}
          setApplied={setApplied}
          handleApplied={handleApplied}

        /> : 
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          userLists={userLists}
          filterData={allData}
          setassigned={setAssigned}
          setpriority={setPriority}
          setstatus={setStatus}
          setElements={setElements}
          data={data}
          groupId={location?.state?.groupId}
        />
      )}
      <Modal
        open={settingsState?.membersModal}
        onClose={()=>{setSettingsState((prev)=>({...prev, membersModal: false})); setEnabled(false)}}
        center
      >
        <>
          <div className={style.memberModalHeading}>Manage Members</div>
          <div className={style.addMembers}>Add Members</div>
          <Select
            styles={customStylesForSpaces}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            isMulti
          ></Select>
          <div className={style.members}>Members</div>
            {members?.map(memberList=>{
              return <div className={style.existingMembersList}>
                <div className={style.memberName}>{memberList?.memberName}</div>
                <div className={style.memberEmail} style={{textOverflow: "ellipsis"}}>{memberList?.memberEmail}</div>
                <div style={{display: "flex", alignItems: "center"}}><RiDeleteBin6Fill /></div>
              </div>
            }
          )}
          <div className={style.notify}>
            <input type="checkbox" className={style.showAllCheckBox} style={{visibility: "visible", accentColor:"#65A15E", transform: "translateY(2px)", margin: "0px 4px"}}/>
            <label>Notify People</label>
          </div>
          <div style={{width : "100%", display: "flex", justifyContent: "center"}}><button className={style.doneButton}>Done</button></div>
        </>
      </Modal>
      <Modal
        open={settingsState?.editModal}
        onClose={()=>{setSettingsState((prev)=>({...prev, editModal: false})); setValidate(false)}}
        center
      >
        <>
          <div className={style.memberModalHeading} style={{width: "18rem", marginBottom: "2rem"}}>Edit Space</div>
          <div className={style.addMembers} style={{marginBottom: "5px"}}>Space Title <span style={{color: 'red'}}>*</span></div>
          <input type="text" style={{width: "100%", padding: "0px 2px"}} value={editSpace} onChange={(e)=>{setValidate(false); setEditSpace(e.target.value)}}/>
          <p className={validate ? style.errorText : style.errorTextHidden}>Please Enter Space Title</p>
          <div 
            style={{width : "100%",marginTop: "4rem", display: "flex", justifyContent: "center"}}
          >
            <button className={style.doneButton}
              onClick={()=>{
                if(editSpace?.trim()?.length>0){
                  editSpaceMutation.mutate({spaceName : editSpace, spaceId : allData?.spaceId});
                  setSettingsState((prev)=>({...prev, editModal: false}));
                }
                else {
                  setValidate(true);
                }
              }}
            >Save
            </button>
          </div>
        </>
      </Modal>
      <ConfirmPopup open={settingsState?.deleteModal}>
        <div className={style.modal_group_member}>
          <div className={style.memberModalHeading} style={{width: "23rem", marginBottom: "1rem"}}>Close Space</div>
          <div style={{width: "23rem", marginBottom: "1rem", display: "flex", justifyContent: "center"}}>Do you want to close this space?</div>
          <div className={style.deleteModalBtns}>
            <button className={style.cancelButton} onClick={()=>setSettingsState((prev)=>({...prev, deleteModal: false}))}>No</button>
            <button className={style.doneButton} onClick={()=>{if(!closeSpaceMutation.isLoading) closeSpaceMutation.mutate(location?.state?.groupId)}}>Yes</button>
          </div>
        </div>
      </ConfirmPopup>
    </>
  );
};

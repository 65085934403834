import React, { useState, useEffect } from "react";
import style from "./ViewTaskComponent.module.css";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import { useMutation, useQuery } from "react-query";
import { addTaskComment, assignedTo, closeRecurringTask, deleteRecurringTask, deleteTask, deleteTaskComment, editTask, getRecurringTaskDetailsByTaskId, getTaskCommentHistory, getTaskDetailsByTaskId } from "../../taskTrackerAPIs/taskTrackerAPI";
import loaderImg from "../../../../assets/loader-green2.gif";
import Moment from "moment";
import { notifyError, notifySuccess } from "../../../atsDashboard/utils/notify";
import Modal from "react-responsive-modal";
import { employeeId, taskTrackerAccessCode } from "../../../../Utils/constants";
import { TiPencil } from "react-icons/ti";
import { MdDelete } from "react-icons/md";
import rubbishBinImg2 from "../../../../assets/rubbish-bin (2).png";
import pencil from "../../../../assets/pencil-edit-button (2).png";
import Pagination from "../../commonComponent/Pagination";
import cookie from "react-cookies";
import ConfirmPopup from "../../taskAssignedByMe/component/ConfirmPopup";
import { EmailTooltip } from "../../../atsDashboard/customTooltip/CustomTooltip";

const ViewTaskComponent = () => {
  const { checkValidateAccess } = useValidationAccess();
  const location = useLocation();
  const history = useHistory();
  const [doneReviewNote, setDoneReviewNote] = useState("");
  const [openDoneRevNote, setOpenDoneRevNote] = useState(false);
  const [status, setStatus] = useState("");
  const [closeNote, setCloseNote] = useState("");
  const [openCloseNote, setOpenCloseNote] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [tempComment, setTempComment] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [taskCommentId, setTaskCommentId] = useState(null);
  const [confirmCommentModal, setConfirmCommentModal] = useState(false);
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [closeRecModal, setCloseRecModal] = useState(false);
  const profile = cookie.load("profile");

  const { data:taskData, isLoading, refetch } = useQuery("getTaskDetialsByTaskId",
     () => {
        if(location.state.recurringTask) return getRecurringTaskDetailsByTaskId(location.state.taskId);
        else return getTaskDetailsByTaskId(location.state.taskId)
     },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );
  
  const { data:commentHistory, refetch: refetchComments } = useQuery("getTaskCommentByTaskId",
    () => getTaskCommentHistory(location.state.taskId),
    {
      refetchOnWindowFocus : false,
    }
  );

  const addCommentMutation = useMutation(addTaskComment, {
    onSuccess: (res) => {
      if (res.statusCode) {
        notifyError("Something went wrong!");
      } else {
        if (status === "Closed") {
          setCloseNote("");
          ChangeStatus("Closed");
          setStatus(null);
        }
        else if (status === "Done") {
          setDoneReviewNote("");
          ChangeStatus("Done");
          setStatus(null);
        }
        else{
          notifySuccess("Comment Added Successfully");
          setTempComment("");
          refetchComments();
        }
      }
      
    },
    onError : ()=> {
      notifyError("Something went wrong!");
    }
  });

  const editTaskMutation = useMutation(editTask, {
    onSuccess: (res) => {
      if (res.taskEdited) {
        setOpenDoneRevNote(false);
        setOpenCloseNote(false);
        refetch();
      } else {
        notifyError("Something went wrong!");
        
      }
    },
    onError : ()=> {
      notifyError("Something went wrong!");
    }
  });

  const deleteTaskMutation = useMutation(deleteTask, {
    onSuccess: (res) => {
      if (res.statusCode!==0) {
        notifyError("Something went wrong!");
      } else {
        notifySuccess(`${location.state.type ?? "Task"} Deleted Successfully`);
        history.goBack();
      }
    },
    onError : ()=> {
      notifyError("Something went wrong!");
      setConfirmModal(false);
    }
  });
  
  const deleteTaskCommentMutation = useMutation(deleteTaskComment, {
    onSuccess: (res) => {
      if (res.statusCode!==0) {
        notifyError("Something went wrong!");
      } else {
        notifySuccess("Comment Deleted Successfully");
      }
      setConfirmCommentModal(false);
      refetchComments();
    },
    onError : ()=> {
      notifyError("Something went wrong!");
    }
  });

  const closeRecurringTaskMutation = useMutation(closeRecurringTask, {
    onSuccess: ()=>{
      notifySuccess("Task Closed Successfully");
      setCloseRecModal(false);
      refetch();
    },
    onError : ()=> {
      notifyError("Something went wrong!");
    }
  });

  const deleteRecurringTaskMutation = useMutation(deleteRecurringTask, {
    onSuccess: ()=>{
      notifySuccess("Task Deleted Successfully");
      history.goBack();
    },
    onError : ()=> {
      notifyError("Something went wrong!");
    }
  });

  function descriptChanges(description) {
    if (!description) return "";

    // Updated regex pattern to match "http", "https" and "www" URLs
    const urlPattern = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;

    // Split the description by newline characters
    const newlineSplit = description.split('\n').map((line, index) => {
      // Split the line by URLs and replace URLs with anchor tags
      const parts = line.split(urlPattern).map((part, subIndex) => {
        if (urlPattern.test(part)) {
          const url = part.startsWith('www') ? `http://${part}` : part;
          return (
            <a href={url} key={`${index}-${subIndex}`} target="_blank" rel="noopener noreferrer" className={style.blueLink}>
              {part}
            </a>
          );
        }
        return part;
      });

      return (
        <React.Fragment key={index}>
          {parts}
          {index < description.split('\n').length - 1 && <br />}
        </React.Fragment>
      );
    });

    return newlineSplit;
  }

  const ChangeStatus = (status) => {
    const data = {
      taskId: taskData?.taskId,
      title: taskData?.title,
      plannedEndDate: Moment(taskData?.plannedEndDate).format("YYYY-MM-DD hh:mm"),
      assignedTo: taskData?.assignedTo,
      priority: taskData?.priority,
      status: status,
      localDateTime: Moment(taskData?.plannedEndDate).format("YYYY-MM-DD hh:mm"),
      groupId: taskData?.groupId,
    };
    editTaskMutation.mutate(data);
  }
  const AddComments = (comment, status)=>{
    if (!comment) {
      setShowErr(true);
      return;
    }
    const data = {
      localDateTime: Moment(Moment().toDate()).format("YYYY-MM-DD hh:mm"),
      closingDate: Moment(Moment().toDate()).format(),
      taskId: taskData?.taskId,
      comment: comment?.trim(),
    }
    setStatus(status);
    addCommentMutation.mutate(data);
  }
  const direct2Edit = (taskData)=>{
    if(location.state.recurringTask){
      taskData.spaceElement = location.state?.type;
      history.push({
        pathname: `${location.pathname}/EditRecurringTask`,
        state: {
          task : taskData,
          spaceId : taskData?.groupId,
        }
      });
    } else {
      history.push({
        pathname: `${location.pathname}/EditTask`,
        state: {
          taskId: taskData?.taskId,
          task : {
            spaceElement: location.state?.type,
          },
          spaceId : taskData?.groupId,
        }
      });
    }
    
  }
  const redirect2History = ()=>{
    if(location.state.recurringTask){
      history.push({
        pathname: `${location.pathname}/recurringTaskHistory`,
        state: {
          task: taskData,
        },
      });
    } else {
      history.push({
        pathname: `${location.pathname}/taskHistory`,
        state: {
          task: taskData,
          type: location?.state?.type ?? "Task",
        },
      });
    } 
  }
  const handleTaskEdit = (comment) => {
    history.push({
      pathname: `${location.pathname}/EditComments`,
      state: {
        comment: comment,
        newData: taskData,
        activePage: 2,
      },
    });
  };
  function handleConfirmCommentPopupOpen(commentId) {
    setTaskCommentId(commentId);
    setConfirmCommentModal(true);
  }
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  useEffect(()=>{
    if(commentHistory?.length > 0){
      const pageCount = Math.ceil(commentHistory.length / 3);
      setpageCount(pageCount);
    }
  },[commentHistory]);

  return(
    <>
      <div className={style.taskAssigned}>
        {isLoading ? 
          <div>
            <img src={loaderImg} alt="loader" className={style.loader} />
            <p className={style.loaderPara}>Loading...</p>
          </div> : 
          <div style={{ padding: "2px 15px" }}>
            <div className={style.container} style={{borderBottom: location.state.recurringTask ? 'none' : '1px solid #00000029'}}>
              <div className={style.subcontainer}>
                <div
                  className={style.subcontainerinner}
                  style={{ width: "100%" }}
                >
                  <h5>{location.state?.type ?? "Task"} Title</h5>
                  <p className={style.titlee}>
                    {taskData?.title}
                  </p>
                </div>
                <div
                  className={style.subcontainerinner}
                  style={{ width: "100%" }}
                >
                  <h5>{location.state?.type ?? "Task"} Description</h5>
                  <p>
                    {descriptChanges(taskData?.description)}
                  </p>
                </div>
                <div
                  className={style.subcontainerinner}
                  style={{ width: "25%" }}
                >
                  <h5>Creation Date</h5>
                  <p>{taskData?.creationDate ? Moment().year() === Moment(taskData?.creationDate).year() ? Moment(taskData?.creationDate).format("DD MMM (ddd)") : Moment(taskData?.creationDate).format("DD MMM YYYY") : '-'}</p>
                </div>
                {taskData?.plannedEndDate &&
                 location.pathname.slice(12,27) !== "/RecurringTasks" &&
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Planned End Date</h5>
                    <p>
                    {taskData?.plannedEndDate ? Moment().year() === Moment(taskData?.plannedEndDate).year() ? Moment(taskData?.plannedEndDate).format("DD MMM (ddd)") : Moment(taskData?.plannedEndDate).format("DD MMM YYYY") : '-'}
                    </p>
                  </div>
                }
                {location.pathname.slice(12,23) === "/ClosedByMe" && <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Closed Date</h5>
                    <p>
                      {taskData?.closingDate ? Moment().year() === Moment(taskData?.closingDate).year() ? Moment(taskData?.closingDate).format("DD MMM (ddd)") : Moment(taskData?.closingDate).format("DD MMM YYYY") : '-'}
                    </p>
                  </div>
                }
                {((location.pathname.slice(12,19) === "/Spaces" && location.state.type !=="Note") || 
                  location.pathname.slice(12,23) === "/ClosedByMe" ||
                  location.pathname.slice(12,29) === "/TaskAssignedByMe" ||
                  location.pathname.slice(12,23) === "/SearchTask" ||
                  (location.pathname.slice(12,27) === "/RecurringTasks" &&
                  location.state.assignedByMe) ||
                  taskData?.taskWatchers?.find(val=>val.id===employeeId)) &&
                    <div
                      className={style.subcontainerinner}
                      style={{ width: "25%" }}
                    >
                      <h5>
                        Assigned To
                      </h5>
                      <p>
                        {taskData?.assignedToName}
                      </p>
                    </div>
                }
                {(location.pathname.slice(12,20) === "/MyTasks" ||
                  (location.pathname.slice(12,27) === "/RecurringTasks" && (!location.state.assignedByMe)) ||
                  location.pathname.slice(12,23) === "/ClosedByMe" ||
                  location.pathname.slice(12,23) === "/SearchTask" ||
                  location.pathname.slice(12,19) === "/Spaces" && location.state.type !== "Note") &&
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>
                      Assigned By
                    </h5>
                    <p>
                      {taskData?.assignedByName}
                    </p>
                  </div>
                }
                {location.pathname.slice(12,19) === "/Spaces" && location.state.type === "Note" &&
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>
                      Created By
                    </h5>
                    <p>
                      {taskData?.assignedByName}
                    </p>
                  </div>
                }
                {((location.pathname.slice(12,19) === "/Spaces" && location.state.type !== "Note" && location.state.type !== "Guideline") ||
                  location.pathname.slice(12,23) === "/ClosedByMe" ||
                  location.pathname.slice(12,20) === "/MyTasks" ||
                  location.pathname.slice(12,23) === "/SearchTask" ||
                  location.pathname.slice(12,29) === "/TaskAssignedByMe") &&
                    <div
                      className={style.subcontainerinner}
                      style={{ width: "25%" }}
                    >
                      <h5>
                        Priority
                      </h5>
                      <p>
                        {taskData?.priority}
                      </p>
                    </div>
                }
                {(location.pathname.slice(12,20) === "/MyTasks" ||
                  location.pathname.slice(12,29) === "/TaskAssignedByMe" ||
                  location.pathname.slice(12,23) === "/SearchTask" ||
                  (location.pathname.slice(12,19) === "/Spaces" && location.state.type === "Task")) &&
                    <div
                      className={style.subcontainerinner}
                      style={{ width: "25%" }}
                    >
                      <h5>
                        Status
                      </h5>
                      <p
                        className={
                          taskData?.status === "Assigned"
                            ? style.asigned_color
                            : taskData?.status === "Done"
                              ? style.done_color
                              : taskData?.status === "Onhold"
                                ? style.onhold_color
                                : style.Closed_color
                        }
                      >
                        {taskData?.status}
                      </p>
                    </div>
                }
                {location.pathname.slice(12,27) === "/RecurringTasks" &&
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>
                      Frequency
                    </h5>
                    <p>
                      {taskData?.frequency} {taskData?.frequency !== 'Daily' ? '(' + taskData?.frequencyValue + ')' : ''}
                    </p>
                  </div>
                }
                {taskData?.taskWatchers?.length>0 &&
                  <div
                  className={style.subcontainerinner}
                  style={{ width: "25%" }}
                >
                  <h5>
                    Watcher
                  </h5>
                  <p>
                    <EmailTooltip
                      value={taskData?.taskWatchers?.map(person => person.name).join(", ")}
                      limit={30}
                      index={"watcherName"}
                    />
                  </p>
                </div>

                }
                {taskData?.groupName &&
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>
                      Space
                    </h5>
                    <p>
                      {taskData?.groupName}
                    </p>
                  </div>
                }
              </div>
            </div>
            {(location.state.recurringTask && !location.state.assignedByMe) ? <></> : <div className={style.rr} style={{ textAlign: "right" }}>
              {
                checkValidateAccess(
                  taskTrackerAccessCode?.done_Review_MyTask
                ) && taskData?.doneTaskButtonVisibility && !location.state.recurringTask && (
                  <button
                    disabled={taskData?.status === "Done" || taskData?.status === "Closed"}
                    onClick={() => {
                      setOpenDoneRevNote(true);
                      setDoneReviewNote("");
                    }}
                    className={(taskData?.status === "Done" || taskData?.status === "Closed") ? style.ttDoneDisabledBtn : style.TaskDone}
                  >
                    Done
                  </button>
                )}
              {
                checkValidateAccess(taskTrackerAccessCode?.close_Task_TaskAssigned) && taskData?.closeButtonVisibility && (
                  <button
                    disabled={taskData?.status === "Closed"}
                    onClick={() => {if(location.state.recurringTask) setCloseRecModal(true); else setOpenCloseNote(true)}}
                    className={taskData?.status === "Closed" ? style.ttCloseddisabledbtn : style.closed}
                  >
                    Close Task
                  </button>
                )
              }
              {checkValidateAccess(
                taskTrackerAccessCode?.view_Task_History_TaskAssigned
              ) && (
                  <button
                    onClick={() => redirect2History()}
                    className={style.taskHistory}
                  >
                    {location.state.recurringTask ? "View Task Logs" : `View ${location.state.type ?? "Task"} History`}
                  </button>
                )}
              {checkValidateAccess(
                  taskTrackerAccessCode?.delete_TaskAssigned
                ) && taskData?.deleteTaskButtonVisibility && (
                  <button onClick={()=>setConfirmModal(true)} className={style.delete}>
                    Delete
                  </button>
              )}
              
              {checkValidateAccess(
                  taskTrackerAccessCode?.edit_TaskAssigned
                ) && taskData?.editTaskButtonVisibility && (
                  <button
                    onClick={() => direct2Edit(taskData)}
                    className={style.edit}
                  >
                    Edit
                  </button>
                )}
            </div>}
        {taskData?.commentButtonVisibility && 
          <div
            style={{ marginTop: "10px", marginBottom: "10px" }}
            id="add_commentid"
          >
            <h5 className={style.comment}>Add Comment</h5> <br />
            <div className={style["text-area-container"]}>
              <textarea
                onChange={(e) => {setTempComment(e.target.value); setShowErr(false)}}
                name="textArea"
                rows={3}
                value={tempComment}
                // ref={commentText}
              // className={style.scrollAdd}
              />
            </div>
            {showErr && (
              <div className={style.req_msg}>
                <p>* Task comment can not empty.</p>
              </div>
            )}
            {checkValidateAccess(
              taskTrackerAccessCode?.add_Comment_TaskAssigned
            ) && (
              <button onClick={()=>AddComments(tempComment)} className={style.addComment} disabled={addCommentMutation.isLoading}>
                Add Comment
              </button>
            )}
          </div>
        }

        {taskData?.commentButtonVisibility &&
          <div className={style.tabl}>
              <span
                style={{ fontWeight: "bold", fontSize: "18px" }}
                className="comment2"
              >
                Comment History
              </span>
              {commentHistory?.length > 0 ? (
                <div>
                  {/* <TableScrollbar rows={2}> */}
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: '6rem' }}>
                          Date
                        </th>
                        <th
                          style={{ width: '10rem' }}
                        >
                          Name
                        </th>
                        <th
                          style={{ width: '34rem' }}
                        >
                          Comment
                        </th>
                        <th
                        style={{ width: '4rem' }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    {/* <form className={style.formtable}> */}
                    <tbody>
                      {commentHistory &&
                        commentHistory.map((comment1, index1) => {
                          return (
                            <tr key={index1}>
                              <td >
                                <span
                                  style={{
                                    fontSize: "16px",
                                    color: "#65A15E",
                                    fontStyle: "italic",
                                  }}
                                >
                                  {comment1.taskModifiedDate && Moment().year() === Moment(comment1.taskModifiedDate).year() ? Moment(comment1.taskModifiedDate).format("DD MMM") : Moment(comment1.taskModifiedDate).format("DD MMM YYYY")}
                                </span>
                              </td>
                              <td
                              >
                                <span
                                  style={{
                                    color: "#65A15E",
                                    fontStyle: "italic",
                                    fontSize: "16px",
                                  }}
                                >
                                  {comment1.name}
                                </span>
                              </td>
                              <td>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    whiteSpace: "pre-line",
                                  }}
                                >
                                  {comment1.taskComment}
                                </p>
                              </td>
                              <td
                                style={{
                                }}
                              >
                                {comment1.name === profile.name ? (
                                  <div style={{minWidth: "52px"}}>
                                    <TiPencil
                                      style={{ cursor: "pointer" }}
                                      size={20}
                                      onClick={() => handleTaskEdit(comment1)}
                                    />
                                    <MdDelete
                                      style={{ cursor: "pointer" }}
                                      size={20}
                                      onClick={() =>
                                        handleConfirmCommentPopupOpen(
                                          comment1.taskCommentId
                                        )
                                      }
                                    />
                                  </div>
                                ) : (
                                  <div style={{minWidth: "52px"}}>
                                    <TiPencil
                                      style={{ cursor: "pointer" }}
                                      size={20}
                                      color="grey"
                                    />
                                    <MdDelete
                                      style={{ cursor: "pointer" }}
                                      size={20}
                                      color="grey"
                                    />
                                  </div>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    {/* </form> */}
                  </table>
                  {/* </TableScrollbar> */}
                  {commentHistory.length > 0 && pageCount > 0 && (
                    <div
                      className={style.paginationmobileContainer}
                      style={{ textAlign: "right" }}
                    >
                      <div className={style.Pagin}>
                        <Pagination
                          pagesCount={pageCount}
                          pageSize={3}
                          onPageChange={handlePageChange}
                          currentPage={currentPage}
                        />
                      </div>
                    </div>
                  )}
                </div>
                ) : (
                  <div className={style.table}>No Data Available</div>
                )}
              </div>
            }
            <AddDoneRevNote
              doneReviewNote={doneReviewNote}
              setDoneReviewNote={setDoneReviewNote}
              openDoneRevNote={openDoneRevNote}
              setOpenDoneRevNote={setOpenDoneRevNote}
              addDoneRevNote={() => {if(!addCommentMutation.isLoading) AddComments(doneReviewNote, "Done")}}
              doneTask={() => {if(!editTaskMutation.isLoading) ChangeStatus("Done")}}
            />
            <AddClosingNote
              openAddCloseNote={openCloseNote}
              setOpenCloseNote={setOpenCloseNote}
              note={closeNote}
              setNote={setCloseNote}
              addCloseNote={() => {if(!addCommentMutation.isLoading) AddComments(closeNote, "Closed")}}
              closeTask={() => {if(!editTaskMutation.isLoading) ChangeStatus("Closed")}}
            />
            <ConfirmPopup open={confirmModal}>
              <div className={style.confirmPopup}>
                <h5 className={style.confirmPopupTitle}>
                  Do you want to delete?
                </h5>
                <div className={style.btnCont}>
                  <button className={style.yesBtn}
                    onClick={()=>{
                      if(location.state.recurringTask){
                        if(!deleteRecurringTaskMutation.isLoading) deleteRecurringTaskMutation.mutate(taskData?.taskId);
                      } else{
                        if(!deleteTaskMutation.isLoading) deleteTaskMutation.mutate(taskData?.taskId);
                      }
                    }}>
                    Yes
                  </button>
                  <button className={style.noBtn} onClick={()=>setConfirmModal(false)}>
                    No
                  </button>
                </div>
              </div>
            </ConfirmPopup>
            <ConfirmPopup open={confirmCommentModal}>
              <div className={style.confirmPopup}>
                <h5 className={style.confirmPopupTitle}>
                  Are you sure you want to delete?
                </h5>
                <div className={style.btnCont}>
                  <button className={style.yesBtn} onClick={()=>{if(!deleteTaskCommentMutation.isLoading) deleteTaskCommentMutation.mutate(taskCommentId)}}>
                    Yes
                  </button>
                  <button
                    className={style.noBtn}
                    onClick={()=>setConfirmCommentModal(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </ConfirmPopup>
            <ConfirmPopup open={closeRecModal}>
              <div className={style.confirmPopup}>
                <h5 className={style.confirmPopupTitle}>
                  Are you sure you want to close this task?
                </h5>
                <div className={style.btnCont}>
                  <button className={style.yesBtn} onClick={()=>{if(!closeRecurringTaskMutation.isLoading) closeRecurringTaskMutation.mutate(taskData?.taskId)}}>
                    Yes
                  </button>
                  <button
                    className={style.noBtn}
                    onClick={()=>setCloseRecModal(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </ConfirmPopup>
          </div>
        }
      </div>   
    </>
  )
};

const AddClosingNote = ({
  openAddCloseNote,
  setOpenCloseNote,
  note,
  setNote,
  addCloseNote,
  closeTask
}) => {
  const [validate, setValidate] = useState(false);
  const handleAddNote = () => {
    if (!note) {
      closeTask()
    } else {
      addCloseNote();
      closeTask();
    }
  };
  return (
    <Modal
      open={openAddCloseNote}
      center
      onClose={() => setOpenCloseNote(false)}
      showCloseIcon={false}
      className={style.modal}
    >
      <div className={style.modalHead}>
        Comment
      </div>
      <div className={style.modalInput}>
        <textarea
          className={style.inpBox}
          value={note}
          onChange={(e) => {setValidate(false); setNote(e.target.value)}}
        />
        {validate && !note && (
          <div style={{ color: "red", fontSize: "15px" }}>
            Please Enter Closing Note
          </div>
        )}
      </div>
      <div className={style.btnROW}>
        <button
          className={style.ttcancelBtn}
          onClick={() => {
            setNote("");
            setOpenCloseNote(false);
            setValidate(false);
          }}
        >
          Cancel
        </button>
        <button className={style.ttAddBtn} onClick={() => handleAddNote()}>
          Close Task
        </button>
      </div>
    </Modal>
  );
};

const AddDoneRevNote = ({
  openDoneRevNote,
  setOpenDoneRevNote,
  doneReviewNote,
  setDoneReviewNote,
  addDoneRevNote,
  doneTask
}) => {
  const [validate, setValidate] = useState(false);
  const handleAddDoneRevNote = () => {
    if (!doneReviewNote) {
      doneTask()
    } else {
      addDoneRevNote();
      doneTask();
    }
  };
  return (
    <Modal
      open={openDoneRevNote}
      center
      onClose={() => setOpenDoneRevNote(false)}
      showCloseIcon={false}
      className={style.modal}
    >
      <div className={style.modalHead}>
        Comment
      </div>
      <div className={style.modalInput}>
        <textarea
          className={style.inpBox}
          value={doneReviewNote}
          onChange={(e) => {setValidate(false); setDoneReviewNote(e.target.value)}}
        />
        {validate && !doneReviewNote && (
          <div style={{ color: "red", fontSize: "15px" }}>
            Please Enter Done(Review) Note
          </div>
        )}
      </div>
      <div className={style.btnROW}>
        <button
          className={style.ttcancelBtn}
          onClick={() => {
            setDoneReviewNote("");
            setOpenDoneRevNote(false);
            setValidate(false);
          }}
        >
          Cancel
        </button>
        <button
          className={style.ttAddBtn}
          onClick={() => handleAddDoneRevNote()}
        >
          Done
        </button>
      </div>
    </Modal>
  );
};

export default ViewTaskComponent;
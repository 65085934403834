import React, { useEffect, useState } from "react";
import constants, { employeeId } from "../../../Utils/constants";
import cookie from "react-cookies";
import SideBar from "../Clients/component/SideBar";
import Style from "./Dashboard.module.css";
import HandShakeIcon from "../../../assets/hand-shake-icon.png";
import HrIcon from "../../../assets/hr-icon.png";
import loaderImg from "../../../assets/loader.gif";
import ProjectWorkIcon from "../../../assets/project-work-icon.png";
import axios from "axios";
import useValidationAccess from "../../custumHooks/useValidationAccess";
import { useHistory } from "react-router-dom";
import { rmsAccessCode } from "../../../Utils/constants";

const apiKey = process.env.REACT_APP_ATS_APP_URL;

export default function Dashboard() {
  let history = useHistory();
  const { checkValidateAccess } = useValidationAccess();

  const baseUrl = constants.apiBaseUrlResource;
  const [isLoaded, setIsLoaded] = useState(false);
  const [poolCount, setyPooCount] = useState(0);
  const [totalOffshore, setTotalResourceOffShore] = useState();
  const [myCount, setMyCount] = useState();
  const [finalStageCount, setMyStageCount] = useState();
  const [totalOnsite, setOnsiteTotalCount] = useState();
  const [count, setCount] = useState("");
  const [partialPoolCount, setpartialCount] = useState();
  const [allData10, setAllData10] = useState({
    empId: employeeId,
    nameEmail: null,
    status: [],
    technology: [],
  });
  const [allData3] = useState({
    bandwidths: [],
    empId: [],
    experienceRange: [],
    salaryRange: [],
    skillId: [],
  });
  const [allData1] = useState({
    action: [1],
    bandwidths: [],
    empId: [],
    projectId: [],
    skillId: [],
    salaryRange: [],
    partners: [],
    employeeStatus: [null, null],
    loggedInUserId : employeeId,
  });
  const [allData, setAllData] = useState({
    statusId: [],
    empId: [],
    skillId: [],
    experienceRange: [],
    salaryRange: [],
  });

  const [allData5] = useState(
    {
      name: null,
      status: [],
      empId: employeeId,
      sortBy: ["excepted_joining_date asc"],
      isMarkForRelease: false,
      skillId: [],
      isForRMS: true,
    }
  );
  const [allData2] = useState({
    action: [1],
    clientId: [],
    employeeId: [],
    skill: [],
    salaryRange: [],
    partners: [],
    startDateMonth: [],
    endDateMonth: [],
    employeeStatus: [null, null],
    quaterlyEndOfDeployment: [],
    quaterlyDeployment: [],
    handleFilter: false,
    partnerId: [],
    salesPersonId: [],
    isForcedAddedToPool: null,
  });
  const getPoolCount = (clearData, currentPage) => {
    axios
      .post(
        baseUrl + "/getPoolResource?" + "limit=" + 10 + "&page=" + currentPage,
        clearData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setyPooCount(res.data.responseData.totalResources);
      })
      .catch(() => { });
  };

  const getOffshorePollCount = (clearData, currentPage) => {
    axios
      .post(
        baseUrl +
        "/getPartialPoolResources?" +
        "limit=" +
        10 +
        "&page=" +
        currentPage,
        clearData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setpartialCount(res.data.responseData.totalResources);
      })
      .catch(() => { });
  };
  const getOffshoreCount = (clearData, currentPage) => {
    setIsLoaded(true);

    axios
      .post(
        baseUrl +
        "/getAddResourceOffshore?" +
        "limit=" +
        10 +
        "&page=" +
        currentPage,
        clearData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setTotalResourceOffShore(res.data.responseData);
      })
      .catch((err) => {
        setIsLoaded(false);
      });
  };
  const getOnsiteCount = (clearData, currentPage) => {
    setIsLoaded(true);
    axios
      .post(
        baseUrl +
        "/getAddResourceOnsite?" +
        "limit=" +
        10 +
        "&page=" +
        currentPage,
        clearData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setOnsiteTotalCount(res.data.responseData);
      })
      .catch((err) => { });
  };
  const getCount = () => {
    setIsLoaded(true);
    axios
      .get(baseUrl + "/getDashboard", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setIsLoaded(false);
        setCount(res.data.responseData);
      })
      .catch(() => {
        setIsLoaded(false);
      });
  };

  const getFinalStageOffersCount = () => {
    const filterData = allData5;
    setIsLoaded(true);
    axios
      .post(
        apiKey + "/getAllOffers?" + "limit=" + 10 + "&page=" + 1,
        filterData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoaded(false);
        setMyStageCount(res.data.responseData.positionCount);
      })
      .catch(() => {
        setIsLoaded(false);
      });
  };



  const getResourceOffShoreData = () => {
    const filterData = allData10;
    setIsLoaded(true);
    axios
      .post(
        apiKey +
        "/get-offer-accepted-candidates?" +
        "limit=" +
        10 +
        "&page=" +
        1,
        filterData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoaded(false);
        setMyCount(res.data.responseData.positionCount);
      })
      .catch(() => {
        setIsLoaded(false);
      });
  };

  useEffect(() => {
    getCount();
    getPoolCount(allData, 1);
    getOffshoreCount(allData1, 1);
    getOnsiteCount(allData2, 1);
    getOffshorePollCount(allData3, 1);
    getResourceOffShoreData();
    getFinalStageOffersCount();
  }, []);

  return (
    <>
      <div className={Style.projectresource_mainSection}>
        <SideBar />
        <div className={Style.projectresource_mainsection}>
          <div
            style={{
              marginLeft: "25px",
              marginTop: "15px",
              padding: "10px 0px",
              fontWeight: "700",
            }}
          >
            Dashboard
          </div>

          {isLoaded ? (
            <div>
              <img
                src={loaderImg}
                alt="loader"
                style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
              />
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  paddingLeft: "38%",
                  position: "absolute",
                  color: "#FBBB08",
                  marginTop: "-4px",
                  fontStyle: "italic",
                  marginLeft: "34.5%",
                }}
              >
                Loading...
              </p>
            </div>
          ) : null}

          {count ? (
            <div style={{ marginLeft: "0px" }}>
              {/* one */}
              <div className={Style.imgSection}>
                {/* {checkValidateAccess(rmsAccessCode?.client_card) && (
                  <div
                    className={Style.imgcontentSection}
                    onClick={() => history.push("/clients")}
                  >
                    <div className={Style.imgdiv}>
                      <img src={HandShakeIcon} alt="this is img section" />
                    </div>
                    <h1>{count.totalClient}</h1>
                    <span>Clients</span>
                  </div>
                )} */}

                {/* {checkValidateAccess(rmsAccessCode?.project_card) && (
                  <div
                    className={Style.imgcontentSection}
                    onClick={() => history.push("/ProjectDetails")}
                  >
                    <div className={Style.imgdiv}>
                      <img src={ProjectWorkIcon} alt="this is img section" />
                    </div>
                    <h1>{count.totalProjects}</h1>
                    <span>Projects</span>
                  </div>
                )} */}
                {checkValidateAccess(rmsAccessCode?.resource_offshore_card) && (
                  <div
                    className={Style.imgcontentSection}
                    onClick={() => history.push("/resourceManagement")}
                  >
                    <div className={Style.imgdiv}>
                      <img src={HrIcon} alt="this is img section" />
                    </div>
                    <h1>
                      {totalOffshore &&
                        `${totalOffshore?.totalResources} (${totalOffshore?.activeResignedResources})`}
                    </h1>

                    <span>Resources (Offshore)</span>
                  </div>
                )}
                {checkValidateAccess(rmsAccessCode?.resource_onsite_card) && (
                  <div
                    className={Style.imgcontentSection}
                    onClick={() => history.push("/ResourcesAllocationOnsite")}
                  >
                    <div className={Style.imgdiv}>
                      <img src={HandShakeIcon} alt="this is img section" />
                    </div>
                    <h1>
                      {totalOnsite &&
                        `${totalOnsite?.totalResources} (${totalOnsite?.activeResignedResources})`}
                    </h1>
                    <span>Resources (Onsite)</span>
                  </div>
                )}
                {checkValidateAccess(rmsAccessCode?.pool_card) && (
                  <div
                    className={Style.imgcontentSection}
                    onClick={() => history.push("/PoolResources")}
                  >
                    <div className={Style.imgdiv}>
                      <img src={ProjectWorkIcon} alt="this is img section" />
                    </div>
                    <h1>{poolCount}</h1>
                    <span>Resources (Pool)</span>
                  </div>
                )}
                {checkValidateAccess(rmsAccessCode?.dashboard_finalstages_candidate_card) && (
                  <div
                    className={Style.offeraccepted}
                    onClick={() => history.push("/OfferAcceptedCandidate")}
                  >
                    <div className={Style.imgdiv}>
                      <img src={HandShakeIcon} alt="this is img section" />
                    </div>
                    <h1>
                      {finalStageCount}
                      {/* {totalOnsite &&
                        `${totalOnsite?.totalResources} (${totalOnsite?.activeResignedResources})`} */}
                    </h1>
                    <span>Final Stage Candidates</span>
                  </div>
                )}
              </div>
              <div className={Style.imgSection}>
                {/* {checkValidateAccess(rmsAccessCode?.pool_card) && (
                  <div
                    className={Style.imgcontentSection}
                    onClick={() => history.push("/PoolResources")}
                  >
                    <div className={Style.imgdiv}>
                      <img src={ProjectWorkIcon} alt="this is img section" />
                    </div>
                    <h1>{poolCount}</h1>
                    <span>Resources (Pool)</span>
                  </div>
                )} */}
                {/* {checkValidateAccess(rmsAccessCode?.partial_pool_card) && (
                  <div
                    className={Style.imgcontentSection}
                    onClick={() => history.push("/OffshoreReport")}
                  >
                    <div className={Style.imgdiv}>
                      <img src={HrIcon} alt="this is img section" />
                    </div>
                    <h1>{partialPoolCount}</h1>
                    <span>Resources (Partial Pool)</span>
                  </div>
                )} */}
                {/* {checkValidateAccess(rmsAccessCode?.resource_onsite_card) && (
                  <div
                    className={Style.offeraccepted}
                    onClick={() => history.push("/OfferAcceptedCandidate")}
                  >
                    <div className={Style.imgdiv}>
                      <img src={HandShakeIcon} alt="this is img section" />
                    </div>
                    <h1>
                      {finalStageCount} */}
                      {/* {totalOnsite &&
                        `${totalOnsite?.totalResources} (${totalOnsite?.activeResignedResources})`} */}
                    {/* </h1>
                    <span>Final Stage Candidates</span>
                  </div>
                )} */}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";
import style from "./SideBar.module.css";
import buttonimg from "../../../assets/collaboration.png";
import Iconopentask from '../../../assets/Iconopentask.png';
import Iconopentask_2 from '../../../assets/Iconopentask_2.png';
import recurring from '../../../assets/closed3.png';
import recurring1 from '../../../assets/wall-clock.png'
import Iconawesometasks_2 from '../../../assets/Iconawesometasks_2.png';
import Iconmetrocross_2 from '../../../assets/Iconmetrocross_2.png';
import Iconawesometasks from '../../../assets/Iconawesometasks.png';
import Iconmetrocross from '../../../assets/Iconmetrocross.png';
import IconClosed_2 from '../../../assets/closed1.png';
import IconClosed from '../../../assets/closed1.png';
import { useHistory, useLocation } from "react-router-dom";
import useValidationAccess from "../../custumHooks/useValidationAccess";
import { taskTrackerAccessCode } from "../../../Utils/constants";
import GroupsIcon from '@mui/icons-material/Groups';
import InnovationMLogo from "../../../assets/Grievance portal/image-innovationm-logo@2x.png";
import { FaSearch } from "react-icons/fa";


const SideBar = () => {

  const { checkValidateAccess } = useValidationAccess();

  const location = useLocation();
  const history = useHistory();


  return (

    <div className={style.leftSection}>
      <div className={style.header}>
        <img
          alt="DashBoardImage"
          src={InnovationMLogo}
          className={style.dashboardImage}
          onClick={() => history.push("/dashboard")}
        />
      </div>
      <button className={style.taskTracker}
        style={{ cursor: 'auto', border: 'none' }}

      >
        <div>
          <img src={buttonimg} alt="img" />
        </div>

        <span >Task Tracker</span>
      </button>

      {checkValidateAccess(
        taskTrackerAccessCode?.my_task_menu
      ) && (<button
        onClick={() => history.push("/taskTracker/MyTasks")}
        className={location.pathname.slice(12,20) === "/MyTasks" ? style.selected : style.myTask}
        style={{ cursor: 'pointer', border: 'none', }}
      >
        <div>
          {location.pathname.slice(12,20) === "/MyTasks" ?
            <img src={Iconopentask} alt="img" /> :
            <img src={Iconopentask_2} alt="img" />
          }

        </div>

        <span><div style={{ marginTop: '5px', marginLeft: '5px' }}>My Tasks</div></span>
      </button>)}

      {checkValidateAccess(
        taskTrackerAccessCode?.search_task_menu
      ) && (<button
        onClick={() => history.push("/taskTracker/SearchTask")}
        className={location.pathname.slice(12,23) === "/SearchTask" ? style.selected : style.myTask}
        style={{ cursor: 'pointer', border: 'none', }}
      >
        <div>
          {location.pathname.slice(12,23) === "/SearchTask" ?
            <FaSearch style={{height:'24px', width:'24px', color:"#651ae"}}/> :
            <FaSearch style={{height:'24px', width:'24px'}}/>
          }
        </div>

        <span><div style={{ marginTop: '5px', marginLeft: '5px' }}>Search Task</div></span>
      </button>)}

      {checkValidateAccess(
        taskTrackerAccessCode?.my_task_menu
      ) && (<button
        onClick={() => history.push("/taskTracker/RecurringTasks")}
        className={location.pathname.slice(12,27) === "/RecurringTasks"
          ? style.selected : style.myTask}
        style={{ cursor: 'pointer', border: 'none', }}
      >
        <div>
          {location.pathname.slice(12,27) === "/RecurringTasks" ?
            <img style={{ height: '24px', width: '24px' }} src={recurring} alt="img" /> :
            <img style={{ height: '24px', width: '24px' }} src={recurring1} alt="img" />
          }

        </div>

        <span><div style={{ marginTop: '5px', marginLeft: '5px' }}>Recurring Tasks</div></span>
      </button>)}


      {checkValidateAccess(
        taskTrackerAccessCode?.create_task_menu
      ) && (
          <button
            onClick={() => history.push('/taskTracker/CreateTask')}
            className={location.pathname === "/taskTracker/CreateTask" ? style.selected : style.myTask}
            style={{ border: 'none' }}
          >
            <div>
            </div>
            <div>
              {location.pathname === "/taskTracker/CreateTask" ?
                <img src={Iconmetrocross_2} alt="img" /> :
                <img src={Iconmetrocross} alt="img" />
              }
            </div>

            <span>Create Task</span>
          </button>)}
      {checkValidateAccess(
        taskTrackerAccessCode?.task_assigned_by_me_menu
      ) && (
          <button
            onClick={() => history.push("/taskTracker/TaskAssignedByMe")}
            className={location.pathname.slice(12,29) === "/TaskAssignedByMe" ? style.selected : style.assignedByMe}
            style={{ cursor: 'pointer', border: 'none', }}
          >
            <div>
              {location.pathname.slice(12,29) === "/TaskAssignedByMe" ?
                <img src={Iconawesometasks_2} alt="img" /> :
                <img src={Iconawesometasks} alt="img" />
              }
            </div>
            <span style={{ whiteSpace: "nowrap" }}>Tasks Assigned by Me</span>
          </button>)}
      {checkValidateAccess(
        taskTrackerAccessCode?.close_task_menu
      ) && (
          <button
            onClick={() => history.push('/taskTracker/ClosedByMe')}
            className={location.pathname.slice(12,23) === "/ClosedByMe" ? style.selected : style.assignedByMe}
            style={{ cursor: 'pointer', border: 'none', }}
          >
            <div>
              {location.pathname.slice(12,23) === "/ClosedByMe" ?
                <img style={{ height: "120%", width: "120%" }} src={IconClosed_2} alt="img" /> :
                <img style={{ height: "120%", width: "120%" }} src={IconClosed} alt="img" />
              }
            </div>
            <span style={{ whiteSpace: "nowrap" }}>Closed Tasks</span>
          </button>)}
      {checkValidateAccess(
        taskTrackerAccessCode?.close_task_menu
      ) && (
          <button
            onClick={() => history.push('/taskTracker/Spaces')}
            className={location.pathname.slice(12,19) === "/Spaces" ? style.selected : style.assignedByMe}
            style={{ cursor: 'pointer', border: 'none', }}
          >
            <div>
              {location.pathname.slice(12,19) === "/Spaces" ?
                <GroupsIcon /> :
                <GroupsIcon />
              }
            </div>
            <span style={{ whiteSpace: "nowrap" }}>Spaces</span>
          </button>)}
    </div>
  );
};

export default SideBar;

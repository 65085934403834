import { useMutation, useQuery } from "react-query";
import BreadCrum from "../../breadcrums/BreadCrum";
import ATSContainer from "../../customTooltip/ATSContainer";
import { MdOutlineInsertComment } from "react-icons/md";
import style from "./mywatchlist.module.scss";
import {
  addCandidateToWishlistReview,
  getWishlistReviewCandidates,
  removeCandidateFromWishlistReview,
} from "../../../../Utils/axios/api";
import { employeeId } from "../../../../Utils/constants";
import moment from "moment";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { CgRemove } from "react-icons/cg";
import { Toastify, ToastifyError } from "../../../../App";
import ATSCard from "../../customTooltip/ATSCard";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import { useState } from "react";
import TotalCount from "../../TotalCount/TotalCount";
import { BiUpArrowAlt } from "react-icons/bi";
import { BiDownArrowAlt } from "react-icons/bi";
import ConfirmPopup from "../../../userManegment/pageLayout/viewUser/ConfirmPopup";
import ATSButton from "../../customTooltip/ATSButton";
// import { Flex } from "antd";
import { FaUserTag } from "react-icons/fa";
import { notifyError } from "../../utils/notify";
import { lazyLoad } from "../../../../GenericComponent/lazyLoad/LazyLoadCommon";
import CommentHistory1 from "../../commonComponent/Comment/CommentHistory/CommentHistory";
import { handleAddComment1, getCommentForCandidate } from "../../commonComponent/CommentCommon";

const MyWatchlist = ({ prevData, setPrevData, setNextScreen }) => {
  const Modal = lazyLoad(() => import('../../commonComponent/Modal/Modal'))
  const [currentPage, setCurrentPage] = useState(1);
  const [currentReviewPage, setCurrentReviewPage] = useState(1);
  const [isModalOpen1, setModalOpen1] = useState(false);
  const [showCommentModal1, setShowCommentModal1] = useState(false);
  const [myCandidateId, setMyCandidateId] = useState(null);
  const [myCandidateName, setMyCandidateName] = useState(null);
  const openModal1 = () => setModalOpen1(true);
  const closeModal1 = () => setModalOpen1(false);
  const [show, setShow] = useState(false);
  const [currentTable, setCurrentTable] = useState("");
  const [candidateId, setCandidateId] = useState(null);
  const [candidateName, setCandidateName] = useState("");
  const [addToMyWatchlist, setAddToMyWatchlist] = useState(false);
  const [myWatchlistSortBy, setMyWatchlistSortBy] = useState("s.skill_name");
  const [myWatchlistSortOrder, setMyWatchlistSortOrder] = useState("ASC");
  const [myWatchlistUploadDateSortBy, setMyWatchlistUploadDateSortBy] = useState("c.created");
  const [myWatchlistUploadDateSortOrder, setMyWatchlistUploadDateSortOrder] = useState("ASC");
  const [reviewWatchlistSortBy, setReviewWatchlistSortBy] = useState("s.skill_name");
  const [reviewWatchlistSortOrder, setReviewWatchlistSortOrder] = useState("ASC");
  const [dateSortBy, setDateSortBy] = useState("review_case_date");
  const [dateSortOrder, setDateSortOrder] = useState("ASC");
  const [isUploadDateVisibleSortBy, setIsUploadDateVisibleSortBy] = useState("c.created");
  const [isUploadDateVisibleSortOrder, setIsUploadDateVisibleSortOrder] = useState("ASC");
  const [isDateVisible, setIsDateVisible] = useState(false);
  const [coreSkillSorting, setCoreSkillSorting] = useState(true);
  const [myWatchlistCoreSkill, setMyWatchlistCoreSkill] = useState(true);
  const [uploadDate, setUploadDate] = useState(false);
  const [WatchListuploadDate, setWatchlistUploadDate] = useState(false);

  const { data, isLoading, isError, refetch } = useQuery(
    ["getWishlistCandidate", currentPage, myWatchlistSortBy, myWatchlistSortOrder],
    () => getWishlistReviewCandidates(employeeId, currentPage, myWatchlistSortBy, myWatchlistSortOrder, false),{
      refetchOnWindowFocus: false,
    }
  );
  const { data: myWatchListUploadDate, isLoading: myWatchListUploadDateLoading, isError: myWatchListUploadDateError, refetch: refetchUploadDate } = useQuery(
    ["getWishlistCandidate", currentPage, myWatchlistSortBy, myWatchlistSortOrder],
    () => getWishlistReviewCandidates(employeeId, currentPage, myWatchlistUploadDateSortBy, myWatchlistUploadDateSortOrder, false), {
      refetchOnWindowFocus: false,
    }
  );
  const {
    data: reviewData,
    isLoading: reviewIsLoading,
    isError: reviewIsError,
    refetch: reviewRefetch,
  } = useQuery(
    ["getWishlistReviewCandidates", currentReviewPage, reviewWatchlistSortBy, reviewWatchlistSortOrder],
    () => getWishlistReviewCandidates(employeeId, currentReviewPage, reviewWatchlistSortBy, reviewWatchlistSortOrder, true), {
      refetchOnWindowFocus: false,
    }
  );
  const {
    data: reviewDate,
    isLoading: reviewIsLoadingDate,
    isError: reviewIsErrorDate,
    refetch: reviewRefetchDate,
  } = useQuery(
    ["getWishlistReviewCandidates", currentReviewPage, reviewWatchlistSortBy, reviewWatchlistSortOrder],
    () => getWishlistReviewCandidates(employeeId, currentReviewPage, dateSortBy, dateSortOrder, true), {
      refetchOnWindowFocus: false,
    }
  );
  const {
    data: reviewUploadDate,
    isLoading: reviewIsLoadingUploadDate,
    isError: reviewIsErrorUploadDate,
    refetch: reviewRefetchUploadDate,
  } = useQuery(
    ["getWishlistReviewCandidates", currentReviewPage, reviewWatchlistSortBy, reviewWatchlistSortOrder],
    () => getWishlistReviewCandidates(employeeId, currentReviewPage, isUploadDateVisibleSortBy, isUploadDateVisibleSortOrder, true), {
      refetchOnWindowFocus: false,
    }
  );
  const handleSort = (field, table) => {
    if (table === "myWatchlist") {
      setMyWatchlistSortBy(field);
      setMyWatchlistSortOrder((prevOrder) => (prevOrder === "ASC" ? "DESC" : "ASC"));
      refetch(); 
      reviewRefetch(); 
    } else if (table === "myWatchlistUploadDate") {
      setMyWatchlistUploadDateSortBy(field);
      setMyWatchlistUploadDateSortOrder((prevOrder) => (prevOrder === "ASC" ? "DESC" : "ASC"));
      refetchUploadDate();
    } else if (table === "reviewWatchlist") {
      setReviewWatchlistSortBy(field);
      setReviewWatchlistSortOrder((prevOrder) => (prevOrder === "ASC" ? "DESC" : "ASC"));
      reviewRefetch(); 
    } else if (table === "reviewWatchlistDate") {
      setDateSortBy(field);
      setDateSortOrder((prevOrder) => (prevOrder === "ASC" ? "DESC" : "ASC"));
      reviewRefetchDate(); 
    } else if (table === "reviewWatchlistUploadDate"){
      setIsUploadDateVisibleSortBy(field);
      setIsUploadDateVisibleSortOrder((prevOrder) => (prevOrder === "ASC" ? "DESC" : "ASC"));
      reviewRefetchUploadDate();
    }
  };
  const mutation = useMutation(removeCandidateFromWishlistReview, {
    onSuccess: (res) => {
      setShow(false);
      Toastify(res);

      refetch();
      reviewRefetch();
    },
    onError: (res) => {
      ToastifyError("Something went wrong");
    },
  });
  const watchlistMutation = useMutation(addCandidateToWishlistReview, {
    onSuccess: (res) => {
      if (res.responseStatus.statusCode === 0) {
        ToastifyError(res?.responseData?.message);
      }
      if (res.responseStatus.statusCode === 1) {
        refetch();
        reviewRefetch();
        Toastify(res?.responseData);
      }
      setAddToMyWatchlist(false);
    },
  });
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  const handleReviewPageChange = (page, status) => {
    if (status === "next") {
      setCurrentReviewPage(page + 1);
    } else if (status === "prev") {
      setCurrentReviewPage(page - 1);
    }
  };
  const handleStatus = (status) => {
    switch (status) {
      case "Accepted":
      case "Offer Accepted":
      case "Shortlisted":
      case "Final Shortlisted":
      case "Joined":
      case "Interview Shortlisted":
      case "Candidate Joined":
      case "Screen Selected":
      case "Test Shortlisted":
      case "Final Select":
      case "R1 Shortlisted":
      case "R2 Shortlisted":
      case "R3 Shortlisted":
      case "CR1 Shortlisted":
      case "CR2 Shortlisted":
      case "CR3 Shortlisted":
        return { color: "green", fontWeight: "600", marginRight: "15px" };
      case "Re-Activated":
      case "Candidate Allocated":
        return { color: "firebrick", fontWeight: "600", marginRight: "15px" };
      case "Offer Released":
      case "Joining In Progress":
      case "Interview Scheduled":
      case "Test Scheduled":
      case "R1 Scheduled":
      case "R2 Scheduled":
      case "R3 Scheduled":
      case "Hold":
      case "On-Hold":
      case "CR1 Scheduled":
      case "CR2 Scheduled":
      case "CR3 Scheduled":
        return { color: "blue", fontWeight: "600", marginRight: "15px" };
      case "Declined":
      case "Rejected":
      case "Interview Rejected":
      case "Interview Cancelled":
      case "Candidate Dropped":
      case "Offer Declined":
      case "Interview Did Not Happen":
      case "Screen Rejected":
      case "Test Rejected":
      case "Screen Rejected":
      case "Drop":
      case "R1 Rejected":
      case "R2 Rejected":
      case "R3 Rejected":
      case "Offer Rejected":
      case "CR1 Rejected":
      case "CR2 Rejected":
      case "CR3 Rejected":
      case "Candidate Absconded":
        return { color: "red", fontWeight: "600", marginRight: "15px" };
      default:
        return { marginRight: "15px" };
    }
  };
  return (
    <>
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
      // PlusComponent={<TotalCount totalCount={data?.totalCount} />}
      />
      <div className={style.heading}>
        <h6>My Watchlist </h6>
        <TotalCount totalCount={data?.totalCount} />
      </div>
      <ATSContainer styles={{ overflow: "auto" }}>
        <div className={style.th1}>
          <div
            style={{
              position: "sticky",
              left: "0px",
              background: "#f5f7f9",
            }}
          >
            <div style={{ alignSelf: "center", padding: "0px" }}>Name</div>
          </div>
          <div
            style={{
              position: "sticky",
              left: "15rem",
              background: "#f5f7f9",
              height: "45px",
            }}
          >
            <div style={{ alignSelf: "center", padding: "0px" }}></div>
          </div>
          <div style={{textDecoration: "underline", cursor: "pointer"}}
          onClick={() => {
            handleSort("c.created", "myWatchlistUploadDate");
            setWatchlistUploadDate(true);
            setMyWatchlistCoreSkill(false);
          }}
          onMouseEnter={(e) => (e.target.style.color = "blue")}
          onMouseLeave={(e) => (e.target.style.color = "#666666")}>
            Upload Date
            {WatchListuploadDate ? (myWatchlistUploadDateSortBy === "c.created" && WatchListuploadDate && myWatchlistUploadDateSortOrder === "ASC") ? (
              <TiArrowSortedDown style={{
            fontSize: "1.3rem",
            color: "blue",
            cursor: "pointer",
            verticalAlign: "text-top",
            }}/>) : (
              <TiArrowSortedUp style={{
              fontSize: "1.3rem",
              color: "blue",
              cursor: "pointer",
              verticalAlign: "text-top"}}
            />
            ) : null}
          </div>
          <div
            
          >
            <div style={{ alignSelf: "center", padding: "0px", textDecoration: "underline", cursor: "pointer"}}
            onClick={() => {
              handleSort("s.skill_name", "myWatchlist");
              setMyWatchlistCoreSkill(true);
              setWatchlistUploadDate(false);
            }}
            onMouseEnter={(e) => (e.target.style.color = "blue")}
            onMouseLeave={(e) => (e.target.style.color = "#666666")}
            >
              Core Skill
              {myWatchlistCoreSkill ? myWatchlistSortBy === "s.skill_name" && myWatchlistCoreSkill && myWatchlistSortOrder === "ASC" ? (
              <TiArrowSortedUp style={{
            fontSize: "1.3rem",
            color: "blue",
            cursor: "pointer",
            verticalAlign: "text-top",
          }} />
            ) : (
              <TiArrowSortedDown style={{
            fontSize: "1.3rem",
            color: "blue",
            cursor: "pointer",
            verticalAlign: "text-top",
          }}/>
            ) : null}
            </div>
          </div>
          <div>DOJ</div>
          <div>Experience</div>
          <div>
            CTC(LPA)
          </div>
          <div>
            Current/Last Company
          </div>
          <div style={{ marginRight: "15px" }}>Current Stage</div>
          <div>
            Created By
          </div>
          <div></div>
        </div>
        {isLoading && <div className={style.loading}>Loading...</div>}
        {isError && <div className={style.loading}>Something went wrong</div>}
        {!isLoading && !isError ? (
          data?.candidateLIstResponses?.length > 0 ? (
            data?.candidateLIstResponses?.map((e) => {
              return (
                <div className={style.td1} key={e?.id}>
                  <div
                    title={e?.name?.length > 16 && e?.name}
                    onClick={() => {
                      setNextScreen("candidateMovement");
                      setPrevData({
                        ...prevData,
                        reportCandidateId: e?.id,
                        sidebar: "Watchlist",
                        page: "Watchlist",
                        page1: "Watchlist",
                        page2: "candidateMovement",
                        page3: null,
                        page4: null,
                        active: "candidateMovement",
                        name: "",
                        positionId: null,
                      });
                    }}
                    style={{
                      position: "sticky",
                      left: "0px",
                      background: "white",
                    }}
                  >
                    {e?.name}
                  </div>
                  <div
                    style={{
                      position: "sticky",
                      left: "15rem",
                      background: "white",
                    }}
                  >
                    <MdOutlineInsertComment
                      style={{ color: "black", height: "13.5px", cursor: "pointer" }}
                      onClick={() => {
                        openModal1()
                        setShowCommentModal1(true);
                        setMyCandidateId(e?.id);
                        setMyCandidateName(e?.name)
                      }}
                      title={" Add Comment For Candidate"}
                    />
                  </div>
                  <div>{moment(e?.uploadDate).format(moment(e?.uploadDate).year() === moment().year() ? 'DD MMM' : 'DD MMM YY')}</div>
                  <div
                    title={e?.coreSkill?.length > 20 && e?.coreSkill}
                    
                  >
                    {e?.coreSkill}
                  </div>
                  <div>
                    {e.expectedDoj
                      ? moment(e.expectedDoj).format("DD MMM")
                      : null}
                  </div>
                  <div>
                    {(function () {
                      let year = Math.floor(e.totalExperienceYear);
                      let month = e.totalExperienceMonth;
                      year = year > 0 ? year + " Y  " : "0 Y";
                      month = month > 0 ? ` - ${month}  M ` : "";
                      return `   ${year}  ${month}`;
                    })()}
                  </div>
                  <div>{e?.ctc}</div>
                  <div title={e?.companyName?.length > 24 && e?.companyName}>
                    {e?.companyName}
                  </div>
                  <div style={handleStatus(e.currentStatus)}>
                    {e.currentStatus}
                  </div>
                  <div title={e?.createdBy?.length > 16 && e?.createdBy}>
                    {e?.createdBy}
                  </div>
                  <div
                    onClick={() => {
                      setShow(true);
                      setCandidateId(e?.id);
                      setCandidateName(e?.name);
                      setCurrentTable("myWatchlist");
                    }}
                    title="Remove from My Watchlist"
                  >
                    <CgRemove />
                  </div>
                </div>
              );
            })
          ) : (
            <div className={style.loading}>No Data</div>
          )
        ) : null}
      </ATSContainer>
      <div style={{ height: "30px" }}>
        <ATSCard>
          <Pagination
            onPageChange={handlePageChange}
            currentPage={currentPage}
            pagesCount={data?.totalCount / 50}
            color="#65A15E"
            color2="white"
          />
        </ATSCard>
      </div>
      <div className={style.heading}>
        <h6>To be Reviewed Watchlist </h6>
        <TotalCount totalCount={reviewData?.totalCount} />
      </div>
      <ATSContainer styles={{ overflow: "auto" }}>
        <div className={style.review_th1} style={{ width: "137rem" }}>
          <div
            style={{
              position: "sticky",
              left: "0px",
              background: "#f5f7f9",
            }}
          >
            Name
          </div>
          <div
            style={{
              position: "sticky",
              left: "15rem",
              background: "#f5f7f9",
              height: "39px"
            }}
          >
          </div>
          <div style={{textDecoration: "underline", cursor: "pointer"}}
          onClick={() => {
            handleSort("c.created", "reviewWatchlistUploadDate");
            setIsDateVisible(false);
            setCoreSkillSorting(false);
            setUploadDate(true);
          }}
          onMouseEnter={(e) => (e.target.style.color = "blue")}
          onMouseLeave={(e) => (e.target.style.color = "#666666")}>
            Upload Date
            {uploadDate ? (isUploadDateVisibleSortBy === "c.created" && uploadDate && isUploadDateVisibleSortOrder === "ASC") ? (
              <TiArrowSortedDown style={{
            fontSize: "1.3rem",
            color: "blue",
            cursor: "pointer",
            verticalAlign: "text-top",
            }}/>) : (
              <TiArrowSortedUp style={{
              fontSize: "1.3rem",
              color: "blue",
              cursor: "pointer",
              verticalAlign: "text-top"}}
            />
            ) : null}
          </div>
          <div 
              style={{textDecoration: "underline", cursor: "pointer"}}
              onClick={() => {
                handleSort("review_case_date", "reviewWatchlistDate");
                setIsDateVisible(true);
                setCoreSkillSorting(false);
                setUploadDate(false);
              }}
              onMouseEnter={(e) => (e.target.style.color = "blue")}
              onMouseLeave={(e) => (e.target.style.color = "#666666")}
          >
            Review Date
            {dateSortBy === "review_case_date" && isDateVisible && (
              dateSortOrder === "ASC" ? (
                <TiArrowSortedDown
                  style={{
                    fontSize: "1.3rem",
                    color: "blue",
                    cursor: "pointer",
                    verticalAlign: "text-top",
                    marginLeft: "-1px",
                  }}
                />
              ) : (
                <TiArrowSortedUp
                  style={{
                    fontSize: "1.3rem",
                    color: "blue",
                    cursor: "pointer",
                    verticalAlign: "text-top",
                    marginLeft: "-1pxpx",
                  }}
                />
              )
            )}
          </div>
          <div>Shared By</div>
          <div>Reason</div>
          <div style={{textDecoration: "underline", cursor: "pointer"}}
          onClick={() => {
            handleSort("s.skill_name", "reviewWatchlist");
            setIsDateVisible(false);
            setCoreSkillSorting(true);
            setUploadDate(false);
          }}
          onMouseEnter={(e) => (e.target.style.color = "blue")}
          onMouseLeave={(e) => (e.target.style.color = "#666666")}
          >
            Core Skill
            {coreSkillSorting ? (reviewWatchlistSortBy === "s.skill_name" && reviewWatchlistSortOrder === "ASC") ? (
              <TiArrowSortedUp style={{
            fontSize: "1.3rem",
            color: "blue",
            cursor: "pointer",
            verticalAlign: "text-top",
            }}/>) : (
              <TiArrowSortedDown style={{
              fontSize: "1.3rem",
              color: "blue",
              cursor: "pointer",
              verticalAlign: "text-top"}}
            />
            ) : null}
          </div>
          <div>DOJ</div>
          <div>Experience</div>
          <div>
            CTC(LPA)
          </div>
          <div>
            Current/Last Company
          </div>
          <div style={{ marginRight: "15px" }}>Current Stage</div>
          <div>
            Created By
          </div>
          <div></div>
          <div style={{ width: "2rem" }}></div>
        </div>
        {reviewIsLoading && <div className={style.loading}>Loading...</div>}
        {reviewIsError && (
          <div className={style.loading}>Something went wrong</div>
        )}
        {!reviewIsLoading && !reviewIsError ? (
          reviewData?.candidateLIstResponses?.length > 0 ? (
            reviewData?.candidateLIstResponses?.map((e) => {
              return (
                <div
                  className={style.review_td1}
                  style={{ width: "137rem" }}
                  key={e?.id}
                >
                  <div
                    onClick={() => {
                      setNextScreen("candidateMovement");
                      setPrevData({
                        ...prevData,
                        reportCandidateId: e?.id,
                        sidebar: "Watchlist",
                        page: "Watchlist",
                        page1: "Watchlist",
                        page2: "candidateMovement",
                        page3: null,
                        page4: null,
                        active: "candidateMovement",
                        name: "",
                        positionId: null,
                      });
                    }}
                    style={{
                      position: "sticky",
                      left: "0px",
                      background: "white",
                    }}
                  >
                    {e?.name}
                  </div>
                  <div style={{
                    position: "sticky",
                    left: "15rem",
                    background: "white",
                  }}>
                    <MdOutlineInsertComment
                      style={{ color: "black", height: "13.5px", cursor: "pointer" }}
                      onClick={() => {
                        openModal1()
                        setShowCommentModal1(true);
                        setMyCandidateId(e?.id);
                        setMyCandidateName(e?.name)
                      }}
                      title={" Add Comment For Candidate"}
                    />
                  </div>
                  <div>{moment(e?.uploadDate).format(moment(e?.uploadDate).year() === moment().year() ? 'DD MMM' : 'DD MMM YY')}</div>
                  <div>
                  {moment(
                    reviewData?.reviewCaseResponses.find(
                      (item) => item.reviewCaseCandidateID === e?.id
                    )?.reviewCaseDate
                  ).format(
                    moment(
                      reviewData?.reviewCaseResponses.find(
                        (item) => item.reviewCaseCandidateID === e?.id
                      )?.reviewCaseDate
                    ).year() === moment().year()
                      ? 'DD MMM'
                      : 'DD MMM YY'
                  )}
                  </div>
                  <div>
                    {
                      reviewData?.reviewCaseResponses.find(
                        (item) => item.reviewCaseCandidateID === e?.id
                      )?.reviewCaseByEmpName
                    }
                  </div>
                  <div title={
                        reviewData?.reviewCaseResponses.find(
                          (item) => item.reviewCaseCandidateID === e?.id
                        )?.reviewCaseReason?.length > 20
                          ? reviewData.reviewCaseResponses.find(
                              (item) => item.reviewCaseCandidateID === e?.id
                            )?.reviewCaseReason
                          : null
                      }
                    >
                      {
                        reviewData?.reviewCaseResponses.find(
                          (item) => item.reviewCaseCandidateID === e?.id
                        )?.reviewCaseReason
                      }
                    </div>
                  <div title={e?.coreSkill?.length > 22 && e?.coreSkill}>
                    {e?.coreSkill}
                  </div>
                  <div>
                    {e.expectedDoj
                      ? moment(e.expectedDoj).format("DD MMM")
                      : null}
                  </div>
                  <div>
                    {(function () {
                      let year = Math.floor(e.totalExperienceYear);
                      let month = e.totalExperienceMonth;
                      year = year > 0 ? year + " Y  " : "0 Y";
                      month = month > 0 ? ` - ${month}  M ` : "";
                      return `   ${year}  ${month}`;
                    })()}
                  </div>
                  <div>{e?.ctc}</div>
                  <div title={e?.companyName?.length > 24 && e?.companyName}>
                    {e?.companyName}
                  </div>
                  <div style={handleStatus(e.currentStatus)}>
                    {e.currentStatus}
                  </div>
                  <div title={e?.createdBy?.length > 10 && e?.createdBy}>
                    {e?.createdBy}
                  </div>
                  <div
                    title="Add to My Watchlist"
                    onClick={() => {
                      setAddToMyWatchlist(true);
                      setCandidateId(e?.id);
                      setCandidateName(e?.name);
                    }}
                  >
                    <FaUserTag
                      style={{
                        color: "black",
                        fontSize: '13.5px'
                      }}
                    />
                  </div>
                  <div
                    style={{ width: "2rem" }}
                    onClick={() => {
                      setShow(true);
                      setCandidateId(e?.id);
                      setCandidateName(e?.name);
                      setCurrentTable("toBeReviewed");
                    }}
                    title="Remove from My Watchlist"
                  >
                    <CgRemove />
                  </div>
                </div>
              );
            })
          ) : (
            <div className={style.review_loading}>No Data</div>
          )
        ) : null}
      </ATSContainer>
      <ATSCard>
        <Pagination
          onPageChange={handleReviewPageChange}
          currentPage={currentReviewPage}
          pagesCount={reviewData?.totalCount / 50}
          color="#65A15E"
          color2="white"
        />
      </ATSCard>
      <ConfirmPopup open={show}>
        <div className={style.removeWatch}>
          <p className={style.text}>
            Do you want to remove{" "}
            <span style={{ fontWeight: "500" }}>{candidateName}</span> from{" "}
            {currentTable === "myWatchlist"
              ? "My Watchlist"
              : "To Be Reviewed Watchlist"}
          </p>
          <div className={style.buttonContainer}>
            <ATSButton onClick={() => setShow(false)}>No</ATSButton>
            <ATSButton
              onClick={() => {
                if (currentTable === "myWatchlist")
                  mutation?.mutate({
                    candidateId: candidateId,
                    requesterId: employeeId,
                    toBeReviewed: false,
                  });
                else
                  mutation?.mutate({
                    candidateId: candidateId,
                    requesterId: employeeId,
                    toBeReviewed: true,
                  });
              }}
            >
              Yes
            </ATSButton>
          </div>
        </div>
      </ConfirmPopup>
      <ConfirmPopup open={addToMyWatchlist}>
        <div className={style.removeWatch}>
          <p className={style.text}>
            Do you want to add{" "}
            <span style={{ fontWeight: "500" }}>{candidateName}</span> to My
            Watchlist?
          </p>
          <div className={style.buttonContainer}>
            <ATSButton onClick={() => setAddToMyWatchlist(false)}>No</ATSButton>
            <ATSButton
              onClick={() => {
                if (employeeId && candidateId) {
                  watchlistMutation.mutate({
                    candidateId: candidateId,
                    requesterId: [employeeId],
                    reviewCaseByEmpId: null,
                    toBeReviewed: false,
                    wishlistReviewCasesId: null,
                  });
                } else {
                  notifyError("Something went wrong!")
                }
              }}
            >
              Yes
            </ATSButton>
          </div>
        </div>
      </ConfirmPopup>

      <Modal isOpen={isModalOpen1} onClose={closeModal1}>
        <div className={style.modal_container_watchlist2}>
          <div className={style.watchlist_inner_container}>
            <CommentHistory1
              candidateId={myCandidateId}
              closeModal={closeModal1}
              setShowCommentModal={setShowCommentModal1}
              myCandidateName={myCandidateName}
              handleAddComment={handleAddComment1}
              getCommentForPosition={getCommentForCandidate}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default MyWatchlist;

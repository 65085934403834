import React, { useState, useEffect } from 'react';
import style from './groups.module.css';
import SideBar from '../commonComponent/SideBar';
import { useHistory } from 'react-router-dom';
import constants from '../../../Utils/constants';
import loaderImg from "./../../../assets/loader-green2.gif";
import { Toastify, ToastifyError } from '../../../App';
import { employeeId } from '../../../Utils/constants';
import IsAdmin from "../../../assets/IsAdmin.png";
import Modal from 'react-responsive-modal';
import { addSpace, groupListing, markAsFavourite } from '../taskTrackerAPIs/taskTrackerAPI';
import { useMutation, useQuery } from 'react-query';
import { FaRegStar, FaStar } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import searchIcon from "../../../assets/Icon-search.png";
import moment from 'moment';
import loaderButton from "../../../assets/rolling-loader.gif";
import Pagination from '../../Projects_and_Resource_Master/CommonComponent/Pagination';

const Groups = () => {

  const history = useHistory();
  const [openSpaceModal, setOpenSpaceModal] = useState(false);
  const [spaceName, setSpaceName] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [validate, setValidate] = useState(false);
  const [searchByName, setSearchByName] = useState('');
  const [tempSpaceId, setTempSpaceId] = useState(null);
  const [page, setPage] = useState(1);

  const viewListHandler = (groupId, group, groupMember, isAdmin) => {
    if(groupMember){
      history.push({
        pathname: "/taskTracker/Spaces/SpaceDetails",
        state: {
          groupId:groupId,
          Groups:group,
          isAdmin: isAdmin,
          isActive: isActive,
        },
      });
    }
    else{
      ToastifyError("You're not a member!");
    }
  };

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setPage(page + 1);
    } else if (status === "prev") {
      setPage(page - 1);
    }
  };

  const markAsFavouriteMutation = useMutation(markAsFavourite,{
    onSuccess: (res)=>{
      if(res?.statusCode){
        ToastifyError(res?.responseData?.message);
      }
      else{
        Toastify(res?.responseData);
        refetch();
      }
    }
  });

  const { data: GroupsList, isLoading: isLoaded, refetch } = useQuery(
    ['groupListing', isActive, searchByName, page],
    ()=>groupListing(isActive, searchByName, page),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    });

  // const getGroupDashboardList = () => {
  //   setIsLoaded(true);
  //   axios
  //     .get(url2 + `/groupDashboard?active=${isActive}&empId=${employeeId}&spaceName=${searchByName}`, {
  //       headers: {
  //         'Access-Control-Allow-Origin': '*',
  //         Authorization: ` ${cookie.load('token')}`,
  //       },
  //     })
  //     .then((res) => {
  //       if(res.data.responseData?.message){
  //         ToastifyError(res.data.responseData?.message)
  //         setIsLoaded(false);
  //       } else{
  //         setgroupList(res.data.responseData);
  //         setIsLoaded(false);
  //       }
  //     })
  //     .catch(() => {});
  // };


  const editSpaceMutation = useMutation(addSpace, {
    onSuccess: (res)=>{
      if(res?.statusCode){
        res?.responseData?.message[0]==='G' ? ToastifyError("Space Name Already Taken!") : ToastifyError(res?.responseData?.message);
      }
      else{
        Toastify(res?.responseData);
        refetch();
      }
    },
    onError: ()=>{
      Toastify("Something Went Wrong!");
    }
  });

  useEffect(() => {
    setPage(1);
  },[isActive, searchByName]);

  return (
    <>
      <div className={style.mainSection}>
        <SideBar />

        <div className={style.rightSection}>
          <div className={style.grpus}>
            <div>Spaces</div>
            <div style={{display: "flex", alignItems: "center"}}>
              <input
                  type="checkbox"
                  className={style.showAllCheckBox}
                  style={{
                      visibility: "visible",
                      accentColor:"#65A15E",
                      transform: "translateY(2px)",
                      margin: "0px 4px 2px 0px"
                  }}
                  checked={!isActive}
                  onChange={()=>setIsActive(prev=>!prev)}
              />
              <label>Show Closed Spaces</label>
            </div>
            <div style={{width: "30%"}}>
              {!searchByName ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt={"searchIcon"} />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setSearchByName('');
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <input
                  className={style.coreSkillInput}
                  placeholder="Search Space"
                  value={searchByName}
                  onChange={(e) => {
                    // setCurrentPage(1);
                    // setAllData(prev => ({...prev, title: e.target.value, page: 1}))
                    setSearchByName(e.target.value);
                  }}
                />
              </div>
            <button className={style.addSpaceBtn} onClick={()=>setOpenSpaceModal(true)}>Add Space</button>
          </div>
          <div className={style.taskAssigned} style={{minHeight: isLoaded ? '60%' : '40px'}}>
          {isLoaded ? (
            <div>
              <img src={loaderImg} alt="loader" className={style.loader} />
              <p className={style.loaderPara}>Loading...</p>
            </div>
          ) : (
            
          
            GroupsList?.length===0 && !isLoaded ? (
              <p className={style.noDataFound} >No Data Available.</p>
            ) : (
                <div className={style.container}>
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Space Name</th>
                          <th>Creation Date</th>
                          <th>Members</th>
                          <th>Tasks</th>
                          <th></th>
                        </tr>
                      </thead>
                      {GroupsList?.groupList?.length>0 ? 
                        <tbody>
                          {GroupsList?.groupList?.map((group) => (
                            <tr className={style.td}>
                              <td> {group?.isAdmin ? <img src={IsAdmin} style={{height: "1.25rem", width: "1.25rem", marginLeft: "10px", marginBottom: "2px"}} alt='Admin' title='Admin'/> : <></>}</td>
                              <td><span style={{cursor: "pointer"}} onClick={()=>viewListHandler(group.groupId, group?.groupName, group?.groupMember, group?.isAdmin)}>{group?.groupName}</span></td>
                              <td>{group?.createdOn ? (moment().year() === moment(group?.createdOn).year() ? moment(group?.createdOn).format("DD MMM") : moment(group?.createdOn).format("DD MMM YYYY")) : '-'}</td>
                              <td>{group?.countByMembers}</td>
                              <td>{group?.countByTasks}</td>
                              <td>{(markAsFavouriteMutation.isLoading && tempSpaceId===group?.groupId) ? <img src={loaderButton} height="20px" width="20px"/> : (group?.favouriteGroup ? <FaStar style={{cursor: "pointer"}} color='yellowgreen' size="25" onClick={()=>{
                                setTempSpaceId(group?.groupId);
                                if(!markAsFavouriteMutation.isLoading) markAsFavouriteMutation.mutate({empId: employeeId, spaceId: [group?.groupId], favouriteGroup: false});
                              }}/> : <FaRegStar style={{cursor: "pointer"}} size="25" onClick={()=>{
                                setTempSpaceId(group?.groupId);
                                if(!markAsFavouriteMutation.isLoading) markAsFavouriteMutation.mutate({empId: employeeId, spaceId: [group?.groupId], favouriteGroup: true});
                              }}/>)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        :
                        <tbody>
                          <tr>
                            <td colSpan="6" style={{textAlign: "center"}}>No Data Available</td>
                          </tr>
                        </tbody>
                      }
                    </table>
                </div>
            )
          )}
          
          </div>

          {/* <div className={style.buttonsContainer}>

            <button 
                className={style.backButton}              
                onClick={()=>(history.goBack())} // window.history.back()
            >
              Back
            </button> */}

            <div 
              className={style.pagination}
            >

              <Pagination
                onPageChange={handlePageChange}
                currentPage={page}
                pagesCount={GroupsList?.totalCount / 10}
                color="#65A15E"
                color2="white"
              />
            </div>
          {/* </div> */}

        </div>
      </div>
      <Modal
        open={openSpaceModal}
        onClose={()=>{setSpaceName(''); setOpenSpaceModal(false); setValidate(false)}}
        center
      >
        <>
          <div className={style.memberModalHeading} style={{width: "18rem", marginBottom: "2rem"}}>Add Space</div>
          <div className={style.addMembers} style={{marginBottom: "5px"}}>Space Title <span style={{color: 'red'}}>*</span></div>
          <input type="text" style={{width: "100%", padding: "0px 2px"}} value={spaceName} onChange={(e)=>{setValidate(false); setSpaceName(e.target.value)}}/>
          <p className={validate ? style.errorText : style.errorTextHidden}>Please Enter Space Title</p>
          <div 
            style={{width : "100%",marginTop: "4rem", display: "flex", justifyContent: "center"}}
          >
            <button className={style.doneButton}
              onClick={()=>{
                if(spaceName?.trim()?.length>0){
                  if(!editSpaceMutation?.isLoading) editSpaceMutation.mutate({spaceName : spaceName });
                  setOpenSpaceModal(false);
                }
                else {
                  setValidate(true);
                }
                setSpaceName('');
              }}
            >Save
            </button>
          </div>
        </>
      </Modal>
    </>
  );
};

export default Groups;

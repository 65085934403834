import React from "react";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import style from "./SideBar.module.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { ums_technology } from "../../../../assets";
import ReportIcon from "@mui/icons-material/Report";
import { rmsAccessCode } from "../../../../Utils/constants";
import DescriptionIcon from "@mui/icons-material/Description";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { GrTechnology } from "react-icons/gr";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import InnovationMLogo from "../../../../assets/Grievance portal/image-innovationm-logo@2x.png";
import { GiSkills } from "react-icons/gi";

const Sidebar = () => {
  const { checkValidateAccess } = useValidationAccess();

  const location = useLocation();
  let history = useHistory();
  return (
    <div className={style.side_bar}>
      {/* Project and Resource Heading */}
      <div className={style.header}>
        <img
          alt="DashBoardImage"
          src={InnovationMLogo}
          className={style.dashboardImage}
          onClick={() => history.push("/dashboard")}
        />
      </div>
      <div className={style.project_heading}>
        <div className={style.prmheading}>
          <span className={style.prmheading}>
            Resources Management <br /> System ( RMS )
          </span>
        </div>
      </div>

      {/* Sidebar buttons */}
      <div className={style.side_buttons_div}>
        {checkValidateAccess(rmsAccessCode?.sideBar_pool) && (
          <Link to="/MyWatchList" style={{ textDecoration: "none" }}>
            <div
              className={
                location.pathname === "/MyWatchList"
                  ? style.active_btn
                  : style.btn_menu
              }
            >
              {<GroupAddIcon className={style.icon} />}
              <div className={style.heading}> My WatchList</div>
            </div>
          </Link>
        )}

        {checkValidateAccess(rmsAccessCode?.sideBar_pool) && (
          <Link to="/ResourceMaster" style={{ textDecoration: "none" }}>
            <div
              className={
                location.pathname === "/ResourceMaster"
                  ? style.active_btn
                  : style.btn_menu
              }
            >
              {<GridViewRoundedIcon className={style.icon} />}
              <div className={style.heading}>Dashboard</div>
            </div>
          </Link>
        )}

        {checkValidateAccess(rmsAccessCode?.sideBar_pool) && (
          <Link to="/PoolResources" style={{ textDecoration: "none" }}>
            <div
              className={
                location.pathname === "/PoolResources" ||
                location.pathname === "/EditPoolResource" ||
                location.pathname === "/PoolResourcesDescription" ||
                location.pathname === "/AddPollResources" ||
                location.pathname === "/PoolResourcesHistory" ||
                location.pathname === "/Pool/TaskDetails" ||
                location.pathname === "/Pool/TaskEdit"
                  ? style.active_btn
                  : style.btn_menu
              }
            >
              {<GroupsIcon className={style.icon} />}
              <div className={style.heading}>Resources (Pool)</div>
            </div>
          </Link>
        )}

        {/* <Link to="/TechnologyDashboard" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/TechnologyDashboard"
                ? style.active_btn
                : style.btn_menu
            }
          >
            {<GridViewRoundedIcon className={style.icon} />}
            <div className={style.heading}>Technology Dashboard</div>
          </div>
        </Link> */}

        {/* {checkValidateAccess(rmsAccessCode?.sideBar_clients) && (
          <Link to="/clients" style={{ textDecoration: "none" }}>
            <div
              className={
                location.pathname === "/clients" ||
                  location.pathname === "/clientdescription" ||
                  location.pathname === "/createClient" ||
                  location.pathname === "/EditClient"
                  ? style.active_btn
                  : style.btn_menu
              }
            >
              {<PersonIcon className={style.icon} />}
              <div className={style.heading}>Clients</div>
            </div>
          </Link>
        )} */}
        {/* {checkValidateAccess(rmsAccessCode?.sideBar_project) && (
          <Link to="/ProjectDetails" style={{ textDecoration: "none" }}>
            <div
              className={
                location.pathname === "/projects" ||
                  location.pathname === "/resource" ||
                  location.pathname === "/ProjectDetails" ||
                  location.pathname === "/EditProject" ||
                  location.pathname === "/availableResources" ||
                  location.pathname === "/AllocateResources" ||
                  location.pathname === "/Projectdescription"
                  ? style.active_btn
                  : style.btn_menu
              }
            >
              <ContentPasteIcon className={style.icon} />

              <div className={style.heading}>Projects</div>
            </div>
          </Link>
        )} */}
        {checkValidateAccess(rmsAccessCode?.sideBar_onsite_report) && (
          <Link to="/onSiteReportDashBoard" style={{ textDecoration: "none" }}>
            <div
              className={
                location.pathname === "/onSiteReportDashBoard" ||
                location.pathname ===
                  "/WeekLyMonthLyDeploye/:reportType/:tabId" ||
                location.pathname === "/DeploymentFromPartner" ||
                location.pathname === "/DeploymentsFromInnovationM" ||
                location.pathname === "/ActiveDeployementsReport" ||
                location.pathname === "/ExpectedClientInformed" ||
                location.pathname === "/WeeklyMonthlyNotification" ||
                location.pathname === "/MonthlyWeeklyRampDown" ||
                location.pathname === "/UpcomingRampDownReport" ||
                location.pathname === "/ResourcesAllocationOnsite" ||
                location.pathname === "/AllocationOnsiteDescription" ||
                location.pathname === "/EditResourcesAllocationOnsite" ||
                location.pathname === "/AddResourcesAllocationOnsite" ||
                location.pathname === "/OnSiteChangeHistory" ||
                location.pathname === "/OnSite/TaskDetails" ||
                location.pathname === "/Onsite/TaskEdit"
                  ? style.active_btn
                  : style.btn_menu
              }
            >
              {<DescriptionIcon className={style.icon} />}
              <div className={style.heading}>Resources (Onsite)</div>
            </div>
          </Link>
        )}

        <div>
          {checkValidateAccess(rmsAccessCode?.sideBar_offshore) && (
            <Link to="/resourceManagement" style={{ textDecoration: "none" }}>
              <div
                className={
                  location.pathname === "/resourceManagement" ||
                  location.pathname === "/ResourcesOffshoreDescription" ||
                  location.pathname === "/EditResourcesOffshore" ||
                  location.pathname === "/AddResourcesOffshore" ||
                  location.pathname === "/OffshoreChangeHistory" ||
                  location.pathname === "/OffShore/TaskDetails" ||
                  location.pathname === "/Offshore/TaskEdit"
                    ? style.active_btn
                    : style.btn_menu
                }
              >
                {<GroupsIcon className={style.icon} />}
                <div className={style.heading}>Resources (Offshore)</div>
              </div>
            </Link>
          )}

          {/* {checkValidateAccess(rmsAccessCode?.sideBar_partial_pool) && (
            <Link to="/OffshoreReport" style={{ textDecoration: "none" }}>
              <div
                className={
                  location.pathname === "/OffshoreReport" ||
                    location.pathname === "/PartialPoolDescription"
                    ? style.active_btn
                    : style.btn_menu
                }
              >
                {<GroupsIcon className={style.icon} />}
                <div className={style.heading}>Resources (Partial Pool)</div>
              </div>
            </Link>
          )} */}

          {checkValidateAccess(rmsAccessCode?.final_stages) && (
            <Link to="/FinalStages" style={{ textDecoration: "none" }}>
              <div
                className={
                  location.pathname === "/FinalStages" ||
                  location.pathname === "/OfferAcceptedCandidate"
                    ? style.active_btn
                    : style.btn_menu
                }
              >
                {<GroupsIcon className={style.icon} />}
                <div className={style.heading}>Final Stages</div>
              </div>
            </Link>
          )}

          {checkValidateAccess(rmsAccessCode?.resource_by_skill) && (
            <Link to="/ResourceBySkill" style={{ textDecoration: "none" }}>
              <div
                className={
                  location.pathname === "/ResourceBySkill"
                    ? style.active_btn
                    : style.btn_menu
                }
              >
                {
                  <GiSkills
                    style={{ width: "24px", height: "24px" }}
                    className={style.icon}
                  />
                }
                <div className={style.heading}>Resource By Skill</div>
              </div>
            </Link>
          )}

          {checkValidateAccess(rmsAccessCode?.blocked_resources) && (
            <Link to="/BlockResourcesList" style={{ textDecoration: "none" }}>
              <div
                className={
                  location.pathname === "/BlockResourcesList" ||
                  location.pathname === "/AddBlockResources" ||
                  location.pathname === "/BlockResourcesView" ||
                  location.pathname === "/EditBlockResources"
                    ? style.active_btn
                    : style.btn_menu
                }
              >
                {<GroupsIcon className={style.icon} />}
                <div className={style.heading}>Resources (Blocked)</div>
              </div>
            </Link>
          )}
          {/* {checkValidateAccess(rmsAccessCode?.sideBar_onsite) && (
            <Link
              to="/ResourcesAllocationOnsite"
              style={{ textDecoration: "none" }}
            >
              <div
                className={
                  location.pathname === "/ResourcesAllocationOnsite" ||
                  location.pathname === "/AllocationOnsiteDescription" ||
                  location.pathname === "/EditResourcesAllocationOnsite" ||
                  location.pathname === "/AddResourcesAllocationOnsite" ||
                  location.pathname === "/OnSiteChangeHistory" ||
                  location.pathname === "/OnSite/TaskDetails" ||
                  location.pathname === "/Onsite/TaskEdit"
                    ? style.active_btn
                    : style.btn_menu
                }
              >
                {<GroupsIcon className={style.icon} />}
                <div className={style.heading}>Resources (Onsite)</div>
              </div>
            </Link>
          )} */}

          {/* <Link to="/ResourceJourney" style={{ textDecoration: "none" }}>
              <div
                className={
                  location.pathname === "/ResourceJourney"
                    ? style.active_btn
                    : style.btn_menu
                }
              >
                {<GroupsIcon className={style.icon} />}
                <div className={style.heading}>Resource Journey</div>
              </div>
            </Link> */}

          {checkValidateAccess(rmsAccessCode?.sideBar_report) && (
            <Link to="/resourceHistory" style={{ textDecoration: "none" }}>
              <div
                className={
                  location.pathname === "/resourceHistory"
                    ? style.active_btn
                    : style.btn_menu
                }
              >
                {<DescriptionIcon className={style.icon} />}
                <div className={style.heading}>Resources (History)</div>
              </div>
            </Link>
          )}

          {checkValidateAccess(rmsAccessCode?.sideBar_report) && (
            <Link to="/OffShoreRecord" style={{ textDecoration: "none" }}>
              <div
                className={
                  location.pathname === "/OffShoreRecord" ||
                  location.pathname === "/OnSiteRecord" ||
                  location.pathname === "/PoolRecord"
                    ? style.active_btn
                    : style.btn_menu
                }
              >
                {<DescriptionIcon className={style.icon} />}
                <div className={style.heading}>Report</div>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

import React, { useEffect, useState } from "react";
import style from "./Edituser.module.css";
import Select from "react-select";
import dropdownIcon from "../../../../assets/arrowdownIcon.png";
import calendarIcon from "../../../../assets/calendarIcon.png";
import DatePicker from "react-datepicker";
import Moment from "moment/moment";
import axios from "axios";
import cookie from "react-cookies";
import SideBar from "../../sidebar/SideBar";
import { toast, ToastContainer } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery as useQuery1, useMutation } from "react-query";
import CreatableSelect from "react-select/creatable";
import calender from "../../../../assets/calblue.png";
import cancel from "../../../../assets/cancell.png";
import { emailExp } from "../../../atsDashboard/utils/validation";
import {
  uploadResume,
  deleteResume,
  getAllDegrees,
  addDegree,
  getWorkExperienceByEmail,
} from "../../../../Utils/axios/api";
import { getAcademicsByEmail } from "../../../../Utils/axios/api";
import moment from "moment";
import { employeeId } from "../../../../Utils/constants";
import Loader from "../../../../GenericComponent/Loader/Loader";

import {
  getAllTechnology,
  getAllType,
  getSalaryRange,
  getAllFunctionalArea,
  getAllPartnerList,
  addSkill,
} from "../../../../Utils/axios/umsApi";
import { ImageProfileView } from "../commonComponent/ImageProfileView";
import { path } from "../../../../routes/PathNames";
import { notifyError } from "../../../atsDashboard/utils/notify";
import { validateMobileNumber } from "../createPosition/validation";
import { validateTotalExperience } from "../../utils/validation";
import { GrPowerReset } from "react-icons/gr";

const CreateUser = () => {
  const history = useHistory();

  const location = useLocation();
  const { id } = location.state || { id: "none" };
  let filterData = location?.state?.filterData;
  let currentPage = location?.state?.currentPage;
  let filter = location?.state?.filter;

  const url = process.env.REACT_APP_UMS_APP_URL;

  const [totalExpYear, setTotalExpYear] = useState(0);
  const [totalExpMonths, setTotalExpMonths] = useState(0);
  const [relevantExpYear, setRelevantExpYear] = useState(0);
  const [relevantExpMonths, setRelevantExpMonths] = useState(0);
  const [selectRole, setSelectRole] = useState();
  const [disableSub, setDisabledSub] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [selecttechnology, setSelscttechnology] = useState();
  const [role, setRole] = useState();
  const [state, setState] = useState({});
  const [validate, setValidate] = useState(false);
  const [emailValidation, setEmailValidation] = useState("Please Enter Email");
  const [personalEmailValidation, setPersonalEmailValidation] = useState(
    "Please Enter Personal Email"
  );
  const [personalValidateEmail, setPersonalValidateEmail] = useState(false);
  const [totalRe, setTotalRe] = useState("Please Enter Relevant Experience");
  const [validatetotalRe, setValidatetotalRe] = useState(false);
  const [totalEx, setTotalEx] = useState("Please Enter Total Experience");
  const [validatetotalEx, setValidatetotalEx] = useState(false);
  const [validateEmail, setValidateEmail] = useState(false);
  const [resumeName, setResumeName] = useState("");
  const [eventImg, setEventImg] = useState("")
  const [acadamicsArray, setAcadamicsArray] = useState([1, 2]);
  const [showImageProfile, setShowImageProfile] = useState(false);
  const [salaryRange, setSalaryRange] = useState();
  const [typeId, setTypeId] = useState(1);
  const [resumeUploadShow, setResumeUploadShow] = useState(false);
  const [eventUploadShow, setEventUploadShow] = useState(false);
  const [updatedSalaryRange, setUpdatedSalaryRange] = useState();
  const [salaryRangeId, setSalaryRangeId] = useState();
  const [salaryDataRange, setSalaryDataRange] = useState(null);
  const [salaryDataKey, setSalaryDataKey] = useState(null);
  const [count, setCount] = useState("");
  const [isRelGreater, setIsRelGreater] = useState(false)
  const [unpaid, setUnpaid] = useState(false)
  const [presentWorkIndex, setPresentWorkIndex] = useState(null);
  const [jobValidate, setJobValidate] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [changedIPR, setChangedIPR] = useState(null)

  console.log("unpaid", unpaid)

  const gradeStatusVal = [
    { value: 1, label: "CGPA" },
    { value: 2, label: "Percentage" },
    { value: 1, label: "Grade" }
  ]

  const getGradeStatusVal = (label) => {
    let val = gradeStatusVal.find(obj => obj.label === label);
    return val ? val.value : "";
  };

  useEffect(() => {
    const tempGS =
      (state?.basic ? parseFloat(state?.basic) : 0) +
      (state?.hra ? parseFloat(state?.hra) : 0) +
      (state?.oa ? parseFloat(state?.oa) : 0) +
      (state?.conveyance ? parseFloat(state?.conveyance) : 0) +
      (state?.bonus ? parseFloat(state?.bonus) : 0);
    setState((previous) => ({ ...previous, grossSalary: tempGS }));
    setSalaryRangeId(
      (tempGS ? parseFloat(tempGS) : 0) +
      (state?.employerPF ? parseFloat(state?.employerPF) : 0)
    );
  }, [
    state?.basic,
    state?.hra,
    state?.bonus,
    state?.conveyance,
    state?.oa,
    state?.employerPF,
  ]);

  useEffect(() => {
    setState({
      ...state,
      salary: salaryRangeId,
      currentYearlyCoreCTC: salaryRangeId * 12,
      currentMonthlyFullCTC:
        salaryRangeId +
        Number(state?.sa1) +
        Number(state?.sa2) +
        Number(state?.incentives) +
        Number(state?.variableBonus) +
        Number(state?.joiningBonus) +
        Number(state?.retentionBonus) +
        Number(state?.others) + 
        Number(state?.insurance) + 
        Number(state?.gratuity),
      currentYearlyFullCTC:
        (salaryRangeId +
          Number(state?.sa1) +
          Number(state?.sa2) +
          Number(state?.incentives) +
          Number(state?.variableBonus) +
          Number(state?.joiningBonus) +
          Number(state?.retentionBonus) +
          Number(state?.others) +
          Number(state?.insurance) + 
          Number(state?.gratuity)) *
        12,
    });

    const url = process.env.REACT_APP_UMS_APP_URL;
    axios
      .get(`${url}/getSalaryRangeBySalary`, {
        params: {
          currentMonthlyFullCTC: salaryRangeId * 12,
        },
      })
      .then((response) => {
        // Handle API response if needed
        setSalaryDataKey(response.data.responseData.key);
        setSalaryDataRange(response.data.responseData.label);
        setUpdatedSalaryRange(response.data.responseData.label);
        setCount(Date.now());
      })
      .catch((error) => {
        // Handle errors if any
        console.error("Error:", error); // Log the error to the console
      });
  }, [salaryRangeId]);

  useEffect(() => {
    setState({
      ...state,
      salaryRangeId: salaryDataKey,
    });
  }, [count]);
  

  console.log("State--- ", state)


  const handleDateAndTime = (date, value) => {
    // Get the current time
    const currentTime = moment();

    // Combine the selected date with current time for default cases
    let selectedDateWithTime = moment(date)
      .set({
        hour: 13,
        minute: 0,
        second: 0,
      })
      .format();

    // Apply constant time for "effectiveDate"
    // if (value === "effectiveDate") {
    //   selectedDateWithTime = moment(date)
    //     .set({
    //       hour: 13, // Constant hour
    //       minute: 0, // Constant minute
    //       second: 0, // Constant second
    //     })
    //     .format();
    // }


    // Update state based on the value
    if (value === "doj") {
      setState({ ...state, dateOfJoining: selectedDateWithTime });
    } else if (value === "dob") {
      setState({ ...state, dob: selectedDateWithTime });
    } else if (value === "marriageAnniversary") {
      setState({ ...state, marriageAnniversary: selectedDateWithTime });
    } else if (value === "effectiveDate") {
      setState({ ...state, salaryComponentsEffectiveFrom: selectedDateWithTime });
    }
  };




  const { data: getCoreSkillData } = useQuery1(
    ["getAllTechnology"],
    getAllTechnology,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: getAllPartnerListData } = useQuery1(
    ["getAllPartnerList"],
    getAllPartnerList,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: getAllTypeData } = useQuery1(
    ["getAllType"],
    () => getAllType(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getAllTypeOption = getAllTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  let index = getAllTypeOption?.findIndex((e) => e.label === "Partner");
  const { data: getAllDegreesData } = useQuery1(
    ["getAllDegreess"],
    getAllDegrees,
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleInputClick = (e) => {
    e.target.addEventListener("mousewheel", (ev) => {
      e.target.blur();
    });
  };

  const handleKeyDown = (e) => {
    // Prevent 'e' from being entered
    if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };

  const getAllDegreesOption =
    getAllDegreesData?.length > 0
      ? getAllDegreesData?.map((i) => ({
        value: i.id,
        label: i.name,
      }))
      : [];
  getAllTypeOption?.splice(index, 1);
  const getApplicationDetails = (id) => {
    axios
      .get(url + `/getUserById?useId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setUpdatedSalaryRange(res.data.responseData.salaryRangeName);
        setState({
          ...res.data.responseData,
          additionalSkill: res.data.responseData?.additionalSkill?.map((e) => ({
            value: e.additionalSkillId,
            label: e.additionalSkillName,
          })),
          uploadImageUrl: res.data.responseData?.uploadImageUrl,
          salaryAsProjectCosting:
            res.data.responseData?.reviseSalaryAsProjectCosting,
        });

        setTotalExpYear(~~(res.data.responseData.totalExperience / 12));
        setTotalExpMonths(res.data.responseData.totalExperience % 12);
        setRelevantExpYear(~~(res.data.responseData.relevantExperience / 12));
        setRelevantExpMonths(res.data.responseData.relevantExperience % 12);
        setSelectRole({
          label: res.data.responseData.employeeDesignation,
          value: res.data.responseData.employeeDesignationId,
        });

        setSelscttechnology({
          label: res.data.responseData.technology,
          value: res.data.responseData.technologyId,
        });
        setSalaryRange({
          label: res.data.responseData.salaryRangeName,
          value: res.data.responseData.salaryRangeId,
        });
        if (res.data.responseData.typeId === 2) {
          setChecked(true);
        } else {
          setChecked(false);
        }
        setTypeId(res.data.responseData.typeId);
        setClientId(res?.data?.responseData?.clientId);
      })
      .catch(() => { });
  };
  const PFApplicationOption = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const genderOption = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];
  const maritalStatusOption = [
    { value: "Married", label: "Married" },
    { value: "Unmarried", label: "Unmarried" },
  ];
  const locations = [
    { value: 1, label: "Offshore" },
    { value: 2, label: "Onsite" }
  ]

  const experienceMutation = useMutation(getWorkExperienceByEmail, {
    onSuccess: (res) => {
      let newData = res?.map((e, index) => {
        if (e.presentlyWorking === true) {
          return { ...e, present: index + 1 };
        } else {
          return { ...e, present: 0 };
        }
      });
      setState((prev) => ({
        ...prev,
        workExpResponseModels: newData,
      }));
    },
  });
  const mutationDegree = useMutation(addDegree, {
    onSuccess: (res) => {
      let academics = state?.educationResponseModels;

      for (let i = 0; i < academics.length; i++) {
        if (academics[i].degreeId.label === res?.name) {
          let obj = {
            label: res?.name,
            value: res?.id,
          };
          academics[i].degreeId = obj;
          break;
        }
      }
    },
  });

  useEffect(() => {
    if (unpaid) {
      setState({
        ...state,
        salary: "",
        sa1: "",
        sa2: "",
        incentives: "",
        variableBonus: "",
        joiningBonus: "",
        retentionBonus: "",
        others: "",
        insurance: "",
        hra: "",
        oa: "",
        basic: "",
        salaryComponentsEffectiveFrom: "",
        grossSalary: "",
        employeePF: "",
        employerPF: "",
        conveyance: "",
        bonus: "",
        currentYearlyCoreCTC: "",
        currentMonthlyFullCTC: "",
        currentYearlyFullCTC: "",
        salaryAsProjectCosting: "",
        salaryNotes: "",
        gratuity: "",
      });
    }
  }, [unpaid]);

  const handleUnpaidChange = () => {
    setUnpaid(!unpaid);
  };

  const CustomInputClgPY = React.forwardRef((props, ref) => {
    let temp = [...state?.educationResponseModels];
    return (
      <>
        {temp[props?.index]?.passingYear ? (
          <div>
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={cancel}
              width="15px"
              height="15px"
              alt="cancel"
              style={{
                position: "absolute",
                right: "5%",
                top: "16%",
                cursor: "pointer",
              }}
              onClick={() => {
                temp[props?.index].passingYear = null;
                setState({
                  ...state,
                  educationResponseModels: temp,
                });
              }}
            />
          </div>
        ) : (
          <div onClick={props.onClick}>
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={calender}
              alt="calender"
              style={{
                position: "absolute",
                right: "5%",
                top: "13%",
                cursor: "pointer",
              }}
            />
          </div>
        )}
      </>
    );
  });
  const getRole = () => {
    axios
      .get(url + `/getAllEmployeeRole`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setRole(res.data.responseData);
      })
      .catch(() => { });
  };
  useEffect(() => {
    setAcadamicsArray([
      ...Array(state?.educationResponseModels?.length).keys(),
    ]);
    setResumeName(state?.uploadImageUrl);
  }, [state]);

  useEffect(() => {
    if (state?.personalEmail?.length > 0) {
      setPersonalValidateEmail(false);
    }
  }, [state?.personalEmail]);

  const handleRedirect = (id) => {
    setTimeout(() => {
      history.push(path.ums_userDescription, {
        id,
        filterData,
        currentPage,
        filter,
      });
    }, 0);
  };
  const checkPartner = () => {
    if (true) {
      return false;
    } else if (state?.partnerId) {
      return false;
    } else {
      return true;
    }
  };
  const removeFieldFromArray = (data) => {
    const newData = data?.filter((e) => e?.companyName !== "");

    return newData;
  };
  const removeFieldFromAcademics = (data) => {
    const newData = data?.filter((e) => e?.degreeId && e?.passingYear !== "");
    return newData;
  };

  const handleUnpaidValidation = () => {
    if (unpaid) {
      return false;
    } else {
      if (
        state?.hra === null ||
        state?.hra === undefined ||
        state?.oa === null ||
        state?.oa === undefined ||
        state?.basic === null ||
        state?.basic === undefined ||
        state?.conveyance === null ||
        state?.conveyance === undefined ||
        !state.salaryAsProjectCosting ||
        !state.salary || !state.salaryComponentsEffectiveFrom
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const checkbond = () => {
    if (state?.bond && !state?.bondDate) {
      return true;
    } else {
      return false;
    }
  };

  const checkJobValidate = () => {
    state.workExpResponseModels.forEach((item, index) => {
      if (item.present === index + 1 && (!item?.startDate || !item.role || !item.reasonForChange)) {

        return true
      }
    })
    return false
  }

  const updateClient = (e) => {
    e.preventDefault();
    let filter = emailExp();
    let specialCheck = true;

    if (state?.email === "") {
      setEmailValidation("Please Enter Email ");
      setValidateEmail(true);
    } else if (!filter.test(state?.email)) {
      setEmailValidation("Please Enter Valid Email ");
      setValidateEmail(true);
    }
    // else if(!filter.test(state?.personalEmail)){
    //   setPersonalEmailValidation("Please Enter Valid Email")
    // } else {
    //   setValidateEmail(false);
    //   setPersonalEmailValidation(false);
    // }

    if (checkJobValidate) {
      setJobValidate(true);
    } else {
    }

    if (state?.personalEmail === "") {
      setPersonalEmailValidation("Please Enter Personal Email ");
      setPersonalValidateEmail(true);
    } else if (!filter.test(state?.personalEmail)) {
      setPersonalEmailValidation("Please Enter Valid Email ");
      setPersonalValidateEmail(true);
    } else {
      setPersonalValidateEmail(false);
    }

    if (totalExpYear === "" || totalExpMonths === "") {
      setTotalEx("Please Enter Total Experience");
      setValidatetotalEx(true);
    } else if (parseInt(totalExpMonths) > 12) {
      setTotalEx("Max 11 months allowed");
      setValidatetotalEx(true);
    } else {
      setValidatetotalEx(false);
    }
    if (relevantExpYear === "" || relevantExpMonths === "") {
      setTotalRe("Please Enter Relevant Experience");
      setValidatetotalRe(true);
    } else if (parseInt(relevantExpMonths) > 12) {
      setTotalRe("Max 11 months allowed");
      setValidatetotalRe(true);
    } else {
      setValidatetotalRe(false);
      specialCheck = false;
    }
    if (!specialCheck && !validatetotalEx && !validateTotalExperience(totalExpYear, totalExpMonths, relevantExpYear, relevantExpMonths)) {
      setIsRelGreater(true)
    } else {
      setIsRelGreater(false)
    }
    if (
      !state.type ||
      !state?.name?.trim() ||
      !state.gender ||
      !state.maritalStatus ||
      !(relevantExpYear !== "") ||
      !(relevantExpMonths !== "") ||
      !(totalExpMonths !== "") ||
      !(totalExpYear !== "") ||
      !filter.test(state?.email) ||
      !filter.test(state?.personalEmail) ||
      state?.personalEmail === "" ||
      // !state?.number ||
      // !state?.code?.trim() ||
      // state?.number.length < 10 ||
      // state?.number.length > 14 ||
      parseInt(relevantExpMonths) > 12 ||
      parseInt(totalExpMonths) > 12 ||
      // !salaryRange?.value ||
      checkPartner() ||
      validateMobileNumber(state?.number) ||
      validateMobileNumber(state?.whatsappNumber) ||
      !state?.functionalAreaId ||
      !selectRole?.value ||
      // !state?.employeeRole ||
      !selectRole?.value ||
      !state?.functionalAreaId ||
      // state?.employeePF === null ||
      // state?.employeePF === undefined ||
      !validateTotalExperience(totalExpYear, totalExpMonths, relevantExpYear, relevantExpMonths) ||
      handleUnpaidValidation() ||
      checkbond() ||
      checkJobValidate() ||
      !state?.location
      // ||
      // state?.bonus === null ||
      // state?.bonus === undefined ||
      // state?.employerPF === null ||
      // state?.employerPF === undefined
    ) {
      setValidate(true);
    } else {
      setDisabledSub(true);
      const data = {
        id: id,
        linkedInUrl: state?.linkedInUrl,
        emergencyContactName: state?.emergencyContactName,
        emergencyContactNumber: state?.emergencyContactNumber,
        folderUrl: state?.folderUrl,
        type: typeId,
        code: state?.code?.trim(),
        name: state?.name?.trim(),
        partnerId: state?.partnerId,
        partnerName: state?.partnerName,
        email: state?.email,
        number: state?.number,
        whatsappNumber: state?.whatsappNumber,
        employeeRole: selectRole?.value,
        careerStartDate: state?.careerStartDate,
        dateOfJoining: state?.dateOfJoining,
        empId: employeeId,
        relevantExperience:
          Number(relevantExpYear * 12) + Number(relevantExpMonths),
        totalExperience: Number(totalExpYear * 12) + Number(totalExpMonths),
        technology: selecttechnology?.value,
        salaryRangeId: salaryDataKey,
        functionalAreaId: state?.functionalAreaId,
        additionalSkills: state?.additionalSkill?.map((e) => e.value),
        personalEmail: state?.personalEmail,
        bond: state?.bond,
        bondDate: state?.bondDate,
        gender: state?.gender,
        maritalStatus: state?.maritalStatus,
        uploadImageUrl: state?.uploadImageUrl,
        eventImageUrl: state?.eventImageUrl,
        note: state?.note,
        location: state?.location,
        educationRequestModels: removeFieldFromAcademics(
          state?.educationResponseModels
        )?.map((e) => ({
          board: e?.board,
          degreeId: e?.degreeId?.value ? e?.degreeId?.value : e?.degreeId,
          grade: e?.grade,
          status: e?.status,
          passingYear: e?.passingYear,
        })),
        dob: state?.dob,
        isBGVeligible: state?.isBGVeligible,
        marriageAnniversary: state?.marriageAnniversary,
        workExpRequestModels: removeFieldFromArray(
          state?.workExpResponseModels
        ),
        salary: state?.salary,
        sa1: state?.sa1,
        sa2: state?.sa2,
        incentives: state?.incentives,
        variableBonus: state?.variableBonus,
        joiningBonus: state?.joiningBonus,
        retentionBonus: state?.retentionBonus,
        others: state?.others,
        insurance: state?.insurance,
        currentYearlyCoreCTC: state?.currentYearlyCoreCTC,
        currentMonthlyFullCTC: state?.currentMonthlyFullCTC,
        currentYearlyFullCTC: state?.currentYearlyFullCTC,
        salaryAsProjectCosting: state?.salaryAsProjectCosting,
        salaryNotes: state?.salaryNotes,
        changeByEmpId: employeeId,
        pfApplicable: state?.pfApplicable,
        hra: state?.hra,
        oa: state?.oa,
        basic: state?.basic,
        salaryComponentsEffectiveFrom: state?.salaryComponentsEffectiveFrom,
        grossSalary: state?.grossSalary,
        employeePF: state?.employeePF,
        employerPF: state?.employerPF,
        conveyance: state?.conveyance,
        bonus: state?.bonus,
        gratuity: state?.gratuity,
        clientId
      };

      axios
        .post(url + "/submitUser", data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        })
        .then((res) => {
          setDisabledSub(false);
          if (res.data.responseStatus.statusCode === 1) {
            notify1(res.data.responseData.message);
          } else {
            // setId(res.data.responseData.id)
            notifySuccess();
            handleRedirect(res.data.responseData.id);
          }
        })
        .catch(() => {
          setDisabledSub(false);
        });
    }
  };

  useEffect(() => {
    getApplicationDetails(id);
    getRole();
  }, []);

  const handleChange = () => {
    setChecked(!checked);
    if (typeId === 2) {
      setState({
        ...state,
        type: null,
      });
    }
    setChangedIPR(true)
  };

  const getpartnerlist = getAllPartnerListData?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          alt="dropdownIcon"
          style={{
            position: "absolute",
            top: "32%",
            right: "6%",
            width: "18px",
          }}
        />
      </span>
    );
  };
  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label ref={ref} style={{ padding: "8px", fontWeight: 600 }}>
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          alt="cancel"
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "30%",
            zIndex: "0",
            cursor: "pointer",
          }}
          onClick={() => setState({ ...state, [props?.properties]: "" })}
        />
      </div>
    );
  });
  const CustomInputClgWXS = React.forwardRef((props, ref) => {
    let temp = [...state?.workExpResponseModels];
    return (
      <>
        {temp[props?.index]?.startDate ? (
          <div>
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={cancel}
              alt="cancel"
              width="15px"
              height="15px"
              style={{
                position: "absolute",
                right: "5%",
                top: "16%",
                cursor: "pointer",
              }}
              onClick={() => {
                temp[props?.index].startDate = null;
                setState({
                  ...state,
                  workExpResponseModels: temp,
                });
              }}
            />
          </div>
        ) : (
          <div onClick={props.onClick}>
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={calender}
              alt="calender"
              style={{
                position: "absolute",
                right: "5%",
                top: "13%",
                cursor: "pointer",
              }}
            />
          </div>
        )}
      </>
    );
  });
  const CustomInputClgWXEND = React.forwardRef((props, ref) => {
    let temp = [...state?.workExpResponseModels];
    return (
      <>
        {temp[props?.index]?.endDate ? (
          <div>
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={cancel}
              width="15px"
              alt="cancel"
              height="15px"
              style={{
                position: "absolute",
                right: "5%",
                top: "16%",
                cursor: "pointer",
              }}
              onClick={() => {
                temp[props?.index].endDate = null;
                setState({
                  ...state,
                  workExpResponseModels: temp,
                });
              }}
            />
          </div>
        ) : (

          <div
            onClick={
              temp[props.index]?.presentlyWorking
                ? null
                : props.onClick
            }
          >
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={calender}
              alt="calender"
              style={{
                position: "absolute",
                right: "5%",
                top: "13%",
                cursor: "pointer",
              }}
            />
          </div>
        )}
      </>
    );
  });
  const uploadResumeMutation = useMutation(uploadResume, {
    onSuccess: (data) => {
      setState({
        ...state,
        uploadImageUrl: data,
      });
      setResumeUploadShow(false);
    },
  });
  const uploadEventImgMutation = useMutation(uploadResume, {
    onSuccess: (data) => {
      setState({
        ...state,
        eventImageUrl: data,
      });
      setEventUploadShow(false);
    },
  });
  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label ref={ref} style={{ padding: "8px", fontWeight: 600 }}>
          {props.value || props.placeholder}
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "13%", zIndex: "0" }}
          src={calendarIcon}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });

  const getRolelist = role?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getTechnologyList = getCoreSkillData?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const { data: getSalaryRangeData } = useQuery1(
    ["getSalaryRange"],
    getSalaryRange,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: getFunctionalData } = useQuery1(
    ["getAllFunctionalArea"],
    getAllFunctionalArea,
    {
      refetchOnWindowFocus: false,
    }
  );
  const getSalaryRangeOption = getSalaryRangeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  const getFunctionOption = getFunctionalData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const customStyles = {
    control: () => ({
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: "transparent 0% 0% no-repeat padding-box",
      backgroundColor: "#f5f6fa",
      minWidth: "inherit",
      margin: "8px 1.5% 0px 1.7%",
      minHeight: "38px",
      outline: "none",
      fontWeight: 500,
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(78 206 199)",
      },
      backgroundColor: state.isSelected ? "rgb(78 206 199)" : "white",
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),

    placeholder: () => ({
      display: "none",
    }),
  };

  const addSkillMutation = useMutation(addSkill, {
    onSuccess: (res) => {
      let selected = state?.additionalSkill;
      selected.pop();

      selected = [
        ...selected,
        {
          value: res?.additionalSkillId,
          label: res?.additionalSkillName,
        },
      ];
      let items = {};
      selected?.forEach((element) => {
        if (!items[element?.value]) {
          items[element?.value] = element;
        }
      });
      let newData = Object.values(items);
      setState((prevData) => ({
        ...prevData,
        additionalSkill: newData,
      }));
    },
  });
  const isSameUser = (a, b) => a.value === b?.value && a?.label === b?.label;
  const handleCross = (left, right, compareFunction) => {
    if (!left) {
      return;
    }
    return left.filter(
      (leftValue) =>
        !right.some((rightValue) => compareFunction(leftValue, rightValue))
    );
  };
  const handleSelect = (e) => {
    setState((prevData) => ({
      ...prevData,
      additionalSkill: e.map((i) => ({
        value: i.value,
        label: i?.label,
      })),
    }));
    let prevSelect = handleCross(state?.additionalSkill, e, isSameUser);

    if (prevSelect?.length > 0) {
      return;
    }
    if (
      getTechnologyList?.some((item) => item.label === e[e.length - 1]?.label)
    ) {
    } else {
      if (e?.length > 0) {
        setTimeout(() => {
          addSkillMutation.mutate({
            skill: e[e.length - 1]?.label,
          });
        }, 1000);
      }
    }
  };


  const handleDateChange = (date, index, type) => {
    let temp = [...state?.workExpResponseModels];
    if (type === "start") {
      temp[index].startDate = moment(date).format();
      if (moment(date).isAfter(temp[index].endDate)) {
        temp[index].endDate = null;
      }
    } else {
      if (moment(date).isBefore(temp[index].startDate)) {
        notify1("End date cannot be before start date");
      } else {
        temp[index].endDate = moment(date).format();
      }
    }
    setState({
      ...state,
      workExpResponseModels: temp,
    });
  };

  const notifySuccess = () =>
    toast.success("User Edit Successfully.", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  const notify1 = (error) =>
    toast.error(error, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  const bondOption = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const handleImage = (e) => {
    const fileName = e.target.files[0].name;
    if (
      fileName?.split(".")[1]?.toLowerCase() === "png" ||
      fileName?.split(".")[1]?.toLowerCase() === "jpg" ||
      fileName?.split(".")[1]?.toLowerCase() === "jpeg"
    ) {
      uploadResumeMutation.mutate(e.target.files[0]);
    } else {
      notifyError("Please select png, jpg or jpeg file only");
    }
    setResumeUploadShow(true);
  };
  const handleImage1 = (e) => {
    const fileName = e.target.files[0].name;
    if (
      fileName?.split(".")[1]?.toLowerCase() === "png" ||
      fileName?.split(".")[1]?.toLowerCase() === "jpg" ||
      fileName?.split(".")[1]?.toLowerCase() === "jpeg"
    ) {
      uploadEventImgMutation.mutate(e.target.files[0]);
    } else {
      notifyError("Please select png, jpg or jpeg file only");
    }
    setEventUploadShow(true);
  };
  const academicsMutation = useMutation(getAcademicsByEmail, {
    onSuccess: (res) => {
      let newData = res.map((e) => {
        return { ...e, degree: { value: e.degreeId, label: e.degree } };
      });
      setState((prevData) => ({
        ...prevData,
        educationResponseModels: newData,
      }));
      let arr = [];
      for (let i = 0; i < res?.length; i++) {
        arr.push(i);
      }
      setAcadamicsArray(arr);
    },
  });
  const bgvOption = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  function parseDate(dateString) {
    const [day, month, year] = dateString.split('-');
    return new Date(`${year}-${month}-${day}`);
  }

  const handleBondDateChange = (date) => {

    const parsedDate1 = parseDate(moment(date).format("DD-MM-YYYY"));
    const parsedDate2 = parseDate(moment(state.dateOfJoining).format("DD-MM-YYYY"));
    if (parsedDate1 && parsedDate1 <= parsedDate2) {
      notifyError("Bond End Date should be greater than Date of Joining");
    } else {
      setState({ ...state, bondDate: date })
    }
  };

  useEffect(() => {
    if (state?.eventImageUrl) {
      setEventImg(state.eventImageUrl);
    }
  }, [state.eventImageUrl]);

  useEffect(() => {
    if (checked && changedIPR === true) {
      setState({
        ...state,
        partnerId: "",
        partnerName: "",
        type: "",
      });
      setTypeId("");
    } else if (changedIPR === true) {
      setState({
        ...state,
        type: "",
      });
      setTypeId("");
    }
  }, [checked])

  return (
    <>
      <ToastContainer />
      {/*<Header />*/}

      <div className={style.main_user_body}>
        {resumeUploadShow && Loader}

        <SideBar />
        <div className={style.main_create}>
          <div className={style.breadscrumb} style={{ padding: "6px " }}></div>

          <div className={style.breadscrumb} style={{ padding: "0px" }}>
            <p className={style.creatUser}>Edit User</p>
          </div>
          <div className={style.createposition}>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>
                  <input
                    type="checkbox"
                    id="demoCheckbox"
                    name="checkbox"
                    value="1"
                    onChange={(e) => handleChange()}
                    style={{
                      visibility: "visible",
                      height: "20px",
                      width: "25px",
                      accentColor: "#289e98"
                    }}
                    checked={checked}
                  />
                  <label
                    for="demoCheckbox"
                    className={style.cu_label}
                    style={{ color: "black" }}
                  >
                    Is partner resource?
                    <span style={{ color: "red" }}>*</span>
                  </label>
                </p>
                {checked ? (
                  <Select
                    styles={customStyles}
                    // selected={state?.type}
                    // defaultValue={getpartnerlist.find(
                    //   (e) => e.label == partnerName
                    // )}
                    value={
                      state?.partnerId
                        ? {
                          value: state?.partnerId,
                          label: state?.partnerName,
                        }
                        : null
                    }
                    classNamePrefix={"create_user"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      setState({
                        ...state,
                        partnerId: e.value,
                        partnerName: e.label,
                        type: 2,
                      });
                      setTypeId(2);
                    }}
                    options={getpartnerlist}
                  />
                ) : (
                  <>
                    <Select
                      styles={customStyles}
                      classNamePrefix={"create_user"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={{
                        value: state?.type,
                        label: state?.type,
                      }}
                      onChange={(e) => {
                        setState({
                          ...state,
                          type: e.value,
                          type: e.label,
                        });
                        setTypeId(e.value);
                      }}
                      options={getAllTypeOption}
                    />
                  </>
                )}
                {!checked && (
                  <p
                    className={
                      validate && !state?.type
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Employee Type
                  </p>
                )}
                {checked && (
                  <p
                    className={
                      validate && !state?.partnerId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Partner Resource
                  </p>
                )}
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>
                  Name <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  value={state?.name}
                  className={style.cu_field2}
                  // placeholder="Search by User Name"
                  onChange={(e) => setState({ ...state, name: e.target.value })}
                ></input>
                <p
                  className={
                    validate && !state?.name?.trim()
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Name
                </p>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  Email <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  value={state?.email || ""}
                  className={style.cu_field2}
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                  onKeyDown={() => setEmailValidation("")}
                ></input>
                <p
                  className={
                    validateEmail ? style.errorText : style.errorTextHidden
                  }
                >
                  {emailValidation}
                </p>
              </div>
            </div>
            {/* Second */}
            <div className={style.Cu_row1} style={{ marginTop: "4px" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>
                  Personal Email <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  value={state?.personalEmail || ""}
                  className={style.cu_field2}
                  onChange={(e) =>
                    setState({ ...state, personalEmail: e.target.value })
                  }
                ></input>
                <p
                  className={
                    personalValidateEmail ? style.errorText : style.errorTextHidden
                  }
                >
                  {personalEmailValidation}
                </p>
              </div>

              <div className={style.cu_col2} style={{ marginLeft: "11px" }}>
                <p className={style.cu_label}>Mobile Number</p>
                <div className={style.cu_row2_col2}>
                  <input
                    defaultValue={state?.number}
                    className={style.cu_field2}
                    // placeholder="Search by User Name"
                    type={"text"}
                    onChange={(e) =>
                      setState({ ...state, number: e.target.value })
                    }
                  ></input>
                </div>
                <p
                  className={
                    validate && validateMobileNumber(state?.number)
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Valid Mobile Number
                </p>
              </div>


              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  Marital Status<span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={maritalStatusOption?.find(
                    (e) => e.value === state?.maritalStatus
                  )}
                  onChange={(e) => {
                    setState({
                      ...state,
                      maritalStatus: e.value,
                    });
                  }}
                  options={maritalStatusOption}
                />
                <p
                  className={
                    validate && !state?.maritalStatus
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Marital Status
                </p>
              </div>
            </div>
            <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>
                  Gender<span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={genderOption?.find((e) => e.value === state?.gender)}
                  onChange={(e) => {
                    setState({
                      ...state,
                      gender: e.value,
                    });
                  }}
                  options={genderOption}
                />
                <p
                  className={
                    validate && !state?.gender
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Gender
                </p>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>WhatsApp Mobile Number</p>
                <div className={style.cu_row2_col2}>
                  <input
                    defaultValue={state?.whatsappNumber}
                    className={style.cu_field2}
                    // placeholder="Search by User Name"
                    type={"text"}
                    onChange={(e) =>
                      setState({ ...state, whatsappNumber: e.target.value })
                    }
                  ></input>
                </div>
                {/* <p
                  className={
                    validateNumber ? style.errorText : style.errorTextHidden
                  }
                >
                  {numerror}
                </p> */}
                <p
                  className={
                    validate && validateMobileNumber(state?.whatsappNumber)
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Valid Whatsapp Mobile Number
                </p>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  Career Start Date <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.csd}>
                  <DatePicker
                    dateFormat="dd MMM yy"
                    // minDate={new Date()}
                    // selected={state.careerStartDate}
                    startDate={null}
                    value={Moment(state?.careerStartDate).format("D MMM YY")}
                    // onChange={(date) => setStartDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) =>
                      setState({ ...state, careerStartDate: date })
                    }
                    defaultValue={null}
                    customInput={<CustomInputClg />}
                    style={{ backgroundColor: "#F5F6FA" }}
                  />
                </div>
              </div>
            </div>
            <div className={style.Cu_row1} style={{ marginTop: "4px" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>
                  Total Experience <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.cu_row2_col2}>
                  <div style={{ width: '100%', marginRight: "1rem" }}>
                    <input
                      style={{ width: '100%' }}
                      type={"number"}
                      className={style.cu_row_field2}
                      placeholder="Years"
                      value={totalExpYear}
                      onChange={(e) => setTotalExpYear(() => e.target.value)}
                      // onKeyDown={() => setValidatetotalEx(false)}
                      onKeyDown={handleKeyDown}
                      onClick={handleInputClick}
                    ></input>
                    {!isRelGreater && totalEx === "Please Enter Total Experience" && (<p
                      // style={{display: isRelGreater && "none"}}
                      className={
                        validatetotalEx ? style.errorText : style.errorTextHidden
                      }
                    >
                      {totalEx}
                    </p>)}
                    {!validateTotalExperience(totalExpYear, totalExpMonths, relevantExpYear, relevantExpMonths) && relevantExpYear && relevantExpMonths && (<p
                      style={{ display: isRelGreater ? "block" : 'none' }}
                      className={
                        isRelGreater ? style.errorText : style.errorTextHidden
                      }
                    >
                      Relevant Experience should be less than Total Experience
                    </p>)}
                  </div>
                  <div style={{ width: "100%" }}>
                    <input
                      style={{ width: '100%' }}
                      type={"number"}
                      value={totalExpMonths}
                      max={13}
                      className={style.cu_row_field2}
                      placeholder="Months"
                      onChange={(e) => setTotalExpMonths(() => e.target.value)}
                      // onKeyDown={() => setValidatetotalEx(false)}
                      onKeyDown={handleKeyDown}
                      onClick={handleInputClick}
                    ></input>
                    {totalEx === "Max 11 months allowed" || totalExpMonths <= 0 && (
                      <p
                        className={
                          validatetotalEx
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        {totalEx}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className={style.cu_col2} style={{ marginLeft: "10px" }}>
                <p className={style.cu_label}>
                  Relevant Experience <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.cu_row2_col2}>
                  <div style={{ width: '100%', marginRight: '1rem' }}>
                    <input
                      style={{ width: '100%' }}
                      type={"number"}
                      className={style.cu_row_field2}
                      placeholder="Years"
                      onChange={(e) => setRelevantExpYear(() => e.target.value)}
                      value={relevantExpYear}
                      onKeyDown={handleKeyDown}
                      onClick={handleInputClick}
                    ></input>
                    {totalRe === "Please Enter Relevant Experience" && (<p
                      className={
                        validatetotalRe ? style.errorText : style.errorTextHidden
                      }
                    >
                      {totalRe}
                    </p>)}
                  </div>
                  <div style={{ width: '100%' }}>
                    <input style={{ width: '100%' }}
                      type={"number"}
                      onChange={(e) => setRelevantExpMonths(() => e.target.value)}
                      className={style.cu_row_field2}
                      placeholder="Months"
                      value={relevantExpMonths}
                      onKeyDown={handleKeyDown}
                      onClick={handleInputClick}
                    ></input>
                    {totalRe === "Max 11 months allowed" && (<p
                      className={
                        validatetotalRe ? style.errorText : style.errorTextHidden
                      }
                    >
                      {totalRe}
                    </p>)}
                  </div>
                </div>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  Date of Joining <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.csd}>
                  <DatePicker
                    dateFormat="dd MMM yy"
                    value={Moment(state?.dateOfJoining).format("D MMM YY")}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) => handleDateAndTime(date, "doj")}
                    disabledKeyboardNavigation
                    defaultValue={null}
                    customInput={<CustomInputClg />}
                    style={{ backgroundColor: "#F5F6FA" }}
                  />
                </div>
              </div>
            </div>
            {/* three */}
            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Technology</p>
                <Select
                  styles={customStyles}
                  menuPlacement="top"
                  value={selecttechnology}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) =>
                    setSelscttechnology({ label: e.label, value: e.value })
                  }
                  options={getTechnologyList}
                />
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Additional Skill</p>
                <CreatableSelect
                  menuPlacement="top"
                  isMulti
                  styles={customStyles}
                  value={state?.additionalSkill}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => handleSelect(e)}
                  options={getTechnologyList}
                  formatCreateLabel={(userInput) => `+ Add "${userInput}"`}
                />
                {/*     <p
                    className={
                      validate && !state?.functionalAreaId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Functional Area
                  </p>*/}
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  Designation<span style={{ color: "red" }}>*</span>
                </p>

                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  value={selectRole}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) =>
                    setSelectRole({ label: e.label, value: e.value })
                  }
                  //   onChange={(e) => { setUsername(e.value); setState({ ...state, code: e.label }) }}
                  options={getRolelist}
                />
                <p
                  className={
                    validate && !selectRole?.value
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Designation
                </p>
              </div>
            </div>
            {/* Four */}
            {
              <div className={style.Cu_row1} style={{ marginTop: "1.5rem" }}>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>
                    Salary Range
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </p>
                  <div
                    className={style.cu_field2}
                    // placeholder="Search by User Name"
                    type={"text"}
                    min={0}
                  >
                    {updatedSalaryRange}
                  </div>
                  {/* <Select
                    styles={customStyles}
                    value={salaryRange}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) =>
                      setSalaryRange({ label: e.label, value: e.value })
                    }
                    options={getSalaryRangeOption}
                  /> */}
                  {/* <p
                  className={
                    validate && !salaryRange?.value
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Salary Range
                </p> */}
                </div>
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>
                    Functional Area<span style={{ color: "red" }}>*</span>
                  </p>
                  <Select
                    styles={customStyles}
                    menuPlacement="top"
                    value={getFunctionOption?.find(
                      (i) => i?.value === state?.functionalAreaId
                    )}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) =>
                      // setSelscttechnology({ label: e.label, value: e.value })
                      setState((prevData) => ({
                        ...prevData,
                        functionalAreaId: e?.value,
                      }))
                    }
                    options={getFunctionOption}
                  />
                  <p
                    className={
                      validate && !state?.functionalAreaId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Functional Area
                  </p>
                </div>
                <div className={style.cu_col3}>
                  <p className={style.cu_label}>Bond</p>

                  <Select
                    styles={customStyles}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    value={bondOption?.find((e) => e.value === state?.bond)}
                    onChange={(e) => {
                      if (!e.value) {
                        setState({
                          ...state,
                          bondDate: "",
                          bond: e.value,
                        });
                      } else {
                        setState({
                          ...state,
                          bond: e.value,
                        });
                      }
                    }}
                    options={bondOption}
                  />
                </div>
              </div>
            }
            {/* Five */}
            {
              <div className={style.Cu_row1} style={{ marginTop: "1.5rem" }}>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>Bond End Date<span style={{ color: "red" }}>*</span></p>
                  <div
                    className={style.csd}
                    style={!state?.bond ? { background: "#8f8b8b69" } : null}
                  >
                    <DatePicker
                      dateFormat="dd MMM yy"
                      value={
                        state?.bondDate &&
                        Moment(state?.bondDate).format("D MMM YY")
                      }
                      onChange={handleBondDateChange}
                      // onChange={(date) =>
                      //   setState({ ...state, bondDate: date })
                      // }
                      disabledKeyboardNavigation
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      defaultValue={null}
                      customInput={
                        state?.bondDate ? (
                          <CustomInputCross properties="bondDate" />
                        ) : (
                          <CustomInputClg />
                        )
                      }
                      disabled={!state?.bond}
                      style={{ backgroundColor: "red" }}
                    />
                  </div>
                  <p
                    className={
                      state?.bond && validate && !state?.bondDate
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Bond End Date
                  </p>
                </div>
                <div className={style.cu_col2}>
                  {!resumeName && (
                    <span className={style.cu_label}>Upload Profile Image</span>
                  )}
                  <div className={style.Cu_row1}>
                    {resumeName ? (
                      <>
                        <div className={style.imageupload}>
                          <label for="file-input">
                            <img
                              id="image"
                              src={resumeName}
                              alt="resumeName"
                              style={{
                                borderRadius: "50%",
                                width: "4.5rem",
                                height: "4.5rem",
                                border: "1px solid black",
                                cursor: "pointer",
                              }}
                              onClick={() => setShowImageProfile(true)}
                            />
                          </label>
                          <p
                            onClick={() => {
                              setState({ ...state, uploadImageUrl: "" });
                              setResumeName(null);
                              deleteResume(state?.uploadImageUrl);
                            }}
                            className={style.rmpdfcross}
                          >
                            x
                          </p>
                        </div>
                      </>
                    ) : (
                      <input
                        id="file-input"
                        className={style.upload_resume}
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          handleImage(e);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className={style.cu_col3}>
                  <p className={style.cu_label}>Date of Birth</p>
                  <div className={style.csd}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      value={state?.dob && Moment(state?.dob).format("D MMM YY")}
                      onChange={(date) => handleDateAndTime(date, "dob")}
                      disabledKeyboardNavigation
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      defaultValue={null}
                      customInput={
                        state?.dob ? (
                          <CustomInputCross properties="dob" />
                        ) : (
                          <CustomInputClg />
                        )
                      }
                      style={{ backgroundColor: "red" }}
                    />
                  </div>
                </div>
              </div>
            }
            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Marriage Anniversary</p>
                <div className={style.csd}>
                  <DatePicker
                    dateFormat="dd MMM yy"
                    value={
                      state?.marriageAnniversary &&
                      Moment(state?.marriageAnniversary).format("D MMM YY")
                    }
                    onChange={(date) => handleDateAndTime(date, "marriageAnniversary")}
                    disabledKeyboardNavigation
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    defaultValue={null}
                    customInput={
                      state?.marriageAnniversary ? (
                        <CustomInputCross properties="marriageAnniversary" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                    style={{ backgroundColor: "red" }}
                  />
                </div>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>LinkedIn URL</p>
                <div className={style.cu_row2_col2}>
                  <input
                    className={style.cu_field2}
                    type="email"
                    value={state?.linkedInUrl}
                    onChange={(e) =>
                      setState({
                        ...state,
                        linkedInUrl: e.target.value,
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>Folder Url</p>
                <input
                  className={style.cu_field2}
                  type="url"
                  value={state?.folderUrl}
                  style={{ marginLeft: "4px" }}
                  onChange={(e) =>
                    setState({
                      ...state,
                      folderUrl: e.target.value,
                    })
                  }
                ></input>
              </div>
            </div>

            <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>BGV Applicable </p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={bgvOption?.find(
                    (e) => e.value === state?.isBGVeligible
                  )}
                  onChange={(e) => {
                    setState({
                      ...state,
                      isBGVeligible: e.value,
                    });
                  }}
                  options={bgvOption}
                />
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>PF Applicable</p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={PFApplicationOption?.find(
                    (e) => e.value === state?.pfApplicable
                  )}
                  onChange={(e) => {
                    setState({
                      ...state,
                      pfApplicable: e.value,
                    });
                  }}
                  options={PFApplicationOption}
                />
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  Location <span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={locations.find(e => e.label === state?.location)}
                  onChange={(e) => {
                    setState({
                      ...state,
                      location: e.label
                    })
                  }}
                  options={locations}
                />
                <p
                  className={
                    !state?.location && validate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Location
                </p>
              </div>
            </div>
            <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Emergency Contact Name</p>
                <div className={style.cu_row2_col2}>
                  <input
                    className={style.cu_field2}
                    type="text"
                    value={state?.emergencyContactName}
                    onChange={(e) =>
                      setState({
                        ...state,
                        emergencyContactName: e.target.value,
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>
                  Emergency Contact Number
                  {/* <span style={{ color: "red" }}>*</span> */}
                </p>
                <div className={style.cu_row2_col2}>
                  <input
                    defaultValue={state?.emergencyContactNumber}
                    className={style.cu_field2}
                    type={"Number"}
                    value={state?.emergencyContactNumber}
                    onChange={(e) =>
                      setState({
                        ...state,
                        emergencyContactNumber: e.target.value,
                      })
                    }
                  // onKeyDown={() => setNumError("")}
                  ></input>
                </div>
                <p
                  className={
                    validate && validateMobileNumber(state?.emergencyContactNumber)
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Valid Emergency Contact Number
                </p>
              </div>
              <div className={style.cu_col3}>
                {!eventImg && (
                  <span className={style.cu_label}>Upload Additional Image</span>
                )}
                <div className={style.Cu_row1}>
                  {eventImg ? (
                    <>
                      <div className={style.imageupload}>
                        <label for="file-input">
                          <img
                            id="image"
                            src={eventImg}
                            alt="eventImg"
                            style={{
                              borderRadius: "50%",
                              width: "4.5rem",
                              height: "4.5rem",
                              border: "1px solid black",
                              cursor: "pointer",
                            }}
                            onClick={() => setShowImageProfile(true)}
                          />
                        </label>
                        <p
                          onClick={() => {
                            setState({ ...state, eventImageUrl: "" });
                            setEventImg(null);
                            deleteResume(state?.eventImageUrl);
                          }}
                          className={style.rmpdfcross}
                        >
                          x
                        </p>
                      </div>
                    </>
                  ) : (
                    <div>
                      <input
                        id="file-input"
                        className={style.upload_resume}
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          handleImage1(e);
                        }}
                      />
                      {/* <p
                        className={
                          !state?.eventImageUrl && validate
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please Upload Additional Image
                      </p> */}
                    </div>
                  )}
                </div>

              </div>
              <ImageProfileView
                show={showImageProfile}
                image={eventImg}
                okaybtn={() => setShowImageProfile(false)}
              ></ImageProfileView>
            </div>
            {/* <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>
                  Salary ( As on Date)<span style={{ color: "red" }}>*</span>{" "}
                </p>
                <div className={style.cu_row2_col2}>
                  <input
                    className={style.cu_field2}
                    type="number"
                    value={state?.salary}
                    onChange={(e) =>
                      setState({
                        ...state,
                        salary: e.target.value,
                      })
                    }
                  ></input>
                </div>
                <p
                  className={validate && !state?.salary ? style.errorText : style.errorTextHidden}
                >
                  Please Enter Salary ( As on Date)
                </p>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>
                  Salary (As per Project & Costing ){" "}
                </p>
                <input
                  className={style.cu_field2}
                  type="number"
                  value={state?.salaryAsProjectCosting}
                  onChange={(e) =>
                    setState({
                      ...state,
                      salaryAsProjectCosting: e.target.value,
                    })
                  }
                ></input>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  Salary Notes
                </p>
                <input
                  className={style.cu_field2}
                  type="textarea"
                  value={state?.salaryNotes}
                  onChange={(e) =>
                    setState({
                      ...state,
                      salaryNotes: e.target.value,
                    })
                  }
                ></input>
              </div>
            </div> */}

            <ImageProfileView
              show={showImageProfile}
              image={resumeName}
              okaybtn={() => setShowImageProfile(false)}
            ></ImageProfileView>
            <div className={style.Cu_row1} style={{ marginTop: "-1.6rem" }}>
              <div className={style.cu_col1}>

              </div>
            </div>

            {/*Note*/}
            <div className={style.Cu_notes} style={{ marginTop: "1rem" }}>
              <p className={style.cu_label}>Notes</p>
              <div>
                <textarea
                  className={style.notes}
                  type="textarea"
                  value={state?.note}
                  onChange={(e) =>
                    setState((prevData) => ({
                      ...prevData,
                      note: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div className={style.createposition}>
            <p className={style.title}>Work Experience</p>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                <p className={style.cu_label}>Company Name</p>
              </div>
              <div className={style.cu_col2_to} style={{ flex: "0 0 23%" }}>
                <div className={style.multiinput}>
                  <div className={style.cu_label}>From</div>
                  <div className={style.cu_label} style={{ paddingRight: "34%" }}>
                    To
                  </div>
                </div>
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 23%" }}>
                <p className={style.cu_label}>Role</p>
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 23%" }}>
                <p className={style.cu_label}>Reason For Change</p>
              </div>
            </div>
            {state?.workExpResponseModels?.map((item, index) => {
              return (
                <div className={style.workExpResponseModels} key={index}>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id={`present${index}`}
                      name={`present`}
                      value={index}
                      checked={state?.workExpResponseModels?.[index].present === index + 1}
                      onChange={() => {
                        let temp = [...state?.workExpResponseModels];
                        temp = temp.map((e) => ({ ...e, present: 0 }));
                        temp[index].present = index + 1;
                        temp[index].endDate = null;
                        setState({
                          ...state,
                          workExpResponseModels: temp,
                        });
                      }}
                    />
                    <label htmlFor={`present${index}`} style={{ fontSize: "1rem", color: "#000000" }}>
                      Presently working here
                    </label> {" "}
                    {state?.workExpResponseModels?.[index].present ===
                      index + 1 && (
                        <span
                          onClick={() => {
                            let temp = [
                              ...(state && state.workExpResponseModels
                                ? state.workExpResponseModels
                                : []
                              )
                            ];
                            temp = temp.map((e) => ({ ...e, present: 0 }));
                            temp[index].present = 0;
                            temp[index].presentlyWorking = false;
                            setPresentWorkIndex(0);
                            temp[index].endDate = null;
                            setState({
                              ...state,
                              workExpResponseModels: temp,
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              let temp = [
                                ...(state && state.workExpResponseModels
                                  ? state.workExpResponseModels
                                  : []),
                              ];
                              temp = temp.map((e) => ({ ...e, present: 0 }));
                              temp[index].present = 0;
                              temp[index].presentlyWorking = false;
                              setPresentWorkIndex(0);
                              temp[index].endDate = null;
                              setState({
                                ...state,
                                workExpResponseModels: temp,
                              });
                            }
                          }}
                          style={{
                            fontSize: "13px",
                            color: "red",
                            cursor: "pointer",
                          }}
                        >
                          <GrPowerReset />
                          &nbsp;Reset
                        </span>
                      )
                    }
                  </div>
                  {state?.workExpResponseModels?.length > 1 && (
                    <button
                      className={style.btn_remove1}
                      onClick={() => {
                        setState({
                          ...state,
                          workExpResponseModels: state?.workExpResponseModels?.filter((_, i) => i !== index),
                        });
                      }}
                    >
                      ❌
                    </button>
                  )}
                  <div className={style.Cu_row1}>
                    <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                      <input
                        className={style.bigInput}
                        onChange={(e) => {
                          let temp = [...state?.workExpResponseModels];
                          temp[index].companyName = e?.target?.value;
                          setState({ ...state, workExpResponseModels: temp });
                        }}
                        value={state?.workExpResponseModels[index]?.companyName}
                      />
                    </div>
                    <div className={style.cu_col2_to} style={{ flex: "0 0 23%" }}>
                      <div className={style.multiinput}>
                        <div>
                          <div className={style.cu_field23} style={{ marginTop: "0.25rem" }}>
                            <DatePicker
                              placeholder="MM YYYY"
                              peekNextMonth
                              showMonthYearPicker
                              selected={
                                state?.workExpResponseModels[index]?.startDate
                                  ? moment(state?.workExpResponseModels[index]?.startDate).toDate()
                                  : null
                              }
                              onChange={(date) => handleDateChange(date, index, 'start')}
                              dateFormat="MMM yyyy"
                              onKeyDown={(e) => e.preventDefault()}
                              clearButton
                              customInput={<CustomInputClgWXS index={index} />}
                            />
                          </div>
                          <p
                            className={
                              jobValidate &&
                                state?.workExpResponseModels[index]?.present === index + 1 &&
                                !state?.workExpResponseModels[index]?.startDate
                                ? style.errorText
                                : style.errorTextHidden
                            }
                          >
                            Please Enter Start Date
                          </p>
                        </div>
                        <div>
                          <div
                            className={style.cu_field23}
                            style={
                              state?.workExpResponseModels[index]?.present === index + 1 || state.workExpResponseModels[index]?.presentlyWorking
                                ? {
                                  marginTop: "0.25rem",
                                  backgroundColor: "#b2a6a66b",
                                  flex: "0 0 8%",
                                }
                                : { marginTop: "0.25rem", flex: "0 0 5%" }
                            }
                          >
                            <DatePicker
                              placeholder="DD MM YYYY"
                              peekNextMonth
                              showMonthYearPicker
                              selected={
                                state?.workExpResponseModels[index]?.endDate
                                  ? moment(state?.workExpResponseModels[index]?.endDate).toDate()
                                  : null
                              }
                              onChange={(date) => handleDateChange(date, index, 'end')}
                              dateFormat="MMM yyyy"
                              onKeyDown={(e) => e.preventDefault()}
                              customInput={<CustomInputClgWXEND index={index} />}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={style.cu_col3} style={{ flex: "0 0 23%" }}>
                      <input
                        className={style.bigInput}
                        onChange={(e) => {
                          let temp = [...state?.workExpResponseModels];
                          temp[index].role = e?.target?.value;
                          setState({ ...state, workExpResponseModels: temp });
                        }}
                        value={state?.workExpResponseModels[index]?.role}
                      />
                      <p
                        className={
                          jobValidate &&
                            state?.workExpResponseModels[index]?.present === index + 1 &&
                            !state?.workExpResponseModels[index]?.role
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please Enter Role
                      </p>
                    </div>
                    <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                      <textarea
                        className={style.bigInput}
                        onChange={(e) => {
                          let temp = [...state?.workExpResponseModels];
                          temp[index].reasonForChange = e?.target?.value;
                          setState({ ...state, workExpResponseModels: temp });
                        }}
                        value={state?.workExpResponseModels[index]?.reasonForChange}
                      />
                      <p
                        className={
                          jobValidate &&
                            state?.workExpResponseModels[index]?.present === index + 1 &&
                            !state?.workExpResponseModels[index]?.reasonForChange
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please Enter Reason for Change
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className={style.workExpButton} style={{ marginTop: "1.3rem", gap: "1rem" }}>
              <button
                className={style.btn_add}
                onClick={() => {
                  setState({
                    ...state,
                    workExpResponseModels: [
                      ...state?.workExpResponseModels,
                      {
                        companyName: "",
                        reasonForChange: "",
                        role: "",
                        endDate: null,
                        startDate: null,
                        present: 0,
                      },
                    ],
                  });
                }}
              >
                Add
              </button>
              <button
                className={style.btn_add}
                onClick={() =>
                  state?.personalEmail
                    ? experienceMutation.mutate({
                      emailId: state?.personalEmail,
                    })
                    : notify1("Please Enter Personal Email")
                }
              >
                Autofill
              </button>
            </div>
          </div>
          <div className={style.createposition}>
            <p className={style.title}>Academics</p>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1} style={{ flex: "0 0 26%" }}>
                <p className={style.cu_label}>Degree</p>
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 30%" }}>
                <p className={style.cu_label}>Board of Education/University</p>
              </div>
              <div className={style.cu_col2_to} style={{ flex: "0 0 10%" }}>
                <p className={style.cu_label}>Passing Year</p>
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 27%" }}>
                <p className={style.cu_label}>Grade </p>
              </div>
            </div>
            {state?.educationResponseModels?.map((item, index) => {
              return (
                <div
                  className={style.candidateAcademics}
                  style={{ marginBottom: "1.6rem" }}
                >
                  {acadamicsArray?.length >= 1 && (
                    <button
                      className={style.btn_remove}
                      style={{ top: "-33%" }}
                      onClick={() =>
                      // remove last value from array
                      {
                        let temp = [...state?.educationResponseModels];
                        temp.splice(index, 1);
                        setState((prevData) => ({
                          ...prevData,
                          educationResponseModels: temp,
                        }));
                        acadamicsArray.splice(index, 1);
                      }
                      }
                    >
                      ❌
                    </button>
                  )}
                  <div className={style.Cu_row1}>
                    <div className={style.cu_col1} style={{ flex: "0 0 26%" }}>
                      <CreatableSelect
                        styles={customStyles}
                        classNamePrefix={"create_position"}
                        options={getAllDegreesOption}
                        value={typeof state?.educationResponseModels[index]?.degree == 'string' ? getAllDegreesOption?.find((item) => item.label == state?.educationResponseModels[index]?.degree) : state?.educationResponseModels[index]?.degree}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => {
                          let temp = [...state?.educationResponseModels];
                          temp[index].degree = e.label;
                          temp[index].degreeId = e.value;
                          setState({
                            ...state,
                            educationResponseModels: temp,
                          });
                          let indexs = getAllDegreesOption.some(
                            (item) => item.value === e.value
                          );

                          !indexs && mutationDegree.mutate({ degree: e.label });
                        }}
                        formatCreateLabel={(userInput) =>
                          `+ Add "${userInput}"`
                        }
                      ></CreatableSelect>
                    </div>
                    <div
                      className={style.cu_col3}
                      style={{ flex: "0 0 30%", marginTop: "0.3rem" }}
                    >
                      <input
                        name="boardEdu"
                        className={style.bigInput}
                        onChange={(e) => {
                          let temp = [...state?.educationResponseModels];
                          temp[index].board = e?.target?.value;
                          setState({
                            ...state,
                            educationResponseModels: temp,
                          });
                        }}
                        value={state?.educationResponseModels[index]?.board}
                      />
                    </div>
                    <div
                      className={style.cu_col2_ac}
                      style={{ flex: "0 0 10%" }}
                    >
                      <div>
                        <div
                          className={style.cu_fieldAC}
                          style={{ marginTop: "0.5rem" }}
                        >
                          <DatePicker
                            placeholder="YYYY"
                            selected={
                              state?.educationResponseModels[index]?.passingYear
                                ? moment(
                                  String(
                                    state?.educationResponseModels[index]
                                      ?.passingYear
                                  )
                                ).toDate()
                                : null
                            }
                            dateFormat="yyyy"
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            // showYearPicker
                            // showYearDropdown
                            showYearPicker
                            dropdownMode="select"
                            onChange={(date) => {
                              let temp = [...state?.educationResponseModels];
                              temp[index].passingYear =
                                moment(date).format("YYYY");
                              setState({
                                ...state,
                                educationResponseModels: temp,
                              });
                            }}
                            customInput={<CustomInputClgPY index={index} />}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={style.cu_col3}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flex: "0 0 27%",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <Select
                          styles={customStyles}
                          classNamePrefix={"create_position"}
                          options={[
                            { value: 1, label: "CGPA" },
                            { value: 2, label: "Percentage" },
                          ]}
                          components={{
                            DropdownIndicator: () => CustomInput(),
                            IndicatorSeparator: () => null,
                          }}
                          value={{ label: state?.educationResponseModels[index].gradeStatus, value: getGradeStatusVal(state?.educationResponseModels[index].gradeStatus) }}
                          onChange={(e) => {
                            let temp = [...state?.educationResponseModels];

                            temp[index].gradeStatus = e.label;
                            setState({
                              ...state,
                              educationResponseModels: temp,
                            });
                          }}
                        ></Select>
                      </div>
                      {state?.educationResponseModels[index]?.gradeStatus ===
                        "CGPA" && (
                          <div style={{ width: "40%" }}>
                            <input
                              name="grade"
                              type="number"
                              min={0}
                              max={10}
                              style={{ marginTop: "0.5rem" }}
                              value={state?.educationResponseModels[index]?.grade}
                              onChange={(e) => {
                                let temp = [...state?.educationResponseModels];
                                if (e?.target?.value >= 0 && e?.target?.value <= 10) {
                                  temp[index].grade = parseFloat(e?.target?.value);
                                }
                                temp[index].rank = null;
                                setState({
                                  ...state,
                                  educationResponseModels: temp,
                                });
                              }}
                              className={style.cu_field233}
                              onKeyDown={handleKeyDown}
                              onClick={handleInputClick}
                            />
                            <p
                              className={
                                state?.educationResponseModels[index]?.grade > 10
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                              style={{ fontSize: "10px" }}
                            >
                              CGPA should be less than 10
                            </p>
                          </div>
                        )}
                      {state?.educationResponseModels[index]?.gradeStatus ===
                        "Percentage" && (
                          <div style={{ width: "40%" }}>
                            <input
                              name="grade"
                              type="number"
                              min={0}
                              max={100}
                              style={{ marginTop: "0.5rem" }}
                              value={state?.educationResponseModels[index]?.grade}
                              onChange={(e) => {
                                let temp = [...state?.educationResponseModels];
                                if (e?.target?.value >= 0 && e?.target?.value <= 100) {
                                  temp[index].grade = parseFloat(e?.target?.value);
                                }
                                temp[index].rank = null;
                                setState({
                                  ...state,
                                  educationResponseModels: temp,
                                });
                              }}
                              className={style.cu_field233}
                              onKeyDown={handleKeyDown}
                              onClick={handleInputClick}
                            />
                            <p
                              className={
                                state?.educationResponseModels[index]?.grade > 100
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                              style={{ fontSize: "10px" }}
                            >
                              % should be less than 100
                            </p>
                          </div>
                        )}
                    </div>
                  </div>

                  {/* Second */}
                  {/* <div className={style.Cu_row1}>
                    <div className={style.cu_col1}></div>
                    <div className={style.cu_col2}></div>
                  </div> */}
                </div>
              );
            })}
            <div className={style.workExpButton} style={{ gap: "1rem" }}>
              <button
                className={style.btn_add}
                onClick={
                  () => {
                    setAcadamicsArray([
                      ...acadamicsArray,
                      Math.floor(Math.random() * 100),
                    ]);
                    setState({
                      ...state,
                      educationResponseModels: [
                        ...state?.educationResponseModels,
                        {
                          degree: "",
                          grade: null,
                          board: "",
                        },
                      ],
                    });
                  }
                  // push new value 2 in array
                }
              >
                Add
              </button>
              <button
                className={style.btn_add}
                onClick={() =>
                  state?.personalEmail
                    ? academicsMutation.mutate({
                      emailId: state?.personalEmail,
                    })
                    : notify1("Please Enter Personal Email")
                }
              >
                Autofill
              </button>
            </div>
          </div>
          {/* Salary Details */}
          <div className={style.createposition}>
            <p className={style.title}>Salary Breakup</p>
            <p
              className={style.cu_label}
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="checkbox"
                id="unpaidCheckbox"
                name="checkbox"
                value="1"
                checked={unpaid}
                onChange={(e) => handleUnpaidChange()}
                style={{
                  visibility: "visible",
                  height: "20px",
                  width: "25px",
                  cursor: "pointer",
                  accentColor: "#289e98"
                }}
              />
              <label
                for="unpaidCheckbox"
                className={style.cu_label}
                style={{ color: "black" }}
              >
                Is Unpaid
              </label>
            </p>

            {!unpaid && (<>
              <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>Effective Date<span style={{ color: "red" }}>*</span>{" "}</p>
                  <div className={style.csd} style={{ marginTop: "10px", marginLeft: "0px" }}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      value={
                        state?.salaryComponentsEffectiveFrom &&
                        Moment(state?.salaryComponentsEffectiveFrom).format("D MMM YY")
                      }
                      onChange={(date) => handleDateAndTime(date, "effectiveDate")}
                      disabledKeyboardNavigation
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      defaultValue={null}
                      customInput={
                        state?.salaryComponentsEffectiveFrom ? (
                          <CustomInputCross properties="salaryComponentsEffectiveFrom" />
                        ) : (
                          <CustomInputClg />
                        )
                      }
                      style={{ backgroundColor: "red" }}
                    />
                  </div>
                  <p
                    className={
                      validate &&
                        !state?.salaryComponentsEffectiveFrom
                        ? style.errorText
                        : style.errorTextHidden2
                    }
                  >
                    Please Select Effective Date
                  </p>
                </div>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>
                    Basic<span style={{ color: "red" }}>*</span>{" "}
                  </p>
                  <div className={style.cu_row2_col2}>
                    <input
                      className={style.cu_field2}
                      type="text"
                      value={state?.basic}
                      onChange={(e) =>
                        setState({
                          ...state,
                          basic: e.target.value ? parseFloat(e.target.value) : 0,
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                  </div>
                  <p
                    className={
                      validate &&
                        (state?.basic === null || state?.basic === undefined)
                        ? style.errorText
                        : style.errorTextHidden2
                    }
                  >
                    Please Enter Basic
                  </p>
                </div>
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>
                    HRA<span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    className={style.cu_field2}
                    type="text"
                    value={state?.hra}
                    onChange={(e) =>
                      setState({
                        ...state,
                        hra: e.target.value ? parseFloat(e.target.value) : 0,
                      })
                    }
                    onKeyDown={(e) => {
                      if (e.keyCode === 38 || e.keyCode === 40) {
                        e.preventDefault();
                      }
                    }}
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57) &&
                      e.preventDefault()
                    }
                  ></input>
                  <p
                    className={
                      validate &&
                        (state?.hra === null || state?.hra === undefined)
                        ? style.errorText
                        : style.errorTextHidden2
                    }
                  >
                    Please Enter HRA
                  </p>
                </div>
              </div>
              <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
                <div className={style.cu_col3}>
                  <p className={style.cu_label}>
                    OA<span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    className={style.cu_field2}
                    type="text"
                    value={state?.oa}
                    onChange={(e) =>
                      setState({
                        ...state,
                        oa: e.target.value ? parseFloat(e.target.value) : 0,
                      })
                    }
                    onKeyDown={(e) => {
                      if (e.keyCode === 38 || e.keyCode === 40) {
                        e.preventDefault();
                      }
                    }}
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57) &&
                      e.preventDefault()
                    }
                  ></input>
                  <p
                    className={
                      validate && (state?.oa === null || state?.oa === undefined)
                        ? style.errorText
                        : style.errorTextHidden2
                    }
                  >
                    Please Enter OA
                  </p>
                </div>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>
                    Conveyance<span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    className={style.cu_field2}
                    type="text"
                    value={state?.conveyance}
                    onChange={(e) =>
                      setState({
                        ...state,
                        conveyance: e.target.value
                          ? parseFloat(e.target.value)
                          : 0,
                      })
                    }
                    onKeyDown={(e) => {
                      if (e.keyCode === 38 || e.keyCode === 40) {
                        e.preventDefault();
                      }
                    }}
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57) &&
                      e.preventDefault()
                    }
                  ></input>
                  <p
                    className={
                      validate &&
                        (state?.conveyance === null ||
                          state?.conveyance === undefined)
                        ? style.errorText
                        : style.errorTextHidden2
                    }
                  >
                    Please Enter Conveyance
                  </p>
                </div>
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>
                    {/* Bonus<span style={{ color: "red" }}>*</span>{" "} */}
                    Bonus
                  </p>
                  <div className={style.cu_row2_col2}>
                    <input
                      className={style.cu_field2}
                      type="text"
                      value={state?.bonus}
                      onChange={(e) =>
                        setState({
                          ...state,
                          bonus: e.target.value ? parseFloat(e.target.value) : 0,
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                  </div>
                  {/* <p
                  className={
                    validate &&
                    (state?.bonus === null || state?.bonus === undefined)
                      ? style.errorText
                      : style.errorTextHidden2
                  }
                >
                  Please Enter Bonus
                </p> */}
                </div>
              </div>
              <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
                <div className={style.cu_col3}>
                  <p className={style.cu_label}>Gross Salary</p>
                  <input
                    readOnly={true}
                    disabled={true}
                    className={`${style.cu_field2} ${style.readonly}`}
                    type="text"
                    value={state?.grossSalary}
                    onChange={(e) =>
                      setState({
                        ...state,
                        grossSalary: state?.basic
                          ? parseFloat(state?.basic)
                          : 0 + state?.hra
                            ? parseFloat(state?.hra)
                            : 0 + state?.oa
                              ? parseFloat(state?.oa)
                              : 0 + state?.conveyance
                                ? parseFloat(state?.conveyance)
                                : 0 + state?.bonus
                                  ? parseFloat(state?.bonus)
                                  : 0,
                      })
                    }
                    onKeyDown={(e) => {
                      if (e.keyCode === 38 || e.keyCode === 40) {
                        e.preventDefault();
                      }
                    }}
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57) &&
                      e.preventDefault()
                    }
                  ></input>
                </div>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>
                    {/* Employer PF<span style={{ color: "red" }}>*</span> */}
                    Employer PF
                  </p>
                  <input
                    className={`${style.cu_field2}`}
                    type="text"
                    value={state?.employerPF}
                    onChange={(e) =>
                      setState({
                        ...state,
                        employerPF: e.target.value
                          ? parseFloat(e.target.value)
                          : 0,
                      })
                    }
                    onKeyDown={(e) => {
                      if (e.keyCode === 38 || e.keyCode === 40) {
                        e.preventDefault();
                      }
                    }}
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57) &&
                      e.preventDefault()
                    }
                  ></input>
                  {/* <p
                  className={
                    validate &&
                    (state?.employerPF === null ||
                      state?.employerPF === undefined)
                      ? style.errorText
                      : style.errorTextHidden2
                  }
                >
                  Please Enter Employer PF
                </p> */}
                </div>
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>
                    {/* Employee PF<span style={{ color: "red" }}>*</span>{" "} */}
                    Employee PF
                  </p>
                  <div className={style.cu_row2_col2}>
                    <input
                      className={style.cu_field2}
                      type="text"
                      value={state?.employeePF}
                      onChange={(e) =>
                        setState({
                          ...state,
                          employeePF: e.target.value
                            ? parseFloat(e.target.value)
                            : 0,
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                  </div>
                  {/* <p
                  className={
                    validate &&
                    (state?.employeePF === null ||
                      state?.employeePF === undefined)
                      ? style.errorText
                      : style.errorTextHidden2
                  }
                >
                  Please Enter Employee PF
                </p> */}
                </div>
              </div>
              <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>

                <div className={style.cu_col2}>
                  <p className={style.cu_label}>Gratuity</p>
                  <input
                    className={style.cu_field2}
                    type="text"
                    value={state?.gratuity}
                    // onChange={(e) =>
                    //   setState({
                    //     ...state,
                    //     gratuity: e.target.value ? parseFloat(e.target.value) : 0,
                    //   })
                    // }
                    onChange={(e) =>
                      setState({
                        ...state,
                        gratuity: e.target.value && Number(e.target.value),
                        currentMonthlyFullCTC:
                          Number(state?.salary || 0) +
                          Number(state?.sa1 || 0) +
                          Number(state?.sa2 || 0) +
                          Number(e.target.value) +
                          Number(state?.incentives || 0) +
                          Number(state?.variableBonus || 0) +
                          Number(state?.joiningBonus || 0) +
                          Number(state?.retentionBonus || 0) +
                          Number(state?.others || 0) + 
                          Number(state?.insurance || 0),
                        currentYearlyFullCTC:
                          (Number(state?.salary || 0) +
                            Number(state?.sa1 || 0) +
                            Number(state?.sa2 || 0) +
                            Number(e.target.value) +
                            Number(state?.incentives || 0) +
                            Number(state?.variableBonus || 0) +
                            Number(state?.joiningBonus || 0) +
                            Number(state?.retentionBonus || 0) +
                            Number(state?.others || 0) + 
                            Number(state?.insurance || 0)) *
                          12,
                      })
                    }
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57) &&
                      e.preventDefault()
                    }
                    onKeyDown={handleKeyDown}
                  ></input>
                </div>
              </div>
              <div
                style={{
                  marginTop: "1.5rem",
                  borderBottom: "2px solid rgba(8, 8, 8, 0.1)",
                }}
              ></div>
              <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>SA1</p>
                  <input
                    className={style.cu_field2}
                    type="number"
                    value={state?.sa1}
                    onChange={(e) => {
                      console.log(" ------ ---- >.>>>>>> ", state?.salary,  Number(state?.salary || 0) ,
                      Number(e.target.value) ,
                      Number(state?.sa2) ,
                      Number(state?.incentives) ,
                      Number(state?.variableBonus) ,
                      Number(state?.joiningBonus) ,
                      Number(state?.retentionBonus) ,
                      Number(state?.others), " ------ " , Number(state?.salary) +
                      Number(e.target.value) +
                      Number(state?.sa2) +
                      Number(state?.incentives) +
                      Number(state?.variableBonus) +
                      Number(state?.joiningBonus) +
                      Number(state?.retentionBonus) +
                      Number(state?.others),)
                      setState({
                        ...state,
                        sa1: e.target.value && Number(e.target.value),
                        currentMonthlyFullCTC:
                          Number(state?.salary || 0) +
                          Number(e.target.value) +
                          Number(state?.sa2 || 0) +
                          Number(state?.incentives  || 0) +
                          Number(state?.variableBonus || 0) +
                          Number(state?.joiningBonus || 0) +
                          Number(state?.retentionBonus  || 0) +
                          Number(state?.others || 0) + 
                          Number(state?.insurance || 0) + 
                          Number(state?.gratuity || 0) ,
                        currentYearlyFullCTC:
                          (Number(state?.salary || 0) +
                            Number(e.target.value) +
                            Number(state?.sa2 || 0) +
                            Number(state?.incentives || 0) +
                            Number(state?.variableBonus || 0) +
                            Number(state?.joiningBonus || 0) +
                            Number(state?.retentionBonus || 0) +
                            Number(state?.others || 0) + 
                            Number(state?.insurance || 0) + 
                            Number(state?.gratuity || 0) ) *
                          12,
                      })
                    }
                    }
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57) &&
                      e.preventDefault()
                    }
                    onKeyDown={handleKeyDown}
                    onClick={handleInputClick}
                  ></input>
                </div>
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>SA2</p>
                  <input
                    className={style.cu_field2}
                    type="number"
                    value={state?.sa2}
                    onChange={(e) =>
                      setState({
                        ...state,
                        sa2: e.target.value && Number(e.target.value),
                        currentMonthlyFullCTC:
                          Number(state?.salary || 0) +
                          Number(state?.sa1 || 0) +
                          Number(e.target.value) +
                          Number(state?.incentives || 0) +
                          Number(state?.variableBonus || 0) +
                          Number(state?.joiningBonus || 0) +
                          Number(state?.retentionBonus || 0) +
                          Number(state?.others || 0) + 
                          Number(state?.insurance || 0) + 
                          Number(state?.gratuity || 0) ,
                        currentYearlyFullCTC:
                          (Number(state?.salary || 0) +
                            Number(state?.sa1 || 0) +
                            Number(e.target.value) +
                            Number(state?.incentives || 0) +
                            Number(state?.variableBonus || 0) +
                            Number(state?.joiningBonus || 0) +
                            Number(state?.retentionBonus || 0) +
                            Number(state?.others || 0) + 
                            Number(state?.insurance || 0) + 
                            Number(state?.gratuity || 0) ) *
                          12,
                      })
                    }
                    onKeyDown={handleKeyDown}
                    onClick={handleInputClick}
                  ></input>
                </div>
                <div className={style.cu_col3}>
                  <p className={style.cu_label}>Incentives</p>
                  <div className={style.cu_row2_col2}>
                    <input
                      className={style.cu_field2}
                      type="number"
                      value={state?.incentives}
                      onChange={(e) =>
                        setState({
                          ...state,
                          incentives: e.target.value && Number(e.target.value),
                          currentMonthlyFullCTC:
                            Number(state?.salary || 0) +
                            Number(state?.sa1 || 0) +
                            Number(state?.sa2 || 0) +
                            Number(e.target.value) +
                            Number(state?.variableBonus || 0) +
                            Number(state?.joiningBonus || 0) +
                            Number(state?.retentionBonus || 0) +
                            Number(state?.others || 0) + 
                            Number(state?.insurance || 0) + 
                            Number(state?.gratuity || 0) ,
                          currentYearlyFullCTC:
                            (Number(state?.salary || 0) +
                              Number(state?.sa1 || 0) +
                              Number(state?.sa2 || 0) +
                              Number(e.target.value) +
                              Number(state?.variableBonus || 0) +
                              Number(state?.joiningBonus || 0) +
                              Number(state?.retentionBonus || 0) +
                              Number(state?.others || 0) + 
                              Number(state?.insurance || 0) + 
                              Number(state?.gratuity || 0) ) *
                            12,
                        })
                      }
                      onKeyDown={handleKeyDown}
                      onClick={handleInputClick}
                    ></input>
                  </div>
                </div>
              </div>
              <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>Variable Bonus</p>
                  <input
                    className={style.cu_field2}
                    type="number"
                    value={state?.variableBonus}
                    onChange={(e) =>
                      setState({
                        ...state,
                        variableBonus: e.target.value && Number(e.target.value),
                        currentMonthlyFullCTC:
                          Number(state?.salary || 0) +
                          Number(state?.sa1 || 0) +
                          Number(state?.sa2 || 0) +
                          Number(state?.incentives || 0) +
                          Number(e.target.value) +
                          Number(state?.joiningBonus || 0) +
                          Number(state?.retentionBonus || 0) +
                          Number(state?.others || 0) + Number(state?.insurance || 0) + Number(state?.gratuity || 0),
                        currentYearlyFullCTC:
                          (Number(state?.salary || 0) +
                            Number(state?.sa1 || 0) +
                            Number(state?.sa2 || 0) +
                            Number(state?.incentives || 0) +
                            Number(e.target.value) +
                            Number(state?.joiningBonus || 0) +
                            Number(state?.retentionBonus || 0) +
                            Number(state?.others || 0)+ Number(state?.insurance || 0) + Number(state?.gratuity || 0)) *
                          12,
                      })
                    }
                    onKeyDown={handleKeyDown}
                    onClick={handleInputClick}
                  ></input>
                </div>
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>Joining Bonus</p>
                  <input
                    className={style.cu_field2}
                    type="number"
                    value={state?.joiningBonus}
                    onChange={(e) =>
                      setState({
                        ...state,
                        joiningBonus: e.target.value && Number(e.target.value),
                        currentMonthlyFullCTC:
                          Number(state?.salary || 0) +
                          Number(state?.sa1 || 0) +
                          Number(state?.sa2 || 0) +
                          Number(state?.incentives || 0) +
                          Number(state?.variableBonus || 0) +
                          Number(e.target.value) +
                          Number(state?.retentionBonus || 0) +
                          Number(state?.others || 0) + Number(state?.insurance || 0) + Number(state?.gratuity || 0),
                        currentYearlyFullCTC:
                          (Number(state?.salary || 0) +
                            Number(state?.sa1 || 0) +
                            Number(state?.sa2 || 0) +
                            Number(state?.incentives || 0) +
                            Number(state?.variableBonus || 0) +
                            Number(e.target.value) +
                            Number(state?.retentionBonus || 0) +
                            Number(state?.others || 0)+ Number(state?.insurance || 0) + Number(state?.gratuity || 0)) *
                          12,
                      })
                    }
                    onKeyDown={handleKeyDown}
                    onClick={handleInputClick}
                  ></input>
                </div>
                <div className={style.cu_col3}>
                  <p className={style.cu_label}>Retention Bonus</p>
                  <div className={style.cu_row2_col2}>
                    <input
                      className={style.cu_field2}
                      type="number"
                      value={state?.retentionBonus}
                      onChange={(e) =>
                        setState({
                          ...state,
                          retentionBonus:
                            e.target.value && Number(e.target.value),
                          currentMonthlyFullCTC:
                            Number(state?.salary || 0) +
                            Number(state?.sa1 || 0) +
                            Number(state?.sa2 || 0) +
                            Number(state?.incentives || 0) +
                            Number(state?.variableBonus || 0) +
                            Number(state?.joiningBonus || 0) +
                            Number(e.target.value) +
                            Number(state?.others || 0) + Number(state?.insurance || 0) + Number(state?.gratuity || 0) ,
                          currentYearlyFullCTC:
                            (Number(state?.salary || 0) +
                              Number(state?.sa1 || 0) +
                              Number(state?.sa2 || 0) +
                              Number(state?.incentives || 0) +
                              Number(state?.variableBonus || 0) +
                              Number(state?.joiningBonus || 0) +
                              Number(e.target.value) +
                              Number(state?.others || 0)+ Number(state?.insurance || 0) + Number(state?.gratuity || 0)) *
                            12,
                        })
                      }
                      onKeyDown={handleKeyDown}
                      onClick={handleInputClick}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                  </div>
                </div>
              </div>
              <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>Others</p>
                  <input
                    className={style.cu_field2}
                    type="number"
                    value={state?.others}
                    onKeyDown={handleKeyDown}
                    onClick={handleInputClick}
                    onChange={(e) =>
                      setState({
                        ...state,
                        others: e.target.value && Number(e.target.value),
                        currentMonthlyFullCTC:
                          Number(state?.salary  || 0) +
                          Number(state?.sa1 || 0) +
                          Number(state?.sa2 || 0) +
                          Number(state?.incentives || 0) +
                          Number(state?.variableBonus || 0) +
                          Number(state?.joiningBonus || 0) +
                          Number(state?.retentionBonus || 0) + Number(state?.insurance || 0) + Number(state?.gratuity || 0) +
                          Number(e.target.value),
                        currentYearlyFullCTC:
                          (Number(state?.salary || 0) +
                            Number(state?.sa1 || 0) +
                            Number(state?.sa2 || 0) +
                            Number(state?.incentives || 0) +
                            Number(state?.variableBonus || 0) +
                            Number(state?.joiningBonus || 0) +
                            Number(state?.retentionBonus || 0) +
                            Number(e.target.value) + Number(state?.insurance || 0) + Number(state?.gratuity || 0)) *
                          12,
                      })
                    }

                  ></input>
                </div>
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>Insurance</p>
                  <input
                    className={style.cu_field2}
                    type="number"
                    value={state?.insurance}
                    onChange={(e) => {
                      console.log(" ------ ---- >.>>>>>> ", state?.salary,  Number(state?.salary || 0) ,
                      Number(e.target.value) ,
                      Number(state?.sa1),
                      Number(state?.sa2),
                      Number(state?.incentives) ,
                      Number(state?.variableBonus) ,
                      Number(state?.joiningBonus) ,
                      Number(state?.retentionBonus) ,
                      Number(state?.others), " ------ " , Number(state?.salary) +
                      Number(e.target.value) +
                      Number(state?.sa1),
                      Number(state?.sa2) +
                      Number(state?.incentives) +
                      Number(state?.variableBonus) +
                      Number(state?.joiningBonus) +
                      Number(state?.retentionBonus) +
                      Number(state?.others),)
                      setState({
                        ...state,
                        insurance: e.target.value && Number(e.target.value),
                        currentMonthlyFullCTC:
                          Number(state?.salary || 0) +
                          Number(e.target.value) +
                          Number(state?.sa1 || 0) +
                          Number(state?.sa2 || 0) +
                          Number(state?.incentives  || 0) +
                          Number(state?.variableBonus || 0) +
                          Number(state?.joiningBonus || 0) +
                          Number(state?.retentionBonus  || 0) +
                          Number(state?.others || 0) +
                          Number(state?.gratuity || 0),
                        currentYearlyFullCTC:
                          (Number(state?.salary || 0) +
                            Number(e.target.value) +
                            Number(state?.sa1 || 0) +
                            Number(state?.sa2 || 0) +
                            Number(state?.incentives || 0) +
                            Number(state?.variableBonus || 0) +
                            Number(state?.joiningBonus || 0) +
                            Number(state?.retentionBonus || 0) +
                            Number(state?.others || 0) +
                            Number(state?.gratuity || 0)) *
                          12,
                      })
                    }
                    }
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57) &&
                      e.preventDefault()
                    }
                    onKeyDown={handleKeyDown}
                    onClick={handleInputClick}
                  ></input>
                </div>
                <div className={style.cu_col3} style={{ visibility: 'hidden' }}>
                  <p className={style.cu_label}>Insurance</p>
                  <input
                    className={style.cu_field2}
                    type="number"
                    value={state?.insurance}
                    onChange={(e) => {
                      console.log(" ------ ---- >.>>>>>> ", state?.salary,  Number(state?.salary || 0) ,
                      Number(e.target.value) ,
                      Number(state?.sa1),
                      Number(state?.sa2),
                      Number(state?.incentives) ,
                      Number(state?.variableBonus) ,
                      Number(state?.joiningBonus) ,
                      Number(state?.retentionBonus) ,
                      Number(state?.others), " ------ " , Number(state?.salary) +
                      Number(e.target.value) +
                      Number(state?.sa1),
                      Number(state?.sa2) +
                      Number(state?.incentives) +
                      Number(state?.variableBonus) +
                      Number(state?.joiningBonus) +
                      Number(state?.retentionBonus) +
                      Number(state?.others),)
                      setState({
                        ...state,
                        insurance: e.target.value && Number(e.target.value),
                        currentMonthlyFullCTC:
                          Number(state?.salary || 0) +
                          Number(e.target.value) +
                          Number(state?.sa1 || 0) +
                          Number(state?.sa2 || 0) +
                          Number(state?.incentives  || 0) +
                          Number(state?.variableBonus || 0) +
                          Number(state?.joiningBonus || 0) +
                          Number(state?.retentionBonus  || 0) +
                          Number(state?.others || 0) + Number(state?.gratuity),
                        currentYearlyFullCTC:
                          (Number(state?.salary || 0) +
                            Number(e.target.value) +
                            Number(state?.sa1 || 0) +
                            Number(state?.sa2 || 0) +
                            Number(state?.incentives || 0) +
                            Number(state?.variableBonus || 0) +
                            Number(state?.joiningBonus || 0) +
                            Number(state?.retentionBonus || 0) +
                            Number(state?.others || 0) + Number(state?.gratuity)) *
                          12,
                      })
                    }
                    }
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57) &&
                      e.preventDefault()
                    }
                    onKeyDown={handleKeyDown}
                    onClick={handleInputClick}
                  ></input>
                </div>
                {/* <div className={style.cu_col3} style={{ visibility: 'hidden' }}>
                  <p className={style.cu_label}>Current Monthly Core CTC</p>
                <input
                  className={style.cu_field2}
                  type="number"
                  value={state?.currentMonthlyCoreCTC}
                  onKeyDown={(e) => {
                    if (e.keyCode === 38 || e.keyCode === 40) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    setState({
                      ...state,
                      currentMonthlyCoreCTC: Number(e.target.value),
                    })
                  }
                ></input>
                  <p className={style.cu_label}>Current Yearly Core CTC</p>
                  <div className={style.cu_row2_col2}>
                    <input
                      className={`${style.cu_field2} ${style.readonly}`}
                      type="number"
                      value={state?.currentYearlyCoreCTC}
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setState({
                          ...state,
                          currentYearlyCoreCTC:
                            e.target.value && Number(e.target.value),
                        })
                      }
                      readOnly
                    ></input>
                  </div>
                </div> */}
              </div>

              <div
                style={{
                  marginTop: "1.5rem",
                  borderBottom: "2px solid rgba(8, 8, 8, 0.1)",
                }}
              ></div>
              <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>Current Monthly Core CTC</p>
                  <div className={style.cu_row2_col2}>
                    <input
                      readOnly
                      className={`${style.cu_field2} ${style.readonly}`}
                      type="number"
                      value={state?.salary}
                      onChange={(e) => setSalaryRangeId(Number(e.target.value))}
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                  </div>
                  <p
                    className={
                      validate && !state?.salary
                        ? style.errorText
                        : style.errorTextHidden2
                    }
                  >
                    Fixed Salary cannot be 0.
                  </p>
                </div>
                <div className={style.cu_col2}>
                  {/* <p className={style.cu_label}>Current Monthly Core CTC</p>
                <input
                  className={style.cu_field2}
                  type="number"
                  value={state?.currentMonthlyCoreCTC}
                  onKeyDown={(e) => {
                    if (e.keyCode === 38 || e.keyCode === 40) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    setState({
                      ...state,
                      currentMonthlyCoreCTC: Number(e.target.value),
                    })
                  }
                ></input> */}
                  <p className={style.cu_label}>Current Yearly Core CTC</p>
                  <div className={style.cu_row2_col2}>
                    <input
                      className={`${style.cu_field2} ${style.readonly}`}
                      type="number"
                      value={state?.currentYearlyCoreCTC}
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setState({
                          ...state,
                          currentYearlyCoreCTC:
                            e.target.value && Number(e.target.value),
                        })
                      }
                      readOnly
                    ></input>
                  </div>
                </div>
                <div className={style.cu_col3}>
                  <p className={style.cu_label}>Current Monthly Full CTC</p>
                  <input
                    className={`${style.cu_field2} ${style.readonly}`}
                    type="number"
                    value={state?.currentMonthlyFullCTC}
                    onKeyDown={(e) => {
                      if (e.keyCode === 38 || e.keyCode === 40) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) =>
                      setState({
                        ...state,
                        currentMonthlyFullCTC:
                          e.target.value && Number(e.target.value),
                      })
                    }
                    readOnly
                  ></input>
                </div>
              </div>
              <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>Current Yearly Full CTC</p>
                  <input
                    className={`${style.cu_field2} ${style.readonly}`}
                    type="number"
                    value={state?.currentYearlyFullCTC}
                    onKeyDown={(e) => {
                      if (e.keyCode === 38 || e.keyCode === 40) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) =>
                      setState({
                        ...state,
                        currentYearlyFullCTC:
                          e.target.value && Number(e.target.value),
                      })
                    }
                    readOnly
                  ></input>
                </div>
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>
                    Salary (As per Project & Costing)
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style.cu_row2_col2}>
                    <input
                      className={style.cu_field2}
                      type="number"
                      value={state?.salaryAsProjectCosting}
                      onChange={(e) =>
                        setState({
                          ...state,
                          salaryAsProjectCosting:
                            e.target.value && Number(e.target.value),
                        })
                      }
                      onKeyDown={handleKeyDown}
                      onClick={handleInputClick}
                    ></input>
                  </div>
                  <p
                    className={
                      validate && !state?.salaryAsProjectCosting
                        ? style.errorText
                        : style.errorTextHidden2
                    }
                  >
                    Please Enter Salary ( As per Project & Costing)
                  </p>
                </div>
                <div className={style.cu_col3} style={{ visibility: "hidden" }}>
                  <p className={style.cu_label}>Joining Bonus</p>
                  <input
                    className={style.cu_field2}
                    type="number"
                    value={state?.joiningBonus}
                    onChange={(e) =>
                      setState({
                        ...state,
                        joiningBonus: e.target.value && Number(e.target.value),
                        currentMonthlyFullCTC:
                          Number(state?.salary) +
                          Number(state?.sa1) +
                          Number(state?.sa2) +
                          Number(state?.incentives) +
                          Number(state?.variableBonus) +
                          Number(e.target.value) +
                          Number(state?.retentionBonus) +
                          Number(state?.others) + Number(state?.insurance) + Number(state?.gratuity),
                        currentYearlyFullCTC:
                          (Number(state?.salary) +
                            Number(state?.sa1) +
                            Number(state?.sa2) +
                            Number(state?.incentives) +
                            Number(state?.variableBonus) +
                            Number(e.target.value) +
                            Number(state?.retentionBonus) +
                            Number(state?.others) + Number(state?.insurance) + Number(state?.gratuity)) *
                          12,
                      })
                    }
                    onKeyDown={(e) => {
                      if (e.keyCode === 38 || e.keyCode === 40) {
                        e.preventDefault();
                      }
                    }}
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57) &&
                      e.preventDefault()
                    }
                  ></input>
                </div>
              </div>
              {/* Notes */}
              <div className={style.Cu_notes} style={{ marginTop: "1rem" }}>
                <p className={style.cu_label}>Salary Note</p>
                <div>
                  <textarea
                    className={style.notes}
                    type="text"
                    value={state?.salaryNotes}
                    onChange={(e) =>
                      setState((prevData) => ({
                        ...prevData,
                        salaryNotes: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </>)}
          </div>
          <div className={style.cu_button} style={{ marginBottom: "1rem" }}>
            <button
              style={{
                padding: "0px 10px",
                background: "#289e98",
                color: "#fff",
                border: "1px solid #4fdf4f",
                height: "37px",
                width: "100px",
                // margin: "5px",
                // marginTop: "15px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push(path.ums_userDescription, {
                  id,
                  filterData,
                  currentPage,
                  filter,
                });
              }}
            >
              Cancel
            </button>
            <button
              style={
                disableSub
                  ? {
                    padding: "0px 10px",
                    background: "gray",
                    color: "#fff",
                    border: "gray",
                    height: "37px",
                    width: "100px",
                    // margin: "5px",
                    // marginTop: "15px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }
                  : {
                    padding: "0px 10px",
                    background: "#289e98",
                    color: "#fff",
                    border: "1px solid #4fdf4f",
                    height: "37px",
                    width: "100px",
                    // margin: "5px",
                    // marginTop: "15px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }
              }
              disabled={disableSub}
              onClick={updateClient}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateUser;
